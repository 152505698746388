import { AccountSetupStepTypePathName } from 'common'

export const LEDGER_INFO_STEP = 1
export const FUND_STEP = 2
export const SET_FLAGS_STEP = 3
export const TRUSTSET_STEP = 4
export const SIGNER_LIST_SET_STEP = 5
export const DISABLE_MASTER_STEP = 6
export const COMPLETING_STEP = 7
export const COMPLETED_STEP = 7

/**
 * A mapping between the account setup step path name and its number. The number will
 * be useful in the modal wizard to understand which step to display.
 */
export const STEP_PATH_NAME_TO_STEP_NUMBER_MAP: Record<
  AccountSetupStepTypePathName,
  number
> = {
  [AccountSetupStepTypePathName.LedgerInfo]: LEDGER_INFO_STEP,
  [AccountSetupStepTypePathName.Fund]: FUND_STEP,
  [AccountSetupStepTypePathName.SetFlags]: SET_FLAGS_STEP,
  [AccountSetupStepTypePathName.TrustSet]: TRUSTSET_STEP,
  [AccountSetupStepTypePathName.SignerListSet]: SIGNER_LIST_SET_STEP,
  [AccountSetupStepTypePathName.DisableMaster]: DISABLE_MASTER_STEP,
  [AccountSetupStepTypePathName.Completing]: COMPLETING_STEP,
  [AccountSetupStepTypePathName.Completed]: COMPLETED_STEP,
}

/** Status code returned by the 'next' endpoint if the account setup is completed */
export const SETUP_COMPLETED_STATUS_CODE = 301

export const DEFAULT_TICK_SIZE = 7
