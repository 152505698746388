import { AccountDropdownOption } from '@/shared/hooks'
import { FullUser } from 'common'
import { useMemo } from 'react'
import { useAccountsMetadata } from '../../../shared/api'

/**
 * Hook to get the accounts which can be added or removed to/from a user.
 *
 * The logic is based on the user permissions.
 * To add or remove an account, the required permission is EDIT_ACCOUNT. This is enforced in the backend:
 * - add: https://github.com/xpring-eng/cbdc-backend/blob/main/cbdc-server/src/main/java/io/ripplex/cbdc/web/controller/AccountEditController.java#L69
 * - remove: https://github.com/xpring-eng/cbdc-backend/blob/main/cbdc-server/src/main/java/io/ripplex/cbdc/web/controller/AccountEditController.java#L82
 *
 * So we can only propose in the UI dropdowns accounts where the user has the permission with the right type.
 *
 * @param fullUser
 * @returns
 */
export const useAllowedUserAccountOptions = (fullUser?: FullUser) => {
  if (!fullUser) return []

  const { data: accounts = [] } = useAccountsMetadata()

  return useMemo(() => {
    const userRolePermissions = fullUser.roles
      .map((role) => {
        return role.rolePermissions
      })
      .flat()

    return accounts.reduce((arr, account) => {
      const isAllowed = userRolePermissions.some((role) => {
        return role.cbdcAccountType === account.accountType
      })
      if (isAllowed) {
        const isAlreadyMember = fullUser.accounts.find(
          ({ cbdcAccountId }) => cbdcAccountId === account.cbdcAccountId,
        )
        if (!isAlreadyMember) {
          arr.push({
            value: account.cbdcAccountId,
            label: account.accountName,
            data: { accountType: account.accountType, balance: '' },
          })
        }
      }
      return arr
    }, [] as AccountDropdownOption[])
  }, [accounts, fullUser])
}
