import { Controls } from '@ripple/design-system'
import {
  AccountType,
  CbdcAccount,
  CbdcAccountMetadata,
  IssuerMetadata,
  PayAccount,
  RequestError,
  TenantWideCbdcAccountBalances,
  getRequestParamsFromControls,
} from 'common'
import { UseQueryOptions, useQuery } from 'react-query'
import {
  getAccountByAddress,
  getAccounts,
  getAccountsBalances,
  getAccountsMetadata,
  getIssuersMetadata,
  getPayAccounts,
} from './accounts.api'

export enum AccountsQueryKeys {
  Accounts = 'accounts',
  AccountDetails = 'account-details',
  AllMeta = 'accounts-metadata',
  Balances = 'accounts-balances',
  IssuersMeta = 'issuers-metadata',
  PayAccounts = 'pay-accounts',
  SingleMeta = 'account-meta',
}

/**
 * Retrieve metadata for all tenant accounts.
 * @returns An array of {@link CbdcAccountMetadata} objects.
 */
export const useAccountsMetadata = (
  opts?: UseQueryOptions<CbdcAccountMetadata[], RequestError>,
) =>
  useQuery<CbdcAccountMetadata[], RequestError>(
    AccountsQueryKeys.AllMeta,
    getAccountsMetadata,
    opts,
  )

type AccountMetaLookupObject = Record<string, CbdcAccountMetadata>

export const useAccountMetadataLookupObject = (
  opts?: UseQueryOptions<CbdcAccountMetadata[], RequestError>,
) => {
  const { data = [], ...rest } = useAccountsMetadata(opts)

  return {
    ...rest,
    accounts: data.reduce((obj, account) => {
      obj[account.cbdcAccountId] = account
      return obj
    }, {} as AccountMetaLookupObject),
  }
}

/**
 * Retrieve issuer accounts metadata.
 * @returns An array of {@link IssuerMetadata} objects.
 */
export const useIssuersMetadata = (
  opts?: UseQueryOptions<IssuerMetadata[], RequestError>,
) =>
  useQuery<IssuerMetadata[], RequestError>(
    AccountsQueryKeys.IssuersMeta,
    getIssuersMetadata,
    // this will return a 412 if no issuer account exists
    // react-query will retry min 3 times in that case,
    // but we know we only need to check once
    { retry: false, ...opts },
  )

/**
 * Retrieve account metadata for payment scenarios.
 * Only requires the user to have a `TRANSACT` permission for any account type.
 * @returns An array of {@link CbdcAccountMetadata} objects.
 */
export const usePayAccounts = (
  accountTypes: AccountType[],
  opts?: UseQueryOptions<PayAccount[], RequestError>,
) =>
  useQuery<PayAccount[], RequestError>(
    [AccountsQueryKeys.PayAccounts, ...accountTypes],
    () => getPayAccounts(accountTypes),
    opts,
  )

/**
 * Retrieve balances for all tenant CBDC accounts.
 * @returns A {@link TenantWideCbdcAccountBalances} object.
 */
export const useAccountsBalances = (
  opts?: UseQueryOptions<TenantWideCbdcAccountBalances, RequestError>,
) =>
  useQuery<TenantWideCbdcAccountBalances, RequestError>(
    AccountsQueryKeys.Balances,
    getAccountsBalances,
    opts,
  )

/**
 * Retrieve a complete account representation for all tenant accounts.
 * @returns An array of {@link CbdcAccount} objects.
 */
export const useAccounts = (
  controls?: Controls,
  opts?: UseQueryOptions<CbdcAccount[], RequestError>,
) => {
  const { queryKeyControls, requestControls } =
    getRequestParamsFromControls(controls)

  return useQuery<CbdcAccount[], RequestError>(
    [AccountsQueryKeys.Accounts, ...queryKeyControls],
    () => getAccounts(requestControls),
    opts,
  )
}

/**
 * Retrieve a CBDC account by its XRP address.
 *
 * @param {string} address The XRP address to look for.
 * @param opts The query options.
 * @returns A {@link CbdcAccount} object.
 */
export const useAccountByAddress = (
  address: string,
  opts?: UseQueryOptions<CbdcAccount, RequestError>,
) =>
  useQuery<CbdcAccount, RequestError>(
    [AccountsQueryKeys.AccountDetails, address],
    () => getAccountByAddress(address),
    opts,
  )
