import { AxiosError } from 'axios'
import {
  AccountLedgerInfo,
  AccountSetup,
  AccountSetupLedgerDetails,
  AccountSetupStepTypePathName,
  CbdcAccount,
  NewAccountSetup,
  PayloadSignature,
  RequestError,
} from 'common'
import { useMutation, useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { requestClient } from '../request-client'
import { ACCOUNT_SETUP_URL } from './account-setup.constants'
import { AccountSetupQueryKeys } from './account-setup.queries'

/**
 * Hook to create an account setup.
 * This hook should be used once the admin creates a new account.
 * It saves some account' details into the database before this information is saved in the XRP Ledger (and so before the admin signs the setup transactions).
 *
 * @returns void
 */
export const useCreateAccountSetup = () => {
  return useMutation<unknown, RequestError, NewAccountSetup>(
    (newAccountSetup) =>
      requestClient.put(`${ACCOUNT_SETUP_URL}/${uuidv4()}`, newAccountSetup),
  )
}

type AccountSetupLedgerDetailsPayload = AccountSetupLedgerDetails &
  Pick<CbdcAccount, 'cbdcAccountId'>

type UpdateAccountSetupLedgerDetailsResponse = {
  accountLedgerInfo: AccountLedgerInfo | undefined
  statusCode: number | undefined
}
/**
 * Hook to update the Ledger Nano details (public key) of an Account
 *
 * @returns An {@link AccountLedgerInfo} object.
 */
export const useUpdateAccountSetupLedgerDetails = () => {
  return useMutation<
    UpdateAccountSetupLedgerDetailsResponse,
    RequestError,
    AccountSetupLedgerDetailsPayload
  >(async (payload) => {
    try {
      const response = await requestClient.put(
        `${ACCOUNT_SETUP_URL}/${payload.cbdcAccountId}/ledger-info`,
        {
          publicKey: payload.publicKey.toUpperCase(),
        },
      )
      return {
        accountLedgerInfo: response.data,
        statusCode: response.status,
      }
    } catch (err) {
      const error = err as Error | AxiosError
      if ('response' in error) {
        return {
          accountLedgerInfo: undefined,
          statusCode: error.response?.status,
        }
      }
      throw error
    }
  })
}

type AccountSetupSignStepPayload = Pick<CbdcAccount, 'cbdcAccountId'> &
  PayloadSignature & {
    pathName: AccountSetupStepTypePathName
  }

/**
 * Submit the signature using this hook.
 *
 * After calling 'next' (ie useAccountSetupNextStep) and getting a step where processingStatus is 'COMPLETE',
 * you can then prompt the user to sign the transaction.
 * It will result in the WorkItem being processed via the reliable submission service.
 * Once this takes place, you should begin polling 'next' again (ie useAccountSetupNextStep) to get information as to whether the current step
 * is done and/or what the next step should be and/or whether the next step is ready for user interaction.
 *
 * @returns An {@link AccountLedgerInfo} object.
 */
export const useAccountSetupSignStep = () => {
  return useMutation<
    AccountLedgerInfo,
    RequestError,
    AccountSetupSignStepPayload
  >(({ cbdcAccountId, pathName, signature }) =>
    requestClient.put(
      `${ACCOUNT_SETUP_URL}/${cbdcAccountId}/${pathName}/signature`,
      { signature },
    ),
  )
}

type FaucetFundingRequest = Pick<AccountLedgerInfo, 'address'>

/**
 * Funds an account with the faucet.
 * This mustn't be used in production. The back-end knows if the faucet is enabled or not.
 *
 * @param {string} address The address to fund.
 * @returns void
 */
export const useAccountSetupFundWithFaucet = () => {
  return useMutation<unknown, RequestError, FaucetFundingRequest>((body) =>
    requestClient.post(`${ACCOUNT_SETUP_URL}/fund`, body),
  )
}

/**
 * Delete an account setup.
 *
 * @param {string} accountId The account setup id.
 * @param {UseQueryOptions} opts The query options.
 * @returns
 */
export const useDeleteAccountSetup = () => {
  const queryClient = useQueryClient()
  return useMutation<AccountSetup, RequestError, { accountId: string }>(
    ({ accountId }) =>
      requestClient.delete(`${ACCOUNT_SETUP_URL}/${accountId}`),
    {
      onSuccess: (_, { accountId }) => {
        queryClient.setQueryData<AccountSetup[]>(
          [AccountSetupQueryKeys.AcctSetups],
          (accountSetups = []) =>
            accountSetups?.filter(
              (accountSetup) => accountSetup.accountId !== accountId,
            ),
        )
      },
    },
  )
}
