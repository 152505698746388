import { ENTRY, LOGIN, LOGOUT, UNAUTHORIZED } from '../constants'
import { getCbdcServerUrl } from './env'

const PREVIOUS_LOCATION_KEY = 'previousLocation'

export const redirectTo = {
  notFound: () => window.location.replace(`/not-found`),
  unauthorized: () => window.location.replace(`/unauthorized`),
  oAuthLogout: () => window.location.replace(`${getCbdcServerUrl()}/logout`),
  oAuthLogin: () =>
    window.location.replace(`${getCbdcServerUrl()}/oauth/auth0/authorize`),
}

const FORGET_PATHS = () => [LOGOUT, UNAUTHORIZED, ENTRY, LOGIN]

const rememberWhereWeWere = (path: string) => {
  const shouldIgnorePath = FORGET_PATHS().find((p) => path.includes(p))
  if (!shouldIgnorePath) {
    sessionStorage.setItem(PREVIOUS_LOCATION_KEY, path)
  }
}

export const recallWhereWeWere = () =>
  sessionStorage.getItem(PREVIOUS_LOCATION_KEY)

const forgetWhereWeWere = () => {
  sessionStorage.removeItem(PREVIOUS_LOCATION_KEY)
}

export const logoutViaOAuth = (opts?: { remember?: true }) => {
  opts?.remember
    ? rememberWhereWeWere(window.location.pathname)
    : forgetWhereWeWere()
  redirectTo.oAuthLogout()
}
