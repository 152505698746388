import { sortWorkItemsOnCreatedAt } from '@/shared/helpers'
import { useWorkItemDetailsGetter } from '@/shared/hooks'
import { Controls } from '@ripple/design-system'
import {
  PageType,
  RequestError,
  WorkItem,
  WorkItemDetailsType,
  WorkItemStatus,
  WorkItemType,
  getAsyncGetterQueryOptions,
  getPageSchema,
  getPaginatedQueryOpts,
  getRequestParamsFromControls,
  safeParseApiResponse,
  workItemSchema,
  workItemTypes,
} from 'common'
import { UseQueryOptions, useQuery } from 'react-query'
import { FreezeQueryKeys } from '../accounts'
import { requestClient } from '../request-client'

export enum WorkItemQueryKeys {
  AccountTransactions = 'account-transactions',
  SingleItem = 'single-work-item',
  Transactions = 'work-items',
}

const allNonSetupTxnTypes = [
  WorkItemType.AuthorizeTrustLine,
  WorkItemType.SignerListSet,
  ...workItemTypes.transactions,
  ...workItemTypes.freezes.all,
]

export type UseWorkItemsProps = {
  cbdcAccountId?: string
  destinationAccountId?: string
  includeAllTypes?: boolean
  includeFreezes?: boolean
  signerUserId?: string
  sourceAccountId?: string
  status?: WorkItemStatus | WorkItemStatus[]
  type?: WorkItemType | WorkItemType[]
  baseQueryKey:
    | FreezeQueryKeys.FreezeHistory
    | WorkItemQueryKeys.Transactions
    | WorkItemQueryKeys.AccountTransactions
}

export const useWorkItems = (
  {
    type,
    status,
    signerUserId,
    baseQueryKey,
    cbdcAccountId,
    includeFreezes,
    includeAllTypes,
    sourceAccountId,
    destinationAccountId,
  }: UseWorkItemsProps,
  controls?: Controls,
  opts?: UseQueryOptions<PageType<WorkItemDetailsType[]>, RequestError>,
) => {
  const getWorkItemDetails = useWorkItemDetailsGetter()
  const { queryKeyControls, requestControls } =
    getRequestParamsFromControls(controls)

  const accountId = cbdcAccountId ?? destinationAccountId ?? sourceAccountId
  const statusStr = Array.isArray(status) ? status.join(',') : status
  const typeStr = type
    ? Array.isArray(type)
      ? type.join(',')
      : type
    : (includeAllTypes
        ? allNonSetupTxnTypes
        : includeFreezes
        ? [...workItemTypes.transactions, ...workItemTypes.freezes.all]
        : workItemTypes.transactions
      ).join(',')

  const optionalParamsStr = JSON.stringify({
    signerUserId,
    status: statusStr,
    type: typeStr,
  })

  return useQuery<PageType<WorkItemDetailsType[]>, RequestError>(
    accountId
      ? [baseQueryKey, accountId, ...queryKeyControls, optionalParamsStr]
      : [baseQueryKey, ...queryKeyControls, optionalParamsStr],
    () =>
      requestClient
        .get('/work-items', {
          params: {
            ...requestControls,
            status: statusStr,
            cbdcAccountId,
            destinationAccountId,
            signerUserId,
            sourceAccountId,
            type: typeStr,
          },
        })
        .then(safeParseApiResponse(getPageSchema(workItemSchema.array(), true)))
        .then((response) => ({
          ...response,
          content: response.content
            .map((tx: WorkItem) => getWorkItemDetails.get(tx, cbdcAccountId))
            .sort(sortWorkItemsOnCreatedAt),
        })),
    getPaginatedQueryOpts(opts, getWorkItemDetails),
  )
}

export const useWorkItem = (
  workItemId: string,
  opts?: UseQueryOptions<WorkItemDetailsType, RequestError>,
) => {
  const getWorkItemDetails = useWorkItemDetailsGetter()

  return useQuery<WorkItemDetailsType, RequestError>(
    [WorkItemQueryKeys.SingleItem, workItemId],
    () =>
      requestClient
        .get(`/work-items/${workItemId}`)
        .then(safeParseApiResponse(workItemSchema))
        .then(getWorkItemDetails.get),
    getAsyncGetterQueryOptions(opts, getWorkItemDetails),
  )
}
