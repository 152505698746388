import {
  ACCOUNTS,
  ACCOUNT_EDIT_TXN,
  ACCOUNT_QUEUED_TRANSACTIONS,
  EXTERNAL_ACCOUNTS,
  MY_TASKS,
  PAYMENTS,
  REDEMPTIONS,
  ROLES,
  SECURITY,
  TRANSACTION,
  USERS,
} from '@/shared/constants/paths'
import { RouteProps } from 'react-router-dom'
import { AccountCreatePage, AccountEditPage, AccountPage } from '../account'
import { AccountsPage } from '../accounts'
import { ExternalAccountPage, ExternalAccountsPage } from '../external-accounts'
import { MyTasksPage } from '../my-tasks'
import { PaymentsPage } from '../payments'
import { RoleCreatePage, RoleEditPage, RoleReadPage } from '../role'
import { RolesPage } from '../roles'
import { SecurityPage } from '../security'
import {
  AccountEditTxnPage,
  QueuedTransactionsPage,
  RedemptionPaymentPage,
  TransactionPage,
} from '../transaction'
import { CreateUserPage, UserReadPage, UsersPage } from '../users'

type PrivateRouteConfig = RouteProps & {
  element: React.ComponentType
  withXRPL?: boolean
}

const privateRoutes: PrivateRouteConfig[] = [
  {
    path: USERS.root,
    element: UsersPage,
  },
  {
    path: USERS.create,
    element: CreateUserPage,
  },
  {
    // indicates it renders descendant routes
    path: `${USERS.overview}/*`,
    element: UserReadPage,
  },
  {
    path: EXTERNAL_ACCOUNTS.root,
    element: ExternalAccountsPage,
  },
  {
    path: `${EXTERNAL_ACCOUNTS.account}/*`,
    element: ExternalAccountPage,
  },
  {
    path: `${ACCOUNTS.root}/*`,
    element: AccountsPage,
  },
  {
    path: ACCOUNTS.create,
    element: AccountCreatePage,
  },
  {
    path: `${ACCOUNTS.account}/*`,
    element: AccountPage,
  },
  {
    path: ACCOUNTS.edit,
    element: AccountEditPage,
  },
  {
    path: SECURITY,
    element: SecurityPage,
  },
  {
    path: ACCOUNT_EDIT_TXN,
    element: AccountEditTxnPage,
  },
  {
    path: PAYMENTS,
    element: PaymentsPage,
  },
  {
    path: TRANSACTION,
    element: TransactionPage,
  },
  {
    path: ACCOUNT_QUEUED_TRANSACTIONS,
    element: QueuedTransactionsPage,
  },
  {
    path: REDEMPTIONS.payment,
    element: RedemptionPaymentPage,
    withXRPL: true,
  },
  {
    path: ROLES.root,
    element: RolesPage,
  },
  {
    path: ROLES.overview,
    element: RoleReadPage,
  },
  {
    // indicates it renders descendant routes
    path: `${ROLES.overview}/*`,
    element: RoleReadPage,
  },
  {
    path: ROLES.create,
    element: RoleCreatePage,
  },
  {
    path: ROLES.edit,
    element: RoleEditPage,
  },
  {
    path: MY_TASKS,
    element: MyTasksPage,
  },
]

export { privateRoutes }
