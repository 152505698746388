import {
  SignerStateChange,
  SignerStateChangeSigner,
  SignerStateChangeSignerUpdates,
  SignerStateChangeWithUpdates,
} from 'common'

/**
 * A function to set all the SignerStateChangeSignerUpdates' fields to true or false.
 *
 * @param {boolean} updated A boolean value
 * @returns A {@link SignerStateChangeSignerUpdates} object.
 */
const setAllSignerFieldsUpdate = (
  updated: boolean,
): SignerStateChangeSignerUpdates => {
  return {
    address: updated,
    keyPairName: updated,
    publicKey: updated,
    signerWeight: updated,
    keyPairId: updated,
    user: updated,
  }
}

/**
 * A helper to compare the previous and proposed signer information (name, public key, address...).
 *
 * @param {SignerStateChangeSigner} previous An object containing the previous signer details (name, address, weight...)
 * @param {SignerStateChangeSigner} proposed An object containing the proposed signer details (name, address, weight...)
 * @returns A {@link SignerStateChangeSignerUpdates} object
 */
const comparePreviousAndProposedSigner = (
  previous: SignerStateChangeSigner,
  proposed: SignerStateChangeSigner,
): SignerStateChangeSignerUpdates => {
  const keys = Object.keys(previous)

  return keys.reduce((obj, key) => {
    if (key === 'user') {
      return {
        ...obj,
        [key]: previous.user.userId !== proposed.user.userId,
      }
    }

    return {
      ...obj,
      [key]:
        previous[key as keyof SignerStateChangeSigner] !==
        proposed[key as keyof SignerStateChangeSigner],
    }
  }, {} as SignerStateChangeSignerUpdates)
}

/**
 * A function to return a SignerStateChange object WITH an "updates" object which indicates if each field has changed or not.
 * That is very useful to then highlight in the UI the fields which have changed.
 *
 * @example
 * {
 *      id: "13e76904-5d45-5429-8918-3e0301e2ed20",
 *      previous: { name: "person 1", address: "r123" ... },
 *      proposed: { name: "person 1", address: "r897" ... },
 *      ,
 *      updates: {
 *          name: false,
 *          address: true,
 *          ...
 *      }
 * }
 * @param {SignerStateChange[]} signers An array of all the signers with the previous and proposed information.
 * @returns An array of {@link SignerStateChangeWithUpdates} object.
 */
export const getSignersWithUpdatedFields = (
  signers: SignerStateChange[],
): SignerStateChangeWithUpdates[] => {
  return signers.map((signer) => {
    if (!signer.previous || !signer.proposed) {
      return {
        ...signer,
        updates: setAllSignerFieldsUpdate(true),
      }
    }

    return {
      ...signer,
      updates: comparePreviousAndProposedSigner(
        signer.previous,
        signer.proposed,
      ),
    }
  })
}

/**
 * Helper to place the elements of a SignerStateChange array at the beginning of the array if they have
 * both 'previous' and 'proposed' properties.
 *
 * @param {SignerStateChange[]} arr A SignerStateChange array.
 * @returns A new array where the elements with 'previous' and 'proposed' are at the beginning.
 */
export const placePreviousAndProposedFirst = (arr: SignerStateChange[]) => {
  // Array with both 'previous' and 'proposed' properties
  const arrWithBoth = arr.filter((el) => {
    return el.proposed && el.previous
  })

  // Sort the array containing both proposed and previous by the user first name.
  const sortedArrWithBoth = arrWithBoth.sort((signerA, signerB) => {
    if (signerA.previous && signerB.previous) {
      const { firstName: firstNameA } = signerA.previous.user
      const { firstName: firstNameB } = signerB.previous.user

      if (firstNameA > firstNameB) return 1
      if (firstNameA < firstNameB) return -1
    }
    return 0
  })

  // Array with either 'proposed' or 'previous'
  const arrWithOnlyOne = arr.filter((el) => {
    return !el.proposed || !el.previous
  })

  return sortedArrWithBoth.concat(arrWithOnlyOne)
}
