import { AccountLedgerInfo } from 'common'

/**
 * Find an account ledger info by address.
 *
 * @param {AccountLedgerInfo[]} accountLedgerInfos A list of account ledger info.
 * @param {string} address The address to find in the list.
 * @returns An {@link AccountLedgerInfo}
 */
export const getAccountLedgerInfoByAddress = (
  accountLedgerInfos: AccountLedgerInfo[],
  address: string,
) => {
  return accountLedgerInfos.find(
    (accountLedgerInfo) => accountLedgerInfo.address === address,
  )
}
