import { useAccounts } from '@/shared/api'
import { CbdcAccount, CbdcAccountMetadata, RequestError } from 'common'
import { useMemo } from 'react'
import { UseQueryOptions } from 'react-query'

type SignerLookup = Record<string, CbdcAccountMetadata[]>

/**
 * Hook that creates a map <SigningKeyPairId, CbdcAccountMetadata>.
 *
 * Useful to know if a user keypair is used for signing on one or more accounts.
 *
 * @example
 * {
 *  "59ee2a96-8c24-4ebb-a1g1-a78407ce2981": [ {accountName: '', ...}, { ... } ]
 * }
 */
export const useAccountsSigningKeypairsLookup = (
  opts?: UseQueryOptions<CbdcAccount[], RequestError>,
) => {
  const { data: accounts = [], ...rest } = useAccounts(undefined, opts)

  const signingKeypairsLookup = useMemo(() => {
    const newLookup: SignerLookup = accounts.reduce(
      (lookup: SignerLookup, account) => {
        account.signers.forEach((signer) => {
          const { keyPairId } = signer

          if (keyPairId) {
            if (!lookup[keyPairId]) {
              lookup[keyPairId] = []
            }

            lookup[keyPairId].push({ ...account })
          }
        })

        return lookup
      },
      {},
    )

    return newLookup
  }, [accounts])

  return {
    signingKeypairsLookup,
    ...rest,
  }
}
