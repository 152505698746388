export default {
  Keypairs: 'Pares de Claves',
  'Keypair-Name': 'Nombre de Par de Claves',
  'Keypair-Type': 'Tipo de Par de Claves',
  'Edit-User-Details': 'Cambiar Datos de Usuario',
  'Add-Accounts': 'Añadir Cuentas',
  'Add-Account': 'Añadir Cuenta',
  'Add-Keypair': 'Añadir Par de Claves',
  'Public-Key': 'Llave pública',
  'Account-Lookup': 'Buscar Cuenta',
  'App-User': 'Usuario de la aplicación',
  Signing: 'Firma',
  'machine-user': 'Usuario de la máquina',
  'machine-user.initiator': 'Usuario de la máquina - iniciador',
  'machine-user.signer': 'Usuario de la máquina - firmante',
  'machine-user.description': 'Un usuario de la máquina tiene la capacidad de acceder de forma segura al sistema backend del Emisor mediante el uso de una clave API secreta única.',
  'machine-user.can-sign': 'Puede firmar para transacciones',
  'machine-user.cannot-sign': 'No puedo firmar para transacciones',
  'New-User': 'Nuevo Usuario',
  'User-Details': 'Datos de Usuario',
  'User-Type': 'Tipo de usuario',
  'Users-Added': 'Usuarios añadidos',
  'Users-Removed': 'Usuarios eliminados',
  'User-List': 'Lista de Usuarios',
  'deactivate-keypair.title': 'Desactivar Par de Claves?',
  'deactivate-keypair.confirmation': '¿Estás seguro de que quieres desactivar este par de claves? Esta acción no podra ser revertida.',
  'deactivate-keypair.success': 'Par de claves desactivado con éxito',
  'deactivate-keypair.error': 'Error al desactivar el par de claves',
  'deactivate-keypair.forbidden.title': 'No puede desactivar este par de claves',
  'deactivate-keypair.forbidden.body': 'Este par de claves se utiliza para firmar transacciones en $t(plurals:accounts, {"count": {{count}}}). Para desactivar este par de claves, retíralo de las $t(plurals:accounts, {"count": {{count}}}) asociadas: {{names}}',
  Role: 'Rol',
  'Key-Type': 'Tipo de clave',
  'machine-user.api-key-card.api-credentials': 'Credenciales API',
  'machine-user.api-key-card.api-secret-key': 'Clave secreta API',
  'machine-user.api-key-card.view-secret-key': 'Ver clave',
  'machine-user.api-key-card.regenerate-secret-key': 'Regenerar clave',
  'machine-user.api-key-card.copy-secret-key': 'Copiar clave',
  'machine-user.api-key-card.generating-secret-key': 'Tu clave secreta se está generando...',
  'machine-user.api-key-card.copy-secret-key-instructions': 'Asegúrese de copiar la clave secreta a continuación, ya que solo se puede ver una vez.',
  'machine-user.api-key-card.copy-secret-key-description': 'Si perdió u olvidó esta clave, puede regenerarla a continuación. Tenga en cuenta que cualquier script o aplicación que utilice esta clave deberá actualizarse.',
  'machine-user.api-key-card.key-generated-on': 'Clave generada el {{date}}',
  'machine-user.api-key-card.key-viewed-on': 'Clave vista el {{date}}',
  'machine-user.regenerate-key-modal.title': '¿Regenerar clave?',
  'machine-user.regenerate-key-modal.message': 'Una vez que regenere su clave secreta API, la clave anterior ya no funcionará para autenticar a su usuario.',
  'machine-user.regenerate-key-modal.error': 'Se produjo un error al regenerar la clave API',
  'machine-user.delete-user.message': 'Si desactiva este usuario, la clave secreta ya no funcionará para autenticarse.',
  'machine-user.delete-user-with-accounts.message': 'Si desactiva este usuario, la clave secreta ya no funcionará para autenticarse y el usuario será eliminado de <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>.',
  'Signer-Accounts': 'Cuentas Firmantes',
  'Total-Accounts': 'Total Cuentas',
  'loading-users': 'Cargando Usuarios...',
  'loading-user': 'Cargando Usuario...',
  'no-users': 'No se han encontrado usuarios',
  'no-user': 'No se ha encontrado un usuario con id: {{userId}}',
  'keypairs.description': 'Este usuario tiene un rol que requiere uno o más pares de claves para firmar transacciones.',
  'keypairs.placeholder': 'Disponible tras la Creación del Usuario',
  'keypairs.creating': 'Generando dirección',
  'create-keypairs.duplicate': 'Este par de claves ya existe. Por favor, inténtelo con una nueva clave.',
  'create-keypairs.error': 'Se ha producido un error creando el par de claves. Por favor inténtelo de nuevo.',
  'connect-ledger-nano.error': 'No se ha poodido comunicar con su dispositivo Ledger',
  'connect-ledger-nano.found': 'Cuenta disponible {{ledgerName}} obtenida',
  'connect-ledger-nano.loading': 'Conectando a Ledger Nano',
  'connect-ledger-nano.description': 'Para finalizar la configuración de la cuenta, conecte su Ledger Nano.',
  'update-role.signer-error': 'Este usuario es firmante en {{count}} $t(plurals:accounts, count). Para cambiar su rol, debe antes eliminarlos como firmantes en todas las cuentas.',
  'You-Cannot-Change-Their-Role': 'Usted no puede cambiar su Rol',
  'add-user-account.instructions': 'Busque y seleccione la cuenta(s) a las que desee añadir {{user}}, y para las cuales {{user}} tiene los permisos correctos.',
  'add-user-account.restrictions': 'La búsqueda de cuentas solo mostrará las cuentas que el usuario puede ver.',
  'add-user-account.success': '{{numAdded}} $t(plurals:accounts, {"count": {{numAdded}}}) añadidas corectamente.',
  'remove-user-account.title': 'Elimine {{numAccounts}} $t(plurals:Accounts, {"count": {{numAccounts}}})?',
  'remove-user-account.prompt': '¿Está seguro que desea eliminar {{user}} de $t(plurals:accounts, {"count": {{count}}}): {{accounts}}?',
  'remove-user-account.success': '{{numRemoved}} $t(plurals:accounts, {"count": {{numRemoved}}}) eliminadas correctamente',
  About: 'Datos',
  'Full-Name': 'Nombre Completo',
  'User-Lookup': 'Búsqueda Usuario',
  'Date-Created': 'Fecha Creación',
  'Create-User': 'Crear Usuario',
  'create-user.name-already-used': 'Nombre ya en uso',
  'create-user.email-already-used': 'Email ya en uso',
  'create-user.machine-user.bad-public-key': 'La clave pública debe estar en formato PEM',
  'create-user.error': 'Se ha producido un error al crear el usuario',
  'create-user.success': 'Usuario creado correctamente',
  'create-keypair.error': 'Error creando $t(plurals:keypairs, {"count": {{count}}})',
  'create-keypair.success': '$t(plurals:keypairs, {"count": {{count}}}) añadidas correctamente',
  'rename-keypair.wrong-id': 'Identificador de par de claves incorrecto {{oldItemIndex}}. El par de claves no ha podido ser renombrado.',
  'add-account-users.instructions': 'Busque y seleccione el/los usuarios que desea añadir a esta cuenta.',
  'add-account-users.success': 'Se han añadido {{count}} usuarios a la cuenta.',
  'add-account-users.error': 'Se ha producido un error al añadir usuarios.',
  'edit-user.instructions': 'Use los campos a continuación para editar el nombre del usuario.',
  'edit-user-role.instructions': 'Busque y seleccione el nuevo rol que desea asignar a este usuario.',
  'edit-user-role.warning': "<Strong>Este rol eliminará al usuario de $t(plurals:accounts, {'count' :{{totalImpactedAccounts}}}).</Strong> Si selecciona esta función y se la asigna al usuario, se eliminará de todas las cuentas que estén en conflicto con los permisos de la nueva función.",
  'edit-user-role.missing-keypair': '<Strong>Este rol requiere un par de claves.</Strong> El rol seleccionado tiene habilitado el permiso "puede firmar". Si selecciona esta función y se la asigna al usuario, deberá asignarle un par de claves antes de que pueda ser seleccionado como firmante en una cuenta.',
  'edit-user-role.success': 'Rol actualizado correctamente',
  'Change-User-Role': 'Cambiar Rol de Usuario',
  'edit-user-role.error': 'El rol no pudo actualizarse',
  'rename-keypair.title': 'Renombrar Par de Claves',
  'rename-keypair.instructions': 'Para renombrar ese par de claves, introduzca un nuevo nombre.',
  'add-keypair.success': 'Par de Claves añadido correctamente',
  'add-keypair.instructions': 'Agregue un par de claves para que este usuario firme transacciones.',
  'update-user-info.error': 'Hubo un error al actualizar la información del usuario.',
  'update-user-info.success': 'Usuario actualizado correctamente.',
  'deactivate-user.cta': 'Desactivar usuario',
  'deactivate-user.allowed': '¿Desactivar usuario?',
  'deactivate-user.confirm': '¿Estás seguro de que quieres desactivar este usuario? Esta acción no podra ser revertida.',
  'deactivate-user.will-lose-accounts': 'Si desactivas a este usuario, se eliminará de <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>. No puede deshacer esta acción.',
  'deactivate-user.denied': 'No puedes desactivar a este usuario',
  'deactivate-user.configured-signer-warning': 'Para desactivar este usuario, primero debe eliminarlo como firmante en <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>.',
  'deactivate-user.unconfigured-signer-warning': 'Para desactivar este usuario, primero debe eliminarlo como firmante en <Strong>$t(plurals:Accounts, {"count": {{numSetups}}}) configuración pendiente</Strong>.',
  'deactivate-user.configured-unconfigured-signer-warning': 'Para desactivar este usuario, primero debe eliminarlo como firmante en <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong> y <Strong>$t(plurals:Accounts , {"count": {{numSetups}}}) configuración pendiente</Strong>.',
  'deactivate-user.error': 'Algo salió mal al desactivar al usuario. Inténtalo de nuevo.',
  'deactivate-user.success': 'El usuario fue desactivado con éxito.',
  'cannot-deactivate-admin': 'No Puedes Desactivarte A Ti Mismo',
  'deactivate-admin-message-one': 'Usted es el único usuario administrador del sistema y no se puede desactivar.',
  'deactivate-admin-message-two': 'Para desactivar este administrador, primero debe crear otro administrador.',
  'no-active-keypair': 'Este usuario no tiene un par de claves activo. Se les debe asignar uno antes de poder seleccionarlos como firmantes de una cuenta.',
  'public-key-banner.title': 'Debes ingresar una clave pública.',
  'machine-user.public-key-card.title': 'Clave pública API',
  'machine-user.public-key-card.view-api-key.title': 'Ver clave pública API',
  'machine-user.public-key-card.edit-api-key.title': 'Editar clave pública API',
  'machine-user.public-key-card.edit-api-key.error': 'Hubo un error al actualizar la clave pública',
  'machine-user.public-key-card.view-api-key.instructions': 'Pegue una copia de su clave pública a continuación. <Enlace>Más información</Enlace>',
  'machine-user.public-key-card.edit-api-key.label': 'Introduzca la clave aquí',
  'machine-user.public-key-card.edit-key': 'Editar clave',
  'machine-user.public-key-card.key-added-on': 'Clave agregada el {{date}}',
  'machine-user.public-key-card.key-updated-on': 'Clave actualizada el {{date}}',
}
