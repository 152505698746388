import { z } from 'zod'
/**
 * Response from the GET /account-setups/faucet endpoint.
 */

export const faucetStatusSchema = z.object({
  isEnabled: z.boolean(),
})

export type FaucetStatus = z.infer<typeof faucetStatusSchema>
