import {
  MachineUser,
  MachineUserApiKey,
  RequestError,
  invalidateQueryCache,
} from 'common'
import { useMutation, useQueryClient } from 'react-query'
import {
  MachineUserRequest,
  UpdateMachineUserPublicKeyRequest,
  createMachineUser,
  getMachineUserApiKey,
  regenerateMachineUserApiKey,
  updateMachineUserPublicKey,
} from './machine-users-api'
import { MachineUserQueryKeys } from './machine-users.queries'

export enum MachineUserMutationKeys {
  CreateUser = 'machine-user-create',
  ViewApiKey = 'machine-user-api-key',
  RegenerateApiKey = 'machine-user-regenerate-api-key',
  UpdatePublicKey = 'machine-user-update-public-key',
}

/**
 * API to create a machine user.
 * @returns A {@link MachineUser}
 */
export const useCreateMachineUser = () => {
  return useMutation<MachineUser, RequestError, MachineUserRequest>(
    createMachineUser,
    { mutationKey: MachineUserMutationKeys.CreateUser },
  )
}

export const useGetMachineUserApiKey = () => {
  const queryClient = useQueryClient()

  return useMutation<
    MachineUserApiKey,
    RequestError,
    Pick<MachineUserRequest, 'userId'>
  >(getMachineUserApiKey, {
    mutationKey: MachineUserMutationKeys.ViewApiKey,
    onSuccess: (_, { userId }) => {
      invalidateQueryCache(queryClient, [[MachineUserQueryKeys.Users, userId]])
    },
  })
}

export const useRegenerateMachineUserApiKey = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, RequestError, Pick<MachineUserRequest, 'userId'>>(
    regenerateMachineUserApiKey,
    {
      mutationKey: MachineUserMutationKeys.RegenerateApiKey,
      onSuccess: (_, { userId }) => {
        invalidateQueryCache(queryClient, [
          [MachineUserQueryKeys.Users, userId],
        ])
      },
    },
  )
}

export const useUpdateMachineUserPublicKey = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, RequestError, UpdateMachineUserPublicKeyRequest>(
    updateMachineUserPublicKey,
    {
      mutationKey: MachineUserMutationKeys.UpdatePublicKey,
      onSuccess: (_, { userId }) => {
        invalidateQueryCache(queryClient, [
          [MachineUserQueryKeys.Users, userId],
        ])
      },
    },
  )
}
