import { z } from 'zod'
import { AccountType } from '../accounts'
import { accountMetadataSchema } from '../cbdc-account-metadata'

export const userAccountMinSchema = z.object({
  cbdcAccountId: z.string(),
  accountName: z.string(),
  accountType: z.nativeEnum(AccountType),
  address: z.string(),
  // dateUserAdded: z.string(),
  isSigner: z.boolean(),
})

// minified user account object
export type UserAccountMin = z.infer<typeof userAccountMinSchema>

export const userAccountSchema = accountMetadataSchema.extend({
  accountType: z.nativeEnum(AccountType),
  signingKey: z.string(),
})

export type UserAccount = z.infer<typeof userAccountSchema>

export type AccountsMap = Record<string, UserAccountMin>
