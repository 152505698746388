/**
 * https://learnmeabitcoin.com/technical/derivation-paths
 * A derivation path for XRP in the Ledger Nano looks like this:
 * m/44'/144'/0'/0/0
 * Which represents the following:
 * m / purpose' / coin_type' / account' / change / index
 *
 * DerivationAccountType will define the account (after "coin_type").
 * The "account" level (after "coin_type") can be used to define different kind of accounts,
 * for example "savings", "treasury", etc.
 *
 * Here we will use it to make the distinction between an account used for:
 * - signing transactions
 * - issuer account
 * - standby accounts
 * - opeational accounts
 *
 * We will then find the addresses at different indexex. We can have more than 2 billion
 * addresses for each "account level", so:
 * m/44'/144'/0'/0/12403 for example to get an address at the index 12403.
 */
export enum DerivationAccountType {
  /** Path: m/44'/144'/0'/0/0 */
  SigningKeyPair,
  /** Path: m/44'/144'/1'/0/0 */
  Issuer,
  /** Path: m/44'/144'/2'/0/0 */
  Standby,
  /** Path: m/44'/144'/3'/0/0 */
  Operational,
  /** Path: m/44'/144'/4'/0/0 */
  Wallet,
}
