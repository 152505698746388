import {
  FreezeType,
  WorkItemStatus,
  WorkItemTransactionSignature,
  WorkItemTransactionSignatureStatus,
  WorkItemType,
  workItemStatuses,
  workItemTypes,
} from 'common'
import dayjs from 'dayjs'

/**
 * Get the summed weight of all completed signatures for a work item.
 * @param signatures An array of {@link WorkItemTransactionSignature}
 * @returns
 */
export const sumCompletedSignatures = (
  signatures: WorkItemTransactionSignature[],
) =>
  signatures.reduce((total, { signatureWeight, status }) => {
    if (status === WorkItemTransactionSignatureStatus.Signed) {
      return total + signatureWeight
    }
    return total
  }, 0)

type CastWorkItem = {
  createdAt: string
}

/**
 * Sort function for work items in list. This assumes objects derived
 * from work items, and only cares that there's a `createdAt` property.
 */
export function sortWorkItemsOnCreatedAt(a: CastWorkItem, b: CastWorkItem) {
  const aDate = dayjs(a.createdAt)
  if (aDate.isBefore(b.createdAt)) {
    return 1
  } else if (aDate.isAfter(b.createdAt)) {
    return -1
  }
  return 0
}

/**
 * Sort function for work item signatures, to sort by user name
 * to sensure the signer list stays consistent across api requests.
 */
export function sortSignaturesByUser(
  a: WorkItemTransactionSignature,
  b: WorkItemTransactionSignature,
) {
  if (a.id < b.id) {
    return 1
  } else if (a.id > b.id) {
    return -1
  }
  return 0
}

/**
 * Finds the correct WorkItemTransactionSignature by userId.
 *
 * @param {WorkItemTransactionSignature[]} txnSignatures A list of WorkItemTransactionSignature.
 * @param {string} userId The user id to find in the transaction signatures list.
 * @returns A {@link WorkItemTransactionSignature}.
 */
export const getWorkItemTransactionSignatureByUserId = (
  txnSignatures: WorkItemTransactionSignature[],
  userId?: string,
) => {
  if (!userId) return

  return txnSignatures.find((txnSignature) => txnSignature.userId === userId)
}

export const isPaymentWorkItem = (type: WorkItemType) =>
  workItemTypes.transactions.includes(type)

export const isDestroyWorkItem = (type?: WorkItemType) =>
  type === WorkItemType.Destroy

export const isMintWorkItem = (type?: WorkItemType) =>
  type === WorkItemType.Mint

export const isAuthTrustlineWorkItem = (type?: WorkItemType) =>
  type === WorkItemType.AuthorizeTrustLine

export const isAccountEditWorkItem = (type?: WorkItemType) =>
  type === WorkItemType.SignerListSet

export const isFreezeWorkItem = (type: WorkItemType): type is FreezeType =>
  workItemTypes.freezes.all.includes(type)

export const isAccountFreezeWorkItem = (type: WorkItemType) =>
  workItemTypes.freezes.account.freeze.includes(type)

export const isAccountUnfreezeWorkItem = (type: WorkItemType) =>
  workItemTypes.freezes.account.unfreeze.includes(type)

export const isGlobalFreezeWorkItem = (type: WorkItemType) =>
  workItemTypes.freezes.global.includes(type)

/**
 * Helper to know if a work item can be cancelled.
 */
export const isCancelable = (status: WorkItemStatus) =>
  workItemStatuses.cancelable.includes(status)

/**
 * Helper to know if a work item can be submitted to the XRP Ledger
 */
export const isSubmittable = (status: WorkItemStatus) =>
  status === WorkItemStatus.ReadyForSubmission
