import i18n from '@/i18n'
import type { XRPLPaymentTransaction } from '@/shared/api'
import {
  downloadAsCsv,
  getFormattedTxnDate,
  getTxnCurrency,
  getTxnDestinationAmount,
} from '@/shared/helpers'
import { isString } from '@ripple/ui-helpers'
import dayjs from 'dayjs'

const ISO_FORMAT = 'YYYY-MM-DD'

export const toISO = (d: string | dayjs.Dayjs = dayjs()) => {
  if (isString(d)) return dayjs(d).format(ISO_FORMAT)
  return d.format(ISO_FORMAT)
}

export const getNextDay = (d: string | dayjs.Dayjs) =>
  toISO((isString(d) ? dayjs(d) : d).add(1, 'day'))

export const getPreviousDay = (d: string) => toISO(dayjs(d).subtract(1, 'day'))

export const exportTransactions = (
  transactions: XRPLPaymentTransaction[],
  address: string,
) => {
  const csv = transactions.map(
    ({ Account: source, date, Destination: destination, Amount: amount }) => {
      const paymentDirection =
        address === destination
          ? i18n.t('common:Received')
          : i18n.t('common:Sent')
      const destinationAmount = getTxnDestinationAmount(amount)
      const currency = getTxnCurrency(amount)

      return [
        i18n.t('common:Payment'),
        paymentDirection,
        getFormattedTxnDate(date),
        source,
        destination,
        `${destinationAmount} ${currency}`,
      ].join(',')
    },
  )

  downloadAsCsv(
    csv,
    `${i18n.t('common:Type')},${i18n.t('common:Payment-Direction')},${i18n.t(
      'common:Date',
    )},${i18n.t('common:From')},${i18n.t('common:To')},${i18n.t(
      'common:Amount',
    )}`,
    `transactions_${address}_${dayjs().format('YYYY-MM-DD')}.csv`,
  )
}
