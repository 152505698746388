import { z } from 'zod'
/**
 * Type used for the body of any signature endpoint (account edit, work-item, account setup).
 */

export const payloadSignatureSchema = z.object({
  signature: z.string(),
})

export type PayloadSignature = z.infer<typeof payloadSignatureSchema>
