export const UX_IDS = {
  // specific account view
  account: {
    page: 'page:account',
    cancelTxn: 'account:cancel-txn',
    txnLink: 'account:txn-link',
    viewQueuedTxns: 'account:view-queued-txns',
    headerActions: {
      accountFreeze: 'account:headerActions:account-freeze',
      deleteSetup: 'account:headerActions:delete-setup',
      globalFreeze: 'account:headerActions:global-freeze',
      newTransaction: 'account:headerActions:new-transaction',
      card: {
        headerActionsCard: 'account:headerActions:card:headerActionsCard',
        paymentBtn: 'account:headerActions:card:paymentBtn',
        destroyBtn: 'account:headerActions:card:destroyBtn',
        freezeBtn: 'account:headerActions:card:freezeBtn',
      },
    },
    pending: {
      cancel: 'account:pending:cancel',
      submit: 'account:pending:submit',
      toggleSigners: 'account:pending:toggle-signers',
      txnActionsMenu: 'account:pending:txn-actions-menu',
      viewTxn: 'account:pending:view-txn',
    },
    settings: {
      editDetails: 'account:settings:edit-details',
      editSigners: 'account:settings:edit-signers',
    },
    tabs: {
      changeLog: 'account:tabs:change-log',
      pending: 'account:tabs:pending',
      settings: 'account:tabs:settings',
      reporting: 'account:tabs:reporting',
      users: 'account:tabs:users',
      redemptionLog: 'account:tabs:redemption-log',
    },
    users: {
      addUsers: 'account:users:add-users',
      removeUsers: 'account:users:remove-users',
    },
  },
  // accounts list page
  accounts: {
    page: 'page:accounts',
    myAccountsTab: 'accounts:tabs:my-accounts',
    pendingConfigTab: 'accounts:tabs:pending-config',
    newAccountBtn: 'accounts:new-account-btn',
    configureBtn: 'accounts:configure-btn',
    deleteConfigAction: 'accounts:delete-config-action',
  },
  externalAccounts: {
    page: 'page:externalAccounts',
    myAccountsTab: 'externalAccounts:tabs:my-accounts',
    pendingConfigTab: 'externalAccounts:tabs:pending-config',
    newAccountBtn: 'externalAccounts:new-account-btn',
    configureBtn: 'externalAccounts:configure-btn',
    deleteConfigAction: 'externalAccounts:delete-config-action',
  },
  // banner elements (page and app level)
  banners: {
    globalFreezeBanner: 'banner:global-freeze',
    globalUnfreezeBtn: 'banner:global-freeze:unfreeze',
    addUserApiKey: 'banner:user-api-key:add',
    globalUnfreezeTxnLink: 'banner:global-freeze:unfreeze-txn-link',
    offlineBanner: 'banner:offline',
    noHidBanner: 'banner:no-hid-support',
    activeIssuerAccountEdit: {
      element: 'banner:active-issuer-account-edit',
      viewDetails: 'banner:active-issuer-account-edit:view-details',
    },
    externalAccount: {
      authTrustline: {
        pending: {
          toast: 'banner:externalAccount:authTrustline:pending:toast',
          viewRequestBtn:
            'banner:externalAccount:authTrustline:pending:viewRequestBtn',
        },
        expired: {
          toast: 'banner:externalAccount:authTrustline:expired:toast',
          retryRequestBtn:
            'banner:externalAccount:authTrustline:expired:retryRequestBtn',
        },
      },
    },
    account: {
      activeFreeze: {
        element: 'banner:account:active-freeze',
        unfreezeBtn: 'banner:account:unfreeze-btn',
      },
      pendingAccountEdit: {
        element: 'banner:account:pending-account-edit',
        txnLink: 'banner:account:pending-account-edit:txn-link',
      },
      pendingGlobalFreeze: {
        element: 'banner:account:pending-global-freeze',
        txnLink: 'banner:account:pending-global-freeze:txn-link',
      },
      pendingFreeze: {
        element: 'banner:account:pending-freeze',
        txnLink: 'banner:account:pending-freeze:txn-link',
      },
      pendingUnfreeze: {
        element: 'banner:account:pending-unfreeze',
        txnLink: 'banner:account:pending-unfreeze:txn-link',
      },
      noTransactUsers: {
        element: 'banner:account:no-transact-users',
        addUsers: 'banner:account:no-transact-users:add-users',
      },
      unconfigured: {
        element: 'banner:account:unconfigured',
        configure: 'banner:account:unconfigured:configure',
      },
    },
  },
  createAccount: {
    page: 'page:create-account',
    cancel: 'create-account:cancel',
    create: 'create-account:create',
  },
  editAccount: {
    page: 'page:edit-account',
    addSigner: 'edit-account:add-signer',
    removeSigner: 'edit-account:remove-signer',
    cancel: 'edit-account:cancel',
    submit: 'edit-account:submit',
  },
  // exporter pages
  exporter: {
    applyFilters: 'exporter:apply-filters',
    backToSearch: 'exporter:back-to-search',
    cancelEdit: 'exporter:cancel-edit-filters',
    editFilters: 'exporter:edit-filters',
    export: 'exporter:export',
    filters: 'exporter:filters',
    filtersForm: 'exporter:filters-form',
    lookupTxns: 'exporter:lookup-txns',
    refresh: 'exporter:refresh',
    searchPage: 'exporter:search-page',
    transactionsLoader: 'exporter:transactions-loader',
  },
  freezeList: {
    page: 'page:freeze-list',
    accountLink: 'freezeList:account-link',
    actions: {
      cancelWorkItem: 'freeze-list:actions:cancel-work-item',
      freeze: 'freeze-list:actions:freeze',
      submitWorkItem: 'freeze-list:actions:submit-work-item',
      unfreeze: 'freeze-list:actions:unfreeze',
    },
  },
  globalNav: {
    element: 'global-nav',
    accountsLink: 'global-nav:accounts',
    externalAccountsLink: 'global-nav:externalAccounts',
    accountSecurityLink: 'global-nav:security',
    myTasksLink: 'global-nav:my-tasks',
    rolesLink: 'global-nav:roles',
    transactionsLink: 'global-nav:transactions',
    usersLink: 'global-nav:users',
  },
  // main layout elements
  layout: {
    exporter: 'layout:exporter',
    wallet: 'layout:wallet',
  },
  modals: {
    accountSetup: {
      modal: 'modal:account-setup',
      closeAfterFinish: 'modal:account-setup:close-after-finish',
      fundAccount: 'modal:account-setup:fund-account',
      getAddress: 'modal:account-setup:get-address',
      goToAccount: 'modal:account-setup:go-to-account',
      retryStep: 'modal:account-setup:retry-step',
      signTransaction: 'modal:account-setup:sign-transaction',
      statusToast: 'modal:account-setup:status-toast',
    },
    accountNameEdit: {
      modal: 'modal:account-name-edit',
      cancel: 'modal:account-name-edit:cancel',
      save: 'modal:account-name-edit:save',
    },
    addAccountUsers: {
      modal: 'modal:add-account-users',
      cancel: 'modal:add-account-users:cancel',
      add: 'modal:add-account-users:add',
    },
    addKeyPair: {
      modal: 'modal:add-keypair',
      cancel: 'modal:add-keypair:cancel',
      save: 'modal:add-keypair:save',
    },
    addUserAccounts: {
      modal: 'modal:add-user-accounts',
      add: 'modal:add-user-accounts:add',
      cancel: 'modal:add-user-accounts:cancel',
    },
    changeUserRole: {
      modal: 'modal:change-user-role',
      cancel: 'modal:change-user-role:cancel',
      save: 'modal:change-user-role:save',
    },
    changeUserRoleDenied: {
      modal: 'modal:change-user-role-denied',
      close: 'modal:change-user-role-denied:close',
    },
    deactivateKeyPair: {
      modal: 'modal:deactivate-keypair',
      cancel: 'modal:deactivate-keypair:cancel',
      close: 'modal:deactivate-keypair:close',
      deactivate: 'modal:deactivate-keypair:deactivate',
    },
    deactivateUser: {
      modal: 'modal:deactivate-user',
      cancel: 'modal:deactivate-user:cancel',
      close: 'modal:deactivate-user:close',
      deactivate: 'modal:deactivate-user:deactivate',
    },
    editAccountApproval: {
      modal: 'modal:edit-account-approval',
      cancel: 'modal:edit-account-approval:cancel',
      continue: 'modal:edit-account-approval:continue',
    },
    internalAccountFreeze: {
      modal: 'modal:internal-account-freeze',
      cancel: 'modal:internal-account-freeze:cancel',
      confirm: 'modal:internal-account-freeze:confirm',
    },
    newTransaction: {
      modal: 'modal:new-tranaction',
      cancel: 'modal:new-tranaction:cancel',
      create: 'modal:new-tranaction:create',
    },
    renameKeyPair: {
      modal: 'modal:rename-keypair',
      cancel: 'modal:rename-keypair:cancel',
      save: 'modal:rename-keypair:save',
    },
    removeUserAccounts: {
      modal: 'modal:remove-user-accounts',
      cancel: 'modal:remove-user-accounts:cancel',
      remove: 'modal:remove-user-accounts:remove',
    },
    createExternalAccount: {
      cancel: 'modal:create-external-account:cancel',
      confirm: 'modal:create-external-account:confirm',
    },
    cancelFreezeConfirm: 'modal:confirm-cancel-freeze',
    cancelWorkItemConfirm: 'modal:confirm-cancel-workitem',
    deleteAccountConfigConfirm: 'modal:confirm-delete-account-config',
    globalFreezeConfirm: 'modal:confirm-global-freeze',
    globalUnfreezeConfirm: 'modal:confirm-global-unfreeze',
    regenerateMachineUserApiKeyConfirm:
      'modal:confirm-regenerate-machine-user-api-key',
    removeUsersConfirm: 'modal:confirm-remove-users',
    reviewAndSubmitTxnConfirm: 'modal:confirm-review-and-submit-txn',
    submitTxnConfirm: 'modal:confirm-submit-txn',
    editRoleDetails: {
      element: 'modal:edit-role-details',
      cancel: 'modal:edit-role-details:cancel',
      confirm: 'modal:edit-role-details:confirm',
    },
    editUserDetails: {
      element: 'modal:edit-user-details',
      cancel: 'modal:edit-user-details:cancel',
      save: 'modal:edit-user-details:save',
    },
    machineUserPublicKey: {
      modal: 'modal:user-public-key',
      cancel: 'modal:user-public-key:cancel',
      save: 'modal:user-public-key:save',
      close: 'modal:user-public-key:close',
    },
    freezeWizard: {
      element: 'modal:freeze-wizard',
      docsLink: 'modal:freeze-wizard:docs-link',
    },
    deleteRole: {
      element: 'modal:delete-role',
      cancel: 'modal:delete-role:cancel',
      confirm: 'modal:delete-role:confirm',
      submitRoleChange: 'modal:delete-role:submit-role-change',
    },
    duplicateRole: {
      element: 'modal:duplicate-role',
      backToEditing: 'modal:duplicate-role:back-to-editing',
      viewRole: 'modal:duplicate-role:view-role',
    },
    destroyRequest: {
      element: 'modal:destroy-request',
      cancel: 'modal:destroy-request:cancel',
      create: 'modal:destroy-request:create',
    },
    mintRequest: {
      element: 'modal:mint-request',
      cancel: 'modal:mint-request:cancel',
      create: 'modal:mint-request:create',
    },
    payRequest: {
      element: 'modal:pay-request',
      cancel: 'modal:pay-request:cancel',
      create: 'modal:pay-request:create',
    },
    submitEditRolePermissions: {
      element: 'modal:submit-edit-role-permissions',
      cancel: 'modal:submit-edit-role-permissions:cancel',
      confirm: 'modal:submit-edit-role-permissions:confirm',
    },
    walletAddTrustline: {
      element: 'modal:wallet-add-trustline',
      actions: {
        add: 'modal:wallet-add-trustline:actions:add ',
        cancel: 'modal:wallet-cancel-trustline:actions:add ',
      },
    },
    walletEditAccountName: {
      element: 'modal:wallet-edit-account-name',
      cancel: 'modal:wallet-edit-account-name:cancel',
      save: 'modal:wallet-edit-account-name:save',
    },
    walletPayment: {
      element: 'modal:wallet-payment',
      cancel: 'modal:wallet-payment:cancel',
      send: 'modal:wallet-payment:send',
    },
    walletReceivePayment: 'modal:wallet-receive-payment',
  },
  // my tasks page
  myTasks: {
    page: 'page:my-tasks',
  },
  // editing a role permissions
  editRolePermissions: {
    page: 'page:edit-role-permissions',
    actions: {
      cancel: 'edit-role-permissions:actions:cancel',
      save: 'edit-role-permissions:actions:save',
    },
  },
  // payment page
  payments: {
    page: 'page:payments',
    header: {
      destroy: 'payments:header:destroy',
      mint: 'payments:header:mint',
      pay: 'payments:header:pay',
    },
    tableRowSubmit: 'payments:table-row-submit',
  },
  // roles list page
  roles: {
    page: 'page:roles-list',
    addNewRole: 'page:roles-list:add-new-role',
    roleCard: 'page:roles-list:role-card',
  },
  // Specific role view
  role: {
    page: 'page:role',
    actions: {
      delete: 'role:actions:delete-role',
    },
    details: {
      editDetails: 'role:details:edit-details',
      editPermissions: 'role:details:edit-permissions',
    },
    tabs: {
      overview: 'role:tabs:overview',
      users: 'role:tabs:users',
    },
    users: {
      userAccountsLink: 'role:users:user-accounts-link',
      userLink: 'role:users:user-link',
    },
  },
  transaction: {
    explorerTxnLink: 'transaction:explorer-txn-link',
    headerActions: {
      cancel: 'transaction:headerActions:cancel',
      sign: 'transaction:headerActions:sign',
      submit: 'transaction:headerActions:submit',
    },
    accountEdit: {
      page: 'page:transaction:accountEdit',
    },
    payment: {
      page: 'page:transaction:payment',
    },
    queuedTxns: {
      page: 'page:transaction:queued-txns',
      viewTxn: 'queued-txns:view-transaction',
    },
    redemption: {
      page: 'page:transaction:redemption',
    },
  },
  // user pages
  users: {
    create: {
      page: 'page:create-user',
    },
    list: {
      page: 'page:users-list',
      addNewUser: 'page:users-list:add-new-user',
    },
    profile: {
      page: 'page:user-profile',
      actions: {
        viewApiKey: 'user-profile:actions:view-api-key',
        regenerateApiKey: 'user-profile:actions:regenerate-api-key',
        viewPublicKey: 'user-profile:actions:view-public-key',
        editPublicKey: 'user-profile:actions:edit-public-key',
        addKeyPair: 'user-profile:actions:add-keypair',
        deactivateUser: 'user-profile:actions:deactivate-user',
        editRole: 'user-profile:actions:edit-role',
        editUserDetails: 'user-profile:actions:edit-user-details',
        keypairOptionMenuTrigger:
          'user-profile:actions:keypair-action-menu-trigger',
        renameKeyPair: 'user-profile:actions:rename-keypair',
        retryLedgerKeyPair: 'user-profile:actions:retry-ledger-keypair',
      },
      accounts: {
        add: 'user-profile:accounts:add',
        delete: 'user-profile:accounts:delete',
      },
      tabs: {
        accounts: 'user-profile:accounts:accounts',
        overview: 'user-profile:accounts:overview',
      },
    },
  },
  // wallet pages
  wallet: {
    page: 'page:wallet',
    addAsset: 'wallet:add-asset',
    connect: 'wallet:connect',
    editAccount: 'wallet:edit-account',
    moreAccounts: 'wallet:more-accounts',
    receivePayment: 'wallet:receive-payment',
    reserveTooltip: 'wallet:reserve-tooltip',
    sendPayment: 'wallet:send-payment',
    txnLink: 'wallet:txn-link',
    viewAllTxns: 'wallet:view-all-txns',
    nav: {
      connectAccount: 'wallet:nav:connect-account',
      account: 'wallet:nav:account-link',
    },
  },
  // shared components
  sharedComponents: {
    accountTotals: {
      inCirculation: 'component:account-totals:in-circulation',
      totalDistributable: 'component:account-totals:total-distributable',
      totalMinted: 'component:account-totals:total-minted',
      totalOnStandby: 'component:account-totals:total-on-standby',
    },
    bottomPageButtons: {
      cancel: 'component:bottom-page-buttons:cancel',
      create: 'component:bottom-page-buttons:create',
    },
    paymentSheet: {
      cancel: 'component:payment-sheet:cancel',
      goToDetails: 'component:payment-sheet:go-to-details',
      submit: 'component:payment-sheet:submit',
    },
    workItemSheet: {
      cancel: 'component:account-action-sheet:cancel',
      goToDetails: 'component:account-action-sheet:go-to-details',
      submit: 'component:account-action-sheet:submit',
    },
    workItems: {
      txnHashLink: 'component:txn-hash-link',
      signWithCustodialKey: 'component:sign-with-custodial-key',
      signWithLedgerKey: 'component:sign-with-ledger-key',
    },
  },
}
