export default {
  'New-Role': 'Nuevo Rol',
  'Role-Name': 'Nombre de Rol',
  'Role-Not-Found': 'Rol No Encontrado',
  'Edit-Permissions': 'Permisos de Edición',
  'Update-Permissions': 'Actualizar Permisos',
  'Permissions-conflict': 'Conflicto de Permisos',
  System: 'Sistema',
  'Delete-Role': 'Eliminar Rol',
  'Back-to-Editing': 'Volver a Edición',
  'Duplicate-Role': 'Duplicar Rol',
  'Account-Permissions': 'Permisos de Cuenta',
  'View-Role': 'Ver Rol',
  'System-Permissions': 'Permisos de Sistema',
  'signing-disabled': 'Firmado no habilitado',
  'signing-enabled': 'Firmado habilitado para',
  'loading-roles': 'Cargando roles...',
  'loading-role': 'Cargando rol...',
  'loading-role-details': 'Cargando detalle del rol...',
  'no-roles-found': 'No se han encontrado roles. Añada uno nuevo.',
  'no-role-found': 'No se ha encontrado rol con id {{roleId}}',
  'create-role.success': 'Nuevo rol {{role}} creado correctamente',
  'create-role.error': 'No se ha creado el nuevo rol',
  'create-role.permissions-conflict': 'Existe un rol con los mismos permisos. Modifique el rol o cambie los permisos seleccionados para crear un nuevo rol.',
  'system-role.overview': 'Usuarios de tipo Administrador de Sistema realiza funciones de mantenimiento de Usuarios, Roles y Cuentas en la plataforma',
  'system-role.permissions.0': 'Puede crear y modificar cuentas (por ejemplo, crear una nueva cuenta intermedia',
  'system-role.permissions.1': 'Puede crear y modificar usuarios',
  'system-role.permissions.2': 'Puede crear y modificar roles',
  'system-role.permissions.3': 'Puede iniciar bloqueos y desbloqueos (por ejemplo, para una cuenta específica)',
  'system-role.permissions.4': 'Puede iniciar bloqueos individuales y globales',
  'system-role.permissions.5': 'Puede crear y modificar cuentas',
  'role-permission.add-account': 'Puede crear una nueva cuenta',
  'role-permission.add-role': 'Puede crear un nuevo rol',
  'role-permission.add-user': 'Puede crear un nuevo usuario',
  'role-permission.delete-account': 'Puede eliminar una cuenta existente',
  'role-permission.delete-role': 'Puede eliminar un rol existente',
  'role-permission.delete-user': 'Puede eliminar un usuario existente',
  'role-permission.edit-account': 'Puede editar detalles de una cuenta (por ejemplo: nombre, lista de firmantes...)',
  'role-permission.edit-role': 'Puede editar un rol',
  'role-permission.edit-user': 'Puede editar los detalles de un usuario',
  'role-permission.read-role': 'Puede ver un rol',
  'role-permission.read-account': 'Puede ver (sólo detalles de transacciones y cuentas)',
  'role-permission.freeze': 'Puede bloquear todas las cuentas o cuentas específicas',
  'role-permission.mint-pay-and-destroy-transact': 'Puede iniciar pagos (por ejemplo: transacciones de pago, emisión o destrucción)',
  'role-permission.pay-and-destroy-transact': 'Puede iniciar pagos (por ejemplo: pagar y destruir transacciones)',
  'role-permission.mint-transact': 'Puede iniciar transacciones de menta',
  'role-permission.sign': 'Puede firmar transacciones de una cuenta (por ejemplo: emisión, cambios...)',
  'account-permission.edit': 'Puede editar detalles de una cuenta (por ejemplo: nombre, lista de firmantes...)',
  'account-permission.sign': 'Puede firmar transacciones de una cuenta (por ejemplo: emisión, cambios...)',
  'account-permission.pay': 'Puede iniciar pagos (por ejemplo: transacciones de pago, emisión o destrucción)',
  'Role-Details': 'Detalles del Rol',
  'Edit-Role-Details': 'Editar Detalles Rol',
  'edit-success': 'Rol modificado correctamente',
  'edit-error': 'Se ha producido un error modificando el rol',
  'edit-description': 'Editar el título y descripción del rol',
  'role-name-required': 'No se ha definido el nombre del rol, es necesario para actualizarlo',
  'role-description-required': 'No se ha definido la descripción del rol, es necesario para actualizarlo',
  'updated-role.prompt': '¿Está seguro de que quiere actualizar los permisos para "{{roleName}}"?',
  'updated-role.warning': 'Al actualizar los permisos de este rol, los usuarios que entren en conflicto con los nuevos permisos seran eliminados de <Strong>$t(plurals:accounts, {"count": {{accountsLosingAllMembersCount}}})</Strong>',
  'updated-role.success': 'Rol actualizado correctamente',
  'updated-role.error': 'No se puedo actualizar el rol',
  'updated-role.conflict': 'Estos cambios no permitirán a {{signersLosingAccessCount}} $t(plurals:users, {"count": {{signersLosingAccessCount}}}) ver cuentas en los que son actualmente firmantes. Debe eliminar antes todos los firmantes del tipo de cuenta antes de modififcar los permisos.',
  'assign-new-role.title': 'Debe asignar un nuevo Rol',
  'assign-new-role.description': 'Asigne un nuevo rol a todos los usuarios para eliminarlos de este rol antes de eliminar el rol',
  'delete-role.title': '¿Eliminar Rol?',
  'delete-role.disabled': 'No se puede eliminar este rol',
  'delete-role.confirmation': '¿Está seguro de que quiere eliminar este rol? Esta acción no podra ser revertida.',
  'delete-success': 'Rol eliminado correctamente',
  'delete-error': 'Se ha producido un error eliminando el rol',
  'delete-role.remove-signers': 'Para poder eliminar este rol, debe antes eliminar <Strong>$t(plurals:signers, {"count": {{signerCount}} })</Strong> como firmantes de <Strong>$t(plurals:accounts, {"count": {{accountCount}} })</Strong>.',
  'delete-role.conflicting-permissions': 'El rol seleccionado posee diferentes permisos al actual. Se eliminaran usuarios de <Strong>$t(plurals:accounts, {"count": {{count}} })</Strong> that $t(plurals:conflicts, {"count": {{count}} }) con este nuevo rol.',
  'no-users-assigned': 'No se han asignado usuarios a este rol',
  'no-users-assigned.description': 'Para asignar este rol a un usuario, vaya a <Button>Usuarios</Button>',
  'role-permission-label.issuer-read': 'Leer cuentas de emisor',
  'role-permission-label.operational-read': 'Leer cuentas operativas',
  'role-permission-label.standby-read': 'Leer cuentas en espera',
  'role-permission-label.issuer-edit': 'Editar cuentas de emisor',
  'role-permission-label.operational-edit': 'Editar cuentas operativas',
  'role-permission-label.standby-edit': 'Editar cuentas en espera',
  'role-permission-label.issuer-sign': 'Regístrate para cuentas de emisor',
  'role-permission-label.operational-sign': 'Regístrate para cuentas operativas',
  'role-permission-label.standby-sign': 'Regístrese para cuentas en espera',
  'role-permission-label.issuer-transact': 'Iniciar transacciones para cuentas de emisor',
  'role-permission-label.operational-transact': 'Iniciar transacciones para cuentas operativas',
  'role-permission-label.standby-transact': 'Iniciar transacciones para cuentas en espera',
}
