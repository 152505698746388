import { z } from 'zod'
import { AccountType } from './accounts'
import { currencyCodeSchema } from './currency'

// Metadata discriminated union
// Mainly for Issuing accounts to include currencyCode
// and also allows simpler future divergence (if needed)
// We want all of them available separately,
// as well as the union type
export const accountMetadataSchema = z.object({
  address: z.string(),
  accountName: z.string(),
  cbdcAccountId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  useAuthTrustLines: z.boolean().optional(),
  isActive: z.boolean().optional(),
})

/**
 * Issuer Metadata
 */

export const issuerMetadataSchema = accountMetadataSchema.merge(
  z.object({
    accountType: z.enum([AccountType.Issuer]),
    currencyCode: currencyCodeSchema,
    useAuthTrustLines: z.boolean(),
    isActive: z.boolean(),
  }),
)

export type IssuerMetadata = z.infer<typeof issuerMetadataSchema>

/**
 * Operational Metadata
 */

export const operationalMetadataSchema = accountMetadataSchema.merge(
  z.object({
    accountType: z.enum([AccountType.Operational]),
  }),
)

/**
 * Standby Metadata
 */

export const standbyMetadataSchema = accountMetadataSchema.merge(
  z.object({
    accountType: z.enum([AccountType.Standby]),
  }),
)

/**
 * Unknown Metadata
 */

export const unknownMetadataSchema = accountMetadataSchema.merge(
  z.object({
    accountType: z.enum([AccountType.Unknown]),
  }),
)

/**
 * Cbdc Account Metadata
 */
export const cbdcAccountMetadataSchema = z.union([
  issuerMetadataSchema,
  operationalMetadataSchema,
  standbyMetadataSchema,
  unknownMetadataSchema,
])

export const cbdcAccountMetadataArraySchema = cbdcAccountMetadataSchema.array()

export type CbdcAccountMetadata = z.infer<typeof cbdcAccountMetadataSchema>
