import {
  CurrencyCode,
  FiatCurrencyCode,
  TenantWideCbdcAccountBalances,
} from 'common'

export type MoneySupply = {
  totalCbdcMinted: number
  totalCbdcSupply: number
  totalCbdcDistributed: number
  totalCirculatingSupply: number
}

export const calculateMoneySupplyFromBalances = (
  balances: TenantWideCbdcAccountBalances,
  currencyCode: CurrencyCode = FiatCurrencyCode.USD,
): MoneySupply => {
  // We currently limit issuers to a single currency and only display a single currency,
  // so we have to coerce to the first element in the array.

  // the minted amount is a debt relationship (negative amount)
  // but we wish to show it positively, hence the abs call

  return {
    totalCbdcMinted: Math.abs(
      parseInt(balances.typeBalances?.ISSUER?.[currencyCode] ?? '0'),
    ),
    totalCbdcSupply: parseInt(
      balances.typeBalances?.STANDBY?.[currencyCode] ?? '0',
    ),
    totalCbdcDistributed: parseInt(
      balances.typeBalances?.OPERATIONAL?.[currencyCode] ?? '0',
    ),
    totalCirculatingSupply: parseInt(
      balances.typeBalances?.EXTERNAL?.[currencyCode] ?? '0',
    ),
  }
}
