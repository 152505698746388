import { z } from 'zod'
import { accountSetupStepTypeSchema } from './account-setup-step-type'

export enum ProcessingStatus {
  // the step has a completed work item
  Complete = 'COMPLETE',
  // a step is constructing the necessary WorkItems to make progress,
  // or that some external interaction must move the flow forward
  NotReady = 'NOT_READY',
  // a WorkItem is currently in the process of being finalized
  Processing = 'PROCESSING',
  // the step has a WorkItem that can be signed
  Ready = 'READY',
}

/**
 * Response from the GET "/account-setups/{accountId}/next" API to understand
 * the next step in the account setup flow.
 * https://github.com/xpring-eng/cbdc-backend/blob/new-account-setup/src/main/java/io/ripplex/cbdc/model/accountsetup/AccountSetupNextStep.java
 */

export const accountSetupNextStepSchema = z.object({
  // Generally speaking, `NOT_READY` and `PROCESSING` mean you're waiting for something
  // to happen while `READY` means the user can do something to advance the flow.
  // The `COMPLETE` flag will likely never been seen on steps beyond the `COMPLETED` step
  // type but exists to avoid ambiguity of having `COMPLETED` be in a processing status
  // of `READY` or something nonsensical.
  processingStatus: z.nativeEnum(ProcessingStatus),
  processing: z.boolean(),
  step: accountSetupStepTypeSchema,
})

export type AccountSetupNextStep = z.infer<typeof accountSetupNextStepSchema>
