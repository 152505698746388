import { dateRangeDisplayText } from '@/pages/exporter/components'
import { TransactionDirection } from 'common'
import {
  BaseTransaction,
  GatewayBalancesResponse,
  Payment,
  Transaction,
  TxResponse,
} from 'xrpl'

export enum XrplQueryKeys {
  ServerInfo = 'server_info',
  Transaction = 'tx',
  AccountTransactions = 'account_tx',
  AccountTrustlines = 'account_lines',
  AccountBalance = 'account_balance',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
  GatewayBalances = 'gateway_balances',
}

export type TxnStatusInfo = Pick<TxResponse['result'], 'meta' | 'validated'>

export type XRPLTransaction<T extends BaseTransaction = Transaction> = Omit<
  TxResponse<T>['result'],
  'meta' | 'validated' | 'hash'
> & { hash?: string }

export type XRPLPaymentTransaction = XRPLTransaction<Payment>

export type AccountIssuedTokens =
  GatewayBalancesResponse['result']['obligations']

export type XRPLTxnStatus = 'success' | 'failed' | 'pending' | 'unrecognized'

export type PaymentTxFilterProps = {
  dateRange?: keyof typeof dateRangeDisplayText
  endDate?: string
  paymentDirection?: TransactionDirection | 'All'
  startDate?: string
  noXRP?: boolean
  includeTxnStatus?: XRPLTxnStatus[]
}
