import i18n from '@/i18n'
import { AccountEditSummary } from 'common'

export const getAccountEditTypeDisplay = ({
  isNameChange,
  isQuorumChange,
  isSignerListChange,
  isUsersChange,
  requestSummary,
}: Pick<
  AccountEditSummary,
  | 'isNameChange'
  | 'isUsersChange'
  | 'isQuorumChange'
  | 'isSignerListChange'
  | 'requestSummary'
>) =>
  isNameChange
    ? i18n.t('accounts:Name-Change')
    : isSignerListChange || isQuorumChange
    ? i18n.t('transactions:Signer-List-Change')
    : isUsersChange
    ? requestSummary
    : ''
