export const EMPTY_PLACEHOLDER = '- -'

export const ENTRY = '/entry'
export const HOME = '/'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const NOT_FOUND = '/not-found'
export const UNAUTHORIZED = '/unauthorized'

export const UX_IDS = {
  appHeader: {
    element: 'app-header',
    logout: 'app-header:logout',
    userInfo: 'app-header:user-info',
  },
  auth: {
    login: 'auth:login',
  },
  globalNav: {
    element: 'global-nav',
  },
  banners: {
    offlineBanner: 'banner:offline',
    noHidBanner: 'banner:no-hid-support',
  },
  errorPages: {
    backToHome: '404:back-to-home',
    notFoundPage: 'page:404',
    unauthorizedPage: 'page:403',
  },
  breadcrumbs: {
    element: 'component:breadcrumbs',
    link: 'component:breadcrumbs:link',
    currentLocation: 'component:breadcrumbs:current-location',
  },
  userMenu: {
    element: 'component:user-menu',
    selectLanguage: 'component:user-menu:select-language',
    selectLanguageOption: 'component:user-menu:select-language-option',
    logout: 'component:user-menu:logout',
  },
  clickToCopy: 'component:click-to-copy',
  navClickTableRow: 'component:nav-click-table-row',
  tableRowWithSheet: 'component:table-row-with-sheet',
  loginRefreshConfirm: 'modal:confirm-refresh-login',
}

export const ROOT_WS_PATH = '/cbdc-ws'

export enum WS_QUEUES {
  ACCOUNT_EDITS = '/user/queue/account-edits',
  ACCOUNT_SETUPS = '/user/queue/account-setups',
  CBDC_ACCOUNTS = '/user/queue/cbdc-accounts',
  GLOBAL_FREEZE = '/user/queue/global-freeze',
  /** role create, delete, update */
  ROLES = '/user/queue/roles',
  /** User events, key pair events */
  USERS = '/user/queue/users',
  WORKITEMS = '/user/queue/work-items',
  EXTERNAL_ACCOUNTS = '/user/queue/external-accounts',
  BALANCES = '/user/queue/balances',
}

// common pagination control ids
export const paginationButtonsData = {
  firstPage: { 'data-testid': 'pagination:first-page' },
  lastPage: { 'data-testid': 'pagination:last-page' },
  nextPage: { 'data-testid': 'pagination:next-page' },
  prevPage: { 'data-testid': 'pagination:prev-page' },
}
// common confirmation action ids
export const confirmationModalButtonsData = {
  cancel: { 'data-testid': 'confirmation-modal:cancel' },
  confirm: { 'data-testid': 'confirmation-modal:confirm' },
}
// common wizard modal action ids
export const wizardModalButtonsData = {
  finish: { 'data-testid': 'wizard-modal:finish' },
  next: { 'data-testid': 'wizard-modal:next' },
  prev: { 'data-testid': 'wizard-modal:prev' },
}
