import i18n from '@/i18n'
import { NewPaymentRequest } from '@/shared/api'
import { MAX_TRUSTLINE_AMOUNT } from '@/shared/constants'
import {
  formatCurrencyValue,
  getAccountMembershipCheck,
} from '@/shared/helpers'
import BigNumber from 'bignumber.js'
import {
  AccountType,
  CurrencyCode,
  IssuerMetadata,
  PayAccount,
  Permission,
  Role,
  UserAccountMin,
} from 'common'
import {
  AccountDropdownOption,
  ExternalDropdownOption,
  PayRequestFormTxnType,
} from './pay-request-form.types'

/**
 * Gets available funds (as a string) for an account and currency code.
 */
export const getAvailableFunds = (
  account: PayAccount,
  currencyCode: CurrencyCode,
) => {
  // This 'if' fixes an issue if the Chief Cashier logs in and only the Issuing account is created
  // https://ripplelabs.atlassian.net/browse/XBS-795
  if (account.balances) {
    return formatCurrencyValue(account.balances[currencyCode])
  }
  return parseInt('0').toLocaleString('en-US')
}

/**
 * Extract the account types where a user has permission
 * to transact.
 * @param {Roles[]} roles Array of user roles
 * @returns {string[]} An array of {@link AccountType}
 */
const getTransactAccountTypes = (roles: Role[]) => {
  return roles[0].rolePermissions.reduce(
    (arr, { cbdcAccountType, permission }) => {
      if (permission === Permission.Transact && cbdcAccountType) {
        arr.push(cbdcAccountType)
      }
      return arr
    },
    [] as AccountType[],
  )
}

type GetSourceAccountsProps = {
  accounts: PayAccount[]
  userAccounts: UserAccountMin[]
  userRoles: Role[]
}
/**
 * Filters accounts by type and ones a user can sign.
 * @returns An array of {@link PayAccount} objects
 */
export const getSourceAccounts = ({
  accounts,
  userAccounts,
  userRoles,
}: GetSourceAccountsProps): PayAccount[] => {
  const accountTypes = getTransactAccountTypes(userRoles)
  const isMember = getAccountMembershipCheck(userAccounts)

  return accounts.reduce((arr, account) => {
    if (accountTypes.includes(account.accountType) && isMember(account.id)) {
      arr.push(account)
    }
    return arr
  }, [] as PayAccount[])
}

type AmountValidityProps = {
  destinations: Array<AccountDropdownOption | ExternalDropdownOption>
  issuer: IssuerMetadata
  sources: AccountDropdownOption[]
  type: PayRequestFormTxnType
  values: NewPaymentRequest
}

/**
 * Checks the form amount field validity based on the source and destination accounts.
 * @param formValues
 * @param value
 * @returns {object}
 */
export const checkAmountValidity = ({
  destinations,
  issuer,
  sources,
  values,
  type,
}: AmountValidityProps) => {
  const { amount, destinationAccount, sourceAccount } = values

  let message = ''

  if (issuer.currencyCode) {
    // 1st check against the source account
    if (amount && sourceAccount && type !== 'mint') {
      const source = sources.find((opt) => opt.value === sourceAccount)
      const hasBalance = source && 'balance' in source.data
      // source account funds are lower than the send amount
      if (
        hasBalance &&
        new BigNumber(
          Math.abs(+(source.data.balance ?? '').replace(/\D/g, '')),
        ).isLessThan(amount)
      ) {
        message = i18n.t('payments:insufficient-funds', {
          source: source.label,
          balance: source.data.balance,
          currencyCode: issuer.currencyCode,
        })
      }
    }

    // 2nd check against the destination account
    // This check needs to happen after the source account to make sure the mint requests work properly
    if (amount && destinationAccount && type !== 'destroy') {
      const destinationAcct = destinations.find(
        (opt) => opt.value === destinationAccount,
      )

      if (destinationAcct) {
        // If the amount is too high, it will have the consequence to exceed the trustline limit
        // (hardcoded amount for now)
        if (new BigNumber(amount).isGreaterThan(MAX_TRUSTLINE_AMOUNT)) {
          message = i18n.t('payments:trustline-limit-exceeded', {
            maxAmount: MAX_TRUSTLINE_AMOUNT.toLocaleString('en-US'),
            currencyCode: issuer.currencyCode,
            destinationAccount: destinationAcct.label,
          })
        }
      }
    }
  }

  return message
}
