import { z } from 'zod'
import { AccountType } from './accounts'
import { balanceMapSchema } from './balances'
import { accountMetadataSchema } from './cbdc-account-metadata'

export const payAccountSchema = accountMetadataSchema
  .pick({
    address: true,
    accountName: true,
    createdAt: true,
    updatedAt: true,
    isActive: true,
  })
  .extend({
    id: z.string(),
    tenantId: z.string(),
    accountType: z.nativeEnum(AccountType),
    destinationTag: z.number().optional(), // 32-bit unsigned int (0-4294967295)
    balances: balanceMapSchema.default({}),
  })

export type PayAccount = z.infer<typeof payAccountSchema>

export const payAccountArraySchema = payAccountSchema.array()
