import { z } from 'zod'
import { transactionSchema } from '../transactions'
import { workItemTransactionSignatureSchema } from './work-item-transaction-signature'
import { WorkItemType } from './work-item-type'

export enum WorkItemTransactionStatus {
  NonFinal = 'NON_FINAL',
  FinalFailure = 'FINAL_FAILURE',
  FinalSuccess = 'FINAL_SUCCESS',
  NonFinalCancelled = 'NON_FINAL_CANCELLED',
}

export const workItemTransactionSchema = z.object({
  createdAt: z.string(),
  /** A UUID */
  destinationAccount: z.string().optional(),
  /** A UUID */
  id: z.string(),
  quorum: z.number(),
  signatures: workItemTransactionSignatureSchema.array(),
  signingMode: z.string(),
  status: z.nativeEnum(WorkItemTransactionStatus),
  /** A UUID */
  tenantId: z.string(),
  transaction: transactionSchema,
  transactionEncoded: z.string(),
  transactionHash: z.string().optional(),
  updatedAt: z.string(),
  workItemType: z.nativeEnum(WorkItemType),
})
export type WorkItemTransaction = z.infer<typeof workItemTransactionSchema>
