import { Controls } from '@ripple/design-system'
import {
  ExternalAccount,
  PageType,
  RequestError,
  getPaginatedQueryOpts,
  getRequestParamsFromControls,
} from 'common'
import { UseQueryOptions, useQuery } from 'react-query'
import {
  getExternalAccount,
  getExternalAccounts,
} from './external-accounts.api'

export enum ExternalAccountsQueryKeys {
  ExternalAccountDetails = 'external-account-details',
  ExternalAccounts = 'external-accounts',
}

/**
 * Retrieve a list of external accounts for a tenant
 * @returns An array of {@link ExternalAccount} objects
 */
export const useExternalAccounts = (
  controls?: Controls,
  opts?: UseQueryOptions<PageType<ExternalAccount[]>, RequestError>,
) => {
  const { queryKeyControls, requestControls } =
    getRequestParamsFromControls(controls)

  const response = useQuery<PageType<ExternalAccount[]>, RequestError>(
    [ExternalAccountsQueryKeys.ExternalAccounts, ...queryKeyControls],
    () => getExternalAccounts(requestControls),
    getPaginatedQueryOpts(opts),
  )

  return response
}

export const useAllExternalAccounts = () =>
  useExternalAccounts({
    pagination: { pageSize: 500, pageIndex: 0 },
  } as Controls)

export const useExternalAccountMetadataLookupObject = () => {
  const { data, ...rest } = useAllExternalAccounts()

  return {
    ...rest,
    externalAccounts:
      data?.content.reduce((obj, account) => {
        obj[account.accountId] = account
        return obj
      }, {} as Record<string, ExternalAccount>) ?? {},
  }
}

/**
 * Retrieve a specific external account
 * @returns an {@link ExternalAccount}
 */
export const useExternalAccount = (accountId: string) =>
  useQuery<ExternalAccount, RequestError>(
    [ExternalAccountsQueryKeys.ExternalAccountDetails, accountId],
    () => getExternalAccount(accountId),
  )
