import { AccountSetup, AccountType } from 'common'

/**
 * Checks if there's an active ISSUER account setup.
 *
 * @param {AccountSetup[]} accountSetups A list of account setups
 * @returns A {@link Boolean}
 */
export function hasIssuerAccountSetup(accountSetups: AccountSetup[]) {
  return accountSetups.some(
    ({ accountType }) => accountType === AccountType.Issuer,
  )
}
