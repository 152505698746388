import {
  RequestError,
  WorkItem,
  WorkItemType,
  invalidateQueryCache,
  safeParseApiResponse,
  workItemSchema,
} from 'common'
import { useMutation, useQueryClient } from 'react-query'
import { requestClient } from '../request-client'
import { WorkItemQueryKeys } from '../work-items'

export enum PaymentsMutationKeys {
  CreateRequest = 'create-payment-request',
  CreateExternalRequest = 'create-external-request',
}

export type NewPaymentRequest = {
  sourceAccount: string // UUID
  workItemType: WorkItemType
  destinationAccount: string // UUID
  amount: string
  currencyCode: string
}

type CreateWorkItemProps = {
  request: NewPaymentRequest
  workItemId: string
  type: WorkItemType
}

export const useCreatePaymentsWorkItem = () => {
  const queryClient = useQueryClient()
  return useMutation<WorkItem, RequestError, CreateWorkItemProps>(
    ({ request, workItemId }) =>
      requestClient
        .put(`/work-items/payments/${workItemId}`, request)
        .then(safeParseApiResponse(workItemSchema)),
    {
      mutationKey: PaymentsMutationKeys.CreateRequest,
      onSuccess: () => {
        invalidateQueryCache(queryClient, [[WorkItemQueryKeys.Transactions]])
      },
    },
  )
}
