import { WS_QUEUES } from '@/shared/constants'
import {
  AccountLedgerInfo,
  AccountSetup,
  AccountSetupEventType,
  AccountSetupNextStep,
  AccountSetupStep,
  AccountSetupStepTypePathName,
  AccountSetupStepWebSocketMessage,
  FaucetStatus,
  PayloadSignature,
  ProcessingStatus,
  RequestError,
  accountLedgerInfoSchema,
  accountSetupNextStepSchema,
  accountSetupSchema,
  accountSetupStepSchema,
  faucetStatusSchema,
  invalidateQueryCache,
  payloadSignatureSchema,
  queryClient,
  safeParseApiResponse,
  useSubscription,
} from 'common'
import { useEffect } from 'react'
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query'
import { AccountsQueryKeys } from '../accounts'
import { requestClient } from '../request-client'
import { ACCOUNT_SETUP_URL } from './account-setup.constants'

export enum AccountSetupQueryKeys {
  AcctSetups = 'account-setups',
  AcctSetup = 'account-setup',
  AcctSetupNextStep = 'account-setup-next-step',
  AcctSetupStep = 'account-setup-step',
  AcctLedgerInfos = 'account-ledger-infos',
  FaucetStatus = 'faucet-status',
  Junkyard = 'junkyard',
}

/**
 * Returns an account information which is either a metadata (such as the account name)
 * or will be saved in the XRPL such as the signers list. Until the transactions are not signed
 * with the external wallet (ie Ledger Nano), the information is only saved in the database.
 *
 * @param {string} accountId The account ID.
 * @param {UseQueryOptions} opts The query options.
 * @returns A {@link useQuery} object where `data` is an {@link AccountSetup}.
 */
export const useAccountSetup = (
  accountId: string,
  opts?: UseQueryOptions<AccountSetup, RequestError>,
) =>
  useQuery<AccountSetup, RequestError>(
    [AccountSetupQueryKeys.AcctSetup, accountId],
    async () => {
      if (accountId) {
        const acctSetupResponse = await requestClient
          .get(`${ACCOUNT_SETUP_URL}/${accountId}`)
          .then(safeParseApiResponse(accountSetupSchema))

        return acctSetupResponse
      }
      return undefined
    },
    opts,
  )

export const getAccountLedgerInfo = (
  accountId: string,
): Promise<AccountLedgerInfo> =>
  requestClient
    .get(`${ACCOUNT_SETUP_URL}/${accountId}/ledger-info`)
    .then(safeParseApiResponse(accountLedgerInfoSchema))

export const getAccountSignature = (
  accountId: string,
  pathName: string,
): Promise<PayloadSignature> =>
  requestClient
    .get(`${ACCOUNT_SETUP_URL}/${accountId}/${pathName}/signature`)
    .then(safeParseApiResponse(payloadSignatureSchema))

/**
 * Prefetch the data of a specific account setup.
 *
 * @param {string} accountId The account ID.
 * @param {UseQueryOptions} opts The query options.
 */
export const useAccountSetupPrefetch = async (
  accountId: string,
  opts?: UseQueryOptions<AccountSetup, RequestError>,
) => {
  await queryClient.prefetchQuery<AccountSetup, RequestError>(
    [AccountSetupQueryKeys.AcctSetup, accountId],
    async () => {
      const acctSetupResponse = await requestClient
        .get(`${ACCOUNT_SETUP_URL}/${accountId}`)
        .then(safeParseApiResponse(accountSetupSchema))

      return acctSetupResponse
    },
    opts,
  )
}

/**
 * Returns all the account setups from the "account_setups" table.
 * An "account setup" is an account that needs to be configured via
 * an external wallet such as the Ledger Nano.
 * Once the account is configured, a new record will be created in the "cbdc_accounts" table.
 *
 * @param {UseQueryOptions} opts The query options.
 * @returns A {@link useQuery} object where `data` is an {@link AccountSetup} array.
 */
export const useAccountSetups = (
  opts?: UseQueryOptions<AccountSetup[], RequestError>,
) =>
  useQuery<AccountSetup[], RequestError>(
    [AccountSetupQueryKeys.AcctSetups],
    () =>
      requestClient
        .get(ACCOUNT_SETUP_URL)
        .then(safeParseApiResponse(accountSetupSchema.array().default([]))),
    opts,
  )

/**
 * This will provide the name of the next step and whether or not it's currently in progress.
 *
 * Important: Whenever the next resource returns a 404 (or potentially 301 according to Ian),
 * it means that there are no more setup steps to complete and the account is usable.
 *
 * This should be polled every 2 seconds approximately by the UI.
 *
 * It returns the following type of entity:
 *
 * @example
 * {
 *    processingStatus: ProcessingStatus
 *    step: string
 * }
 *
 * @param {string} accountId The ID of the account being configured.
 * @param {UseQueryOptions} opts The query options.
 * @returns A {@link useQuery} object where `data` is an {@link AccountSetupNextStep}.
 */
export const useAccountSetupNextStep = (
  accountId: string,
  opts?: UseQueryOptions<AccountSetupNextStep, RequestError>,
) => {
  const queryClient = useQueryClient()
  const response = useQuery<AccountSetupNextStep, RequestError>(
    [AccountSetupQueryKeys.AcctSetupNextStep, accountId],
    async () =>
      requestClient
        .get(`${ACCOUNT_SETUP_URL}/${accountId}/next`)
        .then(safeParseApiResponse(accountSetupNextStepSchema)),
    opts,
  )

  const data = response.data ?? ({} as AccountSetupNextStep)
  // when the setup is COMPLETE, invalidate the meta query
  // so anything relying on it to get issuer or other account info
  // will update faster
  if (data.processingStatus === ProcessingStatus.Complete) {
    invalidateQueryCache(queryClient, [[AccountsQueryKeys.AllMeta]])
  }

  return {
    step: data.step,
    processing: data.processing,
    processingStatus: data.processingStatus,
    ...response,
  }
}

/**
 * Prefetches the data for the next API call for the account setup flow.
 *
 * @param {string} accountId The ID of the account to configure.
 * @param {UseQueryOptions} opts The query options.
 * @returns A {@link useQuery} object where `data` is an {@link AccountSetupNextStep}.
 */
export const useAccountSetupPrefetchNextStep = async (
  accountId: string,
  opts?: UseQueryOptions<AccountSetupNextStep, RequestError>,
) => {
  await queryClient.prefetchQuery<AccountSetupNextStep, RequestError>(
    [AccountSetupQueryKeys.AcctSetupNextStep, accountId],
    async () =>
      requestClient
        .get(`${ACCOUNT_SETUP_URL}/${accountId}/next`)
        .then(safeParseApiResponse(accountSetupNextStepSchema)),
    opts,
  )
}

/**
 * Retrieve the current status of a specific step for an account by its path name.
 *
 * @param {string} accountId The ID of the account being configured.
 * @param {AccountSetupStepTypePathName} pathName The path name.
 * @param {UseQueryOptions} opts The query options.
 * @returns A {@link useQuery} object where `data` is an {@link AccountSetupStep}.
 */
export const useAccountSetupStep = (
  accountId: string,
  pathName: AccountSetupStepTypePathName,
  opts?: UseQueryOptions<AccountSetupStep, RequestError>,
) => {
  return useQuery<AccountSetupStep, RequestError>(
    [AccountSetupQueryKeys.AcctSetupStep, accountId, pathName],
    async () => {
      const acctSetupStepResponse = await requestClient
        .get(`${ACCOUNT_SETUP_URL}/${accountId}/${pathName}`)
        .then(safeParseApiResponse(accountSetupStepSchema))

      return acctSetupStepResponse
    },
    opts,
  )
}

/**
 * Indicates if we use a faucet in the back-end to easily fund an account.
 * If the response is false, the funding must be done manually.
 *
 * @param {UseQueryOptions} opts The query options.
 * @returns void
 */
export const useAccountSetupFaucetStatus = (
  opts?: UseQueryOptions<FaucetStatus, RequestError>,
) => {
  return useQuery<FaucetStatus, RequestError>(
    [AccountSetupQueryKeys.FaucetStatus],
    async () => {
      const faucetStatusResponse = await requestClient
        .get(`${ACCOUNT_SETUP_URL}/faucet`)
        .then(safeParseApiResponse(faucetStatusSchema))

      return faucetStatusResponse
    },
    opts,
  )
}

// Function to call the junkyard API endpoint.
const getJunkyard = () =>
  requestClient
    .get(`${ACCOUNT_SETUP_URL}/junkyard`)
    .then(safeParseApiResponse(accountLedgerInfoSchema.array().default([])))

/**
 * Gets the XRP addresses that we can't use and can't save in the database.
 * Those are addresses which were used for a setup that has been deleted.
 *
 * @param {UseQueryOptions} opts The query options.
 * @returns
 */
export const useAccountSetupJunkyard = (
  opts?: UseQueryOptions<AccountLedgerInfo[], RequestError>,
) => {
  return useQuery<AccountLedgerInfo[], RequestError>(
    [AccountSetupQueryKeys.Junkyard],
    getJunkyard,
    opts,
  )
}

/**
 * Prefetch the XRP address which can't be used.
 *
 * @param {UseQueryOptions} opts The query options.
 */
export const useAccountSetupJunkyardPrefetch = (
  opts?: UseQueryOptions<AccountLedgerInfo[], RequestError>,
) => {
  // prefetch on load
  useEffect(() => {
    queryClient.prefetchQuery<AccountLedgerInfo[], RequestError>(
      [AccountSetupQueryKeys.Junkyard],
      getJunkyard,
      opts,
    )
  }, [])

  // invalidate junkyard when an account setup is deleted
  useSubscription<AccountSetupStepWebSocketMessage>(WS_QUEUES.ACCOUNT_SETUPS, {
    onData: ({ type }) => {
      if (type === AccountSetupEventType.Deleted) {
        invalidateQueryCache(queryClient, [[AccountSetupQueryKeys.Junkyard]])
      }
    },
  })
}
