import { isAdminRole, useAuth } from 'common'

/**
 * Indicates if the authenticated user is an admin.
 * @returns {boolean}
 */
export function useIsCurrentUserAdmin() {
  const { me } = useAuth()
  return isAdminRole(me.roles[0])
}
