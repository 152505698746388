import { z } from 'zod'
import { AccountType } from './accounts'
import { accountMetadataSchema } from './cbdc-account-metadata'

export const externalAccountSchema = accountMetadataSchema
  .pick({ address: true, accountName: true, createdAt: true, updatedAt: true })
  .extend({
    accountId: z.string(), // UUID
    accountType: z.literal(AccountType.External),
    createdBy: z.string(), // UUID userId
    correlationId: z.string().optional(),
    destinationTag: z.number().optional(), // 32-bit unsigned int (0-4294967295)
    isActive: z.boolean(),
  })

export type ExternalAccount = z.infer<typeof externalAccountSchema>
