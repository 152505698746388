import {
  Role,
  RoleChangeStats,
  roleChangeStatsSchema,
  RolePermission,
  roleSchema,
  safeParseApiResponse,
} from 'common'
import { requestClient } from '../request-client'

const ROLES_URL = '/roles'

export const getRoles = (): Promise<Role[]> =>
  requestClient.get(ROLES_URL).then(safeParseApiResponse(roleSchema.array()))

export const getRoleById = (roleId: string): Promise<Role> =>
  requestClient
    .get(`${ROLES_URL}?roleId=${roleId}`)
    .then(safeParseApiResponse(roleSchema))

export const getRoleByName = (roleName: string): Promise<Role> =>
  requestClient
    .get(`${ROLES_URL}?roleName=${roleName}`)
    .then(safeParseApiResponse(roleSchema))

export const getDeleteRoleStats = (roleId: string): Promise<RoleChangeStats> =>
  requestClient
    .get(`${ROLES_URL}/${roleId}/role-delete-stats`)
    .then(safeParseApiResponse(roleChangeStatsSchema))

export const getUpdateRoleStats = (
  roleId: string,
  permissions: RolePermission[],
): Promise<RoleChangeStats> =>
  requestClient
    .post(`${ROLES_URL}/${roleId}/role-update-stats`, permissions)
    .then(safeParseApiResponse(roleChangeStatsSchema))
