import { MachineUserRole, Permission, Role } from '../models'

/**
 * Helper to know if the user role is an admin role.
 */
export const isAdminRole = (role?: Pick<Role, 'roleName'>) =>
  role?.roleName === 'System Admin'

/**
 * Checks if a role contains at least one signer permission.
 * @param role The role to check
 * @returns {boolean}
 */
export const roleHasSignPermission = (role?: Pick<Role, 'rolePermissions'>) =>
  !!role &&
  role?.rolePermissions.some(
    (rolePermission) => rolePermission.permission === Permission.Sign,
  )

/**
 * Helper to know if the user role is an machine user role.
 */
export const isMachineUserRole = (role?: Pick<Role, 'roleName'>) =>
  Object.values(MachineUserRole).includes(
    (role?.roleName ?? '') as MachineUserRole,
  )
