// Fields width (Account name, currency, quorum...)
export const FIELDS_WIDTH = 440

export const DEFAULT_QUORUM = 2

export const MAX_SIGNERS = 8

export const MIN_SIGNERS = 2

export const DEFAULT_SIGNER_WEIGHT = 1

export const MINIMUM_KEYPAIR_WEIGHT = 1

export const MAXIMUM_KEYPAIR_WEIGHT = 13

export const ACCOUNT_EDIT_FORM_ID = 'account-edit-form'

export enum AuthTrustLineOptions {
  Optional = 'auth-optional',
  Required = 'auth-required',
}
