import { FullUser } from '../users'
import { KeyPairWebSocketMessageData } from '../websockets'

export enum UsersEventType {
  Created = 'UserCreated',
  RoleUpdated = 'UserRoleUpdated',
  UserInfoUpdated = 'UserInfoUpdated',
  UserDeactivated = 'UserDeactivated',
  AccountsUpdated = 'UserAccountsUpdated',
  SignerStatusChanged = 'SignerStatusChanged',
}

type BaseUserUpdateEventData = {
  userId: string
  tenantId: string
}

export type UserUpdateEventData = {
  user: BaseUserUpdateEventData & Pick<FullUser, 'user'>
  roles: BaseUserUpdateEventData & Pick<FullUser, 'roles'>
  accounts: BaseUserUpdateEventData & Pick<FullUser, 'accounts'>
  keyPair: BaseUserUpdateEventData & KeyPairWebSocketMessageData
}

export type AnyUserUpdateEventData =
  UserUpdateEventData[keyof UserUpdateEventData]
