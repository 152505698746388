export default {
  'Account-Signers': 'Account Signers',
  'Signers-Quorum': 'Signers & Quorum',
  'New-Account': 'New Account',
  'Fund-Account': 'Fund Account',
  'Go-To-Account': 'Go To Account',
  'View-Accounts': 'View Accounts',
  'Delete-Account': 'Delete Account',
  'Weight-for-Signing': 'Weight for Signing',
  'Add-Users': 'Add Users',
  'All-Accounts': 'All Accounts',
  'Issuer-Account-Address': 'Issuer Account Address',
  'Issuer-Account-Name': 'Issuer Account Name',
  'Freeze-Account': 'Freeze Account',
  'Unfreeze-Account': 'Unfreeze Account',
  'Unfreeze-Accounts': 'Unfreeze Accounts',
  'No-Active-Keypair': 'This user has no active keypair to sign for transactions',
  'Remove-Signer': 'Remove Signer',
  'Cannot-Remove-Signer.hover-label': 'You cannot remove this signer. The account must have at least 2 signers.',
  'No-Queued-Transactions': 'No queued transactions',
  'Add-Signer': 'Add Signer',
  'View-Request': 'View Request',
  'Set-Up-Trustline': 'Set Up Trust Line',
  'Setting-Up-Trustline': 'Setting up Trust Line...',
  'loading-account': 'Loading account...',
  'loading-accounts': 'Loading accounts...',
  'loading-users': 'Loading users on account...',
  'no-issuer-accounts': 'No issuer accounts found.',
  'no-account': 'No account found with the address {{address}}',
  'no-accounts': 'No accounts found.',
  'no-accounts-yet': 'No accounts yet',
  'no-accounts-yet.description': 'You have not been added to any accounts.',
  'no-accounts.description': 'This user has not been added to any accounts in the system.',
  'issuer-trustline.pending': 'The trust line on this account is in review with the issuer signers. Once approved, the authorized account will be able to send and receive currency.',
  'issuer-trustline.retry': 'The request to approve the trustline expired before the issuer signers could review it. Please retry the request.',
  'no-issuer-account': 'No issuer account.',
  'no-issuer-account.description': 'You must create an issuer account to begin minting currency.',
  'no-users': 'No users associated with the account',
  'no-users.cta': 'Finish configuring the account and associate users with it.',
  'Create-Account': 'Create Account',
  'Create-External-Account': 'Create External Account',
  'Change-Log': 'Change Log',
  'change-log.account-setup-incomplete': 'Account setup is incomplete.',
  'change-log.no-changes': 'No account changes yet.',
  'change-log.no-changes.description': 'No account change requests have been made for this account.',
  'issuer-edit-pending': 'The issuer account has a pending account edit. New {{txnType}} requests cannot be initiated until the edit is completed.',
  'edit-success': 'Account name successfully updated.',
  'edit-error': 'Could not update the account name. Please try again.',
  'edit-lag': "The account details couldn't be retrieved for an immediate UI update but the name edit request has been submitted. Contact support if the error persists.",
  'edit-description': 'Edit account name and summarize the reason for the edit.',
  'edit-signers': 'Edit Account Signers of {{accountName}}',
  'edit-signers.success': 'Edit request successfully created',
  'edit-signers.error': "Account couldn't be edited. Please try again.",
  'approval-required': 'These Edits Require Approval',
  'approval-description': 'The selected edits must be approved by the account signers. Until they are approved or rejected, no new transactions can be created using this account.',
  'approval-confirm': 'If you\'d like to continue with this edit request, click "Continue."',
  'remove-users-confirm': 'Users who are removed from the account will no longer be able to see or access this account.',
  'remove-users-title': 'Remove $t(plurals:users, {"count": {{numUsersToRemove}}})?',
  'remove-users-error': 'Something went wrong removing users',
  'remove-users-success_one': 'Successfully removed {{count}} user',
  'remove-users-success_other': 'Successfully removed {{count}} users',
  'no-authorized-payment-users': 'There are no users on the account who can initiate a transaction. To begin making transactions, add a user who can initiate transactions.',
  'signer-request-pending': 'There is a signers/weight edit request "Pending". Certain actions on this account have been disabled while the request is being reviewed.',
  'signer-request-pending-signer': 'You have a signers/weight edit request "Pending" that needs your attention. Please review the request details and sign if applicable.',
  'account-frozen-pending-unfreeze': 'This account is currently frozen. Submitted transactions will fail until the pending unfreeze request has been approved.',
  'account-frozen': 'This account is currently frozen. Submitted transactions will fail until the account is unfrozen.',
  'accounts-frozen': 'All accounts in the system are currently frozen.',
  'accounts-frozen.transactions': 'Submitted transactions will fail until accounts are unfrozen.',
  'freeze-request.success': 'Account freeze request successfully submitted.',
  'unfreeze-request.success': 'Account unfreeze request successfully submitted.',
  'freeze-request.error': 'Something went wrong submitting the freeze request',
  'unfreeze-request.error': 'Something went wrong submitting the unfreeze request',
  'global-freeze-request.success': 'Global freeze request successfully submitted.',
  'global-unfreeze-request.success': 'Global unfreeze request successfully submitted.',
  'unfreeze-request.pending': 'A global unfreeze request is pending.',
  'global-freeze-request.pending': 'A global freeze request is pending. Once submitted to the ledger, all accounts in the system will be frozen.',
  'freeze-request.pending': 'A freeze request for this account has been sent to the issuer account signers for approval.',
  'account-action.target.freeze': 'Enter the wallet address of the account you want to freeze.',
  'account-action.target.unfreeze': 'Enter the wallet address of the account you want to unfreeze.',
  'account-action.individual.freeze': 'Freeze a single account',
  'account-action.individual.unfreeze': 'Unfreeze a single account',
  'account-action.global.freeze': 'Freeze all accounts',
  'account-action.global.unfreeze': 'Unfreeze all accounts',
  'account-global.view-docs': 'Refer to the documentation to learn how this will impact your account.',
  'action-global.confirm.freeze': 'Are you sure you want to freeze all accounts?',
  'action-global.confirm.unfreeze': 'Are you sure you want to unfreeze all accounts?',
  'confirm-freeze-global': 'Confirm global freeze',
  'confirm-unfreeze-global': 'Confirm global unfreeze',
  'freeze-destination.freeze': 'Choose freeze destination',
  'freeze-destination.unfreeze': 'Choose freeze destination',
  'freeze-destination.target.freeze': 'Choose which account (or accounts) you want to freeze.',
  'freeze-destination.target.unfreeze': 'Choose which account (or accounts) you want to unfreeze.',
  'freeze-destination.individual': 'Freeze a single account',
  'account-action.freeze.success': 'Freeze request has been sent for review.',
  'account-action.unfreeze.success': 'Unfreeze request has been sent for review.',
  'account-action.freeze.error': 'Could not create your freeze request',
  'account-action.unfreeze.error': 'Could not create your unfreeze request',
  'Individual-Account': 'Individual Account',
  'Global-Account': 'Global Account',
  'Global-Freeze': 'Global Freeze',
  'Global-Unfreeze': 'Global Unfreeze',
  'Account-Freeze': 'Account Freeze',
  'Account-Unfreeze': 'Account Unfreeze',
  Freeze: 'Freeze',
  Unfreeze: 'Unfreeze',
  'Account-Flag': 'Account Flag',
  'Account-Balance': 'Account Balance',
  'Account-Details': 'Account Details',
  'Sign-Request': 'Sign Request',
  'Currency-Code': 'Currency Code',
  'Unknown-Name': 'Unknown Name',
  'Unknown-Account': 'Unknown Account',
  'Change-Request-Overview': 'Change Request Overview',
  'no-pending-accounts': 'No accounts pending configuration.',
  'no-freeze-requests': 'No freeze requests yet.',
  'no-freeze-requests.instructions': 'Create freeze and unfreeze requests to be signed by the Issuer signers.',
  'cancel-request.error': 'We encoutered an error canceling your request',
  'cancel-request.success': 'Your request has been cancelled',
  'cancel-request.prompt': 'Are you sure you want to cancel this request?',
  'cancel-request.proceed': 'Yes, cancel',
  'cancel-request.cancel': 'Never mind',
  'edit-request.success': 'Edit request submitted to the Ledger',
  'edit-request.signed': 'Successfully signed the edit request',
  'edit-request.error': 'Error submitting the edit request',
  'create-account.success': 'Account successfully created',
  'create-account.error': 'Could not create the account',
  'create-account.acknowledgement': 'I acknowledge that once created, edits to this account will require signing from the account signers.',
  'Authorized-Trustlines': 'Authorized Trust Lines',
  'Authorized-Trustlines.tooltip': 'When enabled, new accounts that establish a trust line to the issuer will first need to be approved by the issuer signers.',
  'Issuer-Settings': 'Issuer Settings',
  'label.require-auth-trustlines': 'Require authorized trust lines',
  'label.do-not-require-auth-trustlines': 'Do not require authorized trust lines',
  'issuer-settings-info-toast': 'Once your issuer account is created on ledger, the currency code and authorized trust line selection cannot be modified.',
  'Create-Issuer-Account': 'Create Issuer Account',
  'create-external-account.success': 'External account successfully created',
  'create-external-account.error': 'Could not create the external account',
  'loading-external-accounts': 'Loading external accounts...',
  'no-external-accounts': 'No external accounts have been created',
  'undefined-account': 'Account undefined',
  'Currency-Unknown': 'Currency Unknown',
  'Address-Unknown': 'Address Unknown',
  'Name-Unknown': 'Name Unknown',
  'Authorize-Signers': 'Authorize Signers',
  'Authorizing-Signers': 'Authorizing Signers...',
  'Account-Field': 'Account Field',
  'Issuer-Required': 'Issuer Required',
  'Issuer-Disabled': 'Issuer Disabled',
  'learn-more-require-auth': 'Learn more about require auth',
  'learn-more-disallow-xrp': 'Learn more about disallow xrp',
  'learn-more-rippling': 'Learn more about Rippling',
  'learn-more-destination-tag': 'Learn more about destination tags',
  'learn-more-ticksize': 'Learn more about TickSize',
  'account-setup.flags.disallow-xrp': 'Disallow XRP',
  'account-setup.flags.default-rippling': 'Default Rippling',
  'account-setup.flags.require-destination-tag': 'Require Destination Tag',
  'account-setup.flags.tick-size': 'TickSize',
  'account-setup.preparing-transaction': 'Preparing transaction for signing...',
  'account-setup.trustline.title': 'Please review the issuer and {{accountType}} account details below to establish a trust line.',
  'account-setup.trustline.success': 'The trust line between the {{accountType}} and issuer account has been created.',
  'account-setup.signers.instructions': 'Sign transaction to add the account signers to the account.',
  'account-setup.signers.success': 'The account signers have been confirmed.',
  'Enable-Flags-and-TickSize': 'Enable Flags and TickSize',
  'Enable-Flags': 'Enable Flags',
  'Enabling-Flags': 'Enabling Flags...',
  'Disable-Master-Key': 'Disable Master Key',
  'Disabling-Master-Key': 'Disabling Master Key...',
  'Connect-Ripple-Custodied-Key': 'Connect Ripple Custodied Key',
  'account-setup.flags.instructions': 'Sign the AccountSet transaction to enable the above flags.',
  'account-setup.flags.success.0': 'Flags and TickSize successfully enabled.',
  'account-setup.flags.success.1': 'Flags successfully enabled.',
  'account-setup.info.custodial.title': 'To finish configuring the account, associate it with a Ripple custodied keypair.',
  'account-setup.master-key.title': 'To finish account configuration, disable the master key to secure the account.',
  'account-setup.master-key.instructions': 'Once you disable the Master Key, only account signers will be able to sign transactions on the account.',
  'account-setup.master-key.success': 'The Master Key of your account has been disabled.',
  'account-setup.fund.title': 'Scan the QR code to fund the account, {{accountName}}. You can fund this account using any wallet.',
  'account-setup.fund.address-not-available': 'The account address is not available',
  'Finish-Account-Setup': 'Finish Account Setup',
  'account-setup.info.ledger.title': 'To finish setting up the account, begin by plugging and connecting in your Ledger Nano.',
  'account-setup.flags.title': 'Please review the flags below to enable them on the {{accountType}} account.',
  'account-setup.completed.no-account-id': 'Could not get the account id',
  'account-setup.loading': 'Loading next step...',
  'account-setup.websocket-error': 'Failed to parse websocket account setup message. {{error}}',
  'account-setup.completed.title': 'The account has been configured. {{accountName}} is now ready to transact.',
  'account-setup.signers.title': 'Review the following signers to authorize them to sign for transactions on the {{accountType}} account.',
  'account-setup.status.waiting': 'Waiting for you to sign the {{transactionType}} transaction on your Ledger Nano...',
  'account-setup.status.submitting': 'Submitting the transaction to the ledger...',
  'account-setup.status.queued': 'Transaction queued...',
  'account-setup.status.retrieving-account': 'Retrieving account from your Ledger Nano...',
  'account-setup.ledger-nano.configuring-account': 'Configuring {{accountName}}',
  'account-setup.account-not-found': 'Account not found.',
  'account-setup.account-details-not-found': 'Account details not found.',
  'account-setup.account-address-not-found': 'Account address not found.',
  'account-setup.ledger-nano.transaction-bytes-not-found': 'Transaction bytes not found.',
  'account-setup.ledger-nano.transaction-signature-not-found': 'Transaction signature not found.',
  'account-setup.ledger-nano.save-key.failed': 'Could not save the public key.',
  'account-setup.ledger-nano.save-signature.failed': 'Could not save the signature.',
  'account-setup.ledger-nano.save-signature.queued': 'The transaction is still queued and can not be processed.',
  'account-setup.ledger-nano.save-address.loading': 'Finding an available address...',
  'account-setup.ledger-nano.save-address.saving': 'Saving the address in the database...',
  'account-setup.ledger-nano.save-address.success': 'The account has been associated with an address.',
  'account-setup.ledger-nano.save-address.failed': 'The address could not be saved. Trying to find another available address...',
  'account-setup.ledger-nano.fund-account.waiting': 'Waiting for the {{accountName}} to be funded...',
  'account-setup.ledger-nano.fund-account.loading': 'Funding {{accountName}}...',
  'account-setup.ledger-nano.fund-account.success': '{{accountName}} has been successfully funded.',
  'account-setup.your-account': 'your account',
  'account-setup.assigning-address': 'Assigning an address to your account...',
  'account-setup.associated-account': 'Your account has been associated with an address.',
  'account-setup.keypair-error': "A Ripple custodied keypair couldn't be generated.",
  'account-setup.info.ledger.connect.title': 'Connect Ledger Nano',
  'unfreeze-account.prompt': 'Are you sure you want to submit an unfreeze request for this account? It will be sent to the signers for review.',
  'freeze-account.description.0': 'If you freeze this account, submitted transactions will fail.',
  'freeze-account.description.1': 'To submit a freeze account request, click “Freeze” and it will be sent to the signers for review.',
  'freeze-account.address-field-label': 'Select account or enter address',
  'delete-account.success': 'The account has been deleted!',
  'delete-account.error': 'Could not delete the account.',
  'delete-account.prompt': 'Are you sure you want to delete the account <Strong>{{name}}</Strong>?',
  'delete-account.confirm': 'Yes, delete',
  'account-actions.loading': 'Loading account actions...',
  'account-edits.loading': 'Loading account edit requests...',
  'xrp-not-valid': 'XRP is not a valid currency code',
  'name-in-use': 'Name is already in use',
  'Funds-Available': 'Funds Available',
  Unconfigured: 'Unconfigured',
  'configure-account.title': 'Configure your account on the ledger',
  'configure-account.description': 'Before you can connect with the issuer to receive currency, you must first configure the account on the ledger.',
  'unconfigured-account.pending': 'Pending configuration...',
  'No-Pending-Transactions': 'No pending transactions',
  'Get-Started': 'Create a new transaction to get started.',
  'View-All-Queued': 'View All Queued',
  'Queued-Transactions': 'Queued Transactions',
  'Loading-Queued-Transactions': 'Loading queued transactions...',
  'Hide-Signers': 'Hide Signers',
  'Show-Signers': 'Show Signers',
  'Available-Balance': 'Available Balance',
  'Submit-For-Approval': 'Submit for Approval',
  'Signers-Must-Be-Members': 'Users must be added to the account before they are able to be selected as account signers.',
  'Loading-accounts-signing-keypairs': 'Loading accounts to determine if the keypair can be deactivated.',
  'Name-Change': 'Name Change',
  'N/A': 'N/A',
  'account-edit-cancel.error': 'Account edit could not be cancelled.',
  'account-edit-cancel.success': 'Account edit cancelled successfully.',
  'Signer-List': 'Signer List',
  'Proposed-Signer-List': 'Proposed Signer List',
  'Account-Name': 'Account Name',
  'signers-list-instructions': 'You must select at least 2 account signers, up to a maximum of 8.',
  'my-accounts': 'My Accounts',
  'external-accounts': 'External Accounts',
  'pending-configuration': 'Pending Configuration',
  'max-eligible-signers': 'There are only {{eligibleSigners}} eligible signers for this account',
  'max-signers': 'The maximum number of signers have been added to this account',
  'cannot-remove-signer': 'This user is a signer on this account. You must remove them as a Signer to make edits.',
  'Configuration-Key': 'Configuration Key',
  'Configuration-Key-Instructions': 'Select the key type to use to configure this account on ledger.',
  'Configure-Issuer': 'Configure Issuer',
  'needs-issuer-setup': 'Issuer account is pending configuration',
  'needs-issuer-setup.description': 'Configure your issuer account to create more accounts and start issuing funds.',
  'external-account.trustline-acknowledgement': 'I acknowledge that once created, trustlines between an external account and the issuer cannot be removed, only frozen. <Link>View docs</Link>',
}
