import {
  AccountType,
  CbdcAccount,
  cbdcAccountArraySchema,
  CbdcAccountMetadata,
  cbdcAccountMetadataArraySchema,
  cbdcAccountSchema,
  IssuerMetadata,
  issuerMetadataSchema,
  PaginationOptions,
  PayAccount,
  payAccountArraySchema,
  safeParseApiResponse,
  TenantWideCbdcAccountBalances,
  tenantWideCbdcAccountBalancesSchema,
} from 'common'
import { requestClient } from '../request-client'

export const getAccountByAddress = (address: string) =>
  requestClient
    .get(`/admin/cbdcAccounts/${address}`)
    .then(safeParseApiResponse(cbdcAccountSchema))

export const getAccountsMetadata = (): Promise<CbdcAccountMetadata[]> =>
  requestClient
    .get('/cbdcAccounts/metadata')
    .then(safeParseApiResponse(cbdcAccountMetadataArraySchema))

export const getPayAccounts = (
  accountTypes: AccountType[],
): Promise<PayAccount[]> =>
  requestClient
    .get(
      `/cbdcAccounts/pay-accounts-all?accountTypes=${accountTypes.join(',')}`,
    )
    .then(safeParseApiResponse(payAccountArraySchema))

export const getAccountsBalances = (): Promise<TenantWideCbdcAccountBalances> =>
  requestClient
    .get('/cbdcAccounts/tenant-wide-cbdc-account-balances')
    .then(safeParseApiResponse(tenantWideCbdcAccountBalancesSchema))

export const getAccounts = (
  params: PaginationOptions = {},
): Promise<CbdcAccount[]> =>
  requestClient
    .get('/cbdcAccounts', { params })
    .then(safeParseApiResponse(cbdcAccountArraySchema))

export const getIssuersMetadata = (): Promise<IssuerMetadata[]> =>
  requestClient
    .get('/cbdcAccounts/issuers-metadata')
    .then(safeParseApiResponse(issuerMetadataSchema.array()))
