import { useMemo } from 'react'
import { useAuth } from '../auth'

// make sure the url has a trailing slash for when we append stuff to it
const maybeAddSlash = (url: string) => (/\/$/.test(url) ? url : `${url}/`)

export function useExplorerURLs() {
  const { me } = useAuth()

  return useMemo(() => {
    const base = me.tenant?.explorerUrl ?? ''
    const safeBase = maybeAddSlash(base)
    return {
      explorerUrl: base,
      getAccountUrl: (address: string) => `${safeBase}accounts/${address}`,
      getTransactionUrl: (hash: string) =>
        `${safeBase}transactions/${hash}/simple`,
    }
  }, [me.tenant.explorerUrl])
}
