export default {
  'Account-Signers': 'Firmantes',
  'Signers-Quorum': 'Firmantes y Quórum',
  'New-Account': 'Nueva Cuenta',
  'Fund-Account': 'Fondear Cuenta',
  'Go-To-Account': 'Ir a la Cuenta',
  'View-Accounts': 'Ver cuentas',
  'Delete-Account': 'Borrar Cuenta',
  'Weight-for-Signing': 'Peso de Firma',
  'Add-Users': 'Añadir Usuarios',
  'All-Accounts': 'Todas las Cuentas',
  'Issuer-Account-Address': 'Dirección de la Cuenta Emisora',
  'Issuer-Account-Name': 'Nombre de la Cuenta Emisora',
  'Freeze-Account': 'Bloquear Cuenta',
  'Unfreeze-Account': 'Desbloquear Cuenta',
  'Unfreeze-Accounts': 'Desbloquear Cuentas',
  'Remove-Signer': 'Eliminar Firmante',
  'Cannot-Remove-Signer.hover-label': 'No puede eliminar este firmante. La cuenta debe tener al menos 2 firmantes.',
  'No-Queued-Transactions': 'Sin transacciones en cola',
  'Add-Signer': 'Añadir Firmante',
  'View-Request': 'Ver Petición',
  'Set-Up-Trustline': 'Crear Línea de Confianza',
  'Setting-Up-Trustline': 'Configurando Línea de Confianza...',
  'loading-account': 'Cargando cuenta...',
  'loading-accounts': 'Cargando cuentas...',
  'loading-users': 'Cargando usuarios de la cuenta...',
  'no-issuer-accounts': 'No se encontraron cuentas de emisor.',
  'no-account': 'No se ha encontrado cuenta con la dirección {{address}}',
  'no-accounts': 'No se han encontrado cuentas.',
  'no-accounts-yet': 'Aún no hay cuentas',
  'no-accounts-yet.description': 'No has sido agregado a ninguna cuenta.',
  'no-accounts.description': 'Este usuario no ha sido agregado a ninguna cuenta en el sistema.',
  'issuer-trustline.pending': 'La línea de confianza de esta cuenta está en revisión con los firmantes emisores. Una vez aprobada, la cuenta autorizada podrá enviar y recibir moneda.',
  'issuer-trustline.retry': 'La solicitud para aprobar la línea de confianza expiró antes de que los firmantes del emisor pudieran revisarla. Vuelva a intentar la solicitud.',
  'no-issuer-account': 'Sin cuenta de emisor.',
  'no-issuer-account.description': 'Debe crear una cuenta de emisor para comenzar a acuñar moneda.',
  'no-users': 'No hay usuarios asociados a esta cuenta',
  'no-users.cta': 'Termina de configurar la cuenta y sus usuarios asociados.',
  'Create-Account': 'Crear Cuenta',
  'Create-External-Account': 'Crear Cuenta Externa',
  'Change-Log': 'Log de Cambios',
  'change-log.account-setup-incomplete': 'No se ha completado la configuración de la Cuenta.',
  'change-log.no-changes': 'Aún no hay cambios en la cuenta.',
  'change-log.no-changes.description': 'No se han realizado solicitudes de cambio de cuenta para esta cuenta.',
  'issuer-edit-pending': 'La cuenta emisora tiene un cambio pendiente. Nuevas peticiones de {{txnType}} no pueden ser iniciadas hasta que el cambio sea completado.',
  'edit-success': 'Nombre de cuenta actualizado con éxito.',
  'edit-error': 'No se pudo actualizar el nombre de la cuenta. Inténtalo de nuevo.',
  'edit-lag': 'No se han podido cargar los detalles de la cuenta aunque la petición de cambio de nombre ha sido enviada. Contacta con soporte si el error persiste.',
  'edit-description': 'Cambia el nombre de la cuenta y describe la razón del cambio',
  'edit-signers': 'Cambia los Firmantes de la Cuenta {{accountName}}',
  'edit-signers.success': 'Petición de cambio creada correctamente',
  'edit-signers.error': 'No se ha podido procesar el cambio en la Cuenta. Por favor, inténtelo de nuevo.',
  'approval-required': 'Estos Cambios requieren Aprobación',
  'approval-description': 'Los cambios seleccionados deben ser aprobados por los firmantes de la cuenta. Hasta que estos no sean aprobados o rechazados no se podrán realizar nuevas transacciones sobre esta cuenta.',
  'approval-confirm': 'Haga click en "Continuar" si desea progresar con esta petición de cambio.',
  'remove-users-confirm': 'Los Usuarios que se eliminan de la cuenta dejaran de verla o tener acceso a ella.',
  'remove-users-title': '¿Eliminar ${numUsersToRemove} $t(plurals:users, {"count": {{numUsersToRemove}}})?',
  'remove-users-error': 'Algo ha fallado tratando de eliminar los usuarios',
  'remove-users-success_one': '{{count}} usuario eliminado correctamente',
  'remove-users-success_other': '{{count}} usuarios eliminados correctamente',
  'no-authorized-payment-users': 'No hay usuarios en la cuenta que puedan iniciar una transacción. Para comenzar a realizar transacciones, añada un usuario que pueda iniciarlas.',
  'signer-request-pending': 'Hay una petición de cambio de firmantes/peso "En Revisión". Algunas acciones en esta cuenta han sido deshabilitadas mientras la petición es revidada.',
  'signer-request-pending-signer': 'Hay una petición de cambio de firmantes/peso "En Revisión" que requiere su atención. Por favor, revise los detalles de la petición.',
  'account-frozen-pending-unfreeze': 'Esta cuenta está actualmente bloqueada. Cualquier transacción enviada fallará hasta la petición de desbloqueo sea aprobada.',
  'account-frozen': 'Esta cuenta está actualmente bloqueada. Cualquier transacción enviada fallará hasta la cuenta sea desbloqueada.',
  'accounts-frozen': 'Todas las cuentas en el sistema estan bloqueadas en este momento.',
  'accounts-frozen.transactions': 'Cualquier transacción enviada fallará hasta que las cuentas sean desbloqueadas.',
  'freeze-request.success': 'Petición de bloqueo de Cuenta enviada correctamente.',
  'freeze-request.error': 'Se ha producido un error enviando la petición de bloqueo de Cuenta.',
  'global-freeze-request.success': 'Petición de bloqueo Global de Cuenta enviada correctamente.',
  'unfreeze-request.pending': 'Existe una petición de desbloqueo global en revision.',
  'freeze-request.pending': 'Se ha enviado una petición de bloqueo para esta cuenta a los firmantes de la cuenta emisora para su aprobación',
  'account-global.view-docs': 'Consulte la documentación para saber cómo afectará esto a su cuenta.',
  'confirm-freeze-global': 'Confirme bloqueo global',
  'confirm-unfreeze-global': 'Confirme desbloqueo global',
  'freeze-destination.individual': 'Bloquear una cuenta',
  'Individual-Account': 'Cuenta Individual',
  'Global-Account': 'Cuenta Global',
  'Global-Freeze': 'Bloqueo Global',
  'Global-Unfreeze': 'Desbloqueo Global',
  'Account-Freeze': 'Bloqueo de Cuenta',
  'Account-Unfreeze': 'Desloqueo de Cuenta',
  Freeze: 'Bloqueo',
  Unfreeze: 'Desbloqueo',
  'No-Active-Keypair': 'Este usuario no tiene un par de claves activas para firmar transacciones',
  'Account-Flag': 'Parámetro de la Cuenta',
  'Account-Balance': 'Balance de la Cuenta',
  'Account-Details': 'Detalles de la Cuenta',
  'Sign-Request': 'Firmar Peticiión',
  'Currency-Code': 'Código de Moneda',
  'Unknown-Name': 'Nombre desconocido',
  'Unknown-Account': 'Cuenta desconocida',
  'Change-Request-Overview': 'Descripcion del Cambio',
  'no-pending-accounts': 'No hay cuentas pendientes de configuración.',
  'no-freeze-requests': 'Aún no hay solicitudes de congelación.',
  'no-freeze-requests.instructions': 'Cree solicitudes de congelación y descongelación para que las firmen los firmantes del Emisor.',
  'cancel-request.prompt': '¿Está seguro de cancelar esta petición?',
  'cancel-request.proceed': 'Si, cancelar',
  'cancel-request.cancel': 'No estoy seguro',
  'edit-request.success': 'Petición de cambio enviada el Registro Contable',
  'edit-request.signed': 'Se ha firmado correctamente la petición de cambio',
  'edit-request.error': 'Error enviando la petición de cambio',
  'create-account.success': 'Cuenta creada correctamente',
  'create-account.error': 'Se ha producido un error al crear la cuenta',
  'create-account.acknowledgement': 'Reconozco que una vez creada, las modificaciones en esta cuenta requerirán la firma de los firmantes de la cuenta.',
  'Authorized-Trustlines': 'Líneas de confianza autorizadas',
  'Authorized-Trustlines.tooltip': 'Cuando esté habilitado, las cuentas nuevas que establezcan una línea de confianza con el emisor primero deberán ser aprobadas por los firmantes del emisor.',
  'Issuer-Settings': 'Configuración del emisor',
  'label.require-auth-trustlines': 'Requerir líneas de confianza autorizadas',
  'label.do-not-require-auth-trustlines': 'No requiere líneas de confianza autorizadas',
  'issuer-settings-info-toast': 'Una vez que se crea su cuenta de emisor en el libro mayor, el código de moneda y la selección de la línea de confianza autorizada no se pueden modificar.',
  'Create-Issuer-Account': 'Crear cuenta de emisor',
  'create-external-account.success': 'Cuenta Externa creada correctamente',
  'create-external-account.error': 'No se ha podido crear la Cuenta Externa',
  'loading-external-accounts': 'Cargando cuentas externas...',
  'no-external-accounts': 'No se han creado cuentas externas',
  'undefined-account': 'Cuenta no definida',
  'Currency-Unknown': 'Moneda no definida',
  'Address-Unknown': 'Dirección no definida',
  'Name-Unknown': 'Nombre desconocido',
  'Authorize-Signers': 'Autorizar firmantes',
  'Authorizing-Signers': 'Autorizando firmantes...',
  'Account-Field': 'Parámetro de Cuenta',
  'Issuer-Required': 'Emisor requerido',
  'Issuer-Disabled': 'Emisor deshabilitado',
  'learn-more-require-auth': 'Más información sobre requerir autenticación',
  'learn-more-disallow-xrp': 'Más información sobre no aceptación de XRP',
  'learn-more-rippling': 'Más información sobre Rippling',
  'learn-more-destination-tag': 'Más información sobre etiquetas de destino',
  'learn-more-ticksize': 'Más información sobre TickSize',
  'account-setup.flags.disallow-xrp': 'No permitir XRP',
  'account-setup.flags.default-rippling': 'Ondulación predeterminada',
  'account-setup.flags.require-destination-tag': 'Requerir etiqueta de destino',
  'account-setup.flags.tick-size': 'TickSize',
  'account-setup.preparing-transaction': 'Preparando la transacción para su firma...',
  'account-setup.trustline.title': 'Revise los detalles del emisor y de la cuenta {{accountType}} a continuación para establecer una línea de confianza.',
  'account-setup.trustline.success': 'La línea de confianza entre las cuentas {{accountType}} y emisora ha sido creada',
  'account-setup.signers.instructions': 'Firme la transacción para añadir los firmantes a la cuenta.',
  'account-setup.signers.success': 'Los firmantes de la cuenta han sido confirmados.',
  'Enable-Flags-and-TickSize': 'Habilitar Parámetros y Ticksize',
  'Enable-Flags': 'Habilitar Parámetros',
  'Enabling-Flags': 'Habilitando banderas...',
  'Disable-Master-Key': 'Deshabilitar Clave Privada',
  'Disabling-Master-Key': 'Desactivando la clave maestra...',
  'Connect-Ripple-Custodied-Key': 'Conectar Clave Custodiada por Ripple',
  'account-setup.flags.instructions': 'Firme la siguiente transaccion para habilitar los parámetros de cuenta indicados arriba.',
  'account-setup.flags.success.0': 'Parámetros de cuenta y Ticksize habilitados correctamente.',
  'account-setup.flags.success.1': 'Parámetros de cuenta habilitados correctamente.',
  'account-setup.info.custodial.title': 'Para terminar la configuración de la cuenta, asociela con un par de claves custodiadas por Ripple.',
  'account-setup.master-key.title': 'Para finalizar la configuración de la cuenta, deshabilite la clave maestra para proteger la cuenta.',
  'account-setup.master-key.instructions': 'Una vez que deshabilite la clave maestra, solo los firmantes de la cuenta podrán firmar transacciones en la cuenta.',
  'account-setup.master-key.success': 'La Clave Principal de la cuenta tipo ha sido deshbailitada.',
  'account-setup.fund.title': 'Escane el codigo QR para añadir fondos a la cuenta {{accountName}}. Puede hacerlo utilizando cualquier billetera.',
  'account-setup.fund.address-not-available': 'La dirección de la cuenta no esta disponible',
  'Finish-Account-Setup': 'Finalizar la Configuración de la Cuenta',
  'account-setup.info.ledger.title': 'Para finalizar la configuración de la cuenta, conecte su Ledger Nano.',
  'account-setup.flags.title': 'Revise las banderas a continuación para habilitarlas en la cuenta {{accountType}}.',
  'account-setup.completed.no-account-id': 'No se pudo obtener el id de la cuenta',
  'account-setup.loading': 'Cargando próximo paso...',
  'account-setup.websocket-error': 'Error procesando el mensaje (websocket) de configuración de cuenta. {{error}}',
  'account-setup.completed.title': 'La cuenta ha sido configurada. {{accountName}} está lista para operar.',
  'account-setup.signers.title': 'Revise los siguientes firmantes para autorizarlos a firmar transacciones en la cuenta {{accountType}}.',
  'account-setup.status.waiting': 'Esperando a que firme la transacción {{transactionType}} en su Ledger Nano...',
  'account-setup.status.submitting': 'Enviando la transacción al registro contable...',
  'account-setup.status.queued': 'Transacción añadida a la cola...',
  'account-setup.status.retrieving-account': 'Obetniendo la cuenta desde su Ledger Nano...',
  'account-setup.ledger-nano.configuring-account': 'Configurando {{accountName}}',
  'account-setup.account-not-found': 'Cuenta no encontrada.',
  'account-setup.account-details-not-found': 'No se han encontrado datos para la cuenta.',
  'account-setup.account-address-not-found': 'No se ha encontrado dirección para la cuenta.',
  'account-setup.ledger-nano.transaction-bytes-not-found': 'No se han encontrado bytes para la transacción.',
  'account-setup.ledger-nano.transaction-signature-not-found': 'No se ha encontrado la firma para la transaccción.',
  'account-setup.ledger-nano.save-key.failed': 'No se ha podido guardar la clave pública.',
  'account-setup.ledger-nano.save-signature.failed': 'No se ha podido guardar la firma.',
  'account-setup.ledger-nano.save-signature.queued': 'La transacción se encuentra todavía en la cola y no puede ser procesada.',
  'account-setup.ledger-nano.save-address.loading': 'Buscando una dirección disponible...',
  'account-setup.ledger-nano.save-address.saving': 'Guardando la dirección en la base de datos...',
  'account-setup.ledger-nano.save-address.success': 'La cuenta ha sido asociada a una dirección.',
  'account-setup.ledger-nano.save-address.failed': 'La dirección no ha podido ser guardada. Intentando buscar otra dirección disponible...',
  'account-setup.ledger-nano.fund-account.waiting': 'Esperando a que fondee la cuenta {{accountName}}...',
  'account-setup.ledger-nano.fund-account.loading': 'Fondeando {{accountName}}...',
  'account-setup.ledger-nano.fund-account.success': 'La cuenta {{accountName}} ha sido fondeada correctamente.',
  'account-setup.your-account': 'su cuenta',
  'account-setup.assigning-address': 'Asignando una dirección a su cuenta...',
  'account-setup.associated-account': 'Su cuenta ha sido asociada a una dirección.',
  'account-setup.keypair-error': 'No se ha podido generar un para de claves custodiadas por Ripple.',
  'account-setup.info.ledger.connect.title': 'Conectar el Ledger Nano',
  'unfreeze-account.prompt': '¿Está seguro de que quiere enviar una petición de desbloqueo para esta cuenta? Será enviada a los firmantes de la cuenta para su revisión.',
  'freeze-account.description.0': 'Si bloquea esta cuenta, cualquier futura transacción fallará.',
  'freeze-account.description.1': 'Para enviar una petición de bloqueo de cuenta, haga click en "Bloquear Cuenta". Esta será enviada a los firmantes para su revisión.',
  'freeze-account.address-field-label': 'Seleccionar cuenta o ingresar dirección',
  'delete-account.success': '¡La cuenta ha sido eliminada!',
  'delete-account.error': 'No se ha podido eliminar esta cuenta.',
  'delete-account.prompt': '¿Está seguro de que quiere eliminar la cuenta <Strong>{{name}}</Strong>?',
  'delete-account.confirm': 'Si, eliminar',
  'account-actions.loading': 'Cargando acciones en la cuenta...',
  'account-edits.loading': 'Cargando peticiones de cambio de la cuenta...',
  'xrp-not-valid': 'XRP no es un código de moneda válido',
  'name-in-use': 'El nombre ya se encuentra en uso',
  'Funds-Available': 'Fondos Disponibles',
  Unconfigured: 'Desconfigurado',
  'configure-account.title': 'Configure su cuenta en el libro mayor',
  'configure-account.description': 'Antes de poder conectarse con el emisor para recibir moneda, primero debe configurar la cuenta en el libro mayor.',
  'unconfigured-account.pending': 'Configuración pendiente...',
  'No-Pending-Transactions': 'No hay transacciones',
  'Get-Started': 'Cree una transacción para comenzar.',
  'View-All-Queued': 'Ver todas en la cola',
  'Queued-Transactions': 'Transacciones en cola',
  'Loading-Queued-Transactions': 'Cargando transacciones en cola...',
  'Hide-Signers': 'Ocultar Firmantes',
  'Show-Signers': 'Mostrar Firmantes',
  'Available-Balance': 'Balance Disponible',
  'Submit-For-Approval': 'Enviar para Aprobación',
  'unfreeze-request.success': 'La petición de desbloqueo de cuenta ha sido enviada correctamente.',
  'unfreeze-request.error': 'Se ha producido un error en la petición de desbloqueo de cuenta.',
  'global-unfreeze-request.success': 'La petición de desbloqueo global ha sido enviada correctamente.',
  'account-action.target.freeze': 'Introduzca la dirección de la cuenta que desea bloquear.',
  'account-action.target.unfreeze': 'Introduzca la dirección de la cuenta que desea desbloquear.',
  'account-action.individual.freeze': 'Bloquear una cuenta concreta',
  'account-action.individual.unfreeze': 'Desbloquear una cuenta concreta',
  'account-action.global.freeze': 'Bloquear todas las cuentas',
  'account-action.global.unfreeze': 'Desbloquear todas las cuentas',
  'action-global.confirm.freeze': '¿Está seguro de bloquear todas las cuentas?',
  'action-global.confirm.unfreeze': '¿Está seguro de desbloquear todas las cuentas?',
  'freeze-destination.freeze': 'Elija la cuenta a bloquear',
  'freeze-destination.unfreeze': 'Elija la cuenta a desbloquear',
  'freeze-destination.target.freeze': 'Elija que cuenta (o cuentas) desea bloquear.',
  'freeze-destination.target.unfreeze': 'Elija que cuenta (o cuentas) desea desbloquear.',
  'account-action.freeze.success': 'La petición de bloqueo ha sido enviada y esta pendiente de revisión.',
  'account-action.unfreeze.success': 'La petición de desbloqueo ha sido enviada y esta pendiente de revisión.',
  'account-action.freeze.error': 'No se ha podido crear su petición de bloqueo',
  'account-action.unfreeze.error': 'No se ha podido crear su petición de desbloqueo',
  'cancel-request.error': 'Se ha producido un error cancelando su petición',
  'cancel-request.success': 'Su petición ha sido cancelada',
  'Signers-Must-Be-Members': 'Los usuarios deben ser añadidos a la cuenta para que puedan ser seleccionados como firmantes.',
  'Loading-accounts-signing-keypairs': 'Cargando cuentas para determinar si el par de claves se puede desactivar.',
  'global-freeze-request.pending': 'Hay una solicitud de congelamiento global pendiente. Una vez enviado al libro mayor, todas las cuentas en el sistema serán congeladas.',
  'Name-Change': 'Cambio de nombre',
  'N/A': 'No aplica',
  'account-edit-cancel.error': 'No se pudo cancelar la edición de la cuenta.',
  'account-edit-cancel.success': 'Edición de cuenta cancelada con éxito.',
  'Signer-List': 'Lista de firmantes',
  'Proposed-Signer-List': 'Lista de firmantes propuesta',
  'Account-Name': 'Nombre de la cuenta',
  'signers-list-instructions': 'Debe seleccionar al menos 2 firmantes de la cuenta, hasta un máximo de 8.',
  'my-accounts': 'Mis Cuentas',
  'external-accounts': 'Cuentas Externas',
  'pending-configuration': 'Configuración Pendiente',
  'max-eligible-signers': 'Solo hay {{eligibleSigners}} firmantes elegibles para esta cuenta',
  'max-signers': 'Se ha agregado el número máximo de firmantes a esta cuenta',
  'cannot-remove-signer': 'Este usuario es un firmante de esta cuenta. Debe eliminarlos como firmante para realizar modificaciones.',
  'Configuration-Key': 'Clave de configuración',
  'Configuration-Key-Instructions': 'Seleccione el tipo de clave que se utilizará para configurar esta cuenta en el libro mayor.',
  'Configure-Issuer': 'Configurar emisor',
  'needs-issuer-setup': 'La cuenta del emisor está pendiente de configuración',
  'needs-issuer-setup.description': 'Configure su cuenta de emisor para crear más cuentas y comenzar a emitir fondos.',
  'external-account.trustline-acknowledgement': 'Reconozco que una vez creadas, las líneas de confianza entre una cuenta externa y el emisor no se pueden eliminar, solo congelar. <Link>Ver documentos</Link>',
}
