/**
 * Helper to get the key index from the derivation path.
 * The key index is the last number from the string.
 * If the path is "44'/144'/0/0/4", the last keyIndex is 4.
 *
 * @param {string} path A derivation path.
 * @returns A number.
 */
export const getKeyIndexFromDerivationPath = (path: string) => {
  const lastSlashIndex = path.lastIndexOf('/')
  if (lastSlashIndex === -1) return lastSlashIndex

  return +path.substring(lastSlashIndex + 1)
}
