import { HasPermissionProps, Role, User, isAdminRole } from 'common'

/**
 * Get the roles associated with a user by using the full list of roles of the application.
 *
 * @param {User} user A user.
 * @param {Role[]} roles All the roles inside the application.
 * @returns A {@link Role} list associated with the user.
 */
export const getUserRoles = (user?: User, roles?: Role[]): Role[] => {
  if (!user || !roles) return []

  return roles.filter((role) => {
    return user.roleIds.includes(role.roleId)
  })
}

const findAccount = (
  accountId?: string,
  accounts?: HasPermissionProps['accounts'],
) => {
  if (!accountId || !accounts) return
  return Array.isArray(accounts)
    ? accounts.find((acct) => acct.cbdcAccountId === accountId)
    : accounts[accountId]
}

/**
 * Addition to the useHasPermission hook.
 * This helper is useful if you are in a situation where you can't use useHasPermission because you would break the hooks rule.
 * https://reactjs.org/docs/hooks-rules.html
 *
 * @param {Permission | Permission[]} permissions Check that the user has those specific permission(s) within his roles.
 * @param {Role[]} userRoles A list of user roles (which will include some role permissions).
 * @returns A boolean
 */
export const hasPermission = ({
  accountId,
  accounts,
  permissions,
  userRoles,
}: HasPermissionProps) => {
  if (!permissions) return true
  if (!userRoles) return false

  const perms = Array.isArray(permissions) ? permissions : [permissions]

  if (!perms.length) return true

  const rolePermissions = userRoles.flatMap((role) => role.rolePermissions)
  const account = findAccount(accountId, accounts)

  const userIsAdmin = isAdminRole(userRoles[0])

  return rolePermissions.some((rolePermission) => {
    return perms.some((perm) => {
      const { cbdcAccountType, isAccountMembershipRequired, permission } = perm
      // admins have access to all accounts, but all accounts aren't
      // provided in their `accounts` field, so using other admin check
      const requiresAccountCheck =
        accountId && isAccountMembershipRequired && !userIsAdmin

      if (cbdcAccountType) {
        return (
          cbdcAccountType === rolePermission.cbdcAccountType &&
          permission === rolePermission.permission &&
          (!requiresAccountCheck || account?.accountType === cbdcAccountType)
        )
      }
      return (
        permission === rolePermission.permission &&
        (!requiresAccountCheck || !!account)
      )
    })
  })
}
