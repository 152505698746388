import { AxiosInstance } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { FullUser, RequestError } from '../../models'
import { getAuthenticatedUser } from './users-api'

export enum CommonUserQueryKeys {
  AuthUser = 'authed-user',
}

export const useAuthenticatedUser = (
  requestClient: AxiosInstance,
  opts?: UseQueryOptions<FullUser, RequestError>,
) =>
  useQuery<FullUser, RequestError>(
    [CommonUserQueryKeys.AuthUser],
    () => getAuthenticatedUser(requestClient),
    opts,
  )
