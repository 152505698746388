import { CbdcSigner, NewAccountSigner } from 'common'

/**
 * Function to cast the "signers" array to an array of {@link NewAccountSigner}.
 * This is mainly used when an admin wants to view an account's details at the following URLs:
 * - /accounts/:address
 * - /accounts/:address/edit
 *
 * @example
 * [
 *   {
 *     userId: "feb976bc-0018-4edf-a4fa-50275370cc40",
 *     address: "rJbLkchesjvMrfbvh7c5ew8VJNSg2M4iJ4",
 *     signerWeight: 2
 *   }
 * ]
 *
 * @param {CbdcSigner[]} [signers] An array of {@link CbdcSigner}
 * @returns {NewAccountSigner[]} An array of {@link NewAccountSigner} objects
 */
export const convertSignersToFormValues = (
  signers: CbdcSigner[] = [],
): NewAccountSigner[] =>
  signers.map((signer) => ({
    signerWeight: signer.signerWeight ?? 1,
    signerUserId: signer.user?.userId ?? '',
    signerKeyPairId: signer.keyPairId ?? '',
  }))
