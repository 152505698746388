import { z } from 'zod'

export enum WorkItemTransactionSignatureStatus {
  Pending = 'PENDING',
  SentForSigning = 'SENT_FOR_SIGNING',
  Signed = 'SIGNED',
  Failed = 'FAILED',
}

export const workItemTransactionSignatureSchema = z.object({
  id: z.string(),
  userId: z.string(),
  userKeyPairId: z.string(),
  address: z.string(),
  publicKey: z.string(),
  signatureWeight: z.number(),
  status: z.nativeEnum(WorkItemTransactionSignatureStatus),
  signature: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  tenantId: z.string(),
})

export type WorkItemTransactionSignature = z.infer<
  typeof workItemTransactionSignatureSchema
>
