import { z } from 'zod'
import { workItemSchema, WorkItemType } from '../workitems'
import { accountEditStateChangeSchema } from './account-edit-state-change.types'
import {
  SignerStateChange,
  SignerStateChangeSigner,
} from './signer-state-change.types'

export enum AccountEditType {
  Name = 'NAME',
  SignerListSet = 'SIGNER_LIST_SET',
}

export enum AccountEditStatus {
  /**
   * The AccountEdit has been queued because only one transaction involving a workitem can be executed at a time.
   */
  Queued = 'QUEUED',
  /**
   * The AccountEdit is pending approval and has not been applied yet.
   */
  PendingSignatures = 'PENDING_SIGNATURES',
  /**
   * The AccountEdit has received all the signatures it needs and is ready to be submitted to the ledger.
   */
  ReadyForSubmission = 'READY_FOR_SUBMISSION',
  /**
   * The transaction to make the actual change in the AccountEdit's WorkItem has been submitted to the ledger and is
   * being processed.
   */
  Submitted = 'SUBMITTED',
  /**
   * The AccountEdit completed successfully and has been applied.
   */
  Complete = 'COMPLETE',
  /**
   * The AccountEdit failed and was not applied.
   */
  Failed = 'FAILED',
  /**
   * The AccountEdit was cancelled.
   */
  Cancelled = 'CANCELLED',
  /**
   * The AccountEdit has expired, meaning the {@code lastLedgerSequence} of its transactions has already passed
   * on ledger and those transactions will therefore never be validated.
   */
  Expired = 'EXPIRED',
}

export const accountEditSummarySchema = z.object({
  cbdcAccountId: z.string(),
  createdAt: z.string(),
  createdBy: z.string(),
  id: z.string(),
  isNameChange: z.boolean(),
  isQuorumChange: z.boolean(),
  isSignerListChange: z.boolean(),
  isUsersChange: z.boolean(),
  requestSummary: z.string(),
  /**
   * The number of signatures required to approve this account edit, if applicable
   *
   * @return number of signatures required
   */
  requiredQuorum: z.number(),
  status: z.nativeEnum(AccountEditStatus),
  tenantId: z.string(),
  updatedAt: z.string(),
  /**
   * The number of signatures * weight obtained so far to approve this account edit, if applicable
   *
   * @return the number of signatures obtained so far
   */
  weightedSignatureCount: z.number(),
})

export type AccountEditSummary = z.infer<typeof accountEditSummarySchema>

export const accountEditMetadataSchema = z.intersection(
  accountEditSummarySchema,
  z.object({
    workItemId: z.string().optional(),
  }),
)

export type AccountEditMetadata = z.infer<typeof accountEditMetadataSchema>

export const accountEditSchema = z.intersection(
  accountEditSummarySchema,
  z.object({
    stateChange: accountEditStateChangeSchema,
    workItem: z
      .intersection(
        workItemSchema,
        z.object({
          type: z.enum([WorkItemType.SignerListSet]),
        }),
      )
      .optional(),
  }),
)

export type AccountEdit = z.infer<typeof accountEditSchema>

export type SignerStateChangeSignerUpdates = Record<
  keyof SignerStateChangeSigner,
  boolean
>

// This type will help to indicate in the UI which field of the Signer details have changed
export type SignerStateChangeWithUpdates = SignerStateChange & {
  /** Object to indicate which signer fields have been updated */
  updates: SignerStateChangeSignerUpdates
}

export const newAccountUsersEdit = z.object({
  cbdcAccountId: z.string(),
  userIds: z.string().array(),
})

export type NewAccountUsersEdit = z.infer<typeof newAccountUsersEdit>
