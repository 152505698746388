export default {
  'invalid-currency-code-error': 'Currency code does not exist on this issuer address',
  Send: 'Send',
  Assets: 'Assets',
  'Add-Asset': 'Add Asset',
  Connect: 'Connect',
  'Connect-Account': 'Connect Account',
  Destination: 'Destination',
  'View-All': 'View All',
  'Recent-Payments': 'Recent Payments',
  'connect-an-account': 'Connect an account',
  'Connect-Xumm-Wallet': 'Import a Xumm Wallet',
  'Send-Payment': 'Send Payment',
  'Sign-Payment': 'Sign Payment',
  'Receive-Payment': 'Receive Payment',
  'fund-this-account': 'Fund this account',
  'reserve-balance': 'Reserve: {{xrpBaseReserve}} XRP',
  More: 'More',
  'Demo-Custodial-Key': 'Demo Custodial Key',
  'Xumm-Wallet': 'Xumm Wallet',
  'account-name-exists': 'That account name already exists',
  'onboarding.select-address': 'Select an address to use',
  'onboarding.ledger-nano.title': 'Connect your Ledger Nano',
  'onboarding.ledger-nano.instructions': 'Plug in your Ledger Nano via USB and click on the "Connect" button',
  'onboarding.xumm-connect.instructions.0': "If you don't have an existing Xumm account, please create one before continuing.",
  'onboarding.xumm-connect.instructions.1': 'In your Xumm app, press the blue button in the center of the toolbar. Then press "Scan QR Code" to import the wallet.',
  'onboarding.xumm-connect.success': 'Success! Click "Next" to continue configuring your account.',
  'onboarding.xumm-network-completed.title': 'Connected to the Sandbox Ledger',
  'onboarding.xumm-network-completed.instructions.0': 'No further action is required. You are already connected to the sandbox ledger. Click "Finish" to finish up importing your account.',
  'onboarding.xumm-network-completed.instructions.1': 'Xumm connected to {{network}}',
  'onboarding.xumm-network.title': 'Connect to the Sandbox Ledger',
  'onboarding.xumm-network.instructions.0': 'In your Xumm app, press the blue button in the center of the toolbar. Then press "Scan QR Code" to connect to the sandbox ledger.',
  'onboarding.xumm-network.instructions.1': 'When you\'re done connecting, press "Finish" to continue to your account.',
  'assets.add.description': 'Add your first asset to get started',
  'not-funded.title': 'Account has not been funded',
  'not-funded.instructions.0': 'To fund your account',
  'not-funded.instructions.1': 'Get your XRP on an exchange',
  'not-funded.instructions.2': 'Send at least {{xrpBaseReserve}} XRP to this account: {{address}}',
  'not-funded.instructions.3': 'No destination tag required',
  'funding-success': 'Account funded successfully',
  'funding-error': 'We were unable to fund the account',
  'account-created': '{{accountName}} was created',
  'account-error': 'There was an error creating the test account',
  'payment-sign': 'Sign payment transaction',
  'payment-signed': 'Payment transaction signed',
  'payment-success': 'Payment sent successfully',
  'payment-error': 'There was an error sending the payment',
  'payment-xumm-instructions': 'Scan QR Code with the Xumm app',
  'asset-success': 'Added asset {{currency}} successfully',
  'asset-error': 'There was an error adding the asset',
  'trustset-sign': 'Sign trust line transaction',
  'trustset-signed': 'Signed trust line transaction',
  'trustset-error': 'There was an error creating the trust line',
  'receive-payment.title': 'Receive payment',
  'receive-payment.instructions': 'To send assets to <Strong>{{name}}</Strong>, scan the QR code or copy the wallet address into a payment created on the sandbox network.',
}
