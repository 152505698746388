import { useAuth } from '../auth'
import { hasPermission } from '../helpers'
import { HasPermissionProps, PermissionWithAccountTypeUnion } from '../models'

/**
 * Hook to check if the logged in user, or another user has the right permission in his assigned role.
 *
 * @param {PermissionWithAccountTypeUnion} permissions The permissions to check against the user role permissions.
 * @param {object} [options] Additional {@link HasPermissionProps} object to pass when needing to provide accounts, roles, or a specific account id to check against
 * @returns {boolean} Boolean indicating if the user has permission
 */
export const useHasPermission = (
  permissions: PermissionWithAccountTypeUnion,
  /** 👇🏻 If undefined, the logged in user will be used. */
  opts?: Omit<HasPermissionProps, 'permissions'>,
) => {
  const { me } = useAuth()

  if (!permissions) return true

  return hasPermission({
    accountId: opts?.accountId,
    accounts: opts?.accounts ?? me.accountsMap,
    userRoles: opts?.userRoles ?? me.roles,
    permissions,
  })
}
