import {
  RequestError,
  safeParseApiResponse,
  xummRequestClient,
  XummWalletPayment,
  xummWalletPaymentSchema,
  XummWalletTrustSet,
  xummWalletTrustSetSchema,
} from 'common'
import { useMutation } from 'react-query'

export enum XummMutationKeys {
  TrustSet = 'xumm-wallet-trustset',
  Payment = 'xumm-wallet-payment',
}

type XummWalletTrustSetProps = {
  accountId: string
  request: XummWalletTrustSetRequest
}

type XummWalletTrustSetRequest = {
  address: string
  userToken: string
  issuerAddress: string
  currencyCode: string
}

export const useXummWalletTrustSet = () =>
  useMutation<XummWalletTrustSet, RequestError, XummWalletTrustSetProps>(
    ({ accountId, request }) =>
      xummRequestClient
        .post(`/wallet/accounts/xumm/${accountId}/trustset`, request)
        .then(safeParseApiResponse(xummWalletTrustSetSchema)),
    {
      mutationKey: XummMutationKeys.TrustSet,
    },
  )

type XummWalletPaymentProps = {
  accountId: string
  request: XummWalletPaymentRequest
}

type XummWalletPaymentRequest = {
  amount: string
  memo: string
  source: string
  userToken: string
  destination: string
  currencyCode: string
  issuerAddress: string
  destinationTag?: number
}

export const useXummWalletPayment = () =>
  useMutation<XummWalletPayment, RequestError, XummWalletPaymentProps>(
    ({ accountId, request }) =>
      xummRequestClient
        .post(`/wallet/accounts/xumm/${accountId}/payments`, request)
        .then(safeParseApiResponse(xummWalletPaymentSchema)),
    {
      mutationKey: XummMutationKeys.Payment,
    },
  )
