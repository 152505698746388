export default {
  signers: '{{count}} signer',
  signers_other: '{{count}} signers',
  users: '{{count}} user',
  users_other: '{{count}} users',
  accounts: '{{count}} account',
  accounts_other: '{{count}} accounts',
  conflicts_one: '{{count}} conflicts',
  conflicts_other: '{{count}} conflict',
  'conflicts-only_one': 'conflicts',
  'conflicts-only_other': 'conflict',
  keypairs: '{{count}} keypair',
  keypairs_other: '{{count}} keypairs',
  Accounts: '{{count}} Account',
  Accounts_other: '{{count}} Accounts',
}
