import {
  RequestError,
  safeParseApiResponse,
  xummRequestClient,
  XummWalletInfo,
  xummWalletInfoSchema,
  XummWalletSetup,
  xummWalletSetupSchema,
} from 'common'
import { useQuery, UseQueryOptions } from 'react-query'

export enum XummQueryKeys {
  Setup = 'xumm-wallet-setup',
  GetInfo = 'xumm-wallet-info',
}

export const getXummWalletInfo = (
  accountId: string,
  payloadId?: string,
): Promise<XummWalletInfo> =>
  xummRequestClient
    .get(
      `/wallet/accounts/xumm/${accountId}` +
        (payloadId ? `?payloadId=${payloadId}` : ''),
    )
    .then(safeParseApiResponse(xummWalletInfoSchema))

export const useXummWalletInfo = (
  accountId: string,
  payloadId: string,
  opts?: UseQueryOptions<XummWalletInfo, RequestError>,
) =>
  useQuery<XummWalletInfo, RequestError>(
    [XummQueryKeys.GetInfo, accountId],
    () => getXummWalletInfo(accountId, payloadId),
    opts,
  )

export const useSetupXummWallet = (
  opts?: UseQueryOptions<XummWalletSetup, RequestError>,
) =>
  useQuery<XummWalletSetup, RequestError>(
    [XummQueryKeys.Setup],
    () =>
      xummRequestClient
        .post(`/wallet/accounts/xumm`, {})
        .then(safeParseApiResponse(xummWalletSetupSchema)),
    opts,
  )
