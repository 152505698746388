import { useLatestGlobalFreezeWorkItem } from '@/shared/api'
import { WS_QUEUES } from '@/shared/constants'
import { isGlobalFreezeWorkItem } from '@/shared/helpers'
import {
  GlobalFreezeEventData,
  WorkItemStatus,
  WorkItemType,
  useSubscription,
  workItemStatuses,
} from 'common'
import { useMemo } from 'react'

export function useGlobalFreezeStatus() {
  const { data, refetch } = useLatestGlobalFreezeWorkItem()

  useSubscription<GlobalFreezeEventData>(WS_QUEUES.GLOBAL_FREEZE, {
    onData: ({ data }) => {
      if (isGlobalFreezeWorkItem(data.type)) {
        refetch()
      }
    },
  })

  return useMemo(() => {
    if (!data) {
      return {
        frozen: false,
        pendingFreeze: false,
        pendingUnfreeze: false,
      }
    }
    const latestIsPending = [
      ...workItemStatuses.pending,
      WorkItemStatus.Queued,
    ].includes(data.status)
    const latestIsFailed = workItemStatuses.failed.includes(data.status)

    const latestIsPendingUnfreeze =
      data.type === WorkItemType.GlobalUnfreeze && latestIsPending
    const latestIsFailedUnfreeze =
      data.type === WorkItemType.GlobalUnfreeze && latestIsFailed

    const latestIsCompletedFreeze =
      data.type === WorkItemType.GlobalFreeze &&
      data.status === WorkItemStatus.Complete

    return {
      frozen:
        latestIsCompletedFreeze ||
        latestIsPendingUnfreeze ||
        latestIsFailedUnfreeze,
      pendingFreeze: data.type === WorkItemType.GlobalFreeze && latestIsPending,
      pendingUnfreeze: latestIsPendingUnfreeze,
    }
  }, [data])
}
