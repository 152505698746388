import { AccountType, Permission, RolePermission } from 'common'

/**
 * Structure the admin permissions of a user by "category".
 * The "categories" are the account types.
 *
 * @param {Permission[]} rolePermissions A list of admin permissions.
 * @returns An object {@link AdminCategoryAndPermissions}.
 */
export const categorizeUserRolePermissions = (
  rolePermissions: RolePermission[],
) => {
  const userPermissionsCategorized: Record<AccountType, Permission[]> = {
    ISSUER: [],
    STANDBY: [],
    OPERATIONAL: [],
    UNKNOWN: [],
    EXTERNAL: [],
  }

  rolePermissions.forEach((rolePermission) => {
    const { cbdcAccountType, permission } = rolePermission

    userPermissionsCategorized[cbdcAccountType ?? AccountType.Unknown].push(
      permission,
    )
  })
  return userPermissionsCategorized
}
