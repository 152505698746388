import { rippleTheme } from '@ripple/design-system'
import {
  AccountSetupAccountType,
  AccountSetupStepTypePathName,
  AccountType,
  DerivationAccountType,
} from 'common'
import { STEP_PATH_NAME_TO_STEP_NUMBER_MAP } from './account-setup-modal.constants'

/**
 * Helper to define if the current wizard step is equal to the step
 * returned by the back-end.
 *
 * @param {number} currentWizardStep The current step in the Wizard modal.
 * @param {AccountSetupStepTypePathName} nextPathName The pathName indicated by the back-end to work on.
 * @returns A {@link Boolean}.
 */
export const isWizardStepEqualToBackEndStep = (
  currentWizardStep: number,
  nextPathName?: AccountSetupStepTypePathName,
) => {
  if (!nextPathName) return true
  return currentWizardStep === STEP_PATH_NAME_TO_STEP_NUMBER_MAP[nextPathName]
}

/**
 * Toast className so it can fit correctly in all the account setup steps.
 */
export const accountSetupToastClassname = rippleTheme.css({
  mt: 2,
})()

export const ACCOUNT_TYPE_TO_DERIVATION_ACCOUNT_TYPE_MAP: Record<
  AccountSetupAccountType,
  DerivationAccountType
> = {
  [AccountType.Issuer]: DerivationAccountType.Issuer,
  [AccountType.Standby]: DerivationAccountType.Standby,
  [AccountType.Operational]: DerivationAccountType.Operational,
}
