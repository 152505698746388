export default {
  'Account-Signers': 'Signataires du compte',
  'Signers-Quorum': 'Signataires et Quorum',
  'New-Account': 'Nouveau compte',
  'Fund-Account': 'Financer le compte',
  'Go-To-Account': 'Aller au compte',
  'View-Accounts': 'Afficher les comptes',
  'Delete-Account': 'Supprimer le compte',
  'Weight-for-Signing': 'Poids pour la signature',
  'Add-Users': 'Ajouter des utilisateurs',
  'All-Accounts': 'Tous les comptes',
  'Issuer-Account-Address': 'Adresse du compte émetteur',
  'Issuer-Account-Name': 'Nom du compte émetteur',
  'Freeze-Account': 'Geler le compte',
  'Unfreeze-Account': 'Débloquer le compte',
  'Unfreeze-Accounts': 'Débloquer les comptes',
  'No-Active-Keypair': "Cet utilisateur n'a pas de paire de clés active à signer pour les transactions",
  'Remove-Signer': 'Supprimer le signataire',
  'Cannot-Remove-Signer.hover-label': 'Vous ne pouvez pas supprimer ce signataire. Le compte doit avoir au moins 2 signataires.',
  'No-Queued-Transactions': "Aucune transaction en file d'attente",
  'Add-Signer': 'Ajouter un signataire',
  'View-Request': 'Voir la demande',
  'Set-Up-Trustline': 'Configurer une ligne de confiance',
  'Setting-Up-Trustline': 'Configuration de la ligne de confiance...',
  'loading-account': 'Chargement du compte...',
  'loading-accounts': 'Chargement des comptes...',
  'loading-users': 'Chargement des utilisateurs du compte...',
  'no-issuer-accounts': 'Aucun compte émetteur trouvé.',
  'no-account': "Aucun compte trouvé avec l'adresse {{address}}",
  'no-accounts': 'Aucun compte trouvé.',
  'no-accounts-yet': "Aucun compte pour l'instant",
  'no-accounts-yet.description': "Vous n'avez été ajouté à aucun compte.",
  'no-accounts.description': "Cet utilisateur n'a été ajouté à aucun compte dans le système.",
  'issuer-trustline.pending': 'La ligne de confiance sur ce compte est en cours de révision avec les émetteurs signataires. Une fois approuvé, le compte autorisé pourra envoyer et recevoir des devises.',
  'issuer-trustline.retry': "La demande d'approbation de la ligne de confiance a expiré avant que les émetteurs signataires puissent l'examiner. Veuillez réessayer la demande.",
  'no-issuer-account': 'Pas de compte émetteur.',
  'no-issuer-account.description': 'Vous devez créer un compte émetteur pour commencer à frapper des devises.',
  'no-users': 'Aucun utilisateur associé au compte',
  'no-users.cta': 'Terminez la configuration du compte et associez des utilisateurs à celui-ci.',
  'Create-Account': 'Créer un compte',
  'Create-External-Account': 'Créer un compte externe',
  'Change-Log': 'Journal des modifications',
  'change-log.account-setup-incomplete': 'La configuration du compte est incomplète.',
  'change-log.no-changes': "Aucun changement de compte pour l'instant.",
  'change-log.no-changes.description': "Aucune demande de changement de compte n'a été effectuée pour ce compte.",
  'issuer-edit-pending': "Le compte émetteur a une modification de compte en attente. Les nouvelles demandes de {{txnType}} ne peuvent pas être initiées tant que la modification n'est pas terminée.",
  'edit-success': 'Nom du compte mis à jour avec succès.',
  'edit-error': 'Impossible de mettre à jour le nom du compte. Veuillez réessayer.',
  'edit-lag': "Les détails du compte n'ont pas pu être récupérés pour une mise à jour immédiate de l'interface utilisateur, mais la demande de modification de nom a été soumise. Contactez le support si l'erreur persiste.",
  'edit-description': 'Modifiez le nom du compte et résumez la raison de la modification.',
  'edit-signers': 'Modifier les signataires du compte {{accountName}}',
  'edit-signers.success': 'Demande de modification créée avec succès',
  'edit-signers.error': "Le compte n'a pas pu être modifié. Veuillez réessayer.",
  'approval-required': 'Ces modifications nécessitent une approbation',
  'approval-description': "Les modifications sélectionnées doivent être approuvées par les signataires du compte. Jusqu'à ce qu'elles soient approuvées ou rejetées, aucune nouvelle transaction ne peut être créée avec ce compte.",
  'approval-confirm': 'Si vous souhaitez poursuivre cette demande de modification, cliquez sur "Continuer".',
  'remove-users-confirm': 'Les utilisateurs qui sont supprimés du compte ne pourront plus voir ou accéder à ce compte.',
  'remove-users-title': 'Supprimer ${numUsersToRemove} $t(plurals:users, {"count": {{numUsersToRemove}}}) ?',
  'remove-users-error': "Une erreur s'est produite lors de la suppression des utilisateurs",
  'remove-users-success_one': '{{count}} utilisateur supprimé avec succès',
  'remove-users-success_other': '{{count}} utilisateurs supprimés avec succès',
  'no-authorized-payment-users': 'Aucun utilisateur du compte ne peut initier une transaction. Pour commencer à effectuer des transactions, ajoutez un utilisateur qui peut initier des transactions.',
  'signer-request-pending': "Il y a une demande de modification des signataires/poids 'En attente de validation'. Certaines actions sur ce compte ont été désactivées pendant l'examen de la demande.",
  'signer-request-pending-signer': 'Vous avez une demande de modification des signataires/poids "En attente de validation" qui nécessite votre attention. Veuillez examiner les détails de la demande et signer si nécessaire.',
  'account-frozen-pending-unfreeze': "Ce compte est actuellement gelé. Les transactions soumises échoueront tant que la demande de dégel en attente n'aura pas été approuvée.",
  'account-frozen': 'Ce compte est actuellement gelé. Les transactions soumises échoueront tant que le compte ne sera pas dégelé.',
  'accounts-frozen': 'Tous les comptes du système sont actuellement gelés.',
  'accounts-frozen.transactions': 'Les transactions soumises échoueront tant que les comptes ne seront pas dégelés.',
  'freeze-request.success': 'Demande de gel de compte soumise avec succès.',
  'unfreeze-request.success': 'Demande de dégel de compte soumise avec succès.',
  'freeze-request.error': "Une erreur s'est produite lors de la soumission de la demande de gel.",
  'unfreeze-request.error': "Une erreur s'est produite lors de la soumission de la demande de dégel du compte.",
  'global-freeze-request.success': 'Demande de gel globale soumise avec succès.',
  'global-unfreeze-request.success': 'Demande de dégel globale soumise avec succès.',
  'unfreeze-request.pending': 'Une demande de dégel globale est en attente de validation.',
  'freeze-request.pending': 'Une demande de gel pour ce compte a été envoyée aux signataires du compte émetteur pour approbation.',
  'account-action.target.freeze': "Entrez l'adresse du compte que vous souhaitez geler",
  'account-action.target.unfreeze': "Entrez l'adresse du compte que vous souhaitez débloquer.",
  'account-action.individual.freeze': 'Geler un compte individuel',
  'account-action.individual.unfreeze': 'Débloquer un compte individuel',
  'account-action.global.freeze': 'Geler tous les comptes',
  'account-action.global.unfreeze': 'Débloquer tous les comptes',
  'account-global.view-docs': 'Consultez la documentation pour savoir comment cela affectera votre compte.',
  'action-global.confirm.freeze': 'Êtes-vous sûr de vouloir geler tous les comptes ?',
  'action-global.confirm.unfreeze': 'Êtes-vous sûr de vouloir débloquer tous les comptes ?',
  'confirm-freeze-global': 'Confirmer le gel global',
  'confirm-unfreeze-global': 'Confirmer le dégel global',
  'freeze-destination.freeze': 'Choisissez la destination du gel',
  'freeze-destination.unfreeze': 'Choisissez la destination du dégel',
  'freeze-destination.target.freeze': 'Choisissez le compte (ou les comptes) que vous souhaitez geler.',
  'freeze-destination.target.unfreeze': 'Choisissez le compte (ou les comptes) que vous souhaitez débloquer.',
  'freeze-destination.individual': 'Geler un compte individuel',
  'account-action.freeze.success': 'La demande de gel a été envoyée pour examen.',
  'account-action.unfreeze.success': 'La demande de dégel a été envoyée pour examen.',
  'account-action.freeze.error': 'Impossible de créer votre demande de gel.',
  'account-action.unfreeze.error': 'Impossible de créer votre demande de dégel.',
  'Individual-Account': 'Compte individuel',
  'Global-Account': 'Compte global',
  'Global-Freeze': 'Gel global',
  'Global-Unfreeze': 'Dégel global',
  'Account-Freeze': 'Gel du compte',
  'Account-Unfreeze': 'Dégel du compte',
  Freeze: 'Geler',
  Unfreeze: 'Débloquer',
  'Account-Flag': 'Drapeau du compte',
  'Account-Balance': 'Solde du compte',
  'Account-Details': 'Détails du compte',
  'Sign-Request': 'Demander une signature',
  'Currency-Code': 'Code de la devise',
  'Unknown-Name': 'Nom inconnu',
  'Unknown-Account': 'Compte inconnu',
  'Change-Request-Overview': 'Aperçu de la demande de modification',
  'no-pending-accounts': 'Aucun compte en attente de configuration.',
  'no-freeze-requests': 'Aucune demande de gel pour l’instant.',
  'no-freeze-requests.instructions': "Créez des demandes de gel et de dégel à signer par les signataires de l'émetteur.",
  'cancel-request.error': "Nous avons rencontré une erreur lors de l'annulation de votre demande",
  'cancel-request.success': 'Votre demande a été annulée',
  'cancel-request.prompt': 'Êtes-vous sûr de vouloir annuler cette demande ?',
  'cancel-request.proceed': 'Oui, annuler',
  'cancel-request.cancel': 'Annuler',
  'edit-request.success': 'Demande de modification soumise au registre',
  'edit-request.signed': 'Demande de modification signée avec succès',
  'edit-request.error': 'Erreur lors de la soumission de la demande de modification',
  'create-account.success': 'Compte créé avec succès',
  'create-account.error': 'Erreur lors de la création du compte',
  'create-account.acknowledgement': "Je reconnais qu'une fois créées, les modifications apportées à ce compte nécessiteront la signature des signataires du compte.",
  'Authorized-Trustlines': 'Lignes de confiance autorisées',
  'Authorized-Trustlines.tooltip': `Lorsqu'ils sont activés, les nouveaux comptes qui établissent une ligne de confiance avec l'émetteur devront d'abord être approuvés par les signataires de l'émetteur.`,
  'Issuer-Settings': "Paramètres de l'émetteur",
  'label.require-auth-trustlines': 'Exiger des lignes de confiance autorisées',
  'label.do-not-require-auth-trustlines': 'Ne nécessite pas de lignes de confiance autorisées',
  'issuer-settings-info-toast': 'Une fois votre compte émetteur créé sur le grand livre, le code de devise et la sélection de ligne de confiance autorisée ne peuvent pas être modifiés.',
  'Create-Issuer-Account': 'Créer un compte émetteur',
  'create-external-account.success': 'Compte externe créé avec succès',
  'create-external-account.error': 'Impossible de créer le compte externe',
  'loading-external-accounts': 'Chargement des comptes externes...',
  'no-external-accounts': "Aucun compte externe n'a été créé",
  'undefined-account': 'Compte indéfini',
  'Currency-Unknown': 'Devise inconnue',
  'Address-Unknown': 'Adresse inconnue',
  'Name-Unknown': 'Nom inconnu',
  'Authorize-Signers': 'Autoriser les signataires',
  'Authorizing-Signers': 'Autoriser les signataires...',
  'Account-Field': 'Champ de compte',
  'Issuer-Required': 'Émetteur requis',
  'Issuer-Disabled': 'Émetteur désactivé',
  'learn-more-require-auth': "En savoir plus sur l'authentification requise",
  'learn-more-disallow-xrp': "En savoir plus sur l'interdiction de XRP",
  'learn-more-rippling': 'En savoir plus sur le "Rippling"',
  'learn-more-destination-tag': 'En savoir plus sur les étiquettes de destination',
  'learn-more-ticksize': 'En savoir plus sur la taille des pas ("TickSize")',
  'account-setup.flags.disallow-xrp': 'Interdire XRP',
  'account-setup.flags.default-rippling': 'Ondulation par défaut',
  'account-setup.flags.require-destination-tag': 'Exiger une balise de destination',
  'account-setup.flags.tick-size': 'TickSize',
  'account-setup.preparing-transaction': 'Préparation de la transaction pour la signature...',
  'account-setup.trustline.title': "Veuillez examiner les détails de l'émetteur et du compte {{accountType}} ci-dessous pour établir une ligne de confiance.",
  'account-setup.trustline.success': "La relation de confiance entre le compte {{accountType}} et l'émetteur a été créée.",
  'account-setup.signers.instructions': 'Signez la transaction pour ajouter les signataires du compte.',
  'account-setup.signers.success': 'Les signataires du compte ont été confirmés.',
  'Enable-Flags-and-TickSize': 'Activer les indicateurs et la taille des pas ("TickSize")',
  'Enable-Flags': 'Activer les indicateurs',
  'Enabling-Flags': 'Activation des indicateurs...',
  'Disable-Master-Key': 'Désactiver la clé maître',
  'Disabling-Master-Key': 'Désactivation de la clé principale...',
  'Connect-Ripple-Custodied-Key': 'Connecter une clé gardée par Ripple',
  'account-setup.flags.instructions': 'Signez la transaction "AccountSet" pour activer les indicateurs ci-dessus.',
  'account-setup.flags.success.0': 'Les indicateurs ("Flags") et la taille des pas ("TickSize") ont été activés avec succès.',
  'account-setup.flags.success.1': 'Les indicateurs ("Flags") ont été activés avec succès.',
  'account-setup.info.custodial.title': 'Pour terminer la configuration du compte, associez-le à une clé gardée par Ripple.',
  'account-setup.master-key.title': 'Pour terminer la configuration du compte, désactivez la clé principale pour sécuriser le compte.',
  'account-setup.master-key.instructions': 'Une fois la clé principale désactivée, seuls les signataires du compte pourront signer des transactions sur le compte.',
  'account-setup.master-key.success': 'La clé maître de votre compte a été désactivée.',
  'account-setup.fund.title': "Scannez le code QR pour financer le compte, {{accountName}}. Vous pouvez financer ce compte à l'aide de n'importe quel portefeuille.",
  'account-setup.fund.address-not-available': "L'adresse du compte n'est pas disponible",
  'Finish-Account-Setup': 'Terminer la configuration du compte',
  'account-setup.info.ledger.title': 'Pour terminer la configuration du compte, commencez par brancher et connecter votre Ledger Nano.',
  'account-setup.flags.title': 'Veuillez consulter les indicateurs ci-dessous pour les activer sur le compte {{accountType}}.',
  'account-setup.completed.no-account-id': "Impossible d'obtenir l'identifiant du compte",
  'account-setup.loading': "Chargement de l'étape suivante...",
  'account-setup.websocket-error': "Échec de l'analyse du message de configuration du compte via le websocket. {{error}}",
  'account-setup.completed.title': 'Le compte a été configuré. {{accountName}} est maintenant prêt à effectuer des transactions.',
  'account-setup.signers.title': 'Vérifiez les signataires suivants pour les autoriser à signer des transactions sur le compte {{accountType}}.',
  'account-setup.status.waiting': 'En attente de la signature de la transaction {{transactionType}} sur votre Ledger Nano...',
  'account-setup.status.submitting': 'Soumission de la transaction au registre...',
  'account-setup.status.queued': "Transaction en file d'attente...",
  'account-setup.status.retrieving-account': 'Récupération du compte à partir de votre Ledger Nano...',
  'account-setup.ledger-nano.configuring-account': 'Configuration de {{accountName}}',
  'account-setup.account-not-found': 'Compte introuvable.',
  'account-setup.account-details-not-found': 'Détails du compte introuvables.',
  'account-setup.account-address-not-found': 'Adresse du compte introuvable.',
  'account-setup.ledger-nano.transaction-bytes-not-found': 'Octets de la transaction introuvables.',
  'account-setup.ledger-nano.transaction-signature-not-found': 'Signature de la transaction introuvable.',
  'account-setup.ledger-nano.save-key.failed': "Impossible d'enregistrer la clé publique.",
  'account-setup.ledger-nano.save-signature.failed': "Impossible d'enregistrer la signature.",
  'account-setup.ledger-nano.save-signature.queued': "La transaction est encore en file d'attente et ne peut pas être traitée.",
  'account-setup.ledger-nano.save-address.loading': "Recherche d'une adresse disponible...",
  'account-setup.ledger-nano.save-address.saving': "Enregistrement de l'adresse dans la base de données...",
  'account-setup.ledger-nano.save-address.success': 'Le compte a été associé à une adresse.',
  'account-setup.ledger-nano.save-address.failed': "L'adresse n'a pas pu être enregistrée. Tentative de recherche d'une autre adresse disponible...",
  'account-setup.ledger-nano.fund-account.waiting': 'En attente du financement du compte, {{accountName}}...',
  'account-setup.ledger-nano.fund-account.loading': 'Financement de {{accountName}}...',
  'account-setup.ledger-nano.fund-account.success': '{{accountName}} a été financé avec succès.',
  'account-setup.your-account': 'votre compte',
  'account-setup.assigning-address': "Attribution d'une adresse à votre compte en cours...",
  'account-setup.associated-account': 'Votre compte a été associé à une adresse.',
  'account-setup.keypair-error': 'Impossible de générer une paire de clés gérées par Ripple.',
  'account-setup.info.ledger.connect.title': 'Connecter Ledger Nano',
  'unfreeze-account.prompt': 'Êtes-vous sûr de vouloir soumettre une demande de dégel pour ce compte ? Elle sera envoyée aux signataires pour examen.',
  'freeze-account.description.0': 'Si vous geler ce compte, les transactions soumises échoueront.',
  'freeze-account.description.1': 'Pour soumettre une demande de gel de compte, cliquez sur "Geler" et elle sera envoyée aux signataires pour examen.',
  'freeze-account.address-field-label': "Sélectionnez le compte ou entrez l'adresse",
  'delete-account.success': 'Le compte a été supprimé !',
  'delete-account.error': 'Impossible de supprimer le compte.',
  'delete-account.prompt': 'Êtes-vous sûr de vouloir supprimer le compte <Strong>{{name}}</Strong>?',
  'delete-account.confirm': 'Oui, supprimer',
  'account-actions.loading': 'Chargement des actions du compte...',
  'account-edits.loading': 'Chargement des demandes de modification du compte...',
  'xrp-not-valid': "XRP n'est pas un code de devise valide",
  'name-in-use': 'Le nom est déjà utilisé',
  'Funds-Available': 'Fonds disponibles',
  Unconfigured: 'Non configuré',
  'configure-account.title': 'Configurez votre compte sur le grand livre',
  'configure-account.description': "Avant de pouvoir vous connecter avec l'émetteur pour recevoir des devises, vous devez d'abord configurer le compte sur le grand livre.",
  'unconfigured-account.pending': 'Configuration en attente de validation...',
  'No-Pending-Transactions': 'Aucune transaction en attente',
  'Get-Started': 'Créez une nouvelle transaction pour commencer.',
  'View-All-Queued': "Voir toutes les transactions en file d'attente",
  'Queued-Transactions': "Transactions en file d'attente",
  'Loading-Queued-Transactions': "Chargement des transactions en file d'attente...",
  'Hide-Signers': 'Masquer les signataires',
  'Show-Signers': 'Afficher les signataires',
  'Available-Balance': 'Solde disponible',
  'Submit-For-Approval': 'Soumettre pour approbation',
  'Signers-Must-Be-Members': 'Les utilisateurs doivent être ajoutés au compte avant de pouvoir être sélectionnés comme signataires du compte.',
  'Loading-accounts-signing-keypairs': 'Chargement des comptes pour determiner si la paire de clefs peut être désactivée.',
  'global-freeze-request.pending': 'Une demande de gel global est en attente. Une fois soumis au grand livre, tous les comptes du système seront gelés.',
  'Name-Change': 'Changement de nom',
  'N/A': "ne s'applique pas",
  'account-edit-cancel.error': "La modification du compte n'a pas pu être annulée.",
  'account-edit-cancel.success': 'Modification du compte annulée avec succès.',
  'Signer-List': 'Liste des signataires',
  'Proposed-Signer-List': 'Liste de signataires proposée',
  'Account-Name': 'Nom du compte',
  'signers-list-instructions': "Vous devez sélectionner au moins 2 signataires de compte, jusqu'à un maximum de 8.",
  'my-accounts': 'Mes Comptes',
  'external-accounts': 'Comptes Externes',
  'pending-configuration': 'Configuration en Attente',
  'max-eligible-signers': "Il n'y a que {{eligibleSigners}} signataires éligibles pour ce compte",
  'max-signers': 'Le nombre maximum de signataires a été ajouté à ce compte',
  'cannot-remove-signer': 'Cet utilisateur est signataire de ce compte. Vous devez les supprimer en tant que signataire pour apporter des modifications.',
  'Configuration-Key': 'Clé de configuration',
  'Configuration-Key-Instructions': 'Sélectionnez le type de clé à utiliser pour configurer ce compte sur le grand livre.',
  'Configure-Issuer': "Configurer l'émetteur",
  'needs-issuer-setup': 'Le compte émetteur est en attente de configuration',
  'needs-issuer-setup.description': 'Configurez votre compte émetteur pour créer plus de comptes et commencer à émettre des fonds.',
  'external-account.trustline-acknowledgement': "Je reconnais qu'une fois créées, les lignes de confiance entre un compte externe et l'émetteur ne peuvent pas être supprimées, mais seulement gelées. <Link>Afficher les documents</Link>",
}
