import {
  ENTRY,
  EntryPage,
  LOGIN,
  LoginPage,
  NOT_FOUND,
  NotFoundPage,
  UNAUTHORIZED,
  UnauthorizedPage,
} from 'common'
import { RouteProps } from 'react-router-dom'

export type ModifiedRouteProps = RouteProps & {
  element: React.ComponentType<any>
}

export const publicRoutes: ModifiedRouteProps[] = [
  { path: LOGIN, element: LoginPage },
  { path: NOT_FOUND, element: NotFoundPage },
  { path: ENTRY, element: EntryPage },
  { path: UNAUTHORIZED, element: UnauthorizedPage },
]
