import { z } from 'zod'
import { baseUserSchema } from '../users'
import { stateChange } from './state-change.types'

export const signerStateChangeSignerSchema = z.object({
  address: z.string(),
  user: baseUserSchema,
  keyPairId: z.string(),
  keyPairName: z.string(),
  publicKey: z.string(),
  signerWeight: z.number(),
})
export type SignerStateChangeSigner = z.infer<
  typeof signerStateChangeSignerSchema
>

export const signerStateChangeSchema = z.intersection(
  stateChange(signerStateChangeSignerSchema),
  z.object({
    signerUserId: z.string(),
  }),
)
export type SignerStateChange = z.infer<typeof signerStateChangeSchema>
