import { z } from 'zod'
import { baseUserSchema, userSchema } from './users'

const baseCbdcSignerSchema = z.object({
  address: z.string().optional(),
  keyPairId: z.string().optional(),
  keyPairName: z.string().optional(),
  publicKey: z.string().optional(),
  signerWeight: z.number().optional(),
})

export const cbdcSignerSchema = baseCbdcSignerSchema.extend({
  user: userSchema.optional(),
})
export type CbdcSigner = z.infer<typeof cbdcSignerSchema>

export const BaseCbdcSignerSchema = baseCbdcSignerSchema.extend({
  user: baseUserSchema.optional(),
})
export type BaseCbdcSigner = z.infer<typeof BaseCbdcSignerSchema>
