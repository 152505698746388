import { Controls } from '@ripple/design-system'
import {
  MAX_API_PAGE_SIZE,
  PageType,
  RequestError,
  WorkItem,
  WorkItemDetailsType,
  safeParseApiResponse,
  workItemTopResponseSchema,
  workItemTypes,
} from 'common'
import { useMemo } from 'react'
import { UseQueryOptions, useQuery } from 'react-query'
import { requestClient } from '../request-client'
import { WorkItemQueryKeys, useWorkItems } from '../work-items'

export enum FreezeQueryKeys {
  FreezeHistory = 'freeze-history',
  LatestGlobalFreeze = 'latest-global-freeze-item',
}

/**
 * Hook to get the latest global freeze work item.
 * You can tell the state of things based on the type and status
 * of the returned work item (e.g. type = global freeze, status = completed, active freeze)
 * @returns
 */
export const useLatestGlobalFreezeWorkItem = (
  opts?: UseQueryOptions<WorkItem, RequestError>,
) =>
  useQuery<WorkItem, RequestError>(
    FreezeQueryKeys.LatestGlobalFreeze,
    () =>
      requestClient
        .get('/work-items/global-freezes/top')
        .then(safeParseApiResponse(workItemTopResponseSchema)),
    opts,
  )

/**
 * Returns the latest freeze request for a specific account.
 * @param {string} accountId The account id to check
 * @param opts Optional useQuery options object
 * @returns {object | undefined} A freeze request work item or undefined
 */
export const useAccountFreezesByAccountId = (
  accountId: string,
  opts?: UseQueryOptions<PageType<WorkItemDetailsType[]>, RequestError>,
) =>
  useWorkItems(
    {
      type: workItemTypes.freezes.account.all,
      destinationAccountId: accountId,
      baseQueryKey: FreezeQueryKeys.FreezeHistory,
    },
    undefined,
    opts,
  )

// This is a temporary workaround to get external account freezes because
// using external accounts with `useAccountFreezesByAccountId` is not currently implemented
export const useExternalAccountFreezesByAccountId = (
  accountId: string,
  opts?: UseQueryOptions<PageType<WorkItemDetailsType[]>, RequestError>,
) => {
  const result = useWorkItems(
    {
      type: workItemTypes.freezes.account.external,
      baseQueryKey: WorkItemQueryKeys.Transactions,
    },
    { pagination: { pageSize: MAX_API_PAGE_SIZE } } as Controls,
    opts,
  )

  const externalAccounts = useMemo(
    () =>
      result.data?.content.filter(
        (account) => account.transaction.destinationAccount === accountId,
      ),
    [result.isFetching],
  )

  return {
    ...result,
    data: externalAccounts,
  }
}
