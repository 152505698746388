import { isUndefined } from '@ripple/ui-helpers'
import {
  AccountLedgerInfo,
  AccountSetup,
  CbdcAccountMetadata,
  FullUser,
  filterArrOfObjects,
} from 'common'
import { getAccountLedgerInfoByAddress } from '../account'

/**
 * Helper to check if an XRP address has already been assigned to a {@link User}.
 *
 * @param {FullUser[]} usersAndKeyPairs The list of {@link FullUser} to filter against.
 * @param {string} address The address to find in the list of {@link FullUser}
 * @returns
 */
export const isAddressUsedAsKeyPair = (
  usersAndKeyPairs: FullUser[],
  address: string,
) => {
  const userAndKeyPairs = filterArrOfObjects(
    usersAndKeyPairs,
    (userAndKeyPairs) => {
      return userAndKeyPairs.keyPairs?.some(
        (userKeyPair) => userKeyPair.address === address,
      )
    },
  )
  return userAndKeyPairs.length > 0
}

/**
 * Helper to check if an XRP address is already used for an {@link AccountSetup}.
 * @param {AccountSetup[]} accountSetups A list of account ledger infos.
 * @param {string} address The public key to find in the list.
 * @returns A {@link Boolean}.
 */
export const isAddressUsedForAccountSetup = (
  accountSetups: AccountSetup[],
  address: string,
) => {
  const accountSetupsArr = filterArrOfObjects(
    accountSetups,
    (acctSetup) => acctSetup.ledgerInfo?.address === address,
  )
  return accountSetupsArr.length > 0
}

/**
 * Helper to check if an XRP address is already used for a {@link CbdcAccount}.
 * @param {AccountSetup[]} accountsMetadata A list of account metadatas.
 * @param {string} address The address to find in the list.
 * @returns A {@link Boolean}.
 */
export const isAddressUsedForConfiguredAccount = (
  accountsMetadata: CbdcAccountMetadata[],
  address: string,
) => {
  const accountsMetaArr = filterArrOfObjects(
    accountsMetadata,
    (acctMeta) => acctMeta.address === address,
  )
  return accountsMetaArr.length > 0
}

/**
 * Helper to check if an XRP address has already been used for a previous setup which has been canceled.
 *
 * @param {AccountLedgerInfo[]} ledgerInfos A list of account ledger info.
 * @param {string} address The XRP address to find in that list.
 * @returns
 */
export const isAddressInJunkyard = (
  ledgerInfos: AccountLedgerInfo[],
  address: string,
) => {
  const account = getAccountLedgerInfoByAddress(ledgerInfos, address)
  return !isUndefined(account)
}
