import {
  ExternalAccount,
  RequestError,
  externalAccountSchema,
  invalidateQueryCache,
  safeParseApiResponse,
} from 'common'
import { useMutation, useQueryClient } from 'react-query'
import { requestClient } from '../request-client'
import { ExternalAccountsQueryKeys } from './external-accounts.queries'

export enum ExternalAccountMutationKeys {
  CreateExternalAccount = 'create-external-account',
  UpdateExternalAccount = 'update-external-account',
}

type NewExternalAccountPayload = {
  address: string
  destinationTag?: number // (0-4294967295)
  accountName: string
  correlationId: string
}

/**
 * Mutation to create a new external account.
 * On success, it updates the live query cache with the newly created item.
 */
export const useCreateExternalAccount = () => {
  const queryClient = useQueryClient()
  return useMutation<ExternalAccount, RequestError, NewExternalAccountPayload>(
    (data) =>
      requestClient
        .post('/external-accounts', data)
        .then(safeParseApiResponse(externalAccountSchema)),
    {
      mutationKey: ExternalAccountMutationKeys.CreateExternalAccount,
      onSuccess: () => {
        invalidateQueryCache(queryClient, [
          [ExternalAccountsQueryKeys.ExternalAccounts],
        ])
      },
    },
  )
}

/**
 * Mutation to update an existing external account.
 * On success, it updates the live query cache with the updated data.
 */
export const useUpdateExternalAccount = () => {
  const queryClient = useQueryClient()
  return useMutation<ExternalAccount, RequestError, ExternalAccount>(
    (data) =>
      requestClient
        .post(`/external-accounts/${data.accountId}`, data)
        .then(safeParseApiResponse(externalAccountSchema)),
    {
      mutationKey: ExternalAccountMutationKeys.UpdateExternalAccount,
      onSuccess: (updatedAccount) => {
        queryClient.setQueriesData<ExternalAccount[]>(
          [ExternalAccountsQueryKeys.ExternalAccounts],
          (externalAccounts = []) =>
            externalAccounts.map((acct) => {
              if (acct.accountId === updatedAccount.accountId) {
                return updatedAccount
              }
              return acct
            }),
        )
      },
    },
  )
}
