import { getUserFullname, hasPermission } from '@/shared/helpers'
import { useUserAccountDetailsGetter } from '@/shared/hooks/users'
import { Controls, prepareQueries } from '@ripple/design-system'
import {
  AccountType,
  BaseUser,
  FullUser,
  PageType,
  Permission,
  RequestError,
  UserDetails,
  getPaginatedQueryOpts,
  getRequestParamsFromControls,
} from 'common'
import { useTranslation } from 'react-i18next'
import { UseQueryOptions, useQuery } from 'react-query'
import { useRoles } from '../roles'
import {
  checkUserEmail,
  getDeactivatedUsers,
  getFullUserById,
  getFullUsers,
  getPossibleAccountUsers,
  getUsers,
  getUsersByAccountId,
  getUsersByAccountTypeAndPermissions,
} from './users-api'
import { UserAccountDetails } from './users-api.types'

export enum UserQueryKeys {
  Users = 'users',
  FullUsers = 'full-users',
  AccountUsers = 'users-by-account-id',
  UsersByAccountType = 'users-by-account-type',
  PossibleAccountUsers = 'possible-account-users',
  UserEmailExists = 'user-email-exists',
  DeactivatedUsers = 'deactivated-users',
}

export const useFullUsers = (
  opts?: UseQueryOptions<FullUser[], RequestError>,
) =>
  useQuery<FullUser[], RequestError>(
    [UserQueryKeys.FullUsers],
    getFullUsers,
    opts,
  )

/**
 * Get a {@link FullUser} by userId.
 * Only admins can call this API.
 *
 * @param {string} userId The userId.
 * @returns A {@link FullUser}.
 */
export const useFullUser = (userId: string) =>
  useQuery<FullUser, RequestError>([UserQueryKeys.FullUsers, userId], () =>
    getFullUserById(userId),
  )

export const useUserAccounts = (userId: string) => {
  const getUserAccountDetails = useUserAccountDetailsGetter()

  return useQuery<UserAccountDetails[], RequestError>(
    [UserQueryKeys.FullUsers, userId, 'accounts'],
    () =>
      getFullUserById(userId).then((fullUser) =>
        fullUser.accounts.map((account) =>
          getUserAccountDetails.get(account, fullUser),
        ),
      ),
    { enabled: getUserAccountDetails.isReady },
  )
}

/**
 * Hook to get a list of {@link UserDetails}
 * @param {object} opts Optional query parameters
 * @param {object} [queryOpts] useQuery options
 * @returns A paginated {@link UserDetails} list
 */
export const useUsers = (
  controls?: Controls,
  opts?: UseQueryOptions<PageType<UserDetails[]>, RequestError>,
) => {
  const { queryKeyControls, requestControls } =
    getRequestParamsFromControls(controls)

  return useQuery<PageType<UserDetails[]>, RequestError>(
    [UserQueryKeys.Users, ...queryKeyControls],
    () => getUsers(requestControls),
    getPaginatedQueryOpts(opts),
  )
}

export const useDeactivatedUsers = () =>
  useQuery<BaseUser[], RequestError>(
    [UserQueryKeys.DeactivatedUsers],
    getDeactivatedUsers,
  )

export type AccountUser = {
  canSign: boolean
  createdAt: string
  email: string
  fullName: string
  roleId: string
  roleName: string
  userId: string
}

type AccountUserOptions = {
  accountId: string
  accountType?: AccountType
  permissions?: Permission[]
}

/**
 * Get a list of {@link User} that are members of an account.
 *
 * @param {object} opts Options for selecting the right user set
 * @param {string} opts.accountId The target account uuid
 * @param {AccountType} [opts.accountType] The target account type
 * @param {Permission[]} [opts.permissions] An optional array of permissions to filter the results
 * @param opts Query options
 * @returns {AccountUser[]} An array of {@link AccountUser}
 */
export const useAccountUsers = (
  { accountId, accountType, permissions = [] }: AccountUserOptions,
  opts?: UseQueryOptions<UserDetails[], RequestError>,
) => {
  const { t } = useTranslation(['common'])
  const rolesResponse = useRoles()
  const userResponse = useQuery<UserDetails[], RequestError>(
    [UserQueryKeys.AccountUsers, accountId, accountType, permissions],
    () => getUsersByAccountId(accountId),
    opts,
  )

  let data: AccountUser[] = []

  if (rolesResponse.data && userResponse.data) {
    const getRole = (roleId: string) =>
      rolesResponse.data.find((role) => role.roleId === roleId)

    // cast users to shape collection controls can use
    data = userResponse.data.map((user) => {
      const roleId = user.roleIds[0]
      const role = getRole(roleId)

      return {
        canSign: role
          ? hasPermission({
              userRoles: [role],
              permissions: [
                { cbdcAccountType: accountType, permission: Permission.Sign },
              ],
            })
          : false,
        createdAt: user.createdAt,
        email: user.username,
        fullName: getUserFullname(user),
        roleId,
        // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
        // @ts-ignore
        roleName: role?.roleName ?? t('common:Unknown'),
        userId: user.userId,
      }
    })
    // apply permissions filters if passed
    if (permissions.length) {
      const mappedPerms = permissions.map((p) => ({
        cbdcAccountType: accountType,
        permission: p,
      }))

      data = data.filter((user) => {
        const role = getRole(user.roleId)
        return role
          ? hasPermission({ userRoles: [role], permissions: mappedPerms })
          : false
      })
    }
  }

  return prepareQueries(data, userResponse, rolesResponse)
}

type PossibleAccountUsersProps = {
  accountId: string
  permissions?: Permission[]
}

/**
 * Hook to get a list of users who could be added to an account.
 * Includes users who have any permission for an account type who
 * are not already a member of the account. Can optionally be filtered
 * by permissions.
 * @param {object} params Required parameters for the hook
 * @param {string} params.accountId The account id to check
 * @param {Permission[]} [params.permissions] Optional permissions filters
 * @param {object} [opts] Optional useQuery options object
 * @returns useQuery response containing a list of {@link FullUser}
 */
export const usePossibleAccountUsers = (
  { accountId, permissions }: PossibleAccountUsersProps,
  opts?: UseQueryOptions<FullUser[], RequestError>,
) =>
  useQuery<FullUser[], RequestError>(
    [UserQueryKeys.PossibleAccountUsers, accountId, permissions],
    () => getPossibleAccountUsers(accountId, permissions),
    opts,
  )

type UsersByAccountTypeAndPermissions = {
  accountType?: AccountType
  permissions?: Permission[]
}

/**
 * Get a list of FullUsers by account type and/or permissions (both optional).
 *
 * @param {Object} object
 * @param {Object} object.accountType The account type
 * @param {Object} object.permissions The permissions
 * @param opts The query options
 * @returns A list of {@link FullUser}
 */

export const useUsersByAccountTypeAndPermissions = (
  { accountType, permissions }: UsersByAccountTypeAndPermissions,
  opts?: UseQueryOptions<FullUser[], RequestError>,
) => {
  return useQuery<FullUser[], RequestError>(
    [UserQueryKeys.UsersByAccountType, accountType, permissions],
    () => getUsersByAccountTypeAndPermissions(accountType, permissions),
    opts,
  )
}

type UsersLookupObject = Record<string, FullUser>
type DeactivatedUsersLookupObject = Record<string, BaseUser>

export const useUsersLookupObject = (
  opts?: UseQueryOptions<FullUser[], RequestError>,
) => {
  const { data = [], ...rest } = useFullUsers(opts)

  return {
    ...rest,
    users: data.reduce<UsersLookupObject>((obj, user) => {
      obj[user.user.userId] = user
      return obj
    }, {}),
  }
}

export const useDeactivatedUsersLookupObject = () => {
  const { data = [], ...rest } = useDeactivatedUsers()

  return {
    ...rest,
    deactivatedUsers: data.reduce<DeactivatedUsersLookupObject>((obj, user) => {
      obj[user.userId] = user
      return obj
    }, {}),
  }
}

export const useUserEmailExists = (
  emailAddress: string,
  opts?: UseQueryOptions<boolean, RequestError>,
) => {
  const { data: emailExists, ...rest } = useQuery<boolean, RequestError>(
    [UserQueryKeys.UserEmailExists, emailAddress],
    () => checkUserEmail(emailAddress),
    opts,
  )

  return { emailExists, ...rest }
}
