import { UserAccountMin } from 'common'

/**
 * Creates a curried function to check if a user is a member of an account.
 * @param {UserAccountMin[]} userAccounts Array of user accounts
 * @returns Function that checks if a user is a member of an account
 */
export const getAccountMembershipCheck =
  (userAccounts: UserAccountMin[]) => (accountId: string) =>
    userAccounts.some(({ cbdcAccountId }) => accountId === cbdcAccountId)
