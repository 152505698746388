import { z } from 'zod'

export enum AccountSetupStepTypeName {
  LedgerInfo = 'LEDGER_INFO',
  Fund = 'FUND',
  SetFlags = 'SET_FLAGS',
  TrustSet = 'TRUST_SET',
  SignerListSet = 'SIGNER_LIST_SET',
  DisableMaster = 'DISABLE_MASTER',
  Completing = 'COMPLETING',
  Completed = 'COMPLETED',
}

/**
 * The different paths for the account setup flow.
 * That will be part of the response from GET "/account-setups/{accountId}/next".
 */
export enum AccountSetupStepTypePathName {
  LedgerInfo = 'ledger-info',
  Fund = 'fund',
  SetFlags = 'set-flags',
  TrustSet = 'trust-set',
  SignerListSet = 'signer-list-set',
  DisableMaster = 'disable-master',
  Completing = 'completing',
  Completed = 'completed',
}

/**
 * The steps types in the account setup flow.
 */
export const accountSetupStepTypeSchema = z.object({
  name: z.nativeEnum(AccountSetupStepTypeName),
  pathName: z.nativeEnum(AccountSetupStepTypePathName),
})

export type AccountSetupStepType = z.infer<typeof accountSetupStepTypeSchema>
