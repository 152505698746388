export const ROLE_PERMISSION_DISPLAY_MAP = {
  ADD_ACCOUNT: 'role-permission.add-account',
  ADD_ROLE: 'role-permission.add-role',
  ADD_USER: 'role-permission.add-user',
  DELETE_ACCOUNT: 'role-permission.delete-account',
  DELETE_ROLE: 'role-permission.delete-role',
  DELETE_USER: 'role-permission.delete-user',
  EDIT_ACCOUNT: 'role-permission.edit-account',
  EDIT_ROLE: 'role-permission.edit-role',
  EDIT_USER: 'role-permission.edit-user',
  READ_ROLE: 'role-permission.read-role',
  READ_ACCOUNT: 'role-permission.read-account',
  FREEZE: 'role-permission.freeze',
  TRANSACT: 'role-permission.pay-and-destroy-transact',
  SIGN: 'role-permission.sign',
} as const
