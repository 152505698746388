import { z } from 'zod'
import { accountNameStateChangeSchema } from './account-name-state-change.types'
import { quorumStateChangeSchema } from './quorum-state-change.types'
import { signerStateChangeSchema } from './signer-state-change.types'
import { usersStateChangeSchema } from './user-state-change.types'

export const accountEditStateChangeSchema = z.object({
  quorumChange: quorumStateChangeSchema,
  nameChange: accountNameStateChangeSchema,
  signerListChanges: signerStateChangeSchema.array(),
  userChanges: usersStateChangeSchema.optional(),
})

export type AccountEditStateChange = z.infer<
  typeof accountEditStateChangeSchema
>
