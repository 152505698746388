import { z } from 'zod'
import { userKeyPairSchema } from '../users'

/**
 * Backend file located in:
 * cbdc-model/src/main/java/io/ripplex/cbdc/core/model/ws/KeyPairWebSocketMessageData.java
 */
export const keyPairWebSocketMessageDataSchema = z.object({
  /** The TenantId of the tenant that the key pair belongs to. */
  tenantId: z.string(),
  /** The UserId of the user who owns the keypair. */
  keyPairOwnerUserId: z.string(),
  /** The unique identifier of this UserKeyPair. */
  userKeyPairId: z.string(),
  /** The UserKeyPair in its updated state. */
  userKeyPair: userKeyPairSchema.optional(),
})

export type KeyPairWebSocketMessageData = z.infer<
  typeof keyPairWebSocketMessageDataSchema
>
