import { AccountsMap } from 'common'

/**
 * Checks is an account id exists in an {@link AccountsMap},
 * and if so, checks whether `isSigner` is true for that account.
 * @param {string} accountId Account id to check against
 * @param {AccountsMap} accountsMap User {@link AccountsMap} object
 * @returns {boolean}
 */
export function userIsAccountSigner(
  accountId: string,
  accountsMap: AccountsMap,
) {
  return accountsMap[accountId]?.isSigner ?? false
}
