import { useAccounts } from '@/shared/api'
import { FullUser, UserAccountMin } from 'common'
import { useCallback } from 'react'
import { useIssuerAccountMetadata } from '../use-issuer-account-metadata'

/**
 * Hook to get a stable function that returns display values for user account details.
 */
export function useUserAccountDetailsGetter() {
  const accounts = useAccounts()
  const { currencyCode = '' } = useIssuerAccountMetadata() ?? {}

  return {
    isReady: !!(accounts.data?.length && currencyCode),
    get: useCallback(
      (userAccount: UserAccountMin, fullUser: FullUser) => {
        const userKeyPairs = fullUser.keyPairs.map(
          (keyPair) => keyPair.userKeyPairId,
        )

        const matchedAccount = accounts.data?.find(
          (acct) => acct.address === userAccount.address,
        )

        const { keyPairName = '' } =
          matchedAccount?.signers.find(
            ({ keyPairId }) => keyPairId && userKeyPairs.includes(keyPairId),
          ) ?? {}

        return {
          ...userAccount,
          signerKeyPair: keyPairName,
          balance: {
            value: matchedAccount?.balances[currencyCode] ?? '0',
            currencyCode,
          },
        }
      },
      [accounts],
    ),
  }
}
