export default {
  Payments: 'Payments',
  'New-Payment': 'New Payment',
  'New-Request': 'New Request',
  'Mint-Requests': 'Mint Requests',
  'Destroy-Requests': 'Destroy Requests',
  'loading-payments': 'Loading {{type}} requests...',
  'account-edit-pending': 'This account has a pending account edit. New requests cannot be initiated until the edit is completed.',
  'available-balance': '{{amount}} {{currency}} Available',
  'no-funds': 'Account has no funds',
  'New-Destroy-Request': 'New Destroy Request',
  'New-Mint-Request': 'New Mint Request',
  'New-Payment-Request': 'New Payment Request',
  'Create-Request': 'Create Request',
  'bad-currency-code': 'Could not retrieve the currency code',
  'no-issuer-account': 'No issuer account found',
  'insufficient-funds': 'Insufficient funds available in {{source}} ({{balance}} {{currencyCode}}).',
  'trustline-limit-exceeded': 'Amount will exceed the trust line limit ({{maxAmount}} {{currencyCode}}) for {{destinationAccount}}.',
  'Payment-Form-Issues': 'There are issues with your form. Please review.',
  'Total-Minted': 'Total Minted',
  'Total-on-Standby': 'Total in Standby Accounts',
  'Total-Distributable': 'Total in Operational Accounts',
  'In-Circulation': 'Total In Circulation',
  'digital-currency': 'digital currency',
  'aggregate-balance.total.tooltip': 'The total {{currency}} supply in the entire system (including internal and external accounts).',
  'aggregate-balance.standby.tooltip': 'The total amount of {{currency}} in Standby Accounts.',
  'aggregate-balance.distribution.tooltip': 'The total amount of {{currency}} in Distribution Accounts.',
  'aggregate-balance.external.tooltip': 'The total amount of {{currency}} in all external accounts.',
  'no-payments': 'No payments',
  'no-payments.destroy': 'No destroy requests found where you are a signer.',
  'no-payments.mint': 'No mint requests found where you are a signer.',
  'no-payments.payment': 'No payment requests found where you are a signer.',
}
