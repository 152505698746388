import {
  getWorkItemFromFreezeAction,
  useCreateFreezeRequest,
} from '@/shared/api'
import {
  useAccountMetadataLookup,
  useIssuerAccountMetadata,
} from '@/shared/hooks'
import { ModalControlModalProps, useToast } from '@ripple/design-system'
import {
  AccountFreezeType,
  FreezeAction,
  useMutationErrorHandler,
} from 'common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

type UseAccountFreezeActionProps = Pick<ModalControlModalProps, 'onClose'> & {
  action: FreezeAction
} & (
    | {
        type: AccountFreezeType.Individual
        address: string
      }
    | {
        address?: never
        type: AccountFreezeType.Global
      }
  )

export function useAccountFreezeAction({
  action,
  address,
  onClose,
  type,
}: UseAccountFreezeActionProps) {
  const { t } = useTranslation(['common', 'accounts'])
  const issuer = useIssuerAccountMetadata()
  const accountLookup = useAccountMetadataLookup()
  const { mutateAsync } = useCreateFreezeRequest()
  const mutationErrorHandler = useMutationErrorHandler()
  const [isExecuting, setIsExecuting] = useState(false)
  const toast = useToast()

  return useMemo(
    () => ({
      isExecuting,
      handleAction: async () => {
        setIsExecuting(true)

        const workItemType = getWorkItemFromFreezeAction(
          action,
          type,
          accountLookup.lookupAccountByAddress(address ?? ''),
        )

        await mutateAsync(
          {
            request: {
              counterpartyAddress: address,
              sourceAccount: issuer?.cbdcAccountId ?? '',
              workItemType,
            },
            setting: type,
            workItemId: uuidv4(),
          },
          {
            onError: (error) => {
              mutationErrorHandler(
                t(
                  action === 'freeze'
                    ? 'accounts:freeze-request.error'
                    : 'accounts:unfreeze-request.error',
                ),
              )(error)
            },
            onSuccess: () => {
              toast.success(
                t(
                  type === AccountFreezeType.Global
                    ? action === 'freeze'
                      ? 'accounts:global-freeze-request.success'
                      : 'accounts:global-unfreeze-request.success'
                    : action === 'unfreeze'
                    ? 'accounts:unfreeze-request.success'
                    : 'accounts:freeze-request.success',
                ),
              )
              onClose()
            },
            onSettled: () => {
              setIsExecuting(false)
            },
          },
        )
      },
    }),
    [address, issuer?.cbdcAccountId, action, accountLookup.isLoading],
  )
}
