import {
  NewRole,
  RequestError,
  Role,
  queryClient,
  roleSchema,
  safeParseApiResponse,
} from 'common'
import { useMutation } from 'react-query'
import { requestClient } from '../request-client'
import { RoleQueryKeys } from './roles.queries'

export const useUpdateRole = (roleId: string) => {
  return useMutation<Role, RequestError, NewRole>(
    (body) =>
      requestClient
        .put(`roles/${roleId}`, body)
        .then(safeParseApiResponse(roleSchema)),
    {
      onSuccess: (updatedRole) => {
        queryClient.setQueryData<Role>(
          [RoleQueryKeys.Roles, roleId],
          () => updatedRole,
        )
      },
    },
  )
}

export const useDeleteRole = (roleId: string) => {
  return useMutation<Role, RequestError, string | undefined>(
    (alternateRoleId) => {
      // delete the role with an optional new roleId
      return requestClient.delete(
        `roles/${roleId}${
          alternateRoleId ? `?alternateRoleId=${alternateRoleId}` : ''
        }`,
      )
    },
    {
      onSuccess: () => {
        queryClient.setQueryData<Role[]>([RoleQueryKeys.Roles], (roles = []) =>
          roles.filter((role) => role.roleId !== roleId),
        )
      },
    },
  )
}

/**
 * Create a new role.
 */
export const useCreateRole = () => {
  return useMutation<Role, RequestError, NewRole>(
    (body) =>
      requestClient.post('roles', body).then(safeParseApiResponse(roleSchema)),
    {
      onSuccess: (newRole) => {
        queryClient.setQueryData<Role[]>(
          [RoleQueryKeys.Roles],
          (roles = []) => [...roles, newRole],
        )
      },
    },
  )
}
