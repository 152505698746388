import i18n from '@/i18n'
import type { FieldValidatorFn } from '@ripple/design-system'
import {
  CollectionChangeHandler,
  CollectionFieldChangeHandler,
  CollectionFieldsState,
} from '@ripple/design-system/lib/types/components/forms/hooks/form-mgmt/form-hooks.types'
import { DEFAULT_QUORUM } from '../constants'
import { AccountFormCollections, AccountFormFields } from '../types'

/**
 * Sums signer weigths and tracks if any of the summed values are empty.
 * @param items An array of signer field state objects
 * @returns {object} An object with the summed weight (`sum`) and a `hasEmptyValues`
 * boolean indicating if any of the weights are less than 1 (an empty field value)
 */
const sumWeights = (
  items: CollectionFieldsState<AccountFormCollections['signers']>[],
) => {
  let hasEmptyValues = false
  const sum = items.reduce((sum, { signerWeight }) => {
    const value = signerWeight.value
    if (!value) {
      hasEmptyValues = true
      return sum
    }
    return sum + value
  }, 0)
  return { sum, hasEmptyValues }
}

export const signerCollectionChangeHandler: CollectionChangeHandler<
  AccountFormCollections['signers'],
  AccountFormCollections,
  Pick<AccountFormFields, 'signerQuorum'>
> = (collection, { updateFields }) => {
  // sync quorum `max` with summed weights
  const { sum, hasEmptyValues } = sumWeights(collection.items)

  if (!hasEmptyValues && sum >= DEFAULT_QUORUM) {
    updateFields({
      signerQuorum: { max: sum },
    })
  }
}

export const signerWeightChangeHandler: CollectionFieldChangeHandler<
  AccountFormCollections['signers'],
  AccountFormCollections,
  Pick<AccountFormFields, 'signerQuorum'>
> = (_, updaters, state) => {
  signerCollectionChangeHandler(state.collections.signers, updaters, state)
}

const xrpRegex = /^xrp$/i

/**
 * Field validator for an issuer account currency code field
 * to ensure no one creates an issuer with XRP (case insensitive) as the currency.
 */
export const disallowXRPAsCode: FieldValidatorFn<'text'> = ({ value }) => {
  return xrpRegex.test(value) ? i18n.t('accounts:xrp-not-valid') : ''
}
