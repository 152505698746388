export default {
  Keypairs: 'Keypairs',
  'Keypair-Name': 'Keypair Name',
  'Keypair-Type': 'Keypair Type',
  'Edit-User-Details': 'Edit User Details',
  'Add-Accounts': 'Add Accounts',
  'Add-Account': 'Add Account',
  'Add-Keypair': 'Add Keypair',
  'Public-Key': 'Public Key',
  'Account-Lookup': 'Account Lookup',
  'App-User': 'App User',
  Signing: 'Signing',
  'machine-user': 'Machine User',
  'machine-user.initiator': 'Machine User Initiator',
  'machine-user.signer': 'Machine User Signer',
  'machine-user.description': 'A Machine User has the ability to securely access the Issuer backend system by using a unique secret API key.',
  'machine-user.can-sign': 'Can sign for transactions',
  'machine-user.cannot-sign': 'Cannot sign for transactions',
  'New-User': 'New User',
  'User-Details': 'User Details',
  'User-Type': 'User Type',
  'Users-Added': 'Users Added',
  'Users-Removed': 'Users Removed',
  'User-List': 'User List',
  'deactivate-keypair.title': 'Deactivate Keypair?',
  'deactivate-keypair.confirmation': "Are you sure you want to deactivate this keypair? You can't undo this action.",
  'deactivate-keypair.success': 'Keypair successfully deactivated',
  'deactivate-keypair.error': 'Failed to deactivate the keypair',
  'deactivate-keypair.forbidden.title': 'You Cannot Deactivate This Keypair',
  'deactivate-keypair.forbidden.body': 'This keypair is used to sign transactions on $t(plurals:accounts, {"count": {{count}}}). To deactivate this keypair, remove it from the associated $t(plurals:accounts, {"count": {{count}}}): {{names}}',
  Role: 'Role',
  'Key-Type': 'Key Type',
  'machine-user.api-key-card.api-credentials': 'API Credentials',
  'machine-user.api-key-card.api-secret-key': 'API Secret Key',
  'machine-user.api-key-card.view-secret-key': 'View Key',
  'machine-user.api-key-card.regenerate-secret-key': 'Regenerate Key',
  'machine-user.api-key-card.copy-secret-key': 'Copy Key',
  'machine-user.api-key-card.generating-secret-key': 'Your secret key is being generated...',
  'machine-user.api-key-card.copy-secret-key-instructions': 'Make sure to copy the secret key below, as it can only be viewed once.',
  'machine-user.api-key-card.copy-secret-key-description': 'If you have lost or forgotten this key, you can regenerate it below. Be aware that any scripts or applications using this key will need to be updated.',
  'machine-user.api-key-card.key-generated-on': 'Key generated on {{date}}',
  'machine-user.api-key-card.key-viewed-on': 'Key viewed on {{date}}',
  'machine-user.regenerate-key-modal.title': 'Regenerate Key?',
  'machine-user.regenerate-key-modal.error': 'There was an error regenerating the API key',
  'machine-user.regenerate-key-modal.message': 'Once you regenerate your API secret key, the old key will no longer work to authenticate your user.',
  'machine-user.delete-user.message': 'If you deactivate this user, the secret key will no longer work to authenticate.',
  'machine-user.delete-user-with-accounts.message': 'If you deactivate this user, the secret key will no longer work to authenticate and the user will be removed from <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>.',
  'Signer-Accounts': 'Signer Accounts',
  'Total-Accounts': 'Total Accounts',
  'loading-users': 'Loading users...',
  'loading-user': 'Loading user...',
  'no-users': 'No users found',
  'no-user': 'No user found with id: {{userId}}',
  'keypairs.description': 'This user has a role that requires one or more keypairs to sign transactions.',
  'keypairs.placeholder': 'Available After User Creation',
  'keypairs.creating': 'Generating Address',
  'create-keypairs.duplicate': 'That keypair already exists. Please try again with a different key.',
  'create-keypairs.error': 'There was an error creating the keypair. Please try again.',
  'connect-ledger-nano.error': 'Could not connect to your Ledger Device',
  'connect-ledger-nano.found': '{{ledgerName}} available account retrieved',
  'connect-ledger-nano.loading': 'Connecting to Ledger Nano',
  'connect-ledger-nano.description': 'To finish setting up the account, begin by plugging and connecting in your Ledger Nano.',
  'update-role.signer-error': 'This user is a signer on $t(plurals:accounts, count). To change their role, you must first remove them as a signer from all accounts.',
  'You-Cannot-Change-Their-Role': 'You Cannot Change Their Role',
  'add-user-account.instructions': 'Search and select the account(s) you wish to add {{user}} to, and for which {{user}} has the right permissions.',
  'add-user-account.restrictions': 'The account lookup will only display accounts the user is permitted to view.',
  'add-user-account.success': '$t(plurals:accounts, {"count": {{numAdded}}}) added successfully',
  'remove-user-account.title': 'Remove $t(plurals:Accounts, {"count": {{numAccounts}}})?',
  'remove-user-account.prompt': 'Are you sure you want to remove {{user}} from $t(plurals:accounts, {"count": {{count}}}): {{accounts}}?',
  'remove-user-account.success': '$t(plurals:accounts, {"count": {{numRemoved}}}) removed successfully',
  About: 'About',
  'Full-Name': 'Full Name',
  'User-Lookup': 'User Lookup',
  'Date-Created': 'Date Created',
  'Create-User': 'Create User',
  'create-user.name-already-used': 'Name already used',
  'create-user.email-already-used': 'Email already in use',
  'create-user.machine-user.bad-public-key': 'Public key must be in PEM format',
  'create-user.error': 'Something went wrong creating the user.',
  'create-user.success': 'User created successfully',
  'create-keypair.error': 'Failed to create $t(plurals:keypairs, {"count": {{count}}})',
  'create-keypair.success': 'Successfully added $t(plurals:keypairs, {"count": {{count}}})',
  'rename-keypair.wrong-id': "Incorrect keypair identifier {{oldItemIndex}}. The keypair couldn't be renamed.",
  'add-account-users.instructions': 'Search and select the user(s) you wish to add to this account.',
  'add-account-users.success': 'Successfully added {{count}} users to the account.',
  'add-account-users.error': 'Something went wrong adding users.',
  'edit-user.instructions': "Use the fields below to edit the user's name.",
  'edit-user-role.instructions': 'Search and select the new role you want to assign to this user.',
  'edit-user-role.warning': "<Strong>This role will remove the user from $t(plurals:accounts, {'count' :{{totalImpactedAccounts}}}).</Strong> If you select this role and assign it to the user, they will be removed from all accounts that are in conflict with the new role's permissions.",
  'edit-user-role.missing-keypair': '<Strong>This role requires a keypair.</Strong> The selected role has the "can sign" permission enabled. If you select this role and assign it to the user, you will need to assign them a keypair before they can be selected as a signer on an account.',
  'edit-user-role.success': 'Role successfully updated',
  'Change-User-Role': 'Change User Role',
  'edit-user-role.error': "Role couldn't be updated",
  'rename-keypair.title': 'Rename Keypair',
  'rename-keypair.instructions': 'To rename this keypair, enter a new name below.',
  'add-keypair.success': 'Keypair successfully added',
  'add-keypair.instructions': 'Add a keypair for this user to sign transactions.',
  'update-user-info.error': "Something went wrong updating the user's information.",
  'update-user-info.success': 'User successfully updated.',
  'deactivate-user.cta': 'Deactivate User',
  'deactivate-user.allowed': 'Deactivate User?',
  'deactivate-user.confirm': 'Do you want to deactivate this user? This action cannot be undone.',
  'deactivate-user.will-lose-accounts': 'If you deactivate this user, they will be removed from <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>. You cannot undo this action.',
  'deactivate-user.denied': 'You Cannot Deactivate This User',
  'deactivate-user.configured-signer-warning': 'To deactivate this user, you must first remove them as a signer on <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>.',
  'deactivate-user.unconfigured-signer-warning': 'To deactivate this user, you must first remove them as a signer on <Strong>$t(plurals:Accounts, {"count": {{numSetups}}}) pending configuration</Strong>.',
  'deactivate-user.configured-unconfigured-signer-warning': 'To deactivate this user, you must first remove them as a signer on <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong> and <Strong>$t(plurals:Accounts, {"count": {{numSetups}}}) pending configuration</Strong>.',
  'deactivate-user.error': 'Something went wrong deactivating the user. Please try again.',
  'deactivate-user.success': 'The user was successfully deactivated.',
  'cannot-deactivate-admin': 'You Cannot Deactivate Yourself',
  'deactivate-admin-message-one': 'You are the only admin user in the system and cannot be deactivated.',
  'deactivate-admin-message-two': 'To deactivate this admin, you must first create another admin.',
  'no-active-keypair': 'This user does not have an active keypair. They must be assigned one before they can be selected as a signer on an account.',
  'public-key-banner.title': 'You must enter a public key.',
  'machine-user.public-key-card.title': 'API Public Key',
  'machine-user.public-key-card.view-api-key.title': 'View API Public Key',
  'machine-user.public-key-card.edit-api-key.title': 'Edit API Public Key',
  'machine-user.public-key-card.edit-api-key.error': 'There was an error updating the public key',
  'machine-user.public-key-card.view-api-key.instructions': 'Paste a copy of your public key below. <Link>Learn more</Link>',
  'machine-user.public-key-card.edit-api-key.label': 'Enter key here',
  'machine-user.public-key-card.edit-key': 'Edit Key',
  'machine-user.public-key-card.key-added-on': 'Key added on {{date}}',
  'machine-user.public-key-card.key-updated-on': 'Key updated on {{date}}',
}
