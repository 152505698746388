import { z } from 'zod'
import { currencyCodeSchema } from './currency'
import { BalancesEventType } from './events'

export const balanceMapSchema = z.record(currencyCodeSchema, z.string())

export type BalanceMap = z.infer<typeof balanceMapSchema>

export type BalanceChangeEvent = {
  TenantId: string
  Address: string
  type: BalancesEventType.Updated
}
