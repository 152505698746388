import { z } from 'zod'
import { cbdcSignerSchema } from '../cbdc-signer'
import { TransactionType } from './transaction-type'

const amountSchema = z.object({
  value: z.string(),
  currency: z.string(),
  issuer: z.string(),
})

export const transactionSchema = z.object({
  Account: z.string(),
  AccountTxnID: z.string().optional(),
  Amount: amountSchema.optional(),
  date: z.string().optional(),
  Destination: z.string().optional(),
  Fee: z.string(),
  Flags: z.number().optional(),
  LimitAmount: amountSchema.optional(),
  ledger_index: z.string().optional(),
  Sequence: z.number(),
  Signers: cbdcSignerSchema.array().optional(),
  SigningPubKey: z.string(),
  SourceTag: z.string().optional(),
  tickSize: z.number().optional(),
  TransactionType: z.nativeEnum(TransactionType),
  TxnSignature: z.string().optional(),
  // Memo ?
})

export type Transaction = z.infer<typeof transactionSchema>
