import {
  BaseUser,
  baseUserSchema,
  FullUser,
  fullUserSchema,
  getPageSchema,
  KeyPairStore,
  PageType,
  PaginationOptions,
  Permission,
  safeParseApiResponse,
  UserDetails,
  userDetailsSchema,
  userKeyPairSchema,
} from 'common'
import { requestClient } from '../request-client'

const userDetailsListSchema = userDetailsSchema.array()
const fullUsersSchema = fullUserSchema.array()

export const getUsers = (
  params: PaginationOptions = {},
): Promise<PageType<UserDetails[]>> =>
  requestClient
    .get('/admin/users', { params })
    .then(safeParseApiResponse(getPageSchema(userDetailsListSchema)))

export const getUsersByAccountId = (
  accountId: string,
): Promise<UserDetails[]> =>
  requestClient
    .get(`/users?cbdcAccountId=${accountId}`)
    .then(safeParseApiResponse(userDetailsListSchema))

export const getFullUsers = (): Promise<FullUser[]> =>
  requestClient.get('/users').then(safeParseApiResponse(fullUsersSchema))

export const getUsersByAccountTypeAndPermissions = (
  accountType?: string,
  permissions?: Permission[],
): Promise<FullUser[]> =>
  requestClient
    .get(`/users?cbdcAccountType=${accountType}&permissions=${permissions}`)
    .then(safeParseApiResponse(fullUsersSchema))

export const getUserById = (id: string): Promise<UserDetails> =>
  requestClient
    .get(`/users/${id}/user`)
    .then(safeParseApiResponse(userDetailsSchema))

export const getDeactivatedUsers = (): Promise<BaseUser[]> =>
  requestClient
    .get(`/deactivated-users`)
    .then(safeParseApiResponse(baseUserSchema))

export const getFullUserById = (id: string): Promise<FullUser> =>
  requestClient
    .get(`/admin/users/${id}`)
    .then(safeParseApiResponse(fullUserSchema))

const getPermissionsQuery = (permissions: Permission[] = []) =>
  permissions.length ? `?permissions=${permissions.join(',')}` : ''

export const getPossibleAccountUsers = (
  accountId: string,
  permissions?: Permission[],
): Promise<FullUser[]> =>
  requestClient
    .get(
      `/users/possible-account-users/${accountId}${getPermissionsQuery(
        permissions,
      )}`,
    )
    .then(safeParseApiResponse(fullUsersSchema))

export const getRoleUsers = (roleId: string): Promise<FullUser[]> =>
  requestClient
    .get(`/users?roleId=${roleId}`)
    .then(safeParseApiResponse(fullUsersSchema))

/**
 * Check if an email has already been assigned to a user across all tenants.
 *
 * @param {string} email An email.
 * @returns
 * - A 200 if the email has been found (so not available)
 * - A 404 if the email has not been found (so available)
 */
export const checkUserEmail = (email: string): Promise<boolean> =>
  requestClient
    .head(`/users/${email}`)
    .then(() => true)
    .catch((err) => {
      if (err.response?.status === 404) {
        return false
      }

      throw err
    })

/**
 * Check if an address has already been assigned to a user keypair across all tenants.
 *
 * @param {string} address An XRPL address.
 * @returns
 * - A 200 if the address has been found (so not available)
 * - A 404 if the address has not been found (so available)
 */
export const getUserKeypairAddressAvailability = (address: string) =>
  requestClient.head(`/users/keypairs/${address}`)

export type CreateUserKeyPairProps = {
  keyPairName: string
  publicKey: string | null
  userId: string
  userKeyPairPlatform: KeyPairStore
}

export type KeypairUpdatePayload = CreateUserKeyPairProps & {
  keyPairId: string
}

/**
 * Single PUT endpoint for creating or updating a keypair
 * @param {KeypairUpdatePayload} data Values to submit to the API
 */
export const createOrUpdateKeypair = ({
  keyPairId,
  userId,
  ...payload
}: KeypairUpdatePayload) =>
  requestClient
    .put(`/users/${userId}/keypairs/${keyPairId}`, payload)
    .then(safeParseApiResponse(userKeyPairSchema))
