import { z } from 'zod'
import { KeyPairCreationStatus } from './keypair-creation-status'
import { KeyPairStore } from './keypair-store'

export const userKeyPairSchema = z.object({
  address: z.string().optional(),
  createdAt: z.string(),
  creationStatus: z.nativeEnum(KeyPairCreationStatus).optional(),
  errorMessage: z.string().optional(),
  isActive: z.boolean(),
  name: z.string(),
  platform: z.nativeEnum(KeyPairStore),
  publicKey: z.string().optional(),
  tenantId: z.string(),
  updatedAt: z.string(),
  userId: z.string(),
  userKeyPairId: z.string(),
})

export type UserKeyPair = z.infer<typeof userKeyPairSchema>
