/**
 * An error for 404 responses
 */
export class NotFoundError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'NotFoundError'
  }
}

/**
 * An error for 403 responses
 */
export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'UnauthorizedError'
  }
}
