import { AnalyticsTrackers, clickEvent } from '../components'
import { isSandbox } from './env'

export const getGoogleAnalyticsTrackingId = () =>
  isSandbox() ? 'G-0BQMTQ6K6V' : 'G-Z93X498H65'

export const trackedActions = {
  ConfirmFreezeAction: 'ConfirmFreezeAction',
  ConfirmAccountEdit: 'ConfirmAccountEdit',
  CreatePaymentRequest: 'CreatePaymentRequest',
} as const

export const trackers: AnalyticsTrackers = {
  click: (analytics, attributes) => {
    analytics.track(clickEvent, attributes)
  },
  page: (analytics) => {
    analytics.page()
  },
  identify: (analytics, user) => {
    const { userId, tenantId } = user

    analytics.identify(userId, {
      tenantId,
    })
  },
}
