import { WALLET } from '@/shared/constants/paths'
import { isLocal, isSandbox } from 'common'
import { AccountOverviewPage } from '../wallet/account-overview.page'
import { WalletRedirect } from '../wallet/components'
import { ConnectAccountPage } from '../wallet/connect-account.page'
import { ModifiedRouteProps } from './public.routes'

export const walletRoutes: ModifiedRouteProps[] =
  isLocal() || isSandbox()
    ? [
        { path: WALLET.root, element: WalletRedirect, index: true },
        { path: WALLET.account, element: AccountOverviewPage },
        { path: WALLET.connectAccount, element: ConnectAccountPage },
      ]
    : []
