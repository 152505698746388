import { useToast } from '@ripple/design-system'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorDetails, RequestError } from '../models'

function formatErrorDetails(details?: ErrorDetails) {
  if (details?.expectedStatuses) {
    return {
      ...details,
      expectedStatuses: details.expectedStatuses.join(', '),
    }
  }
  return details
}

/**
 * This is a list of generic API error codes (e.g. broad issues like a 422) that
 * the backend also supplements with additional error details.
 * Before adding more errors here, ensure the backend provides details for that error.
 */
const genericErrorCodes = ['UNPROCESSABLE_ENTITY']

/**
 * A hook that provides a function which returns a detailed mutation error response.
 * Usually useful for providing more detail as to why a mutation failed
 * instead of a generic failure message.
 *
 * @returns a callback function that returns the error response details or a default
 * error message if the error response does not exist in the form of a toast
 * The callback function takes these params as input:
 * @param msg a default error message to fall back on if error response does not contain details
 * @param error an error response containing the details as to why the mutation failed
 *
 */
export const useMutationErrorHandler = () => {
  const toast = useToast()
  const { t } = useTranslation(['errors'])

  return useCallback(
    (_message = '') =>
      (error: RequestError) => {
        const errorCode = error.response?.data.code
        const errorDetail = error.response?.data.detail

        let message = _message
        if (errorCode) {
          message = t(`errors:${errorCode}`, formatErrorDetails(errorDetail))
          if (errorDetail && genericErrorCodes.includes(errorCode)) {
            message = `${message.replace(/.$/, '')} (${errorDetail})`
          }
        }

        toast.error(message)
      },
    [toast.error],
  )
}
