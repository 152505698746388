import { getTxnStatus } from '@/shared/api'
import { MAX_TRUSTLINE_AMOUNT } from '@/shared/constants'
import { convertCurrencyCodeToHex } from '@/shared/helpers'
import { useLedgerNano } from '@/shared/hooks'
import { ToastContextApi } from '@ripple/design-system'
import { useTranslation } from 'react-i18next'
import {
  Client,
  convertStringToHex,
  encode,
  Payment,
  SubmittableTransaction,
  TrustSet,
  Wallet,
} from 'xrpl'

type TrustSetOptions = {
  walletAddress: string
  issuerAddress: string
  currency: string
  amount?: string
}

type PaymentOptions = {
  walletAddress: string
  issuerAddress: string
  currency: string
  amount: string
  destination: string
  memo: string
  destinationTag?: number
}

/**
 * Create a trust set transaction
 *
 * Flags = [tfSetNoRipple](https://xrpl.org/trustset.html#trustset-flags) + [tfFullyCanonicalSig bug fix](https://github.com/LedgerHQ/app-xrp/issues/18#issuecomment-956126118)
 */
export const getTrustSetTransaction = (
  {
    walletAddress,
    issuerAddress,
    currency,
    amount = MAX_TRUSTLINE_AMOUNT.toString(),
  }: TrustSetOptions,
  publicKey?: string,
): TrustSet => ({
  TransactionType: 'TrustSet',
  Account: walletAddress,
  Flags: 131072 + 2147483648,
  LimitAmount: {
    value: amount,
    issuer: issuerAddress,
    currency: convertCurrencyCodeToHex(currency),
  },
  ...(publicKey && { SigningPubKey: publicKey.toUpperCase() }),
})

export const getPaymentTransaction = (
  {
    walletAddress,
    issuerAddress,
    currency,
    amount,
    destination,
    destinationTag,
    memo,
  }: PaymentOptions,
  publicKey?: string,
): Payment => ({
  TransactionType: 'Payment',
  Account: walletAddress,
  Amount: {
    currency: convertCurrencyCodeToHex(currency),
    value: String(amount),
    issuer: issuerAddress,
  },
  ...(memo !== '' && {
    Memos: [
      {
        Memo: {
          MemoData: convertStringToHex(memo),
        },
      },
    ],
  }),
  ...(destinationTag && { DestinationTag: +destinationTag }),
  Destination: destination,
  Flags: 131072 + 2147483648,
  ...(publicKey && { SigningPubKey: publicKey.toUpperCase() }),
})

export const createLedgerNanoTransaction = async (
  xrplTransaction: SubmittableTransaction,
  bip32Path: string,
  signTransaction: ReturnType<typeof useLedgerNano>['signTransaction'],
) => {
  const signature = await signTransaction(encode(xrplTransaction), bip32Path)

  if (!signature) {
    throw new Error(`Transaction signature not found.`)
  }

  xrplTransaction.TxnSignature = signature

  return xrplTransaction
}

export const createTestAccountTransaction = async (
  xrplTransaction: SubmittableTransaction,
  testAccountSecret: string,
) => {
  const wallet = Wallet.fromSecret(testAccountSecret)
  const signed = wallet.sign(xrplTransaction)

  return signed.tx_blob
}

export const useSubmitAndHandleTrustlineResponse = () => {
  const { t } = useTranslation(['wallet'])

  return async (
    transaction: SubmittableTransaction | string,
    currency: string,
    toast: ToastContextApi,
    client: Client,
  ) => {
    toast.info(t('wallet:trustset-signed'))
    const response = await client.submitAndWait(transaction)

    if (getTxnStatus(response.result) === 'success') {
      toast.success(t('wallet:asset-success', { currency }))
    } else {
      toast.error(t('wallet:trustset-error'))
    }
  }
}

export const useSubmitAndHandlePaymentResponse = () => {
  const { t } = useTranslation(['wallet'])

  return async (
    transaction: SubmittableTransaction | string,
    toast: ToastContextApi,
    client: Client,
  ) => {
    toast.info(t('wallet:payment-signed'))
    const response = await client.submitAndWait(transaction)

    if (getTxnStatus(response.result) === 'success') {
      toast.success(t('wallet:payment-success'))
    } else {
      toast.error(t('wallet:payment-error'))
    }
  }
}
