export default {
  'loading-account-edit': 'Chargement de la modification du compte...',
  'loading-transaction': "Chargement de l'opération...",
  'Request-Type': 'Type de Demande',
  'Keypair-Id': 'Identifiant de la paire de clés',
  'Public-Key': 'Clé publique',
  'Submit-Transaction': 'Soumettre la transaction',
  'Date-Settled': 'Date de règlement',
  'Date-Expired': "Date d'expiration",
  'Date-Failed': "Date d'échec",
  'Date-Sent': "Date d'envoi",
  'Date-Signed': 'Date de signature',
  'Cancel-Transaction': 'Annuler la transaction',
  'transaction.confirm': 'Êtes-vous sûr de vouloir soumettre cette transaction ?',
  'transaction.success': 'Votre transaction a été soumise !',
  'transaction.error': "Une erreur s'est produite lors de la soumission de votre transaction",
  'Date-Canceled': "Date d'annulation",
  'Canceled-By': 'Annulée par',
  'Transaction-Type': 'Type de transaction',
  'Transaction-Direction': 'Direction de la transaction',
  Received: 'Reçue',
  Direction: 'Direction',
  Sent: 'Envoyée',
  'Transaction-Hash': 'Hachage de la transaction',
  'Ledger-Transaction-Hash': 'Hachage des transactions du grand livre',
  'Source-Account': 'Compte source',
  'Source-Account-Name': 'Nom du compte source',
  'Source-Account-Type': 'Type de compte source',
  'Source-Account-Address': 'Adresse du compte source',
  'Destination-Account': 'Compte de destination',
  'Destination-Account-Name': 'Nom du compte de destination',
  'Destination-Account-Type': 'Type de compte de destination',
  'Destination-Account-Address': 'Adresse du compte de destination',
  'Signing-Quorum': 'Quorum de signature',
  'Signing-Quorum-Instructions': 'Saisissez le poids de signataire minimum requis pour approuver une transaction.',
  'Date-Initiated': "Date d'initiation",
  'Initiated-By': 'Initiée par',
  'Workitem-ID': "ID de l'élément de travail",
  'Account-Edit': 'Modification du compte',
  'Account-Setup': 'Configuration du compte',
  'Account-Flags': 'Drapeaux du compte',
  Trustline: 'Ligne de confiance',
  'no-transactions': 'Aucune transaction',
  'no-transactions.description': 'Aucune transaction ne nécessite votre attention.',
  'Destroy-Amount': 'Montant de destruction',
  'Request-Overview': 'Aperçu de la demande',
  'Destroy-Request': 'Demande de destruction',
  'Freeze-Request': 'Demande de gel',
  'Unfreeze-Request': 'Demande de dégel',
  'Global-Freeze-Request': 'Demande de gel global',
  'Global-Unfreeze-Request': 'Demande de dégel global',
  'Mint-Request': 'Demande de création monétaire',
  'Signer-List-Change': 'Changement de Signataires/Quorum',
  'Trustline-Request': 'Demande de ligne de confiance',
  'trustline-request-success': 'Votre demande de ligne de confiance a été créée!',
  'trustline-request-error': 'Nous avons rencontré une erreur lors de la soumission de votre demande de ligne de confiance',
  'Freeze-Account-Name': 'Compte gelé : {{name}}',
  'Transaction-Amount': 'Montant de la transaction',
  'Mint-Amount': 'Montant de la création monétaire',
  'Create-Transaction': 'Créer une transaction',
  'Create-Mint-Request': 'Créer une demande de création monétaire',
  'Payment-Amount': 'Montant du Paiement',
  'Payment-Request': 'Demande de paiement',
  'New-Mint-Request': 'Nouvelle demande de création monétaire',
  'Mint-Request-Disabled.tooltip-description': "La frappe est désactivée car il y a une modification dans la liste des signataires « En révision » sur le compte de l'émetteur.",
  'New-Transaction': 'Nouvelle transaction',
  Signed: 'Signée',
  'Pending-Signature': 'Signature en attente',
  'Amount-To-Destroy': 'Montant à détruire',
  'Amount-To-Mint': 'Montant à créer',
  'Amount-To-Pay': 'Montant à payer',
  'Pay-To': 'Payer à',
  'Mint-To': 'Créer vers',
  'Transaction-Details': 'Details de la transaction',
  'Destroy-Info': "La devise détruite est toujours renvoyée à l'émetteur à partir duquel elle a été reçue.",
  'no-tasks': 'Aucune tâche',
  'all-payments': 'Tous les paiements',
  'assigned-to-me': 'Assigné à moi',
  'Destroy-Always-Returns-To-Issuer': "La monnaie détruite est toujours renvoyée à l'émetteur qui l'a reçue.",
  'Destroy-Source-Placeholder': 'Sélectionnez un compte à détruire à partir de',
  'Mint-Destination-Placeholder': 'Sélectionnez un compte à frapper',
  'Pay-Source-Placeholder': 'Sélectionnez un compte à partir duquel payer',
  'Pay-Destination-Placeholder': 'Sélectionnez un compte sur lequel payer',
  'Destroy-Request-Confirmation': 'Détruire la confirmation de demande',
  'Mint-Request-Confirmation': 'Confirmation de demande de menthe',
  'Review-Mint-Request': 'Examiner la demande de menthe',
  'Review-Pay-Request': 'Examiner la demande de paie',
  'Review-Destroy-Request': 'Examiner la demande de destruction',
  'Review-Trustline-Request': 'Examiner la demande de ligne de confiance',
  'Review-Freeze-Request': 'Examen de la demande de gel',
  'Review-Unfreeze-Request': 'Examiner la demande de dégel',
  'Review-Global-Freeze-Request': 'Examiner la demande de gel global',
  'Review-Global-Unfreeze-Request': 'Examiner la demande de dégel global',
  'Review-Signer-Change-Request': 'Examiner la demande de changement de signataire',
  'Review-Quorum-Change-Request': 'Examiner la demande de changement de quorum',
  'No-HID-Support': 'Votre navigateur ne prend pas en charge HID qui est nécessaire pour utiliser Ledger Nano pour signer des transactions. Veuillez utiliser Chrome, Brave, Edge ou Opera.',
  'Date-Unavailable': 'Date indisponible',
  'error.memo-too-long': 'La taille du mémo est trop longue',
  'error.invalid-address': 'Adresse invalide',
  'Account-Details-Retrieved': 'Détails du compte récupérés.',
  'nano-error.transport-undefined': 'Communication avec le Ledger Nano impossible (transport non défini).',
  'nano-error.unable-to-connect': 'Impossible de se connecter à votre Ledger Nano. Veuillez déverrouiller votre appareil et effacer toutes les transactions non signées avant de réessayer.',
  'nano-error.incorrect-bytes': 'Octets incorrects à signer.',
  'nano-error.txn-too-large': 'Transaction trop importante.',
  'nano-error.invalid-channel': 'Canal invalide, si le problème persiste, reconnectez votre Ledger Nano.',
  'nano-error.ledger-key-index-unreachable': "L'index de clé de grand livre {{index}} n'a pas pu être récupéré.",
  'nano-error.signing-account-not-found': 'Compte de signature introuvable.',
  'nano-error.signature-not-found': 'La signature Nano du grand livre est introuvable.',
  'nano-success.connected': 'Connecté à {{product}}',
  'nano.check-for-signing': 'Vérifiez votre appareil Ledger pour la signature...',
  'nano.open-and-retry': "Ouvrez l'application Ledger Nano XRP et réessayez.",
  'nano.unlock-and-retry': "Déverrouillez votre Ledger, ouvrez l'application XRP et réessayez.",
  'nano.txn-rejected': "Transaction rejetée par l'utilisateur.",
  'destroy-request-error': 'Nous avons rencontré une erreur lors de la création de votre demande de destruction.',
  'mint-request-error': 'Nous avons rencontré une erreur lors de la création de votre demande de menthe.',
  'payment-request-error': 'Nous avons rencontré une erreur lors de la création de votre demande de paiement.',
  'destroy-request-success': 'Votre demande de destruction a été créée!',
  'mint-request-success': "Votre demande d'atelier a été créée!",
  'payment-request-success': 'Votre demande de paiement a été créée!',
  'signature-failed': "Une erreur s'est produite lors de l'ajout de votre signature.",
  'signature-success': 'Votre signature a été ajoutée!',
  'signer-email-not-found': 'Email non trouvé',
  'Account-to-Freeze': 'Compte à geler',
  'Account-to-Unfreeze': 'Compte à débloquer',
  'Global-Freeze-Details': 'Détails du gel global',
  'global-freeze-details-text': "Un gel global affecte toutes les lignes de confiance vers l'émetteur. Une fois gelés, tous les comptes du réseau ne pourront envoyer que des transactions de destruction à l'émetteur, ce qui retirera la monnaie de la circulation.",
  'Global-Unfreeze-Details': 'Détails du dégel global',
  'global-unfreeze-details-text': "Un dégel global affecte toutes les lignes de confiance vers l'émetteur. Une fois dégelés, tous les comptes du réseau pourront effectuer des transactions entre eux.",
  'Freeze-Details': 'Geler les détails',
  'freeze-details-text': "La ligne de confiance entre le compte et l'émetteur sera gelée. Une fois gelé, le compte ne pourra envoyer des paiements que vers le compte de l'émetteur, retirant ainsi cette monnaie de la circulation.",
  'Unfreeze-Details': 'Dégeler les détails',
  'unfreeze-details-text': "La ligne de confiance entre le compte et l'émetteur sera gelée. Une fois dégelé, le compte pourra effectuer des transactions avec tous les comptes du réseau.",
  'All-Accounts': 'Tous les comptes',
  'Submit-Request': 'Envoyer la demande',
  'Signed-Successfully': 'Signé avec succès',
  'reason-for-disabled-submit': 'Une fois le quorum atteint, vous pouvez soumettre la transaction au grand livre.',
  'Account-Overview': 'Aperçu du compte',
  'Request-Details': 'Détails de la demande',
  'freeze-request.freeze.description': "La ligne de confiance entre le compte et l'émetteur sera gelée. Une fois gelé, le compte ne pourra envoyer des paiements que vers le compte de l'émetteur, ce qui signifie retirer la monnaie de la circulation. <Link>Afficher les documents</Link>",
  'freeze-request.unfreeze.description': "La ligne de confiance entre le compte et l'émetteur sera dégelée. Une fois dégelé, le compte pourra effectuer des transactions avec tous les comptes du réseau. <Link>Afficher les documents</Link>",
  'freeze-request.global-freeze.description': "Un gel global affecte toutes les lignes de confiance vers l'émetteur. Une fois gelés, tous les comptes du réseau ne pourront envoyer des paiements qu’à l’émetteur, ce qui signifie retirer la monnaie de la circulation. <Link>Afficher les documents</Link>",
  'freeze-request.global-unfreeze.description': "Un dégel global affecte toutes les lignes de confiance vers l'émetteur. Une fois dégelés, tous les comptes du réseau pourront effectuer des transactions entre eux. <Link>Afficher les documents</Link>",
  'no-security-actions': "Aucune action de sécurité pour l'instant.",
  'no-security-actions.description': 'Aucune action de sécurité ne nécessite votre attention.',
}
