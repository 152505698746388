export default {
  signers: '{{count}} firmantes',
  signers_other: '{{count}} firmantes',
  users: '{{count}} usuario',
  users_other: '{{count}} usuarios',
  accounts: '{{count}} cuenta',
  accounts_other: '{{count}} cuentas',
  conflicts_one: '{{count}} conflictos',
  conflicts_other: '{{count}} conflicto',
  keypairs: '{{count}} par de claves',
  keypairs_other: '{{count}} pares de claves',
  Accounts: '{{count}} Cuenta',
  Accounts_other: '{{count}} Cuentas',
  'conflicts-only_one': 'conflictos',
  'conflicts-only_other': 'conflicto',
}
