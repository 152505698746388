export type GeneralObject = Record<string, unknown>

type FilterOptions = {
  shouldBeUnique: boolean
}

/**
 * Helper to filter an array of object based on specific conditions.
 *
 * @param {Array} array An array of object.
 * @param {Function} predicate A function indicating the conditions to fulfill.
 * @param {boolean} shouldBeUniqueResult Indicate if you expect to have only one element matching your predicate.
 * @returns An array containing the objects matching the predicate function.
 */
export function filterArrOfObjects<T extends GeneralObject>(
  array: T[],
  predicate: (item: T) => boolean,
  filterOptions: {
    shouldBeUnique: true
  },
): T | undefined
export function filterArrOfObjects<T extends GeneralObject>(
  array: T[],
  predicate: (item: T) => boolean,
  filterOptions: {
    shouldBeUnique: false
  },
): T[]
export function filterArrOfObjects<T extends GeneralObject>(
  array: T[],
  predicate: (item: T) => boolean,
  filterOptions: {
    shouldBeUnique: boolean
  },
): T[] | T | undefined
export function filterArrOfObjects<T extends GeneralObject>(
  array: T[],
  predicate: (item: T) => boolean,
): T[]
export function filterArrOfObjects<T extends GeneralObject>(
  array: T[],
  predicate: (item: T) => boolean,
  filterOptions?: FilterOptions,
) {
  let index = -1
  const length = array == null ? 0 : array.length
  let result: T[] = []

  while (++index < length) {
    const object = array[index]
    if (predicate(object)) {
      result = result.concat(object)
    }
  }

  if (filterOptions?.shouldBeUnique) {
    return result.length === 1 ? result[0] : undefined
  }

  return result
}
