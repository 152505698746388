import { useRoles } from '../api'

/**
 * Hook to get a full role from an id.
 * @param {string} roleId Role id to get
 * @returns A {@link Role} object
 */
export function useUserRole(roleId: string) {
  const { data: roles = [] } = useRoles()
  return roles.find((role) => role.roleId === roleId)
}
