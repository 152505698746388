import {
  AccountType,
  Permission,
  PermissionWithAccountType,
  Role,
  RolePermission,
  objectKeys,
} from 'common'
import { RolePermissionFieldsValue } from '../models'

/**
 * Helper to generate the rolePermissions field for a new Role creation.
 *
 * @param {RolePermissionFieldsValue} rolePermissionFieldsValue The role fields that are selected in the table permission.
 * @returns An array of {@link NewRolePermission}.
 */
export const generateNewRolePermissions = (
  rolePermissionFieldsValue: RolePermissionFieldsValue,
) => {
  const permissionsSet = new Set<RolePermission>()

  // issuerEdit, standbyTransact etc.
  const fields = objectKeys(rolePermissionFieldsValue)

  fields.forEach((field) => {
    // get the obj or array from the map
    const something = FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP[field]

    // always an array
    const perms = Array.isArray(something) ? something : [something]

    perms.forEach((perm) => {
      if (!perm.permission) return
      // not adding permission if the field value is false (unchecked)
      if (!rolePermissionFieldsValue[field]) return

      permissionsSet.add(perm)
    })
  })

  return [...permissionsSet]
}

/**
 * Mapping the checkbox permission fields.
 * Helpful for isAccountMembershipRequired (first sheet):
 * https://docs.google.com/spreadsheets/d/1Ga0VZ_QmKi6fh0Jon8UxgUxqHTMmL2qw_oGdEtEK-do/edit?pli=1#gid=1474370440
 */
export const FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP = {
  issuerEdit: {
    cbdcAccountType: AccountType.Issuer,
    isAccountMembershipRequired: true,
    permission: Permission.EditAccount,
  },
  issuerRead: {
    cbdcAccountType: AccountType.Issuer,
    isAccountMembershipRequired: true,
    permission: Permission.ReadAccount,
  },
  issuerSign: {
    cbdcAccountType: AccountType.Issuer,
    isAccountMembershipRequired: true,
    permission: Permission.Sign,
  },
  issuerTransact: {
    cbdcAccountType: AccountType.Issuer,
    isAccountMembershipRequired: true,
    permission: Permission.Transact,
  },
  operationalEdit: {
    cbdcAccountType: AccountType.Operational,
    isAccountMembershipRequired: true,
    permission: Permission.EditAccount,
  },
  operationalRead: {
    cbdcAccountType: AccountType.Operational,
    isAccountMembershipRequired: true,
    permission: Permission.ReadAccount,
  },
  operationalSign: {
    cbdcAccountType: AccountType.Operational,
    isAccountMembershipRequired: true,
    permission: Permission.Sign,
  },
  operationalTransact: {
    cbdcAccountType: AccountType.Operational,
    isAccountMembershipRequired: true,
    permission: Permission.Transact,
  },
  standbyEdit: {
    cbdcAccountType: AccountType.Standby,
    isAccountMembershipRequired: true,
    permission: Permission.EditAccount,
  },
  standbyRead: {
    cbdcAccountType: AccountType.Standby,
    isAccountMembershipRequired: true,
    permission: Permission.ReadAccount,
  },
  standbySign: {
    cbdcAccountType: AccountType.Standby,
    isAccountMembershipRequired: true,
    permission: Permission.Sign,
  },
  standbyTransact: {
    cbdcAccountType: AccountType.Standby,
    isAccountMembershipRequired: true,
    permission: Permission.Transact,
  },
  freeze: {
    cbdcAccountType: AccountType.Issuer,
    isAccountMembershipRequired: false,
    permission: Permission.Freeze,
  },
}

type PermissionMapKey = keyof typeof FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP

/**
 * Helper to get a permission object from the permission map.
 * @param {string} key Permission map key
 * @returns {object} Permission object
 */
export const getPermissionByKey = (key: PermissionMapKey) =>
  FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP[key]

type FindRolePermissionProps = Pick<RolePermission, 'permission'> &
  Pick<Role, 'rolePermissions'> &
  Pick<PermissionWithAccountType, 'cbdcAccountType'>

/**
 * Find a role permission by account type and permission amongst a role permission array.
 *
 * @param {Object} params The parameters to pass to the function.
 * @param {AccountType} params.cbdcAccountType The account type to match.
 * @param {Permission} params.permission The permission to match.
 * @param {AccountType} params.rolePermissions All the user role permissions.
 * @returns A {@link RolePermission} object.
 */
export const getRolePermission = ({
  cbdcAccountType,
  permission,
  rolePermissions,
}: FindRolePermissionProps): RolePermission | undefined => {
  return rolePermissions.find((rolePermission) => {
    return (
      rolePermission.cbdcAccountType === cbdcAccountType &&
      rolePermission.permission === permission
    )
  })
}
