export default {
  'Transaction-Exporter': 'Exportador de Transacciones',
  'Lookup-Transactions': 'Buscar Transacciones',
  'Apply-Filters': 'Aplicar Filtros',
  'Edit-Filters': 'Cambiar Filtros',
  'date-range.all': 'Siempre',
  'date-range.day': 'Últimas 24 horas',
  'date-range.week': 'Últimos 7 días',
  'date-range.month': 'Últimos 30 días',
  'date-range.custom': 'Rango',
  'payment-direction': 'Dirección de pago es {{paymentDirection}}',
  'date.from': 'Fecha desde {{startDate}}',
  'date.between': 'Rango de fechas {{startDate}} to {{endDate}}',
  'date.to': 'Fecha hasta {{endDate}}',
  'date.is': 'Fecha es {{date}}',
  'Date-range': 'Rango de Fechas',
  'Start-date': 'Fecha Inicio',
  'End-date': 'Fecha Final',
  'Payment-direction': 'Dirección de Pago',
  Refresh: 'Refrescar',
  Export: 'Exportar',
  'Back-to-Search': 'Volver a la Búsqueda',
  'last-updated': 'Última actualizacion {{lastUpdate}} UTC',
}
