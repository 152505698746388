import { captureMessage, withScope } from '@sentry/react'
import { AxiosResponse } from 'axios'
import merge from 'deepmerge'
import { z } from 'zod'
import { getResponseData } from '../request-client'

/**
 * This is a stop gap until we can get a better solution for default values
 * in zod schemas, which may be coming in zod@3
 *
 * @summary Function returns default object from Zod schema
 * @version 23.05.15.2
 * @link https://gist.github.com/TonyGravagno/2b744ceb99e415c4b53e8b35b309c29c
 * @author Jacob Weisenburger, Josh Andromidas, Thomas Moiluiavon, Tony Gravagno
 * @param schema z.object schema definition
 * @param options Optional object, see Example for details
 * @returns Object of type schema with defaults for all fields
 * @example
 * const schema = z.object( { ... } )
 * const default1 = defaultZodInstance<typeof schema>(schema)
 * const default2 = defaultZodInstance<typeof schema>(
 *   schema,{ // toggle from these defaults if required
 *     defaultArrayEmpty: false,
 *     defaultDateEmpty: false,
 *     defaultDateUndefined: false,
 *     defaultDateNull: false,
 * } )
 */

function defaultZodInstance<T extends z.ZodTypeAny>(
  schema: z.AnyZodObject | z.ZodEffects<any>,
  options: {
    defaultArrayEmpty?: boolean
    defaultDateEmpty?: boolean
    defaultDateUndefined?: boolean
    defaultDateNull?: boolean
  } = {},
): z.infer<T> {
  const defaultArrayEmpty =
    'defaultArrayEmpty' in options ? options.defaultArrayEmpty : false
  const defaultDateEmpty =
    'defaultDateEmpty' in options ? options.defaultDateEmpty : false
  const defaultDateUndefined =
    'defaultDateUndefined' in options ? options.defaultDateUndefined : false
  const defaultDateNull =
    'defaultDateNull' in options ? options.defaultDateNull : false

  function run(): z.infer<T> {
    if (schema instanceof z.ZodEffects) {
      if (schema.innerType() instanceof z.ZodEffects) {
        return defaultZodInstance(schema.innerType(), options) // recursive ZodEffect
      }
      // return schema inner shape as a fresh zodObject
      return defaultZodInstance(
        z.ZodObject.create(schema.innerType().shape),
        options,
      )
    }

    if (schema instanceof z.ZodType) {
      const the_shape = schema.shape as z.ZodAny // eliminates 'undefined' issue
      const entries = Object.entries(the_shape ?? {})
      const temp = entries.map(([key, value]) => {
        const this_default =
          value instanceof z.ZodEffects
            ? defaultZodInstance(value, options)
            : getDefaultValue(value)
        return [key, this_default]
      })
      return Object.fromEntries(temp)
    } else {
      console.log(`Error: Unable to process this schema`)
      return null // unknown or undefined here results in complications
    }

    function getDefaultValue(dschema: z.ZodTypeAny): any {
      if (dschema instanceof z.ZodDefault) {
        if (!('_def' in dschema)) return undefined // error
        if (!('defaultValue' in dschema._def)) return undefined // error

        return dschema._def.defaultValue()
      }
      if (dschema instanceof z.ZodArray) {
        if (!('_def' in dschema)) return undefined // error
        if (!('type' in dschema._def)) return undefined // error
        // return empty array or array with one empty typed element

        return defaultArrayEmpty
          ? []
          : [getDefaultValue(dschema._def.type as z.ZodAny)]
      }
      if (dschema instanceof z.ZodString) return ''
      if (dschema instanceof z.ZodNumber || dschema instanceof z.ZodBigInt) {
        const value = dschema.minValue ?? 0
        return value
      }
      if (dschema instanceof z.ZodDate) {
        const value = defaultDateEmpty
          ? ''
          : defaultDateNull
          ? null
          : defaultDateUndefined
          ? undefined
          : (dschema as z.ZodDate).minDate
        return value
      }
      if (dschema instanceof z.ZodSymbol) return ''
      if (dschema instanceof z.ZodBoolean) return false
      if (dschema instanceof z.ZodNull) return null
      if (dschema instanceof z.ZodPipeline) {
        if (!('out' in dschema._def)) return undefined // error

        return getDefaultValue(dschema._def.out)
      }
      if (dschema instanceof z.ZodObject) {
        return defaultZodInstance(dschema, options)
      }
      if (
        !dschema?._def ||
        (dschema instanceof z.ZodAny && !('innerType' in dschema._def))
      )
        return undefined // error?

      return getDefaultValue(dschema._def.innerType)
    }
  }

  return run()
}

/**
 * Parse an API response with a zod schema. This includes data extraction from the response
 * via `getResponseData`. Returns the parsed data unless parsing fails,
 * in which case, extend the default zod schema with the response data and logs the parsing error
 * to the console and sentry.
 */
export const safeParseApiResponse =
  <T extends z.ZodTypeAny, R extends AxiosResponse<ReturnType<T['safeParse']>>>(
    schema: T,
  ) =>
  (response: R) => {
    // extract data from response
    const data = getResponseData(response)

    // validate/parse response data via provided schema
    const parsed = schema.safeParse(data)
    // return the parsed data if no issues
    if (parsed.success) return parsed.data
    // send parsing errors to sentry so we can watch/report on them
    if (parsed.error) {
      withScope((scope) => {
        scope.setExtras(parsed)
        scope.setTag('api', 'data error')

        const url = response.request?.responseURL ?? ''
        const msg = `API Response parsing failed for ${url}`
        captureMessage(`API Response parsing failed for ${url}`, 'warning')
        console.warn(msg, parsed)
      })
    }

    return merge(
      defaultZodInstance(schema as unknown as z.AnyZodObject, {
        defaultArrayEmpty: true,
      }),
      data,
    )
  }
