import { useEffect, useState } from 'react'
import { ZodType } from 'zod'

/**
 * Connect to and return uncached data from a websocket by providing a url and zod schema
 * to parse the data with.
 *
 * Optionally, provide an `onData` callback to receive the parsed data in the callback.
 *
 * @returns latest data on the websocket or undefined if no data has been received
 */
export const useWebsocket = <T>({
  url,
  schema,
  onData,
}: {
  url?: string
  schema: ZodType
  onData?: (data: T) => void
}): T | undefined => {
  const [data, setData] = useState<T>()

  useEffect(() => {
    if (!url) return

    const websocket = new WebSocket(url)

    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data)

      const parsed = schema.safeParse(data)
      if (parsed.success) {
        setData(parsed.data)
        onData?.(data)
      }
    }

    return () => {
      websocket.close()
    }
  }, [url])

  return data
}
