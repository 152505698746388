import { AccountIssuedTokens } from '@/shared/api'
import { convertCurrencyCodeToHex } from '@/shared/helpers'

// validates if user entered currency that exists on the address they entered
export const isAccountIssuedToken = (
  tokens: AccountIssuedTokens,
  currency: string,
) => {
  return tokens?.hasOwnProperty(convertCurrencyCodeToHex(currency))
}
