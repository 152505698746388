import { z } from 'zod'

/**
 * Response from the PUT "/account-setups/{accountId}/ledger-info" API which
 * updates the account details with the Ledger Nano account public key.
 */
export const accountLedgerInfoSchema = z.object({
  accountId: z.string(),
  tenantId: z.string(),
  publicKey: z.string(),
  address: z.string(),
})

export type AccountLedgerInfo = z.infer<typeof accountLedgerInfoSchema>
