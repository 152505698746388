import { useMemo } from 'react'
import { METACO_STORAGE } from '../constants'
import { getStorageOptionsForEnv } from '../helpers'
import { useHasMetacoIntegration } from './use-has-metaco-integration'

export function useKeyPairOptions(isMachineUser?: boolean) {
  const hasMetaco = useHasMetacoIntegration()

  return useMemo(() => {
    const opts = getStorageOptionsForEnv(isMachineUser)

    return hasMetaco ? [METACO_STORAGE, ...opts] : opts
  }, [hasMetaco, isMachineUser])
}
