export default {
  'Issuer-Account': 'Compte émetteur',
  'Standby-Account': 'Compte de secours',
  'Operational-Account': 'Compte opérationnel',
  Loading: 'Chargement en cours...',
  New: 'Nouveau',
  Sign: 'Signer',
  Signed: 'Signé',
  Signer: 'Signataire',
  Signers: 'Signataires',
  Nickname: 'Surnom',
  Yes: 'Oui',
  No: 'Non',
  and: 'et',
  User: 'Utilisateur',
  user_one: 'utilisateur',
  user_other: 'utilisateurs',
  Accounts: 'Comptes',
  'My-Tasks': 'Mes Tâches',
  'Save-Changes': 'Sauvegarder les modifications',
  'Edit-Account-Name': 'Modifier le nom du compte',
  'External-Accounts': 'Comptes externes',
  'External-Account': 'Compte externe',
  'All-Accounts': 'Tous les comptes',
  'Account-Name': 'Nom du compte',
  'Destination-Tag': 'Tag de destination',
  'Correlation-Id': 'ID de corrélation',
  Ripple: 'Ripple',
  'Ledger-Nano': 'Ledger Nano',
  'Ripple-Custodial-Key': 'Clé de garde Ripple',
  Metaco: 'Metaco',
  Xumm: 'Xumm',
  Keypair: 'Paire de clés',
  Active: 'Active',
  Failed: 'Échec',
  Edit: 'Modifier',
  Settings: 'Paramètres',
  Users: 'Utilisateurs',
  Mint: 'Créer',
  Pay: 'Payer',
  Roles: 'Rôles',
  Account: 'Compte',
  Role: 'Rôle',
  Name: 'Nom',
  Email: 'Email',
  Issuer: 'Émetteur',
  Standby: 'Standby',
  Operational: 'Opérationnel',
  Destroy: 'Détruire',
  Global: 'Global',
  'Account-Address': 'Adresse du compte',
  'Account-Actions': 'Actions du compte',
  'Account-Edits': 'Modifications du compte',
  'Account-Edit': 'Modification du compte',
  Security: 'Sécurité',
  'Account-Type': 'Type de compte',
  freeze: 'geler',
  mint: 'créer',
  Queued: "En file d'attente",
  'In-Review': 'En Revue',
  Pending: 'En attente de validation',
  Ready: 'Prêt',
  Submit: 'Soumettre',
  Submitted: 'Soumis',
  Settled: 'Régularisé',
  Retry: 'Réessayer',
  'Retry-Request': 'Demande de nouvelle tentative',
  Canceled: 'Annulé',
  Expired: 'Expiré',
  Unknown: 'Inconnu',
  unknown: 'inconnu',
  'First-Name': 'Prénom',
  'Last-Name': 'Nom de famille',
  'Date-Requested': 'Date de la demande',
  'Request-Type': 'Type de demande',
  'Requested-By': 'Demandé par',
  'Reason-for-Edit': 'Raison de la modification',
  'Created-On': 'Créé le',
  'Date-Last-Modified': 'Date/heure de la dernière modification',
  'Date-Created': 'Date/heure de création',
  Enabled: 'Activé',
  Disabled: 'Désactivé',
  Reason: 'Raison',
  Description: 'Description',
  Title: 'Titre',
  Permissions: 'Permissions',
  Delete: 'Supprimer',
  Cancel: 'Annuler',
  Save: 'Enregistrer',
  Close: 'Fermer',
  Date: 'Date',
  'Payment-Direction': 'Direction du paiement',
  Amount: 'Montant',
  From: 'De',
  To: 'À',
  Memo: 'Mémo',
  Received: 'Reçu',
  Payment: 'Paiement',
  Continue: 'Continuer',
  Remove: 'Supprimer',
  Sent: 'Envoyé',
  Currency: 'Devise',
  'Can-Sign': 'Peut signer',
  'Issuer-Address': "Adresse de l'émetteur",
  Add: 'Ajouter',
  All: 'Tous',
  Quorum: 'Quorum',
  NA: 'N/A',
  'QR-Code': 'Code QR',
  'Wallet-Address': 'Adresse du portefeuille',
  'View-Docs': 'Voir les documents',
  Address: 'Adresse',
  Creator: 'Créateur',
  Breadcrumb: "Fil d'Ariane",
  Overview: 'Aperçu',
  Original: 'Original',
  Update: 'Mise à jour',
  Create: 'Créer',
  Type: 'Type',
  Status: 'Statut',
  Success: 'Succès',
  'Get-Address': "Obtenir l'adresse",
  'Cancel-Request': 'Annuler la demande',
  'dont-remind': 'Ne plus me rappeler',
  'Sign-Transaction': 'Signer la transaction',
  'Unknown-Address': 'Adresse inconnue',
  'something-went-wrong': "Quelque chose s'est mal passé",
  'something-went-wrong.adding-signature': "Une erreur s'est produite lors de l'ajout de votre signature.",
  'Copy-ledger-address': "Copier l'adresse Ledger",
  'log-in-again.sign': 'Veuillez vous reconnecter pour signer les transactions.',
  'log-in-again.keypair': 'Veuillez vous reconnecter pour créer la nouvelle paire de clés.',
  'log-in-again.session': "L'utilisateur a été créé avec succès. Pour des raisons de sécurité, veuillez vous reconnecter pour continuer la création d'une paire de clés pour cet utilisateur.",
  Confirm: 'Confirmer',
  'Confirm-Access': "Confirmer l'accès",
  'Log-In': 'Se connecter',
  Configure: 'Configurer',
  'Configure-Account': 'Commencer la Configuration',
  Approve: 'Approuver',
  Approved: 'Approuvé',
  'work-item.id': 'ID de la tâche',
  'work-item.signing-not-implemented': "La signature pour ce type de stockage ({{platform}}) n'est pas encore disponible.",
  Hash: 'Hachage',
  Weight: 'Poids',
  Reporting: 'Reporting',
  'Redemption-Log': null,
  'Signing-Service': 'Service de signature',
  'Needs-to-Approve': 'Doit approuver',
  'Sent-For-Signing': 'Envoyé pour signature',
  'Signing-Failed': 'Échec de la signature',
  'dont-ask-again': 'Ne me demandez plus',
  'unconfigured-account.instructions': "Ce compte n'a pas été entièrement configuré. Veuillez terminer la configuration du compte pour commencer à envoyer et recevoir des transactions.",
  'View-Details': 'Voir les détails',
  Deactivate: 'Désactiver',
  Payments: 'Paiements',
  Actions: 'Actions',
  Details: 'Détails',
  Balance: 'Solde',
}
