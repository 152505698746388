import { z } from 'zod'
import { rolePermissionSchema } from './role-permission'

export const roleSchema = z.object({
  roleId: z.string(),
  tenantId: z.string(),
  roleName: z.string(),
  description: z.string(),
  rolePermissions: rolePermissionSchema.array(),
  createdAt: z.string(),
  updatedAt: z.string(),
})

export type Role = z.infer<typeof roleSchema>
