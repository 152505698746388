import { RequestError, Role, UserKeyPair } from 'common'
import { useMutation } from 'react-query'
import { requestClient } from '../request-client'
import { KeypairUpdatePayload, createOrUpdateKeypair } from './users-api'

export enum UsersMutationKeys {
  CreateUser = 'create-user',
  CreateOrUpdateUserKeyPair = 'create-or-update-user-keypair',
  UpdateAccounts = 'update-user-accounts',
  UpdateUserInfo = 'update-user-info',
}

type NewUserRequest = {
  firstName: string
  lastName: string
  roleIds: string[]
  userId: string
  username: string
}

export const useCreateUser = () =>
  useMutation<void, RequestError, NewUserRequest>(
    (newUser) => requestClient.put(`/users/${newUser.userId}`, newUser),
    { mutationKey: UsersMutationKeys.CreateUser },
  )

export const useUpdateUserInfo = (userId: string) =>
  useMutation<void, RequestError, { firstName: string; lastName: string }>(
    (payload) => requestClient.put(`/users/${userId}/info`, payload),
    { mutationKey: UsersMutationKeys.UpdateUserInfo },
  )

/**
 * API to create or update a user key pair.
 * @returns A {@link UserKeyPair}
 */
export const useCreateOrUpdateUserKeyPair = () =>
  useMutation<UserKeyPair, RequestError, KeypairUpdatePayload>(
    createOrUpdateKeypair,
    { mutationKey: UsersMutationKeys.CreateOrUpdateUserKeyPair },
  )

/**
 * Updates a user roles.
 * RoleS (with an s) because technically a user can be associated with multiple role.
 * But the UI only supports one role per user for now.
 * todo: update when https://ripplelabs.atlassian.net/browse/XBS-1978 is merged
 */
export const useUpdateUserRoles = (userId: string) =>
  useMutation<void, RequestError, Role['roleId'][]>((body) =>
    requestClient.put(`/users/${userId}/roles`, body),
  )

/**
 * Deactivate a user keypair.
 *
 * @param userId The user's userId owning the keypair.
 * @returns void
 */
export const useDeactivateUserKeypair = (userId: string) =>
  useMutation<void, RequestError, { keyPairId: string | number }>(
    ({ keyPairId }) =>
      requestClient.delete(`/users/${userId}/keypairs/${keyPairId}`),
  )

/**
 * Updates account memberships for a single user.
 * @param {string} userId The user to update
 * @returns void
 */
export const useUpdateUserAccounts = (userId: string) =>
  useMutation<any, RequestError, string[]>(
    // this needs to be the complete set of accounts that this change should reflect in the DB
    (accounts: string[]) =>
      requestClient.put(`/users/${userId}/accounts`, accounts),
    { mutationKey: UsersMutationKeys.UpdateAccounts },
  )

export const useDeactivateUser = () =>
  useMutation<void, RequestError, string>((userId) =>
    requestClient.delete(`/users/${userId}`),
  )
