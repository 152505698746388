import { useAuth } from 'common'

/**
 * Checks if the tenant has a Metaco integration
 * which determines if we can create/use Metaco keys.
 */
export function useHasMetacoIntegration() {
  const { me } = useAuth()
  return !!me.tenant.metacoInstanceId
}
