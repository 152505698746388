export default {
  Welcome: 'Bienvenido',
  'Log-in': 'Entrar',
  'Sign-Out': 'Desconectar',
  'Welcome-to-DCM': 'Bienvenido a la Plataforma de Moneda Digital de Ripple',
  offline: 'Parece que no estás conectado. Por favor, compruebe su conexión.',
  Unknown: 'Desconocida',
  Breadcrumb: 'Camino',
  'My-Tasks': 'Mis Tareas',
  'not-found.title': 'La página que busca no existe.',
  'not-found.content.0': 'La página a la que esta intentando acceder no existe. Por favor, contacte con su administrador para mas información.',
  'not-found.content.1': 'Haga click <Link>aquí</Link> para volver a la página principal',
  'no-users': 'No se encontraron usuarios',
  'No-HID-Support': 'Su navegador no es compatible con HID, que se requiere para usar Ledger Nano para firmar transacciones. Utilice Chrome, Brave, Edge o Opera.',
  'unauthorized.title': 'Accesso Restringido',
  'unauthorized.content': 'No tiene acceso a esta página. Por favor, contacte con su administrador para mas información.',
  'Empty-folder': 'Carpeta Vacía',
  English: 'Inglés',
  Spanish: 'Español',
  French: 'Francés',
  'Reset-Password': 'Restablecer la contraseña',
  'View-Menu': 'Ver menú',
}
