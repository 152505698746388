import { AccountSetupKeyStoreType } from 'common'

export const DEFAULT_BASE_RESERVE = 10
// used by the faucet
export const XRP_FUNDING_AMOUNT = 100

export const accountTypeLabel = {
  [AccountSetupKeyStoreType.Xumm]: 'wallet:Xumm-Wallet',
  [AccountSetupKeyStoreType.Custodial]: 'wallet:Demo-Custodial-Key',
  [AccountSetupKeyStoreType.LedgerNano]: 'common:Ledger-Nano',
} as const

export const DEFAULT_ACCOUNT_TYPE = AccountSetupKeyStoreType.Custodial
