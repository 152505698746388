import {
  CollectionControlsCustomOptions,
  Controls,
} from '@ripple/design-system'
import deepmerge from 'deepmerge'
import { UseQueryOptions } from 'react-query'
import { PageType } from '../../models'
import { AsyncGetter } from '../api.types'

/**
 * Use this constant to simulate unpaginated queries (e.g. all results on one page)
 * This should match the max page size currently set on the backend.
 */
export const MAX_API_PAGE_SIZE = 1000

/**
 * Sets the `enabled` flag based on an `AsyncGetter`'s `isReady` state.
 */
export const getAsyncGetterQueryOptions = <T, U>(
  opts: UseQueryOptions<T, U> = {},
  asyncGetter?: AsyncGetter,
): UseQueryOptions<T, U> => {
  // if asyncGetter is provided, ensure asyncGetter.isReady
  // is true before enabling the query
  let enabled = opts.enabled ?? true
  if (enabled && asyncGetter) {
    enabled = asyncGetter.isReady
  }

  return { ...opts, enabled }
}

export const getPaginatedQueryOpts = <T, U>(
  opts: UseQueryOptions<PageType<T>, U> = {},
  asyncGetter?: AsyncGetter,
): UseQueryOptions<PageType<T>, U> => ({
  ...getAsyncGetterQueryOptions(opts, asyncGetter),
  keepPreviousData: true,
})

const getSortQuery = (state?: Controls['sorting']) => {
  const sort = state?.[0]

  return sort ? `${sort.id},${sort.desc ? 'DESC' : 'ASC'}` : ''
}

const defaultCollectionControls = {
  sorting: { enabled: true },
  pagination: {
    defaultPage: 0,
    defaultPageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  },
  response: {
    resultsKey: 'content',
    totalResultsKey: 'totalElements',
  },
}

/**
 * Extendable default `useCollectionControls` options for data from "Pageable" endpoints,
 * which is a backend abstraction that provides pagination and sorting features.
 */
export const getPaginatedControlsOptions = (
  extended: CollectionControlsCustomOptions = {},
): CollectionControlsCustomOptions =>
  deepmerge(defaultCollectionControls, extended)

/**
 * Construct parameters to supplement the `react-query` query key with sorting and pagination params
 * as well corresponding parameters to send to the API as request params
 */
export const getRequestParamsFromControls = (controls?: Controls) => {
  const pageIndex =
    controls?.pagination.pageIndex ??
    defaultCollectionControls.pagination.defaultPage
  const pageSize =
    controls?.pagination.pageSize ??
    defaultCollectionControls.pagination.defaultPageSize

  const sort = getSortQuery(controls?.sorting)

  return {
    queryKeyControls: [`${pageIndex}:${pageSize}`, sort],
    requestControls: {
      sort,
      size: pageSize,
      page: pageIndex,
    },
  }
}
