import { Environment, getReleaseEnv, isLocal } from './env'

enum ExplorerUrl {
  Cbdc = 'https://dev.explorer.cbdc.xpring.dev',
  Colombia = 'https://custom.xrpl.org/cbdc-colombia.cbdc-sandbox.rippletest.net',
  Devnet = 'https://devnet.xrpl.org',
  Mainnet = 'https://livenet.xrpl.org',
  Testnet = 'https://testnet.xrpl.org',
}

enum ExplorerWebsocket {
  Cbdc = 'wss://s1.cbdc-sandbox.rippletest.net:51233',
  Colombia = 'wss://cbdc-colombia.cbdc-sandbox.rippletest.net:51233',
  Devnet = 'wss://s.devnet.rippletest.net:51233',
  Mainnet = 'wss://xrplcluster.com',
  Testnet = 'wss://s.altnet.rippletest.net:51233',
}

enum FaucetHostname {
  Cbdc = 'dev.faucet.cbdc.xpring.dev',
  Colombia = 'cbdc-demos.dev.dcm.ripplesandbox.com/api/v1/xrpl/faucet/banrep/dev',
  Devnet = 'faucet.devnet.rippletest.net',
  Testnet = 'faucet.altnet.rippletest.net',
}

const EXPLORER_ENDPOINTS: {
  https: Record<string, ExplorerUrl>
  wss: Record<string, ExplorerWebsocket>
  faucet: Record<string, FaucetHostname>
} = {
  https: {
    [Environment.Prod]: ExplorerUrl.Mainnet,
    [Environment.Stage]: ExplorerUrl.Testnet,
    [Environment.SandboxDev]: ExplorerUrl.Cbdc,
    [Environment.SandboxProd]: ExplorerUrl.Cbdc,
    [Environment.SandboxStage]: ExplorerUrl.Cbdc,
    [Environment.SandboxProdColombia]: ExplorerUrl.Colombia,
  },
  wss: {
    [Environment.Prod]: ExplorerWebsocket.Mainnet,
    [Environment.Stage]: ExplorerWebsocket.Testnet,
    [Environment.SandboxDev]: ExplorerWebsocket.Cbdc,
    [Environment.SandboxProd]: ExplorerWebsocket.Cbdc,
    [Environment.SandboxStage]: ExplorerWebsocket.Cbdc,
    [Environment.SandboxProdColombia]: ExplorerWebsocket.Colombia,
  },
  faucet: {
    [Environment.Stage]: FaucetHostname.Testnet,
    [Environment.SandboxDev]: FaucetHostname.Cbdc,
    [Environment.SandboxProd]: FaucetHostname.Cbdc,
    [Environment.SandboxStage]: FaucetHostname.Cbdc,
    [Environment.SandboxProdColombia]: FaucetHostname.Colombia,
  },
}

const EXPLORER_URL = isLocal()
  ? ExplorerUrl.Cbdc
  : EXPLORER_ENDPOINTS.https[getReleaseEnv()]

const EXPLORER_WEBSOCKET = isLocal()
  ? ExplorerWebsocket.Cbdc
  : EXPLORER_ENDPOINTS.wss[getReleaseEnv()]

const FAUCET_HOSTNAME = isLocal()
  ? FaucetHostname.Cbdc
  : EXPLORER_ENDPOINTS.faucet[getReleaseEnv()]

export const getExplorerWebsocket = () => EXPLORER_WEBSOCKET
export const getFaucetHost = () => FAUCET_HOSTNAME

export const getExplorerTransactionLink = (txId: string) =>
  `${EXPLORER_URL}/transactions/${txId}`
