import { z } from 'zod'
import { newAccountSignerSchema } from './new-account-signer'

/**
 * Part of the body for the PUT "/account-setups/{accountId}" API.
 * https://github.com/xpring-eng/cbdc-manager-backend/blob/main/cbdc-manager-work-items/src/main/java/io/ripplex/cbdc/workitems/model/api/NewAccountSignerList.java
 *
 * Body for the POST /account-edits API to create a new "account edit" (to update the account name, quorum or signer list)
 */
export const newAccountSignerListSchema = z.object({
  quorum: z.number(),
  signers: newAccountSignerSchema.array().default([]),
})

export type NewAccountSignerList = z.infer<typeof newAccountSignerListSchema>
