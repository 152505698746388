import { KeyPairStore } from 'common'

export type KeyPairStorageOption = {
  name: KeyPairStore
  displayName: string
  // Disable the option (or not) in the Key Location dropdown when you create a user or add a Keypair.
  isDisabled: boolean
  type: KeyPairStore
}

export const KEYPAIR_STORAGE_DISPLAY_NAME_MAP = {
  GCP_KMS: 'common:Ripple',
  METACO: 'common:Metaco',
  LEDGER_NANO: 'common:Ledger-Nano',
  XUMM: 'common:Xumm',
} as const

export const BACKEND_STORAGE: KeyPairStorageOption = {
  name: KeyPairStore.GcpKms,
  displayName: 'Ripple',
  isDisabled: false,
  type: KeyPairStore.GcpKms,
}

export const METACO_STORAGE: KeyPairStorageOption = {
  name: KeyPairStore.Metaco,
  displayName: 'Metaco',
  isDisabled: false,
  type: KeyPairStore.Metaco,
}

export const LEDGER_NANO_STORAGE: KeyPairStorageOption = {
  name: KeyPairStore.LedgerNano,
  displayName: 'Ledger Nano',
  isDisabled: false,
  type: KeyPairStore.LedgerNano,
}

export const XUMM_STORAGE: KeyPairStorageOption = {
  name: KeyPairStore.Xumm,
  displayName: 'Xumm',
  isDisabled: true,
  type: KeyPairStore.Xumm,
}
