import { invalidateQueryCache, useXRPLContext } from 'common'
import { useMutation, useQueryClient } from 'react-query'
import { XrplQueryKeys } from './xrpl.types'

enum XrplMutationKeys {
  Transaction = 'create_transaction',
}

export const useCreateTransaction = (address: string) => {
  const queryClient = useQueryClient()
  const { client } = useXRPLContext()

  return useMutation(
    () =>
      client.request({
        command: XrplQueryKeys.AccountTrustlines,
        account: address,
      }),
    {
      mutationKey: XrplMutationKeys.Transaction,
      onSuccess: () => {
        invalidateQueryCache(queryClient, [
          [XrplQueryKeys.AccountBalance, address],
          [XrplQueryKeys.AccountTrustlines, address],
          [XrplQueryKeys.AccountTransactions, address],
        ])
      },
    },
  )
}
