export default {
  'New-Role': 'Nouveau rôle',
  'Role-Name': 'Nom du rôle',
  'Role-Not-Found': 'Rôle introuvable',
  'Edit-Permissions': 'Modifier les permissions',
  'Update-Permissions': 'Mettre à jour les permissions',
  'Permissions-conflict': 'Conflit de permissions',
  System: 'Système',
  'Delete-Role': 'Supprimer le rôle',
  'Back-to-Editing': 'Retour à la modification',
  'Duplicate-Role': 'Dupliquer le rôle',
  'Account-Permissions': 'Permissions du compte',
  'View-Role': 'Voir le rôle',
  'System-Permissions': 'Permissions système',
  'signing-disabled': 'Signature désactivée',
  'signing-enabled': 'Signature activée sur',
  'loading-roles': 'Chargement des rôles...',
  'loading-role': 'Chargement du rôle...',
  'loading-role-details': 'Chargement des détails du rôle...',
  'no-roles-found': "Aucun rôle trouvé. Essayez d'ajouter un nouveau rôle.",
  'no-role-found': "Aucun rôle trouvé avec l'identifiant {{roleId}}",
  'create-role.success': 'Nouveau rôle {{role}} créé avec succès',
  'create-role.error': 'Impossible de créer le nouveau rôle',
  'create-role.permissions-conflict': 'Un rôle avec les mêmes permissions existe déjà. Modifiez le rôle existant ou changez les permissions sélectionnées pour créer un nouveau rôle.',
  'system-role.overview': "Les utilisateurs administrateurs système effectuent des fonctions liées à la maintenance des utilisateurs, des rôles et des comptes dans l'application.",
  'system-role.permissions.0': 'Peut créer et modifier des comptes (par ex. créer un nouveau compte standby)',
  'system-role.permissions.1': 'Peut créer et modifier des utilisateurs',
  'system-role.permissions.2': 'Peut créer et modifier des rôles',
  'system-role.permissions.3': 'Peut initier des demandes de gel et de dégel (par ex. geler un compte externe spécifique)',
  'system-role.permissions.4': 'Peut initier des gels globaux et individuels de compte',
  'system-role.permissions.5': 'Peut créer et modifier des comptes',
  'role-permission.add-account': 'Peut ajouter un nouveau compte',
  'role-permission.add-role': 'Peut ajouter un nouveau rôle',
  'role-permission.add-user': 'Peut ajouter un nouvel utilisateur',
  'role-permission.delete-account': 'Peut supprimer un compte existant',
  'role-permission.delete-role': 'Peut supprimer un rôle existant',
  'role-permission.delete-user': 'Peut supprimer un utilisateur existant',
  'role-permission.edit-account': 'Peut modifier les détails du compte (par ex. nom du compte, liste des signataires, etc.)',
  'role-permission.edit-role': 'Peut modifier un rôle',
  'role-permission.edit-user': "Peut modifier les détails d'un utilisateur",
  'role-permission.read-role': 'Peut consulter le rôle',
  'role-permission.read-account': 'Peut consulter (peut afficher les détails du compte et des transactions uniquement)',
  'role-permission.freeze': 'Peut geler tous les comptes ou un compte individuel',
  'role-permission.mint-pay-and-destroy-transact': 'Peut initier des paiements (par ex. transactions de création, paiement et destruction)',
  'role-permission.pay-and-destroy-transact': 'Peut initier des paiements (par exemple payer et détruire des transactions)',
  'role-permission.mint-transact': 'Peut lancer des transactions à la menthe',
  'role-permission.sign': 'Peut signer les transactions du compte (par ex. transactions de création, demandes de modification, etc.)',
  'account-permission.edit': 'Peut modifier les détails du compte (par ex. nom du compte, liste des signataires, etc.)',
  'account-permission.sign': 'Peut signer les transactions du compte (par ex. transactions de création, demandes de modification, etc.)',
  'account-permission.pay': 'Peut initier des paiements (par ex. transactions de création, paiement et destruction)',
  'Role-Details': 'Détails du rôle',
  'Edit-Role-Details': 'Modifier les détails du rôle',
  'edit-success': 'Détails du rôle mis à jour avec succès',
  'edit-error': "Une erreur s'est produite lors de la mise à jour du rôle",
  'edit-description': 'Modifier le titre et la description du rôle.',
  'role-name-required': "Le nom du rôle n'est pas défini, il est nécessaire de mettre à jour le rôle",
  'role-description-required': "La description du rôle n'est pas définie, elle est nécessaire pour mettre à jour le rôle",
  'updated-role.prompt': 'Voulez-vous vraiment mettre à jour les permissions pour "{{roleName}}" ?',
  'updated-role.warning': 'En mettant à jour les permissions pour ce rôle, les utilisateurs seront supprimés de <Strong>{{accountsLosingAllMembersCount}} {{accountsLosingAllMembersCount, plural, one {compte} other {comptes}}}</Strong> qui sont en conflit avec le nouvel ensemble de permissions.',
  'updated-role.success': 'Rôle mis à jour avec succès',
  'updated-role.error': 'Impossible de mettre à jour le rôle',
  'updated-role.conflict': "Ces modifications empêcheront $t(plurals:users, {'count': {{signersLosingAccessCount}}}) de pouvoir voir les comptes pour lesquels ils sont actuellement des signataires. Vous devez d'abord supprimer tous les signataires pour un type de compte avant de modifier les permissions du rôle.",
  'assign-new-role.title': 'Vous devez assigner un nouveau rôle',
  'assign-new-role.description': 'Assignez un nouveau rôle à tous les utilisateurs pour les supprimer de ce rôle avant de supprimer le rôle.',
  'delete-role.title': 'Supprimer le rôle ?',
  'delete-role.disabled': 'Vous ne pouvez pas supprimer ce rôle',
  'delete-role.confirmation': 'Êtes-vous sûr de vouloir supprimer ce rôle ? Cette action ne peut pas être annulée.',
  'delete-success': 'Rôle supprimé avec succès',
  'delete-error': "Une erreur s'est produite lors de la suppression du rôle",
  'delete-role.remove-signers': "Pour supprimer ce rôle, vous devez d'abord supprimer <Strong>{{signerCount}} {{signerCount, plural, one {signataire} other {signataires}}}</Strong> en tant que signataires de <Strong>{{accountCount}} {{accountCount, plural, one {compte} other {comptes}}}</Strong>.",
  'delete-role.conflicting-permissions': 'Le rôle sélectionné a des permissions différentes du rôle actuel. Les utilisateurs seront supprimés de <Strong>{{count}} {{count, plural, one {compte} other {comptes}}}</0> qui sont en conflit avec les permissions du nouveau rôle.',
  'no-users-assigned': "Aucun utilisateur n'a été affecté à ce rôle",
  'no-users-assigned.description': 'Pour attribuer ce rôle à un utilisateur, accédez à <Button>Utilisateurs</Button>',
  'role-permission-label.issuer-read': 'Lire les comptes des émetteurs',
  'role-permission-label.operational-read': 'Lire les comptes opérationnels',
  'role-permission-label.standby-read': 'Lire les comptes de réserve',
  'role-permission-label.issuer-edit': "Modifier les comptes de l'émetteur",
  'role-permission-label.operational-edit': 'Modifier les comptes opérationnels',
  'role-permission-label.standby-edit': 'Modifier les comptes de réserve',
  'role-permission-label.issuer-sign': "Inscrivez-vous pour les comptes de l'émetteur",
  'role-permission-label.operational-sign': 'Signer pour les comptes opérationnels',
  'role-permission-label.standby-sign': 'Inscrivez-vous pour les comptes de réserve',
  'role-permission-label.issuer-transact': "Initier des transactions pour les comptes de l'émetteur",
  'role-permission-label.operational-transact': 'Initier des transactions pour les comptes opérationnels',
  'role-permission-label.standby-transact': 'Initier des transactions pour les comptes de réserve',
}
