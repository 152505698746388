export default {
  INTERNAL_SERVER_ERROR: `Une erreur inattendue s'est produite.`,
  UNAUTHORIZED: `Vous n'avez pas accès à cette ressource. Veuillez vérifier vos informations d'identification.`,
  UNPROCESSABLE_ENTITY: 'Argument fourni non valide.',
  ID_MISMATCH: `L'ID fourni dans le paramètre path ne correspond pas à l'ID dans le corps de la demande.`,
  INVALID_PROPERTY_VALUE: 'Valeur non valide fournie dans le corps de la demande.',
  UNKNOWN_PROPERTY: 'Une propriété inconnue a été fournie dans le corps de la demande.',
  CANNOT_EXCHANGE_OAUTH_CODE: `Impossible d'échanger le code OAuth contre un jeton d'accès.`,
  CANNOT_CONNECT_AUTH0_API: `Un problème est survenu lors de la connexion à l'API Auth0.`,
  CANNOT_AUTHENTICATE_AUTH0_API: `Un problème est survenu lors de l'authentification avec l'API Auth0.`,
  PASSWORD_AUTH_FAILED: `Nom d'utilisateur ou mot de passe invalide.`,
  PASSWORD_AUTH_NOT_ALLOWED: `L'authentification par mot de passe n'est pas autorisée.`,
  SESSION_USER_NOT_IN_SYSTEM: `L'ID utilisateur dans le jeton de session n'est pas valide.`,
  CANNOT_PARSE_AUTH0_JWT: `Impossible d'analyser Auth0 JWT. Le JWT peut être mal formé ou incorrect.`,
  NO_VALID_REFRESH_TOKENS: `Jeton d'actualisation d'authentification invalide ou introuvable.`,
  CANNOT_ENCRYPT_SESSION: 'Impossible de chiffrer la session.',
  CANNOT_DECRYPT_SESSION: 'Impossible de déchiffrer la session.',
  CANNOT_CREATE_USER: 'Impossible de créer un utilisateur.',
  CANNOT_CREATE_JWT: `Impossible de créer JWT pour l'utilisateur.`,
  ILLEGAL_KEYPAIR_STATE: `État de la paire de clés illégale. La paire de clés doit être dans l'état {{keyPairId}} pour continuer.`,
  UNSUPPORTED_KEYPAIR_PLATFORM: `La plate-forme de paire de clés fournie n'est pas prise en charge.`,
  SIGNING_SERVICE_DISABLED: 'Le service de signature est désactivé dans cet environnement.',
  KEYPAIR_CONFLICT: 'La paire de clés fournie existe déjà.',
  KEYPAIR_NOT_FOUND: 'Paire de clés introuvable. Veuillez fournir une paire de clés valide.',
  KEYPAIR_ASSOCIATED_TO_SIGNER: 'La paire de clés est actuellement associée à un signataire et ne peut pas être désactivée.',
  ROLE_ROLEID_CONFLICT: 'Un rôle avec le même ID existe déjà.',
  ROLE_PERMISSIONS_CONFLICT: `Un rôle avec le même ensemble d'autorisations existe déjà.`,
  ROLE_NOT_FOUND: 'Rôle introuvable. Veuillez fournir un rôle valide.',
  ROLE_ASSOCIATED_TO_USERS: 'Il existe encore des utilisateurs associés à ce rôle. Veuillez dissocier les utilisateurs avant de tenter de le supprimer.',
  ALTERNATE_ROLE_NEEDED: 'Vous devez fournir un rôle alternatif afin de supprimer le rôle actuel.',
  ADMIN_ROLE_CANNOT_BE_DELETED: `Le rôle d'administrateur système ne peut pas être supprimé.`,
  SOME_ROLES_NOT_FOUND: `Les rôles requis n'ont pas été trouvés.`,
  TENANT_NOT_FOUND: 'Locataire non trouvé. Veuillez fournir un locataire valide.',
  TENANT_TENANTID_CONFLICT: 'Un locataire avec le même ID existe déjà',
  TENANT_NAME_CONFLICT: 'Un locataire portant le même nom existe déjà.',
  ILLEGAL_INDIVIDUAL_FREEZE_WORKITEM: `Demande de gel individuelle illégale. Veuillez vérifier l'état du compte.`,
  ILLEGAL_INDIVIDUAL_UNFREEZE_WORKITEM: `Demande de dégel individuelle illégale. Veuillez vérifier l'état du compte.`,
  ILLEGAL_GLOBAL_FREEZE_WORKITEM: `Demande de gel global illégale. Veuillez vérifier l'état du compte de l'émetteur`,
  ILLEGAL_GLOBAL_UNFREEZE_WORKITEM: `Demande de dégel globale illégale. Veuillez vérifier l'état du compte de l'émetteur.`,
  ILLEGAL_WORKITEM_STATE: `État WorkItem illégal. Le WorkItem doit être dans l'état {{expectedStatuses}} pour continuer.`,
  INSUFFICIENT_FUNDS: 'Le compte source ne dispose pas de fonds suffisants.',
  INVALID_SIGNER: `Signataire invalide. L'utilisateur ne fait pas partie de la liste des signataires du compte.`,
  NO_TRUSTLINE_TO_ISSUER: `Une ligne de confiance entre le compte et l'émetteur est introuvable.`,
  WORKITEM_WORKITEMID_CONFLICT: 'Un WorkItem avec le même ID existe déjà.',
  WORKITEM_NOT_FOUND: 'Le WorkItem que vous avez fourni est introuvable.',
  WORKITEM_SIGNATURE_CONFLICT: 'Le WorkItem a déjà été signé.',
  WORKITEM_TRANSACTION_NOT_FOUND: 'La transaction WorkItem est introuvable.',
  WORKITEM_TRANSACTION_SIGNATURE_NOT_FOUND: 'La signature de la transaction WorkItem est introuvable.',
  EXTERNAL_ACCOUNT_ACCOUNTID_CONFLICT: 'Un compte externe avec cette adresse existe déjà.',
  EXTERNAL_ACCOUNT_ADDRESS_DEST_TAG_CONFLICT: 'Un ExternalAccount avec cette balise de destination existe déjà.',
  EXTERNAL_ACCOUNT_NAME_CONFLICT: 'Un compte externe portant ce nom existe déjà.',
  EXTERNAL_ACCOUNT_CORRELATIONID_CONFLICT: 'Un ExternalAccount avec cet ID de corrélation existe déjà.',
  EXTERNAL_ACCOUNT_NOT_FOUND: `Ce compte externe n'existe pas dans le système.`,
  ACCOUNT_EDIT_EDITID_CONFLICT: 'Une modification de compte avec cet ID existe déjà dans le système.',
  ACCOUNT_EDIT_NOT_FOUND: `Cet identifiant AccountEdit n'a pas été trouvé dans le système.`,
  ACTIVE_ACCOUNT_EDIT_BLOCKS_NEW_WORKITEMS: 'Il y a une modification de compte en attente sur le compte qui bloque la création de nouvelles transactions.',
  NEW_NAME_MUST_DIFFER_FROM_OLD: `Le nom du compte modifié ne peut pas être le même que l'original.`,
  NEITHER_SIGNERS_NOR_QUORUM_CHANGES: `Le compte modifié ne peut pas avoir les mêmes paramètres de signataire que l'original.`,
  NEW_SIGNERS_SHOULD_BE_MEMBERS: `Les utilisateurs ne peuvent pas être ajoutés en tant que signataires à un compte à moins qu'ils ne soient d'abord ajoutés en tant que membres.`,
  SOME_USERS_ARE_MEMBERS_ALREADY: 'Certains des utilisateurs ajoutés étaient déjà membres du compte.',
  SOME_USERS_LACK_ACCOUNT_PERMISSIONS: `Impossible d'ajouter certains utilisateurs au compte car ils ne disposent pas des autorisations appropriées.`,
  CANNOT_REMOVE_SIGNERS: `Impossible de supprimer des utilisateurs du compte, car ils doivent d'abord être supprimés de la liste des signataires du compte.`,
  USERS_NEED_TO_BE_MEMBERS: 'Impossible de supprimer des utilisateurs du compte car ils ne sont pas membres.',
  ACCOUNT_NOT_FOUND: `Ce compte n'existe pas dans le système.`,
  ACCOUNT_LEDGER_INFO_ACCOUNTID_CONFLICT: 'Un compte avec cet identifiant existe déjà dans le système.',
  ACCOUNT_LEDGER_INFO_ADDRESS_CONFLICT: 'Un compte avec cette adresse existe déjà dans le système',
  ISSUER_ACCOUNT_NOT_FOUND: 'Un compte émetteur doit être créé et configuré avant que tout autre compte puisse être créé',
  SETUP_ACCOUNTID_CONFLICT: 'Une configuration de compte avec cet ID existe déjà dans le système',
  SETUP_NAME_CONFLICT: 'Une configuration de compte portant ce nom existe déjà dans le système',
  SETUP_NOT_FOUND: `Cette configuration de compte n'existe pas dans le système`,
  ACCOUNT_LEDGER_INFO_MISSING: 'Impossible de rassembler les informations du grand livre requises pour la configuration du compte',
  NON_ISSUER_USE_AUTH_TRUSTLINES: 'Seul le compte émetteur peut déterminer si des lignes de confiance autorisées sont requises',
  ISSUER_MISSING_TICK_SIZE: 'Une taille de tick doit être définie sur le compte émetteur',
  SETUP_INVALID_TICK_SIZE: 'La taille de tick définie doit être comprise entre 3 et 15.',
  SETUP_ISSUER_ACCOUNTID_MISSING: 'Un identifiant de compte émetteur est requis pour la configuration du compte',
  SETUP_ISSUER_ACCOUNTID_NOT_EMPTY: `L'ID du compte émetteur est généré en interne par l'API. Veuillez laisser cette valeur vide`,
  SETUP_MISSING_SIGNERS: 'Une liste de signataires est requise pour la configuration du compte',
  SETUP_TOO_MANY_SIGNERS: 'Le nombre de signataires sur un compte ne peut pas dépasser 8',
  SETUP_CURRENCY_XRP_NOT_ALLOWED: `Le code de devise XRP n'est pas valide, veuillez en choisir un autre`,
  SETUP_FLAG_ALREADY_SET: 'Ce drapeau a déjà été activé sur le grand livre',
  SETUP_SIGNER_LIST_ALREADY_EXISTS: 'La liste des signataires a déjà été définie sur le grand livre',
  SETUP_TRUSTLINE_ALREADY_EXISTS: 'Une ligne de confiance existe déjà pour ce compte sur le grand livre',
  SETUP_TICK_SIZE_ALREADY_EXISTS: 'La taille du tick a déjà été définie sur le grand livre',
  SETUP_STEP_INVALID: `Impossible de passer à l'étape de configuration suivante sans le nom de l'ensemble d'étapes`,
  SETUP_NAME_EMPTY: 'Le nom du compte ne peut pas être vide',
  ISSUER_ALREADY_EXISTS: 'Un compte émetteur existe déjà',
  ACCOUNT_EXISTS_ON_LEDGER: 'Cette adresse de compte existe déjà sur le grand livre',
  SETUP_STEP_NOT_FOUND: `L'étape de configuration demandée n'existe pas dans le système`,
  SETUP_STEP_SHOULD_HAVE_ONE_TRANSACTION: `L'étape de configuration ne peut pas être associée à plusieurs éléments de travail.`,
  SETUP_STEP_SHOULD_HAVE_ONE_SIGNATURE: 'Une seule signature doit être ajoutée à chaque étape de configuration',
  SETUP_STEP_HAS_NO_PENDING_WORKITEM: `Impossible de trouver l'étape de configuration en attente à soumettre`,
  CUSTODIAL_KEYS_DISABLED: 'Ce type de clé est désactivé',
  SETUP_INVALID_ACCOUNT_TYPE: 'Ce type de compte ne nécessite pas de configuration de compte.',
  SETUP_INVALID_SIGNERS: `Les signataires ajoutés à ce compte doivent disposer de l'autorisation de signature sur {{accountType}}`,
  USER_USERNAME_CONFLICT: 'Un utilisateur avec cet email existe déjà',
  USER_USERID_CONFLICT: 'Un utilisateur avec cet identifiant existe déjà',
  USER_SELF_DEACTIVATION: 'Les utilisateurs ne peuvent pas se désactiver',
  USER_ACCOUNT_SIGNER: `L'utilisateur est signataire de ce compte`,
  USER_ACCOUNT_SETUP_SIGNER: 'Cet utilisateur est signataire du compte',
  LAST_ADMIN_DEACTIVATION: `L'administrateur système ne peut pas être supprimé car il s'agit du seul utilisateur administrateur système du système.`,
  USER_USERNAME_NOT_FOUND: 'Impossible de trouver {{username}} dans le système',
  USER_USERID_NOT_FOUND: 'Impossible de trouver {{userId}} dans le système',
  USER_CANNOT_BE_ADMIN: 'Les utilisateurs non-administrateurs ne peuvent pas être transformés en administrateur dans le système',
  ADMIN_CANNOT_BE_NON_ADMIN: 'Les utilisateurs administrateurs du système ne peuvent pas être modifiés en non-administrateurs',
  USER_CANNOT_HAVE_MULTIPLE_ROLES: `Chaque utilisateur du système ne peut avoir qu'un seul rôle`,
  MACHINE_USERS_FEATURE_UNAVAILABLE: `L'utilisateur n'a pas accès à la fonctionnalité utilisateur de la machine`,
}
