import { contextFactory } from '@ripple/design-system'
import { ConnectedAccounts, WalletContextApi } from './wallet-types'

const [WalletApiContext, useWalletApi] = contextFactory<WalletContextApi>({
  hook: 'useWalletApi',
  provider: 'WalletProvider',
})

const [WalletStateContext, useWalletState] = contextFactory<ConnectedAccounts>({
  hook: 'useWalletState',
  provider: 'WalletProvider',
})

export { WalletApiContext, useWalletApi, WalletStateContext, useWalletState }
