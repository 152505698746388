import { z } from 'zod'
import { accountLedgerInfoSchema } from '../account-ledger-info'
import { AccountType } from '../accounts'
import { cbdcSignerSchema } from '../cbdc-signer'

const accountSetupAccountTypeSchema = z.union([
  z.enum([AccountType.Issuer]),
  z.enum([AccountType.Standby]),
  z.enum([AccountType.Operational]),
])

export type AccountSetupAccountType = z.infer<
  typeof accountSetupAccountTypeSchema
>

export enum AccountSetupKeyStoreType {
  Custodial = 'CUSTODIAL',
  LedgerNano = 'LEDGER_NANO',
  // Xumm is only a valid keystore in the wallet
  Xumm = 'XUMM',
}

/**
 * Response from the GET "/account-setups/{accountId}" API.
 * https://github.com/xpring-eng/cbdc-backend/blob/new-account-setup/src/main/java/io/ripplex/cbdc/model/accountsetup/AccountSetup.java
 */
export const accountSetupSchema = z.object({
  accountId: z.string(),
  accountType: accountSetupAccountTypeSchema,
  creatingAdminUserId: z.string(),
  currencyCode: z.string(),
  issuerAccountId: z.string().optional(),
  keyStoreType: z.nativeEnum(AccountSetupKeyStoreType),
  ledgerInfo: accountLedgerInfoSchema.optional(),
  name: z.string(),
  signerList: z.object({
    quorum: z.number(),
    signers: cbdcSignerSchema.array().default([]),
  }),
  tickSize: z.number().optional(),
  useAuthTrustLines: z.boolean(),
})

export type AccountSetup = z.infer<typeof accountSetupSchema>
