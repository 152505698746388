import {
  AccountEditsMetadata,
  useAccountEditsMetadata,
} from '@/shared/hooks/account-edits-hooks/use-account-edits-metadata-getter'
import { Controls, prepareQueries } from '@ripple/design-system'
import {
  AccountEdit,
  AccountEditMetadata,
  AccountEditType,
  PageType,
  RequestError,
  accountEditMetadataSchema,
  accountEditSchema,
  getPageSchema,
  getPaginatedQueryOpts,
  getRequestParamsFromControls,
  safeParseApiResponse,
} from 'common'
import { UseQueryOptions, useQuery } from 'react-query'
import { useAccountsMetadata } from '../accounts'
import { requestClient } from '../request-client'
import { ACCOUNT_EDITS_URL } from './account-edits.constants'

export enum AccountEditQueryKeys {
  FullEdit = 'account-edit',
  MetaList = 'account-edits-meta',
}

type AccountEditsOptions = {
  cbdcAccountId?: string
  /** If true will only retrieve the edits for the signers and quorum */
  hasWorkItem?: boolean
  withAccountName?: boolean
  type?: AccountEditType
}

export const useAccountChangeLog = (
  accountEditOptions: AccountEditsOptions = {},
  controls?: Controls,
  opts?: UseQueryOptions<PageType<AccountEditMetadata[]>, RequestError>,
) => {
  const getChangeLogItem = useAccountEditsMetadata()

  const accountsMetadataResponse = useAccountsMetadata({
    enabled: !!accountEditOptions?.withAccountName,
  })

  const accountEditsResponse = useAccountEdits(
    accountEditOptions,
    controls,
    opts,
  )

  return prepareQueries<PageType<AccountEditsMetadata[]>>(
    {
      totalElements: accountEditsResponse.data?.totalElements,
      content: (accountEditsResponse.data?.content ?? [])
        ?.filter((acctEdit) =>
          accountEditOptions.cbdcAccountId
            ? acctEdit.cbdcAccountId === accountEditOptions.cbdcAccountId
            : true,
        )
        .map((acctEdit) =>
          getChangeLogItem(acctEdit, accountsMetadataResponse.data),
        ),
    },
    accountEditsResponse,
    accountsMetadataResponse,
  )
}

/**
 * Retrieve list of all account edit metadata.
 * Metadata excludes work item and state change data.
 * @returns An array of {@link AccountEdit} objects.
 */
export const useAccountEdits = (
  accountEditOptions: AccountEditsOptions = {},
  controls?: Controls,
  opts?: UseQueryOptions<PageType<AccountEditMetadata[]>, RequestError>,
) => {
  const { cbdcAccountId, hasWorkItem, type } = accountEditOptions

  const { queryKeyControls, requestControls } =
    getRequestParamsFromControls(controls)

  const response = useQuery<PageType<AccountEditMetadata[]>, RequestError>(
    [AccountEditQueryKeys.MetaList, type ?? 'ALL', ...queryKeyControls],
    () =>
      requestClient
        .get(ACCOUNT_EDITS_URL, {
          params: {
            ...requestControls,
            hasWorkItem,
            cbdcAccountId,
          },
        })
        .then(
          safeParseApiResponse(
            getPageSchema(accountEditMetadataSchema.array()),
          ),
        ),
    getPaginatedQueryOpts(opts),
  )

  return response
}

type PossibleEditIds =
  | { editId: string; workItemId?: never }
  | { workItemId: string; editId?: never }

/**
 * Retrieve a single, complete account edit by id,
 * including work item and state change data.
 *
 * @params editId | workItemId - The account edit id or associated work item id.
 * @param {UseQueryOptions} opts Optional - React query options
 * @returns An {@link AccountEdit} object.
 */
export const useAccountEdit = (
  { editId, workItemId }: PossibleEditIds,
  opts?: UseQueryOptions<AccountEdit, RequestError>,
) =>
  useQuery<AccountEdit, RequestError>(
    [AccountEditQueryKeys.FullEdit, editId],
    () =>
      requestClient
        .get(
          `/account-edits${
            editId ? `/${editId}` : `?workItemId=${workItemId}`
          }`,
        )
        .then(safeParseApiResponse(accountEditSchema)),
    opts,
  )
