import { isString } from '@ripple/ui-helpers'

export enum Environment {
  Prod = 'rippleprod-prod',
  Stage = 'rippleprod-stg',
  SandboxDev = 'sandbox-dev',
  SandboxProd = 'sandbox-prod',
  SandboxProdColombia = 'sandbox-prod-colombia',
  SandboxStage = 'sandbox-stg',
}

/**
 * Generates an environment variable getter.
 * @param {string} defaultValue An optional default value to use if the env var is unset
 * @param {string} value The env value (vite will replace with the value at build time)
 * @returns {string} Env variable value or default
 */
const envStringGetter = (defaultValue: string, value?: string) => () => {
  return isString(value) ? value : defaultValue
}

/** Get the value for the UI_REACT_APP_CBDC_SERVER_URL env var */
export const getCbdcServerUrl = envStringGetter(
  'http://localhost:8080',
  import.meta.env.UI_REACT_APP_CBDC_SERVER_URL,
)
/** Get the value for the UI_REACT_APP_DC3PO_SERVER_URL env var */
export const getDC3POServerUrl = envStringGetter(
  'http://localhost:8083',
  import.meta.env.UI_REACT_APP_DC3PO_SERVER_URL,
)

// TODO: Dirty workaround
export const isCustomEnv = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const env = queryParameters.get('env')
  console.log('queryParameters', queryParameters, 'env', env)
  return env
}

/** Get the value for the UI_REACT_APP_RELEASE_ENV env var */
export const getReleaseEnv = envStringGetter(
  Environment.SandboxDev,
  isCustomEnv() || import.meta.env.UI_REACT_APP_RELEASE_ENV,
)
/** Get the value for the UI_REACT_APP_DOMAIN env var */
export const getReactAppDomain = envStringGetter(
  'localhost',
  isCustomEnv() || import.meta.env.UI_REACT_APP_DOMAIN,
)

/** Indicates if the app is running locally */
export const isLocal = () => getReactAppDomain().includes('local')
/** Indicates if the app is running in a sandbox environment */
export const isSandbox = () => getReleaseEnv().includes('sandbox')
