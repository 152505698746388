export default {
  'invalid-currency-code-error': `Le code de devise n'existe pas sur cette adresse d'émetteur`,
  Send: 'Envoyer',
  Assets: 'Actifs',
  'Add-Asset': 'Ajouter un actif',
  Connect: 'Connecter',
  'Connect-Account': 'Connecter un compte',
  Destination: 'Destination',
  'View-All': 'Voir tout',
  'Recent-Payments': 'Paiements récents',
  'connect-an-account': 'Connecter un compte',
  'Connect-Xumm-Wallet': 'Connecter le portefeuille Xumm',
  'Send-Payment': 'Envoyer un paiement',
  'Sign-Payment': 'Signer un paiement',
  'Receive-Payment': 'Recevoir un paiement',
  'fund-this-account': 'Financer ce compte',
  'reserve-balance': 'Réserve : {{xrpBaseReserve}} XRP',
  More: 'Plus',
  'Demo-Custodial-Key': 'Clé de garde démo',
  'Xumm-Wallet': 'Portefeuille Xumm',
  'account-name-exists': 'Ce nom de compte existe déjà',
  'onboarding.select-address': 'Sélectionner une adresse à utiliser',
  'onboarding.ledger-nano.title': 'Connecter votre Ledger Nano',
  'onboarding.ledger-nano.instructions': 'Branchez votre Ledger Nano via USB et cliquez sur le bouton "Connecter"',
  'onboarding.xumm-connect.success': 'Succès ! Cliquez sur "Suivant" pour continuer la configuration de votre compte.',
  'onboarding.xumm-network.title': 'Connecter au réseau Sandbox',
  'onboarding.xumm-network.instructions.0': "Dans votre application Xumm, appuyez sur le bouton bleu au centre de la barre d'outils. Ensuite, appuyez sur 'Scanner le code QR' pour vous connecter au réseau Sandbox.",
  'onboarding.xumm-network.instructions.1': 'Lorsque vous avez terminé la connexion, appuyez sur "Terminer" pour accéder à votre compte.',
  'assets.add.description': 'Ajoutez votre premier actif pour commencer',
  'not-funded.title': "Le compte n'a pas été financé",
  'not-funded.instructions.0': 'Pour financer votre compte',
  'not-funded.instructions.1': "Obtenez vos XRP sur une plateforme d'échange",
  'not-funded.instructions.2': 'Envoyez au moins {{xrpBaseReserve}} XRP sur ce compte : {{address}}',
  'not-funded.instructions.3': 'Aucun tag de destination requis',
  'funding-success': 'Financement du compte effectué avec succès',
  'funding-error': "Nous n'avons pas pu financer le compte",
  'account-created': '{{accountName}} a été créé',
  'account-error': "Une erreur s'est produite lors de la création du compte de test",
  'payment-sign': 'Signer la transaction de paiement',
  'payment-signed': 'Transaction de paiement signée',
  'payment-success': 'Paiement envoyé avec succès',
  'payment-error': "Une erreur s'est produite lors de l'envoi du paiement",
  'payment-xumm-instructions': "Scanner le code QR avec l'application Xumm",
  'asset-success': 'Actif {{currency}} ajouté avec succès',
  'asset-error': "Une erreur s'est produite lors de l'ajout de l'actif",
  'trustset-sign': 'Signer la transaction de ligne de confiance',
  'trustset-signed': 'Transaction de ligne de confiance signée',
  'trustset-error': "Une erreur s'est produite lors de la création de la ligne de confiance",
  'receive-payment.title': 'Recevoir un paiement',
  'receive-payment.instructions': "Pour envoyer des actifs à <Strong>{{name}}</Strong>, scannez le code QR ou copiez l'adresse du portefeuille dans un paiement créé sur le réseau Sandbox.",
  'onboarding.xumm-connect.instructions.0': null,
  'onboarding.xumm-connect.instructions.1': null,
  'onboarding.xumm-network-completed.title': null,
  'onboarding.xumm-network-completed.instructions.0': null,
  'onboarding.xumm-network-completed.instructions.1': null,
}
