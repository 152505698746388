import {
  AccountType,
  EMPTY_PLACEHOLDER,
  TransactionDirection,
  WorkItem,
  WorkItemDetailsType,
  WorkItemTransactionSignatureStatus,
  WorkItemType,
} from 'common'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  formatDatetime,
  getCurrencyFromTxn,
  getTxnAmount,
  isFreezeWorkItem,
  isGlobalFreezeWorkItem,
  sumCompletedSignatures,
} from '../helpers'
import { useAccountMetadataLookup, useUserLookup } from './lookups'

const txnTypeMap = {
  [WorkItemType.AccountSetupDisableMaster]: 'transactions:Account-Setup',
  [WorkItemType.AccountSetupFlagSet]: 'transactions:Account-Flags',
  [WorkItemType.AccountSetupTrustSet]: 'transactions:Trustline',
  [WorkItemType.AuthorizeTrustLine]: 'transactions:Trustline-Request',
  [WorkItemType.Destroy]: 'transactions:Destroy-Request',
  [WorkItemType.GlobalFreeze]: 'transactions:Global-Freeze-Request',
  [WorkItemType.GlobalUnfreeze]: 'transactions:Global-Unfreeze-Request',
  [WorkItemType.IndividualFreeze]: 'transactions:Freeze-Request',
  [WorkItemType.IndividualUnfreeze]: 'transactions:Unfreeze-Request',
  [WorkItemType.IndividualFreezeExternal]: 'transactions:Freeze-Request',
  [WorkItemType.IndividualUnfreezeExternal]: 'transactions:Unfreeze-Request',
  [WorkItemType.Mint]: 'transactions:Mint-Request',
  [WorkItemType.PayToExternal]: 'transactions:Payment-Request',
  [WorkItemType.PayToOperational]: 'transactions:Payment-Request',
  [WorkItemType.PayToStandby]: 'transactions:Payment-Request',
  [WorkItemType.SignerListSet]: 'transactions:Signer-List-Change',
} as const

/**
 * Hook to get a stable function that returns display values for work items.
 * This hook is specifically for use in work item collections, to bring in user and account
 * details in a performant way and reduce common display formatting.
 */
export function useWorkItemDetailsGetter() {
  const { t } = useTranslation(['accounts', 'common', 'transactions'])
  const userLookup = useUserLookup()
  const accountLookup = useAccountMetadataLookup()

  return {
    isReady: !(userLookup.isLoading || accountLookup.isLoading),
    get: useCallback(
      (workItem: WorkItem, cbdcAccountId?: string): WorkItemDetailsType => {
        const {
          canceledBy,
          createdAt,
          createdBy,
          id,
          sourceAccount,
          status,
          transactions,
          type,
          updatedAt,
        } = workItem

        const {
          destinationAccount,
          quorum,
          signatures,
          transactionHash,
          transaction,
        } = transactions[0] || {}
        const summed = sumCompletedSignatures(signatures)
        const formattedAmount = getTxnAmount(transaction)
        const isFreeze = isFreezeWorkItem(type)
        const isGlobalFreeze = isGlobalFreezeWorkItem(type)

        const destAcct = accountLookup.lookupAccount(destinationAccount ?? '')

        const destination = {
          id: destinationAccount,
          address: accountLookup.displayAccountAddress(
            destinationAccount ?? '',
          ),
          name: isGlobalFreeze
            ? t('accounts:All-Accounts')
            : destAcct?.accountName ?? transaction.Destination,
          type: accountLookup.displayAccountType(destinationAccount ?? ''),
          // add in external account props for external types
          ...(destAcct?.accountType === AccountType.External && {
            correlationId: destAcct?.correlationId,
            destinationTag: destAcct?.destinationTag,
          }),
        }

        return {
          amount: formattedAmount,
          cancellation: canceledBy
            ? {
                date: formatDatetime(updatedAt),
                user: userLookup.displayUserName(canceledBy),
              }
            : undefined,
          creation: {
            date: formatDatetime(createdAt),
            user: userLookup.displayUserName(createdBy),
          },
          currency: getCurrencyFromTxn(transaction),
          direction: cbdcAccountId
            ? cbdcAccountId === destinationAccount
              ? TransactionDirection.Received
              : TransactionDirection.Sent
            : TransactionDirection.Sent,
          id,
          freeze: isFreeze
            ? {
                isGlobal: isGlobalFreeze,
                target: destination,
                displayTitle: t('transactions:Freeze-Account-Name', {
                  name: isGlobalFreeze ? t('common:Global') : destination.name,
                }),
              }
            : undefined,
          lastUpdated: formatDatetime(updatedAt),
          quorumDisplay: `${summed}/${quorum}`,
          quorum,
          signatures,
          signers: signatures.map((sig) => ({
            email: userLookup.displayUserEmail(sig.userId),
            isSigned: sig.status === WorkItemTransactionSignatureStatus.Signed,
            keyPairId: sig.userKeyPairId,
            name: userLookup.displayUserName(sig.userId),
            sentOn: formatDatetime(sig.createdAt),
            signedOn: formatDatetime(sig.updatedAt),
            userId: sig.userId,
            weight: sig.signatureWeight,
          })),
          signedWeight: summed,
          transaction: transactions[0],
          transactionHash: transactionHash ?? EMPTY_PLACEHOLDER,
          type,
          typeDisplay: t(txnTypeMap[type]),
          sourceAccount: {
            id: sourceAccount,
            address: accountLookup.displayAccountAddress(sourceAccount),
            name:
              accountLookup.lookupAccount(sourceAccount)?.accountName ??
              transaction.Account,
            type: accountLookup.displayAccountType(sourceAccount),
          },
          destinationAccount: destination,
          status,
        }
      },
      [accountLookup.isLoading, userLookup.isLoading, t],
    ),
  }
}
