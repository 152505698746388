import {
  ACCOUNTS,
  ACCOUNT_EDIT_TXN,
  ACCOUNT_QUEUED_TRANSACTIONS,
  EXTERNAL_ACCOUNTS,
  MY_TASKS,
  PAYMENTS,
  REDEMPTIONS,
  ROLES,
  SECURITY,
  TRANSACTION,
  USERS,
} from '@/shared/constants'
import { Permission, RoutePermissions } from 'common'
import { FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP } from '../role/helpers'

export const ROUTE_PERMISSIONS_MAP: Record<string, RoutePermissions> = {
  [USERS.root]: {
    permissions: [
      {
        permission: Permission.AddUser,
      },
      {
        permission: Permission.EditUser,
      },
      {
        permission: Permission.DeleteUser,
      },
    ],
    openRoute: false,
  },
  [USERS.create]: {
    permissions: { permission: Permission.AddUser },
    openRoute: false,
  },
  [USERS.overview]: {
    permissions: [
      { permission: Permission.EditUser },
      { permission: Permission.DeleteUser },
    ],
    openRoute: false,
  },
  [`${USERS.overview}/*`]: {
    permissions: [
      { permission: Permission.EditUser },
      { permission: Permission.DeleteUser },
    ],
    openRoute: false,
  },
  [`${ACCOUNTS.root}/*`]: {
    openRoute: true,
  },
  [EXTERNAL_ACCOUNTS.root]: {
    permissions: [FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerRead],
    openRoute: false,
  },
  [`${EXTERNAL_ACCOUNTS.account}/*`]: {
    permissions: [FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerRead],
    openRoute: false,
  },
  [ACCOUNTS.create]: {
    permissions: [{ permission: Permission.AddAccount }],
    openRoute: false,
  },
  [`${ACCOUNTS.account}/*`]: {
    openRoute: true,
  },
  [ACCOUNTS.edit]: {
    permissions: [
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerEdit,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.standbyEdit,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.operationalEdit,
    ],
    openRoute: false,
  },
  [SECURITY]: {
    permissions: [
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.freeze,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerSign,
    ],
    openRoute: false,
  },
  [ACCOUNT_EDIT_TXN]: {
    permissions: [
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerEdit,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerSign,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.operationalEdit,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.operationalSign,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.standbyEdit,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.standbySign,
    ],
    openRoute: false,
  },
  [MY_TASKS]: {
    permissions: [{ permission: Permission.Sign }],
    openRoute: false,
  },
  [PAYMENTS]: {
    allowAdmins: false,
    permissions: [
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.operationalRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.standbyRead,
    ],
    openRoute: false,
  },
  [TRANSACTION]: {
    permissions: [
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.operationalRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.standbyRead,
    ],
    openRoute: false,
  },
  [ACCOUNT_QUEUED_TRANSACTIONS]: {
    permissions: [
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.operationalRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.standbyRead,
    ],
    openRoute: false,
  },
  [REDEMPTIONS.payment]: {
    permissions: [
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.issuerRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.operationalRead,
      FIELD_TO_ACCT_TYPE_AND_PERMISSION_MAP.standbyRead,
    ],
    openRoute: false,
  },
  [ROLES.root]: {
    permissions: [
      { permission: Permission.AddRole },
      { permission: Permission.EditRole },
      { permission: Permission.DeleteRole },
    ],
    openRoute: false,
  },
  [ROLES.create]: {
    permissions: { permission: Permission.AddRole },
    openRoute: false,
  },
  [ROLES.edit]: {
    permissions: [{ permission: Permission.EditRole }],
    openRoute: false,
  },
  [ROLES.overview]: {
    permissions: [
      {
        permission: Permission.AddRole,
      },
      {
        permission: Permission.EditRole,
      },
      {
        permission: Permission.DeleteRole,
      },
    ],
    openRoute: false,
  },
  [`${ROLES.overview}/*`]: {
    permissions: [
      {
        permission: Permission.AddRole,
      },
      {
        permission: Permission.EditRole,
      },
      {
        permission: Permission.DeleteRole,
      },
    ],
    openRoute: false,
  },
}

type RoutePermissionsMapKey = keyof typeof ROUTE_PERMISSIONS_MAP

/**
 * Returns the route permissions object associated with a path.
 *
 * @param {string} path The route path.
 * @returns A {@link RoutePermissions} object.
 */
export const getRoutePermissionsObject = (path: RoutePermissionsMapKey) => {
  return ROUTE_PERMISSIONS_MAP[path]
}

/**
 * Returns only the permissions associated with a path.
 *
 * @param {string} path The route path.
 * @returns A {@link PermissionWithAccountType} array.
 */
export const getRoutePermissions = (path: RoutePermissionsMapKey) => {
  return ROUTE_PERMISSIONS_MAP[path].permissions ?? []
}
