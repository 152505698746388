import { useDeleteRole } from '@/shared/api'
import { ROLES } from '@/shared/constants'
import { useToast } from '@ripple/design-system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export function useDeleteRoleHandler(roleId: string) {
  const { t } = useTranslation(['roles'])
  const [isExecuting, setIsExecuting] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()
  const { mutateAsync } = useDeleteRole(roleId)

  return {
    isExecuting,
    onDelete: async (newRoleId = '') => {
      setIsExecuting(true)

      await mutateAsync(newRoleId, {
        onSuccess: () => {
          toast.success(t('roles:delete-success'))
          navigate(ROLES.root)
        },
        onError: (err) => {
          toast.error(t('roles:delete-error'))
          toast.error(err.message)
          setIsExecuting(false)
        },
      })
    },
  }
}
