import {
  AccountType,
  EMPTY_PLACEHOLDER,
  Permission,
  Role,
  Transaction,
} from 'common'
import { formatCurrencyValue } from '../money-format.helpers'
import { hasPermission } from '../users'
import { convertHexCurrencyCodeToString } from './currency-code.helpers'

/**
 * Exracts and formats an amount from a {@link Transaction} object.
 * Returns a placeholder if the transaction has no amount.
 * @param {Transaction} transaction A work item transaction object
 * @returns {string} Formatted amount or placeholder text
 */
export const getTxnAmount = (transaction: Transaction) => {
  const amount = transaction.Amount?.value
  return amount ? formatCurrencyValue(amount) : EMPTY_PLACEHOLDER
}

/**
 * Exracts and formats a currency code from a {@link Transaction} object.
 * @param {Transaction} transaction A work item transaction object
 * @returns {string} Human-readable currency code
 */
export const getCurrencyFromTxn = (transaction: Transaction) =>
  convertHexCurrencyCodeToString(transaction.Amount?.currency ?? '')

/**
 * A user has external account access if he can create transactions from an Operational account.
 */
export const hasExternalAccountAccess = (userRoles: Role[]) =>
  hasPermission({
    userRoles,
    permissions: [
      {
        permission: Permission.Transact,
        cbdcAccountType: AccountType.Operational,
      },
    ],
  })
