import { useAccountSetupSignStep, useAccountSetupStep } from '@/shared/api'
import { WS_QUEUES } from '@/shared/constants'
import { useLedgerNano } from '@/shared/hooks'
import {
  AccountSetupStepWebSocketMessage,
  ProcessingStatus,
  useMutationErrorHandler,
  useSubscription,
} from 'common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ACCOUNT_TYPE_TO_DERIVATION_ACCOUNT_TYPE_MAP,
  isWizardStepEqualToBackEndStep,
} from '../../account-setup-modal.helpers'
import { CommonStepProps } from '../../account-setup-modal.types'

export enum ToastStatus {
  None = 'NONE',
  Error = 'ERROR',
  Retrieving = 'RETRIEVING_ACCOUNT',
  Submitting = 'SUBMITTING_TO_XRPL',
  Success = 'SUCCESS',
  TransactionQueued = 'TRANSACTION_QUEUED',
  Waiting = 'WAITING_TO_SIGN',
}

/**
 * Hook for all of the logic of signing and saving a signature
 * for account configuration steps that needs signatures. It
 * simplifies the steps themselves, as well as the toast display.
 * @param step The step currently being display
 * @param stepProps The step component props object
 * @returns Props needed to render step contact and manually trigger signing (e.g. via a retry button)
 */
export function useAccountSetupSignature(
  step: number,
  {
    accountSetup,
    ledgerNanoAccount,
    pathName,
    processingStatus,
    saveLedgerAccount,
  }: CommonStepProps,
) {
  const { t } = useTranslation(['accounts'])
  const [errorMessage, setErrorMessage] = useState<string>()
  const [bytesToSign, setBytesToSign] = useState<string>()
  /** Indicates if we are at a stage where we are trying to find the correct account from the Nano */
  const [isRetrievingAccount, setIsRetrievingAccount] = useState<boolean>(false)
  /** Are we signing with the Ledger Nano */
  const [isSigning, setIsSigning] = useState(false)
  /** Are we calling the back-end to save the signature and submit it to the XRPL */
  const [isSavingSignature, setIsSavingSignature] = useState(false)

  // When a step reaches a successful state, the next step will be returned
  // from the `/next` endpoint. This check helps us determine things like
  // whether we've reached a successful point for the current step.
  const isMatchingStep = isWizardStepEqualToBackEndStep(step, pathName)

  const { getXrpAccountFromLedgerByAddress, removeToasts, signTransaction } =
    useLedgerNano()

  const { data: accountSetupStep, refetch } = useAccountSetupStep(
    accountSetup.accountId,
    pathName,
  )

  useSubscription<AccountSetupStepWebSocketMessage>(WS_QUEUES.ACCOUNT_SETUPS, {
    onData: ({ data }) => {
      if (data.accountId === accountSetup.accountId) refetch()
    },
  })

  // Clear out any active toasts when a step using this hook unmounts
  useEffect(() => {
    return () => {
      removeToasts()
    }
  }, [])

  // Get the transaction bytes to sign.
  useEffect(() => {
    setBytesToSign(
      // The bytes to sign will be the only pending transaction
      accountSetupStep?.pendingWorkItem?.transactions[0]?.transactionEncoded,
    )
  }, [accountSetupStep])

  // When the step appears, this is the effect that triggers all the steps
  useEffect(() => {
    if (
      bytesToSign &&
      isMatchingStep &&
      processingStatus === ProcessingStatus.Ready
    )
      fetchAccountSignAndSave()
  }, [bytesToSign, isMatchingStep, processingStatus])

  // Account XRP address which must exist fore these steps
  const address = accountSetup?.ledgerInfo?.address

  /**
   * ******************************************************************************************
   * Main function that executes all the steps:
   * - Retrieve the correct account from the Ledger Nano if needed to know its derivation path.
   * - Ask the user to sign the transaction with the Ledger Nano.
   * - Save the signature in the database. The back-end will submit it to the XRPL.
   * ******************************************************************************************
   */
  const fetchAccountSignAndSave = async () => {
    setErrorMessage('')

    if (!ledgerNanoAccount) {
      // Step 1
      ledgerNanoAccount = await fetchAccountFromNano()
    }

    if (ledgerNanoAccount) {
      // Step 2
      const signature = await sign(ledgerNanoAccount.bip32Path)
      if (signature) {
        // Step 3
        await saveSignatureInDb(signature)
      }
    }
  }

  /**
   * ******************************************************************************************
   * Step 1, Retrieve the correct account from the Ledger Nano if needed to know its derivation path.
   * ******************************************************************************************
   */
  const fetchAccountFromNano = async () => {
    if (!accountSetup) {
      setErrorMessage(t('accounts:account-setup.account-not-found'))
      return
    }

    if (!address) {
      setErrorMessage(t('accounts:account-setup.account-address-not-found'))
      return
    }

    setIsRetrievingAccount(true)

    // Find the DerivationAccountType based on the account setup account type
    const derivationAccountType =
      ACCOUNT_TYPE_TO_DERIVATION_ACCOUNT_TYPE_MAP[accountSetup.accountType]

    ledgerNanoAccount = await getXrpAccountFromLedgerByAddress(
      derivationAccountType,
      address,
    )
    if (!ledgerNanoAccount) {
      setIsRetrievingAccount(false)
      setErrorMessage(t('accounts:account-setup.account-not-found'))
      return
    }
    setIsRetrievingAccount(false)
    saveLedgerAccount(ledgerNanoAccount)

    return ledgerNanoAccount
  }

  /**
   * ******************************************************************************************
   * Step 2, sign the transaction with the Ledger Nano.
   * ******************************************************************************************
   */
  const sign = async (path: string) => {
    if (!bytesToSign) {
      setErrorMessage(
        t('accounts:account-setup.ledger-nano.transaction-bytes-not-found'),
      )
      return
    }
    setIsSigning(true)

    const signature = await signTransaction(bytesToSign, path)
    if (!signature) {
      setIsSigning(false)
      setErrorMessage(
        t('accounts:account-setup.ledger-nano.transaction-signature-not-found'),
      )
      return
    }
    setIsSigning(false)

    return signature
  }

  /**
   * ******************************************************************************************
   * Step 3,save the signature in the database. The back-end will submit it to the XRPL.
   * ******************************************************************************************
   */
  const signMutation = useAccountSetupSignStep()
  const mutationErrorHandler = useMutationErrorHandler()

  const saveSignatureInDb = async (signature: string) => {
    setIsSavingSignature(true)

    if (processingStatus === ProcessingStatus.Ready) {
      // workitem has moved to a 'pending for signatures' state
      await signMutation.mutateAsync(
        {
          cbdcAccountId: accountSetup.accountId,
          pathName,
          signature,
        },
        {
          onError: mutationErrorHandler(
            t('accounts:account-setup.ledger-nano.save-signature.failed'),
          ),
        },
      )
      return
    }

    setIsSavingSignature(false)
    setErrorMessage(
      t('accounts:account-setup.ledger-nano.save-signature.queued'),
    )
  }

  // calculate the toast status so it can decide which message to show
  let toastStatus = ToastStatus.TransactionQueued

  const isRetrieving = isMatchingStep && isRetrievingAccount
  const isWaiting = isMatchingStep && isSigning
  const isSubmitting =
    isMatchingStep &&
    (isSavingSignature || processingStatus === ProcessingStatus.Processing)
  const hasError = isMatchingStep && errorMessage
  const isSuccess = !isMatchingStep && !errorMessage

  if (isRetrieving) {
    toastStatus = ToastStatus.Retrieving
  } else if (isWaiting) {
    toastStatus = ToastStatus.Waiting
  } else if (isSubmitting) {
    toastStatus = ToastStatus.Submitting
  } else if (hasError) {
    toastStatus = ToastStatus.Error
  } else if (isSuccess) {
    toastStatus = ToastStatus.Success
  }

  return {
    accountSetup,
    accountSetupStep,
    address,
    fetchAccountSignAndSave,
    errorMessage,
    toastStatus,
  }
}
