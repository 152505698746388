import {
  CollectionConfig,
  FormValues,
} from '@ripple/design-system/lib/types/components/forms/hooks/form-mgmt/form-hooks.types'
import { useMemo } from 'react'
import { PermissionFields, RoleCreateFieldsConfig } from '../models'

/**
 * Hook to check if at least one permission is selected.
 */
export const usePermissionSelected = (
  roleFormValues: FormValues<RoleCreateFieldsConfig, CollectionConfig>,
) =>
  useMemo(
    () =>
      Object.keys(PermissionFields).some(
        (permissionField) => roleFormValues[permissionField],
      ),
    [roleFormValues],
  )
