import { z } from 'zod'
import { AccountType } from './accounts'
import { balanceMapSchema } from './balances'
import {
  CbdcAccountMetadata,
  issuerMetadataSchema,
  operationalMetadataSchema,
  standbyMetadataSchema,
  unknownMetadataSchema,
} from './cbdc-account-metadata'
import { cbdcSignerSchema } from './cbdc-signer'
import { trustLineSchema } from './trustline'

// Account type discriminated union
// We want all of them available separately,
// as well as the union type
const baseAccountSchema = z.object({
  signerQuorum: z.number(),
  balances: balanceMapSchema.default({}),
  signers: cbdcSignerSchema.array().default([]),
  trustLines: trustLineSchema.array().default([]),
})

const issuingAccountSchema = baseAccountSchema.merge(issuerMetadataSchema)
export type IssuingAccount = z.infer<typeof issuingAccountSchema>

const operationalAccountSchema = baseAccountSchema.merge(
  operationalMetadataSchema,
)
export type OperationalAccount = z.infer<typeof operationalAccountSchema>

const standbyAccountSchema = baseAccountSchema.merge(standbyMetadataSchema)
export type StandbyAccount = z.infer<typeof standbyAccountSchema>

const unknownAccountSchema = baseAccountSchema.merge(unknownMetadataSchema)
export type UnknownAccount = z.infer<typeof unknownAccountSchema>

/**
 * Cbdc Account schema
 */
export const cbdcAccountSchema = z.union([
  issuingAccountSchema,
  operationalAccountSchema,
  standbyAccountSchema,
  unknownAccountSchema,
])

export type CbdcAccount = z.infer<typeof cbdcAccountSchema>

export const cbdcAccountArraySchema = cbdcAccountSchema.array()

export type CbdcAccountHierarchy<T extends CbdcAccountMetadata> = {
  issuing: T[]
  standby: T[]
  operational: T[]
}

export type CbdcAccountType = {
  name: string
  type: AccountType
}
