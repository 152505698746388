import i18n from '@/i18n'
import { FieldValidatorFn } from '@ripple/design-system'
import { AccountSetup, CbdcAccountMetadata } from 'common'

/**
 * Validator for `accountName` field in account forms. Checks if an account name
 * is already in use in internal accounts or account setups.
 *
 * @param {CbdcAccountMetadata[]} accountsMeta The list of all accounts (metadata)
 * @param {AccountSetup[]} accountSetups The list of all account setups
 * @returns A {@link FieldValidatorFn}.
 */
export const validateAccountName =
  (
    accountsMeta: CbdcAccountMetadata[],
    accountSetups: AccountSetup[],
  ): FieldValidatorFn<'text'> =>
  ({ value }) => {
    const isInUse = accountsMeta.some(
      (accountMeta) => accountMeta.accountName === value,
    )
    const isInSetup = accountSetups.some(
      (accountSetup) => accountSetup.name === value,
    )

    return isInUse || isInSetup ? i18n.t('accounts:name-in-use') : ''
  }
