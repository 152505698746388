export default {
  INTERNAL_SERVER_ERROR: 'Un error inesperado ha ocurrido.',
  UNAUTHORIZED: 'No tienes acceso a este recurso. Por favor verifique sus credenciales.',
  UNPROCESSABLE_ENTITY: 'Se proporcionó un argumento no válido.',
  ID_MISMATCH: 'El ID proporcionado en el parámetro de ruta no coincide con el ID del cuerpo de la solicitud.',
  INVALID_PROPERTY_VALUE: 'Valor no válido proporcionado en el cuerpo de la solicitud.',
  UNKNOWN_PROPERTY: 'Se proporcionó una propiedad desconocida en el cuerpo de la solicitud.',
  CANNOT_EXCHANGE_OAUTH_CODE: 'No se puede intercambiar el código OAuth por un token de acceso.',
  CANNOT_CONNECT_AUTH0_API: 'Hubo un problema al conectarse a la API Auth0.',
  CANNOT_AUTHENTICATE_AUTH0_API: 'Hubo un problema al autenticar con la API Auth0.',
  PASSWORD_AUTH_FAILED: 'Usuario o contraseña invalido.',
  PASSWORD_AUTH_NOT_ALLOWED: 'No se permite la autenticación de contraseña.',
  SESSION_USER_NOT_IN_SYSTEM: 'El ID de usuario en el token de sesión no es válido.',
  CANNOT_PARSE_AUTH0_JWT: 'No se puede analizar Auth0 JWT. El JWT podría tener un formato incorrecto o ser incorrecto.',
  NO_VALID_REFRESH_TOKENS: 'El token de actualización de autenticación no es válido o no se encuentra.',
  CANNOT_ENCRYPT_SESSION: 'No se puede cifrar la sesión.',
  CANNOT_DECRYPT_SESSION: 'No se puede descifrar la sesión.',
  CANNOT_CREATE_USER: 'No se puede crear usuario.',
  CANNOT_CREATE_JWT: 'No se puede crear JWT para el usuario.',
  ILLEGAL_KEYPAIR_STATE: 'Estado de par de claves ilegal. El par de claves debe estar en el estado {{keyPairId}} para continuar.',
  UNSUPPORTED_KEYPAIR_PLATFORM: 'La plataforma de par de claves proporcionada no es compatible.',
  SIGNING_SERVICE_DISABLED: 'El servicio de firma está deshabilitado en este entorno.',
  KEYPAIR_CONFLICT: 'El par de claves proporcionado ya existe.',
  KEYPAIR_NOT_FOUND: 'Par de claves no encontrado. Proporcione un par de claves válido.',
  KEYPAIR_ASSOCIATED_TO_SIGNER: 'El par de claves está actualmente asociado a un firmante y no se puede desactivar.',
  ROLE_ROLEID_CONFLICT: 'Ya existe un rol con el mismo ID.',
  ROLE_PERMISSIONS_CONFLICT: 'Ya existe un rol con el mismo conjunto de permisos.',
  ROLE_NOT_FOUND: 'Rol no encontrado. Proporcione un rol válido.',
  ROLE_ASSOCIATED_TO_USERS: 'Todavía hay usuarios asociados a este rol. Por favor desasocia a los usuarios antes de intentar eliminarlo.',
  ALTERNATE_ROLE_NEEDED: 'Debe proporcionar un rol alternativo para eliminar el actual.',
  ADMIN_ROLE_CANNOT_BE_DELETED: 'La función de administrador del sistema no se puede eliminar.',
  SOME_ROLES_NOT_FOUND: 'No se encontraron los roles requeridos.',
  TENANT_NOT_FOUND: 'Inquilino no encontrado. Proporcione un inquilino válido.',
  TENANT_TENANTID_CONFLICT: 'Ya existe un inquilino con el mismo ID',
  TENANT_NAME_CONFLICT: 'Ya existe un inquilino con el mismo nombre.',
  ILLEGAL_INDIVIDUAL_FREEZE_WORKITEM: 'Solicitud de congelamiento individual ilegal. Por favor verifique el estado de la cuenta.',
  ILLEGAL_INDIVIDUAL_UNFREEZE_WORKITEM: 'Solicitud de descongelación individual ilegal. Por favor verifique el estado de la cuenta.',
  ILLEGAL_GLOBAL_FREEZE_WORKITEM: 'Solicitud de congelación global ilegal. Por favor verifique el estado de la cuenta del Emisor',
  ILLEGAL_GLOBAL_UNFREEZE_WORKITEM: 'Solicitud de descongelación global ilegal. Por favor verifique el estado de la cuenta del Emisor.',
  ILLEGAL_WORKITEM_STATE: 'Estado de elemento de trabajo ilegal. El WorkItem debe estar en el estado {{expectedStatuses}} para continuar.',
  INSUFFICIENT_FUNDS: 'La cuenta de origen no tiene fondos suficientes.',
  INVALID_SIGNER: `Firmante no válido. El usuario no forma parte de la lista de firmantes de la cuenta.`,
  NO_TRUSTLINE_TO_ISSUER: 'No se puede encontrar una línea de confianza entre la cuenta y el Emisor.',
  WORKITEM_WORKITEMID_CONFLICT: 'Ya existe un WorkItem con el mismo ID.',
  WORKITEM_NOT_FOUND: 'No se puede encontrar el WorkItem que proporcionó.',
  WORKITEM_SIGNATURE_CONFLICT: 'El WorkItem ya ha sido firmado.',
  WORKITEM_TRANSACTION_NOT_FOUND: 'No se puede encontrar la transacción WorkItem.',
  WORKITEM_TRANSACTION_SIGNATURE_NOT_FOUND: 'No se puede encontrar la firma de la transacción WorkItem.',
  EXTERNAL_ACCOUNT_ACCOUNTID_CONFLICT: 'Ya existe una cuenta externa con esa dirección.',
  EXTERNAL_ACCOUNT_ADDRESS_DEST_TAG_CONFLICT: 'Ya existe una cuenta externa con esa etiqueta de destino.',
  EXTERNAL_ACCOUNT_NAME_CONFLICT: 'Ya existe una cuenta externa con ese nombre.',
  EXTERNAL_ACCOUNT_CORRELATIONID_CONFLICT: 'Ya existe una cuenta externa con ese ID de correlación.',
  EXTERNAL_ACCOUNT_NOT_FOUND: 'Esa Cuenta Externa no existe en el sistema.',
  ACCOUNT_EDIT_EDITID_CONFLICT: 'Ya existe una edición de cuenta con ese ID en el sistema.',
  ACCOUNT_EDIT_NOT_FOUND: 'Ese ID de AccountEdit no se encontró en el sistema.',
  ACTIVE_ACCOUNT_EDIT_BLOCKS_NEW_WORKITEMS: 'Hay una edición de cuenta pendiente en la cuenta que bloquea la creación de nuevas transacciones..',
  NEW_NAME_MUST_DIFFER_FROM_OLD: 'El nombre de cuenta editado no puede ser el mismo que el original.',
  NEITHER_SIGNERS_NOR_QUORUM_CHANGES: 'La cuenta editada no puede tener la misma configuración de firmante que la original.',
  NEW_SIGNERS_SHOULD_BE_MEMBERS: 'Los usuarios no se pueden agregar como firmantes a una cuenta a menos que primero se agreguen como miembros.',
  SOME_USERS_ARE_MEMBERS_ALREADY: 'Algunos de los usuarios agregados ya eran miembros de la cuenta.',
  SOME_USERS_LACK_ACCOUNT_PERMISSIONS: 'No se pueden agregar algunos usuarios a la cuenta porque no tienen los permisos correctos.',
  CANNOT_REMOVE_SIGNERS: 'No se pueden eliminar usuarios de la cuenta porque primero deben eliminarse de la lista de firmantes de la cuenta.',
  USERS_NEED_TO_BE_MEMBERS: 'No se pueden eliminar usuarios de la cuenta porque no son miembros.',
  ACCOUNT_NOT_FOUND: 'Esa cuenta no existe en el sistema.',
  ACCOUNT_LEDGER_INFO_ACCOUNTID_CONFLICT: 'Ya existe una cuenta con ese ID en el sistema.',
  ACCOUNT_LEDGER_INFO_ADDRESS_CONFLICT: 'Ya existe una cuenta con esa dirección en el sistema',
  ISSUER_ACCOUNT_NOT_FOUND: 'Se debe crear y configurar una cuenta de emisor antes de poder crear otras cuentas.',
  SETUP_ACCOUNTID_CONFLICT: 'Ya existe una configuración de cuenta con ese ID en el sistema.',
  SETUP_NAME_CONFLICT: 'Ya existe una configuración de cuenta con ese nombre en el sistema.',
  SETUP_NOT_FOUND: 'Esa configuración de cuenta no existe en el sistema.',
  ACCOUNT_LEDGER_INFO_MISSING: 'No se puede recopilar la información del libro mayor necesaria para la configuración de la cuenta',
  NON_ISSUER_USE_AUTH_TRUSTLINES: 'Sólo la Cuenta del Emisor puede determinar si se requieren Líneas Fiduciarias Autorizadas',
  ISSUER_MISSING_TICK_SIZE: 'Se debe establecer un tamaño de tick en la cuenta del emisor',
  SETUP_INVALID_TICK_SIZE: 'El tamaño de tick establecido debe estar dentro del rango 3-15',
  SETUP_ISSUER_ACCOUNTID_MISSING: 'Se requiere una identificación de cuenta del emisor para configurar la cuenta.',
  SETUP_ISSUER_ACCOUNTID_NOT_EMPTY: 'La ID de la cuenta del emisor la genera internamente la API. Por favor deje este valor vacío',
  SETUP_MISSING_SIGNERS: 'Se requiere una lista de firmantes para configurar la cuenta.',
  SETUP_TOO_MANY_SIGNERS: 'El número de firmantes de una cuenta no puede exceder de 8',
  SETUP_CURRENCY_XRP_NOT_ALLOWED: 'El código de moneda XRP no es válido, elija uno diferente',
  SETUP_FLAG_ALREADY_SET: 'Esta bandera ya se ha configurado en el libro mayor.',
  SETUP_SIGNER_LIST_ALREADY_EXISTS: 'La lista de firmantes ya se ha configurado en el libro mayor.',
  SETUP_TRUSTLINE_ALREADY_EXISTS: 'Ya existe una línea de confianza para esta cuenta en el libro mayor',
  SETUP_TICK_SIZE_ALREADY_EXISTS: 'El tamaño del tick ya se ha establecido en el libro mayor.',
  SETUP_STEP_INVALID: 'No se puede pasar al siguiente paso de configuración sin el nombre del conjunto de pasos',
  SETUP_NAME_EMPTY: 'El nombre de la cuenta no puede estar vacío.',
  ISSUER_ALREADY_EXISTS: 'Ya existe una cuenta de emisor',
  ACCOUNT_EXISTS_ON_LEDGER: 'Esta dirección de cuenta ya existe en el libro mayor.',
  SETUP_STEP_NOT_FOUND: 'El paso de configuración solicitado no existe en el sistema.',
  SETUP_STEP_SHOULD_HAVE_ONE_TRANSACTION: 'El paso de configuración no puede tener más de un elemento de trabajo asociado',
  SETUP_STEP_SHOULD_HAVE_ONE_SIGNATURE: 'Solo se debe agregar una firma a cada paso de configuración',
  SETUP_STEP_HAS_NO_PENDING_WORKITEM: 'No se puede encontrar el paso de configuración pendiente para enviar',
  CUSTODIAL_KEYS_DISABLED: 'Este tipo de clave está deshabilitado',
  SETUP_INVALID_ACCOUNT_TYPE: 'Este tipo de cuenta no requiere una configuración de cuenta.',
  SETUP_INVALID_SIGNERS: 'Los firmantes agregados a esta cuenta deben tener permiso para firmar el {{accountType}}',
  USER_USERNAME_CONFLICT: 'Un usuario con ese email ya existe',
  USER_USERID_CONFLICT: 'Ya existe un usuario con ese ID',
  USER_SELF_DEACTIVATION: 'Los usuarios no pueden desactivarse',
  USER_ACCOUNT_SIGNER: 'El usuario es firmante en esta cuenta.',
  USER_ACCOUNT_SETUP_SIGNER: 'Este usuario es un firmante de la cuenta.',
  LAST_ADMIN_DEACTIVATION: 'El administrador del sistema no se puede eliminar porque es el único usuario administrador del sistema en el sistema.',
  USER_USERNAME_NOT_FOUND: 'No se puede encontrar {{username}} en el sistema',
  USER_USERID_NOT_FOUND: 'No se puede encontrar {{userId}} en el sistema',
  USER_CANNOT_BE_ADMIN: 'Los usuarios que no son administradores no pueden cambiarse a administradores en el sistema',
  ADMIN_CANNOT_BE_NON_ADMIN: 'Los usuarios administradores en el sistema no se pueden cambiar a no administradores',
  USER_CANNOT_HAVE_MULTIPLE_ROLES: 'Cada usuario en el sistema solo puede tener un rol',
  MACHINE_USERS_FEATURE_UNAVAILABLE: 'El usuario no tiene acceso a la función de usuario de la máquina',
}
