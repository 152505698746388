import { contextFactory } from '@ripple/design-system'
import { trackedActions } from '../../helpers/analytics'
import { AnalyticsContextApi } from './analytics.types'

const [AnalyticsContext, useAnalytics] = contextFactory<
  AnalyticsContextApi<typeof trackedActions>
>({
  hook: 'useAnalytics',
  provider: 'AnalyticsProvider',
})

export { AnalyticsContext, useAnalytics }
