import { useMultiTriggerModal } from '@ripple/design-system'
import {
  AddKeypairTriggerData,
  DeactivateKeypairData,
  RenameKeypairTriggerData,
} from '../components'

export const useUserMultiModal = () => {
  return {
    addKeypairModalControls: useMultiTriggerModal<AddKeypairTriggerData>(),
    deactivateKeypairModalControls:
      useMultiTriggerModal<DeactivateKeypairData>(),
    renameKeypairModalControls:
      useMultiTriggerModal<RenameKeypairTriggerData>(),
  }
}
