import i18n from '@/i18n'
import { FieldValidatorFn } from '@ripple/design-system'
import { UserFormCollectionsKeypairValues } from './user-form.types'

/**
 * A form validator to make sure no other keypair has the same name.
 *
 * @param {object} keypairs The already existing keypairs.
 * @returns A string.
 */
export const nameValidator =
  (
    keypairs: UserFormCollectionsKeypairValues['keypairs'][],
  ): FieldValidatorFn<'text'> =>
  ({ value }) => {
    const maybeSameKeypairName = keypairs.find(
      (keypair) => keypair.name === value,
    )

    return maybeSameKeypairName
      ? i18n.t('users:create-user.name-already-used')
      : ''
  }
