import {
  AccountFreezeType,
  FreezeType,
  RequestError,
  WorkItem,
  getResponseData,
} from 'common'
import { useMutation } from 'react-query'
import { requestClient } from '../request-client'

export enum FreezeMutationKeys {
  CreateFreeze = 'create-freeze',
}

export type FreezeRequest = {
  sourceAccount: string // UUID
  workItemType: FreezeType
  /** Required for individual freeze action */
  counterpartyAddress?: string
}

type FreezeRequestProps = {
  request: FreezeRequest
  setting: AccountFreezeType
  workItemId: string
}

export const useCreateFreezeRequest = () => {
  return useMutation<WorkItem, RequestError, FreezeRequestProps>(
    ({ request, setting, workItemId }) =>
      requestClient
        .put(`/work-items/${setting}-freezes/${workItemId}`, request)
        .then(getResponseData),
  )
}

export const useCancelFreezeRequest = () => {
  return useMutation<
    void,
    RequestError,
    Pick<FreezeRequestProps, 'setting' | 'workItemId'>
  >(({ setting, workItemId }) =>
    requestClient.delete(`/work-items/${setting}-freezes/${workItemId}`),
  )
}
