import { z } from 'zod'

const sortSchema = z.object({
  empty: z.boolean(),
  sorted: z.boolean(),
  unsorted: z.boolean(),
})

const pageableSchema = z.object({
  offset: z.number(),
  paged: z.boolean(),
  pageNumber: z.number(),
  pageSize: z.number(),
  sort: sortSchema,
  unpaged: z.boolean(),
})

const basePageSchema = z.object({
  size: z.number(),
  last: z.boolean(),
  first: z.boolean(),
  empty: z.boolean(),
  sort: sortSchema,
  pageable: pageableSchema,
  number: z.number(),
  totalPages: z.number(),
  numberOfElements: z.number(),
  totalElements: z.union([z.number(), z.undefined()]),
})

type BasePage = z.infer<typeof basePageSchema>

export function getPageSchema<T extends z.ZodTypeAny>(
  contentSchema: T,
  defaultEmptyResult = false,
) {
  return basePageSchema.extend({
    content: defaultEmptyResult
      ? // @ts-expect-error : T is always an array, but ts can't figure it out
        contentSchema.optional().default([])
      : contentSchema.optional(),
  })
}

/**
 * Type to represent a Page type from SpringBoot.
 * https://docs.spring.io/spring-data/commons/docs/current/api/org/springframework/data/domain/Page.html
 */
export type PageType<Content> = Pick<BasePage, 'totalElements'> & {
  content: Content
}
