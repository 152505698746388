import { saveAs } from 'file-saver'

/**
 * Converts an array of string data to a csv and downloads the file from the browser
 * @param csv string array that contains the row data at each array index
 * @param headings the header of each column in the returned csv separated by a comma
 * @param filename the name you want to give the csv file to download
 * @returns downloaded csv file
 */
export const downloadAsCsv = (
  csv: string[],
  headings: string,
  filename: string,
) => {
  csv.unshift(headings)
  const csvBlob = new Blob([csv.join('\n')], { type: 'text/csv' })
  saveAs(csvBlob, filename)
}
