import { AxiosInstance } from 'axios'
import { FullUser, fullUserSchema } from '../../models'
import { safeParseApiResponse } from '../utils'

export const getAuthenticatedUser = (
  requestClient: AxiosInstance,
): Promise<FullUser> =>
  requestClient
    .get(`/users/me`)
    .then(safeParseApiResponse(fullUserSchema))
    .catch((error) => {
      // ignore unauthorized errors
      if (error?.response?.status === 401) {
        return null
      }

      throw error
    })
