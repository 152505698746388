import { XummQueryKeys, fundAccount } from '@/shared/api'
import { WALLET } from '@/shared/constants'
import { useToast } from '@ripple/design-system'
import {
  AccountSetupKeyStoreType,
  injectPathParams,
  invalidateQueryCache,
  useXRPLContext,
} from 'common'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { ConnectWalletAccountFormControl } from '../components'
import { XRP_FUNDING_AMOUNT } from '../constants'
import { useWalletApi } from '../context'

export const useCreateWalletAccount = (keyStore: AccountSetupKeyStoreType) => {
  const { t } = useTranslation(['wallet'])
  const toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { client } = useXRPLContext()
  const { addAccount, faucetHost } = useWalletApi()

  const handlers: Record<
    AccountSetupKeyStoreType,
    (formControl: ConnectWalletAccountFormControl) => Promise<void>
  > = {
    [AccountSetupKeyStoreType.Xumm]: async (formControl) => {
      const { accountName, shouldFundAccount } = formControl.getValues()
      const [selectedAccount] = formControl.collections.get('xummAccount').items

      const address = selectedAccount?.address.value
      const userToken = selectedAccount?.userToken.value
      const payloadId = selectedAccount?.payloadId.value
      const accountId = selectedAccount?.accountId.value

      if (!shouldFundAccount) {
        addAccount({
          type: AccountSetupKeyStoreType.Xumm,
          name: accountName,
          payloadId,
          userToken,
          accountId,
          address,
        })
      }

      if (faucetHost && shouldFundAccount) {
        try {
          const transactionResponse = await fundAccount(
            client,
            address,
            faucetHost,
          )

          if (transactionResponse === 'tesSUCCESS') {
            addAccount({
              type: AccountSetupKeyStoreType.Xumm,
              name: accountName,
              payloadId,
              userToken,
              accountId,
              address,
            })

            toast.success(t('wallet:funding-success'))
          } else {
            toast.error(transactionResponse)
          }
        } catch {
          toast.error(t('wallet:funding-error'))
        }
      }

      invalidateQueryCache(queryClient, [[XummQueryKeys.Setup]])
      navigate(injectPathParams(WALLET.account, { address }))
    },
    [AccountSetupKeyStoreType.LedgerNano]: async (formControl) => {
      const { accountName, shouldFundAccount } = formControl.getValues()
      const [selectedAccount] =
        formControl.collections.get('ledgerNanoAccount').items
      const address = selectedAccount.address.value

      if (!shouldFundAccount) {
        addAccount({
          type: AccountSetupKeyStoreType.LedgerNano,
          name: accountName,
          address,
          bip32Path: selectedAccount.bip32Path.value,
        })
        navigate(injectPathParams(WALLET.account, { address }))
      }

      if (faucetHost && shouldFundAccount) {
        try {
          const transactionResponse = await fundAccount(
            client,
            address,
            faucetHost,
          )

          if (transactionResponse === 'tesSUCCESS') {
            addAccount({
              type: AccountSetupKeyStoreType.LedgerNano,
              name: accountName,
              address,
              bip32Path: selectedAccount.bip32Path.value,
            })

            toast.success(t('wallet:funding-success'))
            navigate(injectPathParams(WALLET.account, { address }))

            return
          }

          toast.error(transactionResponse)
        } catch {
          toast.success(t('wallet:funding-error'))
        }
      }
    },
    [AccountSetupKeyStoreType.Custodial]: async (formControl) => {
      try {
        const { wallet } = await client.fundWallet(undefined, {
          faucetHost,
          amount: `${XRP_FUNDING_AMOUNT}`,
        })

        const { seed, classicAddress: address } = wallet

        if (!seed) {
          throw new Error('The server did not return a wallet seed')
        }

        const { accountName } = formControl.getValues()

        addAccount({
          type: AccountSetupKeyStoreType.Custodial,
          address,
          secret: seed,
          name: accountName,
        })

        toast.success(t('wallet:account-created', { accountName }))

        navigate(injectPathParams(WALLET.account, { address }))
      } catch (error) {
        toast.error(
          error instanceof Error ? error.message : t('wallet:account-created'),
        )
      }
    },
  }

  return handlers[keyStore]
}
