import { Environment, KeyPairStore, getReleaseEnv } from 'common'
import {
  BACKEND_STORAGE,
  KeyPairStorageOption,
  LEDGER_NANO_STORAGE,
  METACO_STORAGE,
  XUMM_STORAGE,
} from '../constants'

const KEYPAIR_STORAGE_METHODS = [
  METACO_STORAGE,
  BACKEND_STORAGE,
  LEDGER_NANO_STORAGE,
  XUMM_STORAGE,
]

const STORAGE_METHODS_MAP: Record<string, KeyPairStorageOption[]> = {
  [Environment.Prod]: [LEDGER_NANO_STORAGE],
  [Environment.Stage]: [LEDGER_NANO_STORAGE],
  [Environment.SandboxDev]: [BACKEND_STORAGE, LEDGER_NANO_STORAGE],
  [Environment.SandboxProd]: [BACKEND_STORAGE, LEDGER_NANO_STORAGE],
  [Environment.SandboxStage]: [BACKEND_STORAGE, LEDGER_NANO_STORAGE],
}

/**
 * Get an array of storage method options based on the app env.
 * @returns An array of {@link KeyPairStorageOption} objects
 */
export const getStorageOptionsForEnv = (isMachineUser?: boolean) =>
  (STORAGE_METHODS_MAP[getReleaseEnv()] ?? []).filter((option) =>
    isMachineUser ? option.type !== KeyPairStore.LedgerNano : true,
  )

/**
 * Helper to get a storage method option from an {@link KeyPairStore} type.
 * @param method An {@link KeyPairStore} type
 * @returns A {@link KeyPairStorageOption} object
 */
export const getStorageTypeFromSigningMethod = (method?: KeyPairStore) => {
  if (!method) return BACKEND_STORAGE

  return (
    KEYPAIR_STORAGE_METHODS.find((k) => k.name === method) || BACKEND_STORAGE
  )
}
