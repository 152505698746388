export default {
  'Issuer-Account': 'Issuer Account',
  'Standby-Account': 'Standby Account',
  'Operational-Account': 'Operational Account',
  Loading: 'Loading...',
  New: 'New',
  Sign: 'Sign',
  Signed: 'Signed',
  Signer: 'Signer',
  Signers: 'Signers',
  Nickname: 'Nickname',
  Yes: 'Yes',
  No: 'No',
  and: 'and',
  User: 'User',
  user_one: 'user',
  user_other: 'users',
  Accounts: 'Accounts',
  'My-Tasks': 'My Tasks',
  'Save-Changes': 'Save Changes',
  'Edit-Account-Name': 'Edit Account Name',
  'External-Accounts': 'External Accounts',
  'External-Account': 'External Account',
  'All-Accounts': 'All Accounts',
  'Account-Name': 'Account Name',
  'Destination-Tag': 'Destination Tag',
  'Correlation-Id': 'Correlation Id',
  Ripple: 'Ripple',
  'Ledger-Nano': 'Ledger Nano',
  'Ripple-Custodial-Key': 'Ripple Custodial Key',
  Metaco: 'Metaco',
  Xumm: 'Xumm',
  Keypair: 'Keypair',
  'In-Review': 'In Review',
  Pending: 'Pending',
  Active: 'Active',
  Failed: 'Failed',
  Edit: 'Edit',
  Settings: 'Settings',
  Users: 'Users',
  Mint: 'Mint',
  Pay: 'Pay',
  Roles: 'Roles',
  Account: 'Account',
  Role: 'Role',
  Name: 'Name',
  Email: 'Email',
  Issuer: 'Issuer',
  Standby: 'Standby',
  Operational: 'Operational',
  Destroy: 'Destroy',
  Global: 'Global',
  'Account-Address': 'Account Address',
  'Account-Actions': 'Account Actions',
  'Account-Edits': 'Account Edits',
  'Account-Edit': 'Account Edit',
  Security: 'Security',
  'Account-Type': 'Account Type',
  freeze: 'freeze',
  mint: 'mint',
  Queued: 'Queued',
  Ready: 'Ready',
  Submit: 'Submit',
  Submitted: 'Submitted',
  Settled: 'Settled',
  Retry: 'Retry',
  'Retry-Request': 'Retry Request',
  Canceled: 'Canceled',
  Expired: 'Expired',
  Unknown: 'Unknown',
  unknown: 'unknown',
  'First-Name': 'First Name',
  'Last-Name': 'Last Name',
  'Date-Requested': 'Date Requested',
  'Request-Type': 'Request Type',
  'Requested-By': 'Requested By',
  'Reason-for-Edit': 'Reason for Edit',
  'Created-On': 'Created On',
  'Date-Last-Modified': 'Date/Time Last Modified',
  'Date-Created': 'Date/Time Created',
  Enabled: 'Enabled',
  Disabled: 'Disabled',
  Reason: 'Reason',
  Description: 'Description',
  Title: 'Title',
  Permissions: 'Permissions',
  Delete: 'Delete',
  Cancel: 'Cancel',
  Save: 'Save',
  Close: 'Close',
  Date: 'Date',
  'Payment-Direction': 'Payment Direction',
  Amount: 'Amount',
  From: 'From',
  To: 'To',
  Memo: 'Memo',
  Received: 'Received',
  Payment: 'Payment',
  Continue: 'Continue',
  Remove: 'Remove',
  Sent: 'Sent',
  Currency: 'Currency',
  'Can-Sign': 'Can Sign',
  'Issuer-Address': 'Issuer Address',
  Add: 'Add',
  All: 'All',
  Quorum: 'Quorum',
  NA: 'N/A',
  'QR-Code': 'QR Code',
  'Wallet-Address': 'Wallet Address',
  'View-Docs': 'View Docs',
  Address: 'Address',
  Creator: 'Creator',
  Breadcrumb: 'Breadcrumb',
  Overview: 'Overview',
  Original: 'Original',
  Update: 'Update',
  Create: 'Create',
  Type: 'Type',
  Status: 'Status',
  Success: 'Success',
  'Get-Address': 'Get Address',
  'Cancel-Request': 'Cancel Request',
  'dont-remind': "Don't remind me again",
  'Sign-Transaction': 'Sign Transaction',
  'Unknown-Address': 'Unknown Address',
  'something-went-wrong': 'Something went wrong',
  'something-went-wrong.adding-signature': 'Something went wrong adding your signature.',
  'Copy-ledger-address': 'Copy ledger address',
  'log-in-again.sign': 'Please log in again to sign transactions.',
  'log-in-again.keypair': 'Please log in again to create the new keypair.',
  'log-in-again.session': 'The user was successfully created. For security reasons, please log in again to continue creating a keypair for this user.',
  Confirm: 'Confirm',
  'Confirm-Access': 'Confirm Access',
  'Log-In': 'Log In',
  Configure: 'Configure',
  'Configure-Account': 'Begin Configuration',
  Approve: 'Approve',
  Approved: 'Approved',
  'work-item.id': 'Work Item ID',
  'work-item.signing-not-implemented': 'Signing for this type of storage ({{platform}}) is not available yet.',
  Hash: 'Hash',
  Weight: 'Weight',
  Reporting: 'Reporting',
  'Redemption-Log': 'Redemption Log',
  'Signing-Service': 'Signing Service',
  'Needs-to-Approve': 'Needs to Approve',
  'Sent-For-Signing': 'Sent For Signing',
  'Signing-Failed': 'Signing Failed',
  'dont-ask-again': "Don't ask me again",
  'unconfigured-account.instructions': 'This account has not been fully configured. Please finish configuring the account to begin sending and receiving transactions.',
  'View-Details': 'View Details',
  Deactivate: 'Deactivate',
  Payments: 'Payments',
  Actions: 'Actions',
  Details: 'Details',
  Balance: 'Balance',
}
