export enum UserType {
  AppUser = 'app-user',
  MachineUser = 'machine-user',
}
/**
 * These values are from hardcoded values in the backend that differentiate a
 * machine user initiator from a machine user signer.
 */
export enum MachineUserRole {
  Signer = 'Machine User Signer',
  Initiator = 'Machine User Initiator',
}
