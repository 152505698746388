import { AccountDropdownOption } from '@/shared/hooks'

export const getSelectedOptionBalance = (
  id: string,
  options: AccountDropdownOption[],
): string => {
  const { data: { balance = '' } = {} } =
    options.find(({ value }) => value === id) ?? {}
  // strip thousands but save decimals
  return balance.replace(/[^\d.]/g, '')
}
