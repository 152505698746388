import {
  ExternalAccount,
  PageType,
  PaginationOptions,
  externalAccountSchema,
  getPageSchema,
  safeParseApiResponse,
} from 'common'
import { requestClient } from '../request-client'

export const getExternalAccounts = (
  params?: PaginationOptions,
): Promise<PageType<ExternalAccount[]>> =>
  requestClient
    .get('/external-accounts', { params })
    .then(
      safeParseApiResponse(getPageSchema(externalAccountSchema.array(), true)),
    )

export const getExternalAccount = (
  accountId: string,
): Promise<ExternalAccount> =>
  requestClient
    .get(`/external-accounts/${accountId}`)
    .then(safeParseApiResponse(externalAccountSchema))
