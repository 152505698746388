import { useAuth } from '../auth'
import { isAdminRole } from '../helpers'
import { GetRoutePermissionsObjectFn } from '../models'
import { useHasPermission } from './use-has-permission'

export function useHasRouteAccess(
  path: string,
  getRoutePermissionsObject: GetRoutePermissionsObjectFn,
) {
  const { me } = useAuth()
  const { allowAdmins = true, permissions = [] } =
    getRoutePermissionsObject(path)
  const hasPermission = useHasPermission(permissions)

  return hasPermission && (allowAdmins || !isAdminRole(me.roles[0]))
}
