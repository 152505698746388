import { AccountType, Permission, Role, RolePermission } from 'common'
import { RoleCreateFieldsConfig, RolePermissionsFieldsConfig } from '../models'

type UsePermissionFieldProps = Partial<Pick<Role, 'rolePermissions'>> & {
  isRoleCreation?: boolean
}

/**
 * Hook to return all the form fields config required for the role title, role description and role permissions.
 */
export function usePermissionFieldsConfig(
  opts: UsePermissionFieldProps & { isRoleCreation?: true },
): RoleCreateFieldsConfig
export function usePermissionFieldsConfig(
  opts: UsePermissionFieldProps & { isRoleCreation?: false },
): RolePermissionsFieldsConfig
export function usePermissionFieldsConfig({
  isRoleCreation,
  rolePermissions,
}: UsePermissionFieldProps) {
  let fields: RolePermissionsFieldsConfig | RoleCreateFieldsConfig = {
    issuerEdit: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Issuer,
        fieldPermission: Permission.EditAccount,
        rolePermissions,
      }),
    },
    issuerRead: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Issuer,
        fieldPermission: Permission.ReadAccount,
        rolePermissions,
      }),
    },
    issuerSign: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Issuer,
        fieldPermission: Permission.Sign,
        rolePermissions,
      }),
    },
    issuerTransact: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Issuer,
        fieldPermission: Permission.Transact,
        rolePermissions,
      }),
    },
    standbyEdit: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Standby,
        fieldPermission: Permission.EditAccount,
        rolePermissions,
      }),
    },
    standbyRead: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Standby,
        fieldPermission: Permission.ReadAccount,
        rolePermissions,
      }),
    },
    standbySign: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Standby,
        fieldPermission: Permission.Sign,
        rolePermissions,
      }),
    },
    standbyTransact: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Standby,
        fieldPermission: Permission.Transact,
        rolePermissions,
      }),
    },
    operationalEdit: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Operational,
        fieldPermission: Permission.EditAccount,
        rolePermissions,
      }),
    },
    operationalRead: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Operational,
        fieldPermission: Permission.ReadAccount,
        rolePermissions,
      }),
    },
    operationalTransact: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Operational,
        fieldPermission: Permission.Transact,
        rolePermissions,
      }),
    },
    operationalSign: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Operational,
        fieldPermission: Permission.Sign,
        rolePermissions,
      }),
    },
    freeze: {
      type: 'checkbox',
      required: false,
      value: getPermissionFieldValue({
        fieldAcctType: AccountType.Issuer,
        fieldPermission: Permission.Freeze,
        rolePermissions,
      }),
    },
  }

  // We need a title and description when we create a role
  if (isRoleCreation) {
    fields = {
      ...fields,
      title: { type: 'text' },
      description: { type: 'text' },
    }
  }

  return fields
}

/**
 * Get the value of the checkbox field (true/false).
 *
 * @param {Object} params The function parameters.
 * @param {Object} params.fieldAcctType The field's account type (Issuer, Standby or Operation).
 * @param {Object} params.fieldPermission The field's permission (Sign, Transact, etc.).
 * @param {Object} params.rolePermissions The role' permissions. If a role is defined, we will check if a permission is matching the fieldAcctType and fieldPermission.
 * @returns A boolean.
 */
const getPermissionFieldValue = ({
  fieldAcctType,
  fieldPermission,
  rolePermissions,
}: {
  fieldAcctType: AccountType
  fieldPermission: Permission
  rolePermissions?: RolePermission[]
}) => {
  return rolePermissions?.some((perm) => {
    const { cbdcAccountType, permission } = perm

    return cbdcAccountType
      ? cbdcAccountType === fieldAcctType && permission === fieldPermission
      : permission === fieldPermission
  })
}
