import {
  FullUser,
  RequestError,
  Role,
  RoleChangeStats,
  RolePermission,
  queryClient,
} from 'common'
import { UseQueryOptions, useQuery } from 'react-query'
import { getRoleUsers } from '../users/users-api'
import {
  getDeleteRoleStats,
  getRoleById,
  getRoleByName,
  getRoles,
  getUpdateRoleStats,
} from './roles-api'

export enum RoleQueryKeys {
  RoleDeleteStats = 'role-delete-stats',
  RoleUpdateStats = 'role-update-stats',
  Roles = 'roles',
  RoleUsers = 'role-users',
}

export const useRoles = (opts?: UseQueryOptions<Role[], RequestError>) => {
  return useQuery<Role[], RequestError>([RoleQueryKeys.Roles], getRoles, opts)
}

export const usePrefetchRoles = async (
  opts?: UseQueryOptions<Role[], RequestError>,
) => {
  await queryClient.prefetchQuery([RoleQueryKeys.Roles], getRoles, opts)
}

/**
 * Get a role by roleId.
 *
 * @param {string} roleId The Role ID.
 * @param {UseQueryOptions} opts The React Query options
 * @returns A {@link Role}
 */
export const useRoleById = (
  roleId: string,
  opts?: UseQueryOptions<Role, RequestError>,
) => {
  return useQuery<Role, RequestError>(
    [RoleQueryKeys.Roles, roleId],
    () => getRoleById(roleId),
    opts,
  )
}

/**
 * Get a role by roleName.
 *
 * @param {string} roleId The Role name.
 * @param {UseQueryOptions} opts The React Query options
 * @returns A {@link Role}
 */
export const useRoleByName = (
  roleName: string,
  opts?: UseQueryOptions<Role, RequestError>,
) => {
  return useQuery<Role, RequestError>(
    [RoleQueryKeys.Roles, roleName],
    () => getRoleByName(roleName),
    opts,
  )
}

/**
 * Get the users associated with a role.
 *
 * @param {string} roleId The Role ID.
 * @param {UseQueryOptions} opts The React Query options
 * @returns A list of {@link UserDetails}.
 */
export const useRoleUsers = (
  roleId: string,
  opts?: UseQueryOptions<FullUser[], RequestError>,
) => {
  return useQuery<FullUser[], RequestError>(
    [RoleQueryKeys.RoleUsers, roleId],
    () => getRoleUsers(roleId),
    opts,
  )
}

/**
 * Query to check if a role can be deleted.
 * @param {string} roleId The role id to check
 * @param opts Query options
 * @returns A {@link RoleChangeStats} object
 */
export const useDeleteRoleStats = (
  roleId: string,
  opts?: UseQueryOptions<RoleChangeStats, RequestError>,
) =>
  useQuery<RoleChangeStats, RequestError>(
    [RoleQueryKeys.RoleDeleteStats, roleId],
    () => getDeleteRoleStats(roleId),
    opts,
  )

type UseUpdateRoleStatsProps = {
  newRoleId?: string
  roleId: string
  permissions: RolePermission[]
}
/**
 * Checks if a role permissions change is allowed.
 * @param {string} roleId The role id to check
 * @param permissions Set of new permissions to check the current role against.
 * @param opts UseQuery options
 * @returns A {@link RoleChangeStats} object
 */
export const useUpdateRoleStats = (
  { roleId, newRoleId, permissions }: UseUpdateRoleStatsProps,
  opts?: UseQueryOptions<RoleChangeStats, RequestError>,
) =>
  useQuery<RoleChangeStats, RequestError>(
    [RoleQueryKeys.RoleUpdateStats, roleId, newRoleId],
    () => getUpdateRoleStats(roleId, permissions),
    opts,
  )
