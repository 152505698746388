export default {
  'invalid-currency-code-error': 'El código de moneda no existe en esta dirección de emisor',
  Send: 'Enviar',
  Assets: 'Activos',
  'Add-Asset': 'Añadir Activo',
  Connect: 'Conectar',
  'Connect-Account': 'Conectar Cuenta',
  Destination: 'Destino',
  'View-All': 'Ver Todas',
  'Recent-Payments': 'Pagos Recientes',
  'connect-an-account': 'Conectar una cuenta',
  'Connect-Xumm-Wallet': 'Conectar con Xumm',
  'Send-Payment': 'Enviar Pago',
  'Sign-Payment': 'Firmar Pago',
  'Receive-Payment': 'Recibir Pago',
  'fund-this-account': 'Fondear esta cuenta',
  'reserve-balance': 'Reserva: {{xrpBaseReserve}} XRP',
  More: 'Más',
  'Demo-Custodial-Key': 'Demo Clave Custodiada',
  'Xumm-Wallet': 'Xumm Wallet',
  'account-name-exists': 'El nombre de cuenta ya existe',
  'onboarding.select-address': 'Seleccione una dirección a utilizar',
  'onboarding.ledger-nano.title': 'Conectar con su Ledger Nano',
  'onboarding.ledger-nano.instructions': 'Conecte su Ledger Nano via USB y haga click en el botón "Conectar',
  'onboarding.xumm-connect.success': '¡Bien! Haga click en "Siguiente para continuar configurando su cuenta',
  'onboarding.xumm-network.title': 'Conectar con Sandbox Ledger',
  'onboarding.xumm-network.instructions.0': 'En su app Xumm, haga click en el botón azul en el centro de la barra de herramientas. Tras ello, haga click en "Scan QR Code" (Escanee el código QR) para conectar con el registro contable del entorno de sandbox.',
  'onboarding.xumm-network.instructions.1': 'Cuando haga terminado de conectar, haga click en "Finalizar" para continuar hacia sus cuentas',
  'assets.add.description': 'Añada su primer activo para comenzar',
  'not-funded.title': 'La cuenta no ha sido fondeada',
  'not-funded.instructions.0': 'Para fondear su cuenta',
  'not-funded.instructions.1': 'Consiga XRP en una casa de cambio',
  'not-funded.instructions.2': 'Envíe al menos {{xrpBaseReserve}} XRP a esta cuenta: {{address}}',
  'not-funded.instructions.3': 'No se requiere etiqueta de destino',
  'funding-success': 'Cuenta fondeada correctamente',
  'funding-error': 'No ha sido posible fondear su cuenta',
  'account-created': '{{accountName}} ha sido creada',
  'account-error': 'Se ha producido un error creando la cuenta de pruebas',
  'payment-sign': 'Firmar transacción de pago',
  'payment-signed': 'Transacción de pago firmada',
  'payment-success': 'Pago enviado correctamente',
  'payment-error': 'Se ha producido un error enviando el pago',
  'payment-xumm-instructions': 'Escanee el cógigo QR con su app Xumm',
  'asset-success': 'Se ha añadido {{currency}} correctamente',
  'asset-error': 'Se ha producido un error añadiendo el activo',
  'trustset-sign': 'Firme la transacción para la línea de confianza',
  'trustset-signed': 'Se ha firmado la transacción para la línea de confianza',
  'trustset-error': 'Se ha producido un error creando la línea de confianza',
  'receive-payment.title': 'Recibir pago',
  'receive-payment.instructions': 'Para enviar activos a <Strong>{{name}}</Strong>, escanee el cógigo QR o copie la dirección de la billetera en un pago creado en la red de sandbox',
  'onboarding.xumm-connect.instructions.0': null,
  'onboarding.xumm-connect.instructions.1': null,
  'onboarding.xumm-network-completed.title': null,
  'onboarding.xumm-network-completed.instructions.0': null,
  'onboarding.xumm-network-completed.instructions.1': null,
}
