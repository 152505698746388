import { captureException } from '@sentry/react'
import { QueryClient } from 'react-query'
import { NotFoundError, UnauthorizedError } from './utils'

const logError = (error: unknown) => {
  console.error(error)
  captureException(error)
}

const ifQueryClientError = (error: unknown) =>
  error instanceof NotFoundError || error instanceof UnauthorizedError

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: ifQueryClientError,
      onError: logError,
    },
    mutations: {
      useErrorBoundary: ifQueryClientError,
      onError: logError,
    },
  },
})

/**
 * Invalidates the query cache for the given list of query keys.
 * @example
 * invalidateQueryCache([
 *   ["key1"],
 *   ["key2", 123]
 * ])
 */
export const invalidateQueryCache = (
  client: QueryClient,
  queryKeys: (string | number | Record<string, unknown>)[][],
) => {
  for (const queryKey of queryKeys) {
    client.invalidateQueries({ queryKey })
  }
}
