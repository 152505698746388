import { useUpdateAccountSetupLedgerDetails } from '@/shared/api'
import { getKeyIndexFromDerivationPath } from '@/shared/helpers'
import { useLedgerNano } from '@/shared/hooks'
import { Clock3Icon, ToastType, useToast } from '@ripple/design-system'
import { ProcessingStatus } from 'common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ACCOUNT_TYPE_TO_DERIVATION_ACCOUNT_TYPE_MAP,
  isWizardStepEqualToBackEndStep,
} from '../../account-setup-modal.helpers'
import { CommonStepProps } from '../../account-setup-modal.types'

/**
 * Hook which is used only for the first step to connect to the ledger nano and retrieve
 * an available XRP address.
 *
 * @param {number} step The ledger step, which is 1.
 * @param {CommonStepProps} Object All the props which are common to all the steps.
 * @param {boolean} show Indicates if the modal is visible or closed.
 * @returns An object containing:
 * - errorMessage: A potential error message.
   - retryHandler: A function to retry the flow.
   - toastMessage: The toast message.
   - toastType: The toast type.
   - toastIcon: The toast icon.
 */
export const useLedgerNanoAccountSetup = (
  step: number,
  {
    accountSetup,
    ledgerNanoAccount,
    pathName,
    processingStatus,
    saveLedgerAccount,
    show,
  }: CommonStepProps,
) => {
  const { t } = useTranslation(['accounts'])
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isSavingAddress, setIsSavingAddress] = useState(false)
  const [isRetrievingAccount, setIsRetrievingAccount] = useState(false)
  const [keyIndex, setKeyIndex] = useState<number>(0)
  const { getFirstAvailableAccount, removeToasts } = useLedgerNano()
  const toast = useToast()

  // Check if the step returned by the back-end corresponds to the step of the Ledger Nano (ie 1).
  const isLedgerStep = isWizardStepEqualToBackEndStep(step, pathName)

  // Clear out any active toasts if the modal closes
  useEffect(() => {
    return () => {
      removeToasts()
    }
  }, [])

  useEffect(() => {
    /**
     * It's important to check isLedgerStep for the situation where we open the modal and we are at
     * a step after the ledger nano step.
     * When the modal opens, for a short period of time, the currentStep returned by useWizard is 1 and then changes to the step indicated by the back-end.
     * The fact that currentStep=1 renders the Ledger Nano component step and triggers the connection with the Ledger (which is unnecessary).
     */
    if (!ledgerNanoAccount && isLedgerStep) fetchAccountAndSavePublicKey()
  }, [ledgerNanoAccount])

  const fetchAccountAndSavePublicKey = async () => {
    if (!show) return

    if (!ledgerNanoAccount) {
      // Step 1
      ledgerNanoAccount = await fetchAccountFromNano()
    }

    if (ledgerNanoAccount) {
      // Step 2
      await savePublicKey(ledgerNanoAccount.publicKey)
    }
  }

  /**
   * ******************************************************************************************
   * Step 1, Retrieve the correct account from the Ledger Nano if needed to know its derivation path.
   * ******************************************************************************************
   */
  const fetchAccountFromNano = async () => {
    if (!accountSetup) {
      setErrorMessage(t('accounts:account-setup.account-details-not-found'))
      return
    }
    setIsRetrievingAccount(true)

    // Find the DerivationAccountType based on the account setup account type
    const derivationAccountType =
      ACCOUNT_TYPE_TO_DERIVATION_ACCOUNT_TYPE_MAP[accountSetup.accountType]

    ledgerNanoAccount = await getFirstAvailableAccount({
      derivationAccountType,
      keyIndex,
    })

    if (!ledgerNanoAccount) {
      setErrorMessage(t('accounts:account-setup.account-not-found'))
      setIsRetrievingAccount(false)
      return
    }
    // Save the account details in the state to have access to it faster in the next steps.
    saveLedgerAccount(ledgerNanoAccount)
    setErrorMessage(undefined)
    setIsRetrievingAccount(false)

    return ledgerNanoAccount
  }

  /**
   * ******************************************************************************************
   * Step 2, Save the ledger nano account public key in the database
   * ******************************************************************************************
   */
  const updateLedgerDetailsMutation = useUpdateAccountSetupLedgerDetails()
  const savePublicKey = async (pubKey: string) => {
    if (!show) return

    setIsSavingAddress(true)
    await updateLedgerDetailsMutation.mutateAsync(
      {
        cbdcAccountId: accountSetup.accountId,
        publicKey: pubKey,
      },
      {
        onError: () => {
          defineNextIndexToUse(false)
          setIsSavingAddress(false)
          setErrorMessage(
            t('accounts:account-setup.ledger-nano.save-key.failed'),
          )
        },
        onSuccess: (response) => {
          handleStatusCode(response.statusCode)
        },
      },
    )
  }

  /**
   * ******************************************************************************************
   * 👇🏻 Helpers 👇🏻
   * ******************************************************************************************
   */

  /**
   * Defines the logic regarding the status code received from the back-end
   * @param {number} statusCode The status code of the API response.
   * @returns void
   */
  const handleStatusCode = (statusCode?: number) => {
    if (statusCode === 200) {
      setErrorMessage(undefined)
      // No need to setIsSavingAddress(false) as we want to avoid displaying a wrong toast message for a very short period of time
      return
    }

    /**
     * Status code === 409 means that we tried to insert a public key which already exists in the table from another tenant.
     * That's a very rare scenario.
     *
     * Status code === 422 means potentially that the account we try to save has already been configured.
     * That's also a rare scenario.
     */
    if (statusCode === 409 || statusCode === 422) {
      toast.info(t('accounts:account-setup.ledger-nano.save-address.failed'))
      defineNextIndexToUse(true)

      // Setting the ledger account to undefined will trigger the useEffect with ledgerNanoAccount as dependency.
      // Then the connection to the ledger will start again to find an available address.
      saveLedgerAccount(undefined)
    } else {
      defineNextIndexToUse(false)
      setIsSavingAddress(false)
      setErrorMessage(t('accounts:account-setup.ledger-nano.save-key.failed'))
    }
  }

  /**
   * Finds the last key index which was used.
   * Then set in state the key index to use the next time we want to find an available address.
   */
  const defineNextIndexToUse = (increaseIndex: boolean) => {
    const lastKeyIndex = getKeyIndexFromDerivationPath(
      ledgerNanoAccount?.bip32Path ?? '',
    )

    if (increaseIndex) {
      setKeyIndex(lastKeyIndex + 1)
    } else {
      setKeyIndex(lastKeyIndex)
    }
  }

  /**
   * What happens when the user clicks on the retry text button
   */
  const retryHandler = () => {
    setErrorMessage(undefined)
    defineNextIndexToUse(false)
    fetchAccountAndSavePublicKey()
  }

  // Toast message, type and icon depending on the context.
  let toastMessage = ''
  let toastType: ToastType = 'info'
  let toastIcon = undefined

  const isFindingAccount = isRetrievingAccount && isLedgerStep
  const isSaving =
    !isRetrievingAccount &&
    isLedgerStep &&
    (isSavingAddress || processingStatus === ProcessingStatus.Processing)
  const hasError = !isRetrievingAccount && isLedgerStep && errorMessage
  const isSuccess = !isRetrievingAccount && !isLedgerStep

  if (isFindingAccount) {
    toastMessage = t('accounts:account-setup.ledger-nano.save-address.loading')
    toastIcon = Clock3Icon
  } else if (isSaving) {
    toastMessage = t('accounts:account-setup.ledger-nano.save-address.saving')
    toastIcon = Clock3Icon
  } else if (isSuccess) {
    toastMessage = t('accounts:account-setup.ledger-nano.save-address.success')
    toastType = 'success'
  } else if (hasError) {
    toastMessage = errorMessage
    toastType = 'error'
  }

  return {
    errorMessage,
    retryHandler,
    toastMessage,
    toastType,
    toastIcon,
  }
}
