import { z } from 'zod'
import { accountSetupNextStepSchema } from './account-setup-next-step'

/**
 * This is the object that is being sent from the backend to the client to indicate AccountSetupStep
 * updates when they are subscribed to the AccountSetup's queue.
 *
 * https://github.com/xpring-eng/cbdc-backend/blob/main/cbdc-model/src/main/java/io/ripplex/cbdc/core/model/ws/AccountSetupStepWebSocketMessage.java
 */
export const accountSetupWebSocketMessageSchema = z.object({
  tenantId: z.string(),
  accountId: z.string(),
  adminId: z.string(),
})

export const accountSetupStepWebSocketMessageSchema =
  accountSetupWebSocketMessageSchema.extend({
    nextStep: accountSetupNextStepSchema,
  })

export type AccountSetupWebSocketMessage = z.infer<
  typeof accountSetupWebSocketMessageSchema
>

export type AccountSetupStepWebSocketMessage = z.infer<
  typeof accountSetupStepWebSocketMessageSchema
>
