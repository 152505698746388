import { OptionalExceptFor } from '@ripple/design-system'
import { z } from 'zod'
import { AccountType } from '../accounts'
import { Permission } from '../permissions'

export const rolePermissionSchema = z.object({
  isAccountMembershipRequired: z.boolean(),
  cbdcAccountType: z.nativeEnum(AccountType).optional(),
  permission: z.nativeEnum(Permission),
})

export type RolePermission = z.infer<typeof rolePermissionSchema>

// will allow us to manage the routes access
export type PermissionWithAccountType = OptionalExceptFor<
  RolePermission,
  'permission'
>

/**
 * An object or array of {@link PermissionWithAccountType}.
 */
export type PermissionWithAccountTypeUnion =
  | PermissionWithAccountType
  | PermissionWithAccountType[]
