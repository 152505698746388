import {
  MachineUserSettings,
  machineUserSettingsSchema,
  safeParseApiResponse,
} from 'common'
import { requestClient } from '../request-client'

export const getMachineUsersSettings = (): Promise<MachineUserSettings> =>
  requestClient
    .get(`/tenant-settings/machine-users`)
    .then(safeParseApiResponse(machineUserSettingsSchema))
