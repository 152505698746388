import { setDsTranslateFn } from '@ripple/design-system'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // namespaces and resources are loaded asynchronously by `I18nProvider`
    ns: [],
    resources: {},
    // the default namespace must be `ds` for design-system translations to load
    defaultNS: 'ds',
    fallbackLng: (userCode) => {
      // If userCode is `es` or `es-UNKNOWN` or `es-ES`, use `es-ES`
      // if userCode is `UNKNOWN`, use `en-US`
      const knownLocales = Object.keys(i18n.store.options.resources ?? {})

      return [
        ...knownLocales.filter((key) => key.startsWith(userCode.split('-')[0])),
        'en-US',
      ]
    },
    interpolation: {
      escapeValue: false, // react already escapes for xss protection => https://www.i18next.com/translation-function/interpolation#unescape
    },
    debug:
      new URL(location.href).hostname.includes('localhost') &&
      process.env.NODE_ENV !== 'test',
  })

// Configure ds to use i18n.t
setDsTranslateFn(i18n.t.bind(i18n))

// this export is used for translation key type checking
export * as resources from '@/i18n/locales/en-US'

export default i18n
