import AppXrp from '@ledgerhq/hw-app-xrp'
import Transport from '@ledgerhq/hw-transport'
import TransportWebHID from '@ledgerhq/hw-transport-webhid'
import { useToast } from '@ripple/design-system'
import to from 'await-to-js'
import { AxiosError } from 'axios'
import { DerivationAccountType, LedgerNanoXrpAccount } from 'common'
import { useRef, useState } from 'react'
import i18n from '../../i18n'
import {
  getUserKeypairAddressAvailability,
  useAccountSetupJunkyard,
  useAccountSetups,
  useAccountsMetadata,
} from '../api'
import {
  isAddressInJunkyard,
  isAddressUsedForAccountSetup,
  isAddressUsedForConfiguredAccount,
} from '../helpers'

// Defines the maximum key index in the Ledger Nano, this is a "limitation" of the derivation path and can't be higher.
export const MAX_KEY_INDEX = 20
const TRANSPORT_UNDEFINED_ERROR = () =>
  i18n.t('transactions:nano-error.transport-undefined')

const UNRESPONSIVE_DEVICE = () => ({
  error: 'UNRESPONSIVE_DEVICE',
  timeout: 5000,
  toastMesssage: i18n.t('transactions:nano-error.unable-to-connect'),
})

/**
 * Custom Hook to use the Ledger Nano.
 *
 * @param {Boolean} asAdmin Optional, if false it means this hook is used by a non-admin user. Defaults to true.
 * @returns An object containing:
 * - getLedgerName: A function to get the Ledger Name.
 * - getLedgerTransport: A function to get the Transport object required to communicate with the Ledger Nano.
 * - getXrpAccount: A function to retrieve details about a specific XRP Account.
 * - getXrpAccountFromLedgerByAddress: A function to retrieve a Ledger Nano account by its address.
 * - ledgerDevices: A list of Ledger Nano that were previously authorized by the user.
 * - maxAccountIndex: The maximum Ledger index where we fetch an XRP account.
 * - removeToasts: A function to remove the toasts which don't disappear automatically (ie. autoDismiss set to 0).
 * - signTransaction: A function to sign a transaction.
 */
export function useLedgerNano(asAdmin = true) {
  const toast = useToast()
  const xrpAppNotOpenedToastRef = useRef<string>()
  const ledgerpNotOpenedToastRef = useRef<string>()
  const ledgerUnresponsiveRef = useRef<string>()
  const waitingForSignatureToastRef = useRef<string>()
  const [isDeviceProcessing, setIsDeviceProcessing] = useState(false)
  const { data: accountSetups = [] } = useAccountSetups({ enabled: asAdmin })
  const { data: acctsMetadata = [] } = useAccountsMetadata({ enabled: asAdmin })
  const { data: junkyard = [] } = useAccountSetupJunkyard({ enabled: asAdmin })

  /**
   * Function to get a {@link Transport} object to communicate with the Ledger Nano.
   * For most main actions (sign txn or get xrp account details) you need to
   * use this function first.
   *
   * @returns A {@link Promise} with a Transport object.
   */
  const getLedgerTransport = async () => {
    // List the devices previously authorized by the user.
    const [errDevicesList, devicesList] = await to(TransportWebHID.list())
    if (errDevicesList) {
      handleError(errDevicesList)
      return
    }

    if (devicesList.length === 0) {
      // Trying to connect to the Ledger device with HID protocol
      // This will open the pop-up next to the URL in the browser.
      const [err, transport] = await to(TransportWebHID.create())
      if (err) handleError(err)
      return transport
    } else {
      // TODO: For now we open the first device connected.
      // We will check with the team if we want to handle multiple devices connected.
      // Let's do things step by step...
      const firstDevice = devicesList[0]

      if (firstDevice.opened) {
        return new TransportWebHID(firstDevice)
      } else {
        const [err, transport] = await to(TransportWebHID.open(firstDevice))
        if (err) handleError(err)
        return transport
      }
    }
  }

  /**
   * Function to retrieve details about a specific XRP Account.
   *
   * @param {number} acctIndex To retrieve an address at a specific index in the Ledger Nano.
   */
  const getXrpAccount = async (
    accountIndex: DerivationAccountType,
    keyIndex = 0,
    ledgerTransport?: Transport,
  ) => {
    let transport = ledgerTransport
    if (!transport) {
      transport = await getLedgerTransport()
      if (!transport) {
        toast.error(TRANSPORT_UNDEFINED_ERROR())
        return undefined
      }
    }

    removeToasts()

    const xrp = new AppXrp(transport)

    try {
      // For derivation path, read: https://learnmeabitcoin.com/technical/derivation-paths
      const bip32Path = `44'/144'/${accountIndex}'/0/${keyIndex}`

      const addressDetails = (await Promise.race([
        xrp.getAddress(bip32Path),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error(UNRESPONSIVE_DEVICE().error)),
            UNRESPONSIVE_DEVICE().timeout,
          ),
        ),
      ])) as Awaited<ReturnType<typeof xrp.getAddress>>

      return { ...addressDetails, bip32Path }
    } catch (error) {
      if (error instanceof Error) {
        handleError(error)
      }
      return undefined
    }
  }

  /**
   * Retrieve a list of Ledger Nano that were previously authorized by the user.
   *
   * @returns a {@link Promise<HIDDevice[]>}
   */
  const ledgerDevices = () => {
    return TransportWebHID.list()
  }

  /**
   * Method to sign a transaction blob.
   *
   * @param transport
   * @param serializedTxnBlob
   * @param isEd25519 Optionally enable or not the ed25519 curve (secp256k1 is default)
   * @returns The transaction signature
   */
  const signTransaction = async (
    serializedTxnBlob: string,
    bip32Path = "44'/144'/0'/0/0",
    isEd25519 = false,
  ): Promise<string | undefined> => {
    const transport = await getLedgerTransport()
    if (!transport) {
      toast.error(TRANSPORT_UNDEFINED_ERROR())
      return
    }

    removeToasts()

    toast.success(
      i18n.t('transactions:nano-success.connected', {
        product: transport.deviceModel?.productName,
      }),
    )
    const xrp = new AppXrp(transport)
    waitingForSignatureToastRef.current = toast.info({
      message: i18n.t('transactions:nano.check-for-signing'),
      autoDismissAfter: 0,
    })

    // Attempt to sign the transaction with the Ledger
    const [err, signature] = await to(
      xrp.signTransaction(bip32Path, serializedTxnBlob, isEd25519),
    )
    if (err) {
      handleError(err)
    }
    if (waitingForSignatureToastRef.current) {
      toast.remove(waitingForSignatureToastRef.current)
    }

    return signature
  }

  /**
   * Helper to remove some toast notifications. We typically remove them
   * once the user has taken an action to go to the next step.
   */
  const removeToasts = () => {
    // Remove the notification to tell the user to open the XRP App on the Ledger Nano.
    if (xrpAppNotOpenedToastRef.current) {
      toast.remove(xrpAppNotOpenedToastRef.current)
    }
    // Remove the notification to tell the user to unlock his Ledger Nano and to open the XRP App.
    if (ledgerpNotOpenedToastRef.current) {
      toast.remove(ledgerpNotOpenedToastRef.current)
    }
    // Remove the notification to tell the user to open their device and close any unsigned transactions.
    if (ledgerUnresponsiveRef.current) {
      toast.remove(ledgerUnresponsiveRef.current)
    }
  }

  /**
   * Helper function to display a useful message to the end user based on the Error message.
   *
   * @param error The {@link Error} returned by the Ledger Nano.
   */
  const handleError = (error: Error) => {
    switch (error.message) {
      case 'Ledger device: UNKNOWN_ERROR (0x650f)': {
        // More info about the error if needed in the future:
        // statusCode: 25871
        // statusText: "UNKNOWN_ERROR"
        xrpAppNotOpenedToastRef.current = toast.warning({
          message: i18n.t('transactions:nano.open-and-retry'),
          autoDismissAfter: 0,
        })
        break
      }
      case 'Ledger device: UNKNOWN_ERROR (0x680b)': {
        // More info about the error if needed in the future:
        // statusCode: 26635
        // statusText: "UNKNOWN_ERROR"
        toast.error(i18n.t('transactions:nano-error.incorrect-bytes'))
        break
      }
      case 'Ledger device: Condition of use not satisfied (denied by the user?) (0x6985)': {
        // More info about the error if needed in the future:
        // statusCode: 27013
        // statusText: "CONDITIONS_OF_USE_NOT_SATISFIED"
        if (waitingForSignatureToastRef.current) {
          toast.remove(waitingForSignatureToastRef.current)
        }
        toast.info(i18n.t('transactions:nano.txn-rejected'))
        break
      }
      case 'Ledger device: Incorrect length (0x6700)': {
        // More info about the error if needed in the future:
        // statusCode: 26368
        // statusText: "INCORRECT_LENGTH"
        toast.error(i18n.t('transactions:nano-error.txn-too-large'))
        break
      }
      case 'Ledger device: UNKNOWN_ERROR (0x6b0c)': {
        // More info about the error if needed in the future:
        // statusCode: 27404
        // statusText: 'UNKNOWN_ERROR'
        ledgerpNotOpenedToastRef.current = toast.warning({
          message: i18n.t('transactions:nano.unlock-and-retry'),
          autoDismissAfter: 0,
        })
        break
      }
      case 'Invalid channel': {
        // There are no status code or status text for this error.
        // https://support.ledger.com/hc/en-us/articles/360026371854-Invalid-channel?support=true
        toast.warning({
          message: i18n.t('transactions:nano-error.invalid-channel'),
          autoDismissAfter: 8000,
        })
        break
      }
      case UNRESPONSIVE_DEVICE().error: {
        // This is thrown in the case when the Ledger Nano doesn't respond when trying to connect.
        // This occurs when the device goes to sleep (e.g. screen saver) when a transaction is in an unsigned state.
        ledgerUnresponsiveRef.current = toast.error({
          message: UNRESPONSIVE_DEVICE().toastMesssage,
          autoDismissAfter: 0,
        })
        break
      }
      default: {
        toast.error(error.message)
      }
    }
  }

  /**
   * Helper function to get an XRP account details from the Ledger Nano by its address.
   * It will loop through the bip32 paths inside the Ledger Nano until the address is
   * retrieved or the loop limit is reached (20 by default).
   *
   * @param {string} address The address to retrieve from the Ledger Nano.
   * @param {number} keyIndexLimit A number indicating the maximum amount of key indexes to check for a particular account index.
   * @returns A {@link Promise}<LedgerNanoXrpAccount | undefined>
   */
  const getXrpAccountFromLedgerByAddress = async (
    accountIndex: DerivationAccountType,
    address: string,
    keyIndexLimit = MAX_KEY_INDEX,
  ): Promise<LedgerNanoXrpAccount | undefined> => {
    let correctAccount: LedgerNanoXrpAccount | undefined = undefined,
      keyIndex = 0

    try {
      setIsDeviceProcessing(true)

      while (!correctAccount && keyIndex < keyIndexLimit) {
        const [err, ledgerXrpAccount] = await to(
          getXrpAccount(accountIndex, keyIndex),
        )
        if (err) {
          handleError(err)
          break
        }
        if (!ledgerXrpAccount) {
          toast.error(
            i18n.t('transactions:nano-error.ledger-key-index-unreachable'),
          )
          break
        }
        if (ledgerXrpAccount.address === address) {
          correctAccount = {
            ...ledgerXrpAccount,
            bip32Path: `44'/144'/${accountIndex}'/0/${keyIndex}`,
          }
          break
        }
        keyIndex++
      }

      return correctAccount
    } finally {
      setIsDeviceProcessing(false)
    }
  }

  /**
   * Function to get the Ledger name.
   *
   * @returns A string, the Ledger Nano name.
   */
  const getLedgerName = async () => {
    const transport = await getLedgerTransport()
    return transport?.deviceModel?.productName ?? 'Ledger Nano'
  }

  type FirstAvailableAccountParams = {
    blackoutAddresses?: string[]
    derivationAccountType: DerivationAccountType
    keyIndex?: number
    ledgerTransport?: Transport
  }

  /**
   * Function to retrieve the first available account which is not used as
   * KeyPair or Account.
   *
   * @param {DerivationAccountType} accountIndex The account index in the derivation path which corresponds to the type of ledger nano address we want to retrieve.
   * @param {Transport} ledgerTransport Optional - A Transport object to communicate with the Ledger Nano.
   * @returns
   */
  const getFirstAvailableAccount = async ({
    blackoutAddresses,
    derivationAccountType,
    keyIndex = 0,
    ledgerTransport,
  }: FirstAvailableAccountParams): Promise<
    LedgerNanoXrpAccount | undefined
  > => {
    let account = undefined,
      transport = ledgerTransport

    if (!transport) {
      transport = await getLedgerTransport()
      if (!transport) {
        toast.error(TRANSPORT_UNDEFINED_ERROR())
        return undefined
      }
    }

    try {
      setIsDeviceProcessing(true)
      // Loop through the accounts in the Ledger until an account is found and the max index is reached.
      while (!account && keyIndex < MAX_KEY_INDEX) {
        const [errGetAcct, ledgerAccount] = await to(
          getXrpAccount(derivationAccountType, keyIndex, transport),
        )

        if (errGetAcct) {
          handleError(errGetAcct)
          return
        }
        if (!ledgerAccount) break

        const ledgerAddress = ledgerAccount.address

        if (derivationAccountType === DerivationAccountType.SigningKeyPair) {
          try {
            // Check if the keypair address is already registered in the db across all tenants
            await getUserKeypairAddressAvailability(ledgerAddress)
            // If the API succeeds and return a 200, that means the address is already used, so we need to move
            // to the next index (ie. address) in the Ledger Nano.
          } catch (err) {
            if (
              err instanceof AxiosError &&
              // If the keypair is not registered, then the API returns a 404 (Not Found)
              err.response?.status === 404
            ) {
              if (
                !blackoutAddresses ||
                !blackoutAddresses.includes(ledgerAccount.address)
              ) {
                account = ledgerAccount
              }
            }
          }
        } else {
          // Check if the address hasn't been assigned to an account
          if (
            !isAddressUsedForAccountSetup(accountSetups, ledgerAddress) &&
            !isAddressUsedForConfiguredAccount(acctsMetadata, ledgerAddress) &&
            !isAddressInJunkyard(junkyard, ledgerAddress)
          ) {
            account = ledgerAccount
          }
        }
        keyIndex++
      }
      return account
    } finally {
      setIsDeviceProcessing(false)
    }
  }

  /**
   * Get `options.limit` number of accounts on the Ledger Nano after `options.offset`
   *
   * @param {DerivationAccountType} accountIndex The account index in the derivation path which corresponds to the type of ledger nano address we want to retrieve.
   * @param {{ limit: number, offset: number }} options Pagination options for the operation
   * @param {Transport} ledgerTransport Optional - A Transport object to communicate with the Ledger Nano.
   * @returns
   */
  const getAccounts = async (
    accountIndex: DerivationAccountType,
    options: {
      limit: number
      offset: number
    } = { limit: MAX_KEY_INDEX, offset: 0 },
    ledgerTransport?: Transport,
  ): Promise<LedgerNanoXrpAccount[] | undefined> => {
    let transport = ledgerTransport
    if (!transport) {
      transport = await getLedgerTransport()
      if (!transport) {
        toast.error(TRANSPORT_UNDEFINED_ERROR())
        return undefined
      }
    }

    try {
      setIsDeviceProcessing(true)

      let { offset } = options
      const accounts = [],
        total = options.limit + offset
      while (offset < total) {
        const [errGetAcct, ledgerAccount] = await to(
          getXrpAccount(accountIndex, offset, transport),
        )

        if (errGetAcct) {
          handleError(errGetAcct)
          return
        }

        if (!ledgerAccount) return

        accounts.push(ledgerAccount)

        offset++
      }

      return accounts
    } finally {
      setIsDeviceProcessing(false)
    }
  }

  /**
   * Function to sign a transaction when we don't know exactly which keypair in the
   * ledger nano to use. For that we need to find first the correct index in the ledger nano
   * by using the workitem transaction signature address.
   *
   * @param {string} address The XRP address which needs to be used to sign the transaction.
   * @param {string} transactionEncoded The transaction encoded blob.
   * @returns A {@link string} signature from the Ledger Nano.
   */
  const findNanoAddressAndSign = async (
    address: string,
    transactionEncoded: string,
  ) => {
    const signingXrpAccountFromLedger = await getXrpAccountFromLedgerByAddress(
      DerivationAccountType.SigningKeyPair,
      address,
    )

    if (!signingXrpAccountFromLedger) {
      toast.error(i18n.t('transactions:nano-error.signing-account-not-found'))
      return
    }

    // Attempt to sign using the Ledger Nano
    const ledgerNanoSignature = await signTransaction(
      transactionEncoded,
      signingXrpAccountFromLedger.bip32Path,
    )

    if (!ledgerNanoSignature) {
      toast.error(i18n.t('transactions:nano-error.signature-not-found'))
    }

    return ledgerNanoSignature
  }

  return {
    isDeviceProcessing,
    findNanoAddressAndSign,
    getLedgerName,
    getLedgerTransport,
    getXrpAccount,
    getXrpAccountFromLedgerByAddress,
    getFirstAvailableAccount,
    ledgerDevices,
    maxAccountIndex: MAX_KEY_INDEX,
    removeToasts,
    signTransaction,
    getAccounts,
  }
}
