export default {
  Welcome: 'Bienvenue',
  'Log-in': 'Se connecter',
  'Sign-Out': 'Se déconnecter',
  'Welcome-to-DCM': 'Bienvenue dans le gestionnaire de devises numériques Ripple.',
  offline: 'Vous semblez être hors ligne. Veuillez vérifier votre connexion.',
  Unknown: 'Inconnu',
  Breadcrumb: "Fil d'Ariane",
  'My-Tasks': 'Mes Tâches',
  'not-found.title': "La page que vous recherchez n'existe pas.",
  'not-found.content.0': "La page que vous essayez d'accéder n'existe pas. Veuillez contacter votre administrateur pour plus d'informations.",
  'not-found.content.1': "Cliquez <Link>ici</Link> pour retourner à la page d'accueil",
  'no-users': 'Aucun utilisateur trouvé',
  'No-HID-Support': 'Votre navigateur ne prend pas en charge HID qui est nécessaire pour utiliser Ledger Nano pour signer des transactions. Veuillez utiliser Chrome, Brave, Edge ou Opera.',
  'unauthorized.title': 'Votre accès est restreint',
  'unauthorized.content': "Vous n'avez pas accès à cette page. Veuillez contacter votre administrateur pour plus d'informations.",
  'Empty-folder': 'Dossier vide',
  English: 'Anglaise',
  Spanish: 'Espagnol',
  French: 'Français',
  'Reset-Password': 'Réinitialiser le mot de passe',
  'View-Menu': 'Afficher le menu',
}
