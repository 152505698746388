import { messageCallbackType, StompHeaders } from '@stomp/stompjs'
import { useContext, useEffect } from 'react'
import { WS_QUEUES } from '../../constants'
import { WebsocketContext } from '../../contexts'

type SubscriptionOptions = {
  deps?: any[]
  headers?: StompHeaders
}

/**
 * Hook to subscribe to one or multiple useStomp queues.
 *
 * @param queues The queues to subscribe to. It can be a string for a single queue or an array of strings for multiple queues.
 * @param onMessage Callback called when a message arrives for this subscription.
 * @param opts Additional options for this subscription, check '@stomp/stompjs' (https://github.com/stomp-js/stompjs) docs.
 */
export const useStomp = (
  queues: WS_QUEUES | WS_QUEUES[],
  onMessage: messageCallbackType,
  opts: SubscriptionOptions = {},
) => {
  const wsContext = useContext(WebsocketContext)
  const { headers = {}, deps = [] } = opts

  if (!wsContext) {
    throw new Error('useSubscription must be used inside WebsocketProvider')
  }

  const destinations = Array.isArray(queues) ? queues : [queues]

  useEffect(() => {
    const cleanUpFunctions: VoidFunction[] = []

    destinations.forEach((destination) => {
      cleanUpFunctions.push(
        wsContext.subscribe(destination, onMessage, headers),
      )
    })

    return () => {
      cleanUpFunctions.forEach((cleanUpFunction) => {
        cleanUpFunction()
      })
    }
  }, [
    Object.values(destinations).toString(),
    Object.values(headers).toString(),
    ...deps,
  ])
}
