import { AccountUser } from '@/shared/api'
import { BaseCbdcSigner } from 'common'

/**
 * Maps collection selection state to user ids
 * @param {RowSelectionState} state Row selection state
 * @param rows Collection rows array, with the data being {@link AccountUser} objects
 * @returns {string[]} An array of user ids
 */
export const getSelectedUserIds = (
  state: Record<string, boolean>,
  rows: { row: AccountUser }[] = [],
) =>
  Object.entries(state).reduce((arr, [key, value]) => {
    if (value) {
      const row = rows[+key]
      if (row) arr.push(row.row.userId)
    }
    return arr
  }, [] as string[])

/**
 * Curried function to check if an account member is a signer on the account.
 * @param {BaseCbdcSigner[]} signers Array of account signers
 * @returns A function accepting a user and returning a boolean indicating if they are a signer
 */
export const userIsSigner =
  (signers: BaseCbdcSigner[]) =>
  ({ userId }: AccountUser) =>
    !!signers.find((signer) => signer.user?.userId === userId)
