import { Role, RolePermission, objectKeys } from 'common'

const permissionsMatch = (p1: RolePermission[], p2: RolePermission[]) => {
  if (p1.length !== p2.length) return false
  return p1.every((perm) =>
    p2.find((perm2) => {
      for (const key of objectKeys(perm)) {
        if (perm[key] !== perm2[key]) return false
      }
      return true
    }),
  )
}

export const findDuplicateRole = (
  roles: Role[],
  permissions: RolePermission[],
) =>
  roles.find(({ rolePermissions }) =>
    permissionsMatch(rolePermissions, permissions),
  )
