export default {
  'New-Role': 'New Role',
  'Role-Name': 'Role Name',
  'Role-Not-Found': 'Role Not Found',
  'Edit-Permissions': 'Edit Permissions',
  'Update-Permissions': 'Update Permissions',
  'Permissions-conflict': 'Permissions conflict',
  System: 'System',
  'Delete-Role': 'Delete Role',
  'Back-to-Editing': 'Back to Editing',
  'Duplicate-Role': 'Duplicate Role',
  'Account-Permissions': 'Account Permissions',
  'View-Role': 'View Role',
  'System-Permissions': 'System Permissions',
  'signing-disabled': 'Signing not enabled',
  'signing-enabled': 'Signing enabled on',
  'loading-roles': 'Loading roles...',
  'loading-role': 'Loading role...',
  'loading-role-details': '"Loading role details..."',
  'no-roles-found': 'No roles found. Try adding a new role.',
  'no-role-found': 'No role found with the id {{roleId}}',
  'create-role.success': 'New Role {{role}} successfully created',
  'create-role.error': 'Could not create new role',
  'create-role.permissions-conflict': 'A role with the same permissions already exists. Modify the existing role, or change the selected permissions to create a new role.',
  'system-role.overview': 'System Admin users perform functions surrounding the maintenance of Users, Roles, and Accounts within the application.',
  'system-role.permissions.0': 'Can create and modify accounts (e.g. create a new standby account)',
  'system-role.permissions.1': 'Can create and modify users',
  'system-role.permissions.2': 'Can create and modify roles',
  'system-role.permissions.3': 'Can initiate freeze and unfreeze requests (e.g. freezing a specific external account)',
  'system-role.permissions.4': 'Can initiate global and individual account freezes',
  'system-role.permissions.5': 'Can create and modify accounts',
  'role-permission.add-account': 'Can add a new account',
  'role-permission.add-role': 'Can add a new role',
  'role-permission.add-user': 'Can add a new user',
  'role-permission.delete-account': 'Can delete an exisiting account',
  'role-permission.delete-role': 'Can delete an exisiting role',
  'role-permission.delete-user': 'Can delete an existing user',
  'role-permission.edit-account': 'Can edit account details (e.g. account name, signer list, etc.)',
  'role-permission.edit-role': 'Can edit a role',
  'role-permission.edit-user': 'Can edit the details of a user',
  'role-permission.read-role': 'Can read role',
  'role-permission.read-account': 'Can read (can view account and transaction details only)',
  'role-permission.freeze': 'Can freeze all the accounts or an individual account',
  'role-permission.mint-pay-and-destroy-transact': 'Can initiate payments (e.g. mint, pay, and destroy transactions)',
  'role-permission.pay-and-destroy-transact': 'Can initiate payments (e.g. pay and destroy transactions)',
  'role-permission.mint-transact': 'Can initiate mint transactions',
  'role-permission.sign': 'Can sign for account transactions (e.g. mint transactions, change requests, etc.)',
  'account-permission.edit': 'Can edit account details (e.g. account name, signer list, etc.)',
  'account-permission.sign': 'Can sign for account transactions (e.g. mint transactions, change requests, etc.)',
  'account-permission.pay': 'Can initiate payments (e.g. mint, pay, and destroy transactions)',
  'Role-Details': 'Role Details',
  'Edit-Role-Details': 'Edit Role Details',
  'edit-success': 'Role details updated successfully',
  'edit-error': 'An error happened while updating the role',
  'edit-description': 'Edit the role title and description.',
  'role-name-required': 'Role name is not defined, it is required to update the role',
  'role-description-required': 'Role description is not defined, it is required to update the role',
  'updated-role.prompt': 'Are you sure you want to update the permissions for "{{roleName}}"?',
  'updated-role.warning': 'By updating the permissions for this role, users will be removed from <Strong>$t(plurals:accounts, {"count": {{accountsLosingAllMembersCount}}})</Strong> that are in conflict with the new permission set.',
  'updated-role.success': 'Role updated successfully',
  'updated-role.error': 'Could not update the role',
  'updated-role.conflict': 'These changes will prevent $t(plurals:users, {"count": {{signersLosingAccessCount}}}) from being able to see accounts where they are currently signers. You must first remove all signers for an account type before modifying the role permissions.',
  'assign-new-role.title': 'You Must Assign A New Role',
  'assign-new-role.description': 'Assign a new role to all users to remove them from this role before deleting the role.',
  'delete-role.title': 'Delete Role?',
  'delete-role.disabled': 'You Cannot Delete This Role',
  'delete-role.confirmation': 'Are you sure you want to delete this role? You cannot undo this action.',
  'delete-success': 'Role deleted successfully',
  'delete-error': 'An error occured while deleting the role',
  'delete-role.remove-signers': 'To delete this role, you must first remove <Strong>$t(plurals:users, {"count": {{signerCount}} })</Strong> as signers from <Strong>$t(plurals:accounts, {"count": {{accountCount}} })</Strong>.',
  'delete-role.conflicting-permissions': 'The selected role has different permissions from the current role. Users will be removed from <Strong>$t(plurals:accounts, {"count": {{count}} })</Strong> that $t(plurals:conflicts, {"count": {{count}} }) with the new role permissions.',
  'no-users-assigned': 'No users have been assigned this role',
  'no-users-assigned.description': 'To assign this role to a user, go to <Button>Users</Button>',
  'role-permission-label.issuer-read': 'Read issuer accounts',
  'role-permission-label.operational-read': 'Read operational accounts',
  'role-permission-label.standby-read': 'Read standby accounts',
  'role-permission-label.issuer-edit': 'Edit issuer accounts',
  'role-permission-label.operational-edit': 'Edit operational accounts',
  'role-permission-label.standby-edit': 'Edit standby accounts',
  'role-permission-label.issuer-sign': 'Sign for issuer accounts',
  'role-permission-label.operational-sign': 'Sign for operational accounts',
  'role-permission-label.standby-sign': 'Sign for standby accounts',
  'role-permission-label.issuer-transact': 'Initiate transactions for issuer accounts',
  'role-permission-label.operational-transact': 'Initiate transactions for operational accounts',
  'role-permission-label.standby-transact': 'Initiate transactions for standby accounts',
}
