import {
  getAccountLedgerInfo,
  getAccountSignature,
  useAccountSetupSignStep,
  useAccountSetupStep,
} from '@/shared/api'
import { WS_QUEUES } from '@/shared/constants'
import {
  AccountSetupStepWebSocketMessage,
  ProcessingStatus,
  useMutationErrorHandler,
  useSubscription,
} from 'common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isWizardStepEqualToBackEndStep } from '../../account-setup-modal.helpers'
import { CommonStepProps } from '../../account-setup-modal.types'
import { ToastStatus } from '../ledger-nano'

/**
 * Hook for all of the logic of signing and saving a signature
 * for account configuration steps that needs signatures. It
 * simplifies the steps themselves, as well as the toast display.
 * @param step The step currently being display
 * @param stepProps The step component props object
 * @returns Props needed to render step contact and manually trigger signing (e.g. via a retry button)
 */
export function useAccountSetupSignature(
  step: number,
  {
    accountSetup,
    pathName,
    processingStatus,
    custodialAccount,
    saveCustodialAccount,
  }: CommonStepProps,
) {
  const { t } = useTranslation(['accounts'])
  const [errorMessage, setErrorMessage] = useState<string>()
  const [bytesToSign, setBytesToSign] = useState<string>()
  /** Are we calling the back-end to save the signature and submit it to the XRPL */
  const [isSavingSignature, setIsSavingSignature] = useState(false)

  // When a step reaches a successful state, the next step will be returned
  // from the `/next` endpoint. This check helps us determine things like
  // whether we've reached a successful point for the current step.
  const isMatchingStep = isWizardStepEqualToBackEndStep(step, pathName)

  const { accountId } = accountSetup

  const { data: accountSetupStep, refetch } = useAccountSetupStep(
    accountId,
    pathName,
  )

  useSubscription<AccountSetupStepWebSocketMessage>(WS_QUEUES.ACCOUNT_SETUPS, {
    onData: ({ data }) => {
      if (data.accountId === accountId) refetch()
    },
  })

  // Get the transaction bytes to sign.
  useEffect(() => {
    setBytesToSign(
      // The bytes to sign will be the only pending transaction
      accountSetupStep?.pendingWorkItem?.transactions[0]?.transactionEncoded,
    )
  }, [accountSetupStep])

  // Account XRP address which must exist fore these steps
  const address = accountSetup?.ledgerInfo?.address

  /**
   * ******************************************************************************************
   * Main function that executes all the steps:
   * - Retrieve the correct keystore from the backend and sign the transaction
   * - Save the signature in the database. The back-end will submit it to the XRPL.
   * ******************************************************************************************
   */
  const fetchAccountSignAndSave = async () => {
    if (!bytesToSign) {
      console.log('no bytes to sign')
      return
    }

    setErrorMessage('')

    if (!custodialAccount) {
      // Step 1
      custodialAccount = await fetchAccount()
    }

    if (custodialAccount) {
      // Step 2
      const { signature } = await getAccountSignature(accountId, pathName)

      if (signature) {
        // Step 3
        await saveSignatureInDb(signature)
      }
    }
  }

  /**
   * ******************************************************************************************
   * Step 1, Retrieve the correct custodial account if needed.
   * ******************************************************************************************
   */
  const fetchAccount = async () => {
    if (!accountSetup) {
      setErrorMessage(t('accounts:account-setup.account-not-found'))
      return
    }

    if (!address) {
      setErrorMessage(t('accounts:account-setup.account-address-not-found'))
      return
    }

    custodialAccount = await getAccountLedgerInfo(accountId)
    if (!custodialAccount) {
      setErrorMessage(t('accounts:account-setup.account-not-found'))
      return
    }
    saveCustodialAccount(custodialAccount)

    return custodialAccount
  }

  /**
   * ******************************************************************************************
   * Step 3,save the signature in the database. The back-end will submit it to the XRPL.
   * ******************************************************************************************
   */
  const signMutation = useAccountSetupSignStep()
  const mutationErrorHandler = useMutationErrorHandler()

  const saveSignatureInDb = async (signature: string) => {
    setIsSavingSignature(true)

    if (processingStatus === ProcessingStatus.Ready) {
      // workitem has moved to a 'pending for signatures' state
      await signMutation.mutateAsync(
        {
          cbdcAccountId: accountId,
          pathName,
          signature,
        },
        {
          onError: mutationErrorHandler(
            t('accounts:account-setup.ledger-nano.save-signature.failed'),
          ),
        },
      )
      return
    }

    setIsSavingSignature(false)
    setErrorMessage(
      t('accounts:account-setup.ledger-nano.save-signature.queued'),
    )
  }

  // calculate the toast status so it can decide which message to show
  let toastStatus = ToastStatus.TransactionQueued

  const isWaiting = isMatchingStep
  const isSubmitting =
    isMatchingStep &&
    (isSavingSignature || processingStatus === ProcessingStatus.Processing)
  const hasError = isMatchingStep && errorMessage
  const isSuccess = !isMatchingStep && !errorMessage

  if (isWaiting) {
    toastStatus = ToastStatus.None
  } else if (isSubmitting) {
    toastStatus = ToastStatus.Submitting
  } else if (hasError) {
    toastStatus = ToastStatus.Error
  } else if (isSuccess) {
    toastStatus = ToastStatus.Success
  }

  return {
    isMatchingStep,
    accountSetup,
    accountSetupStep,
    address,
    fetchAccountSignAndSave,
    errorMessage,
    toastStatus,
    isSubmitting,
  }
}
