export default {
  Welcome: 'Welcome',
  'Log-in': 'Log in',
  'Sign-Out': 'Sign Out',
  'Welcome-to-DCM': 'Welcome to the Ripple Digital Currency Manager.',
  offline: 'You appear to be offline. Please check your connection.',
  Unknown: 'Unknown',
  Breadcrumb: 'Breadcrumb',
  'My-Tasks': 'My Tasks',
  'not-found.title': 'The page you are looking for does not exist.',
  'not-found.content.0': 'The page you are trying to access does not exist. Please contact your Admin for further information.',
  'not-found.content.1': 'Click <Link>here</Link> to go home',
  'no-users': 'No users found',
  'No-HID-Support': 'Your browser does not support HID which is required to use the Ledger Nano to sign transactions. Please use Chrome, Brave, Edge or Opera.',
  'unauthorized.title': 'Your Access Is Restricted',
  'unauthorized.content': 'Your do not have access to this page. Please contact your Admin for further information.',
  'Empty-folder': 'Empty folder',
  English: 'English',
  Spanish: 'Spanish',
  French: 'French',
  'Reset-Password': 'Reset Password',
  'View-Menu': 'View Menu',
}
