import { FieldConfigs } from '@ripple/design-system'

export const PermissionFields = {
  issuerEdit: 'issuerEdit',
  issuerRead: 'issuerRead',
  issuerSign: 'issuerSign',
  issuerTransact: 'issuerTransact',
  standbyEdit: 'standbyEdit',
  standbyRead: 'standbyRead',
  standbySign: 'standbySign',
  standbyTransact: 'standbyTransact',
  operationalEdit: 'operationalEdit',
  operationalRead: 'operationalRead',
  operationalSign: 'operationalSign',
  operationalTransact: 'operationalTransact',
  freeze: 'freeze',
} as const

/**
 * The table checkbox fields configs for a role (create or edit).
 */
export type RolePermissionsFieldsConfig = {
  [PermissionFields.issuerEdit]: FieldConfigs['checkbox']
  [PermissionFields.issuerRead]: FieldConfigs['checkbox']
  [PermissionFields.issuerSign]: FieldConfigs['checkbox']
  [PermissionFields.issuerTransact]: FieldConfigs['checkbox']
  [PermissionFields.standbyEdit]: FieldConfigs['checkbox']
  [PermissionFields.standbyRead]: FieldConfigs['checkbox']
  [PermissionFields.standbySign]: FieldConfigs['checkbox']
  [PermissionFields.standbyTransact]: FieldConfigs['checkbox']
  [PermissionFields.operationalEdit]: FieldConfigs['checkbox']
  [PermissionFields.operationalRead]: FieldConfigs['checkbox']
  [PermissionFields.operationalSign]: FieldConfigs['checkbox']
  [PermissionFields.operationalTransact]: FieldConfigs['checkbox']
  [PermissionFields.freeze]: FieldConfigs['checkbox']
}

/**
 * The role name and description configs.
 */
export type RoleCreateFieldsConfig = RolePermissionsFieldsConfig & {
  title: FieldConfigs['text']
  description: FieldConfigs['text']
}

export type RolePermissionFieldsValue = Record<
  keyof RolePermissionsFieldsConfig,
  NonNullable<FieldConfigs['checkbox']['value']>
>
