import { z } from 'zod'
import { tenantSchema } from '../tenant'

export const baseUserSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  userId: z.string(),
  username: z.string(),
})
export type BaseUser = z.infer<typeof baseUserSchema>

export const userSchema = baseUserSchema.extend({
  roleIds: z.string().array(),
  tenant: tenantSchema,
  tenantId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
})

export type User = z.infer<typeof userSchema>
