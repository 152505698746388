import { z } from 'zod'
import { rolePermissionSchema } from './role-permission'

/**
 * Request body object to create a new Role.
 */
export const newRoleSchema = z.object({
  roleName: z.string(),
  description: z.string(),
  rolePermissions: rolePermissionSchema.array(),
})

/**
 * Request body object to create a new Role.
 */
export type NewRole = z.infer<typeof newRoleSchema>
