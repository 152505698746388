import { z } from 'zod'
import { AccountType } from './accounts'
import { balanceMapSchema } from './balances'

export const tenantWideCbdcAccountBalancesSchema = z.object({
  ledgerIndex: z.number(),
  balances: z.record(z.string(), balanceMapSchema).optional(),
  typeBalances: z
    .record(z.nativeEnum(AccountType), balanceMapSchema)
    .optional(),
})

export type TenantWideCbdcAccountBalances = z.infer<
  typeof tenantWideCbdcAccountBalancesSchema
>
