import { z } from 'zod'
import { currencyCodeSchema } from './currency'

export const trustLineSchema = z.object({
  account: z.string(),
  balance: z.string(),
  currency: currencyCodeSchema,
  limit: z.string(),
  // eslint-disable-next-line camelcase
  limit_peer: z.string(),
})

export type Trustline = z.infer<typeof trustLineSchema>
