import { z } from 'zod'
import { CbdcSigner } from '../cbdc-signer'
import { TransactionDirection } from '../transactions'
import {
  WorkItemTransaction,
  workItemTransactionSchema,
} from './work-item-transaction'
import { WorkItemTransactionSignature } from './work-item-transaction-signature'
import { WorkItemType } from './work-item-type'

export enum WorkItemStatus {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Queued = 'QUEUED',
  PendingSignatures = 'PENDING_SIGNATURES',
  ReadyForSubmission = 'READY_FOR_SUBMISSION',
  Submitted = 'SUBMITTED',
  Unknown = 'UNKNOWN',
}

export const workItemStatuses = {
  failed: [
    WorkItemStatus.Failed,
    WorkItemStatus.Expired,
    WorkItemStatus.Cancelled,
  ],
  pending: [
    WorkItemStatus.Submitted,
    WorkItemStatus.PendingSignatures,
    WorkItemStatus.ReadyForSubmission,
  ],
  cancelable: [
    WorkItemStatus.Queued,
    WorkItemStatus.PendingSignatures,
    WorkItemStatus.ReadyForSubmission,
  ],
  settled: [
    WorkItemStatus.Failed,
    WorkItemStatus.Expired,
    WorkItemStatus.Complete,
    WorkItemStatus.Cancelled,
  ],
}

export type WorkItemStatusDetails = {
  displayName: string
  value: WorkItemStatus
  color: string
  iconType: 'check' | 'clock' | 'close' | 'reload'
}

export const workItemTopSchema = z.object({
  type: z.nativeEnum(WorkItemType),
  status: z.nativeEnum(WorkItemStatus),
})

export const workItemSchema = workItemTopSchema.extend({
  /** A UUID */
  id: z.string(),
  type: z.nativeEnum(WorkItemType),
  status: z.nativeEnum(WorkItemStatus),
  sourceAccount: z.string(),
  transactions: workItemTransactionSchema.array(),
  lastLedgerSequenceOffset: z.number(),
  /** A UUID */
  canceledBy: z.string().optional(),
  createdBy: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  /** A UUID */
  tenantId: z.string(),
})

type WorkItemBase = z.infer<typeof workItemSchema>

export const workItemTopResponseSchema = workItemTopSchema.or(z.null())

export type WorkItem<T extends WorkItemType = WorkItemType> = Omit<
  WorkItemBase,
  'type'
> & { type: T }

type Target = {
  correlationId?: string | undefined
  destinationTag?: number | undefined
  id: string | undefined
  address: string
  name: string
  type: string
}

export type WorkItemDetailsType = {
  amount: string
  cancellation?: {
    date: string
    user: string
  }
  creation: {
    date: string
    user: string
  }
  currency: string
  direction: TransactionDirection
  id: string
  freeze?: {
    isGlobal: boolean
    target: Target
    displayTitle: string
  }
  lastUpdated: string
  quorumDisplay: string
  quorum: number
  signatures: WorkItemTransactionSignature[]
  signers: CbdcSigner[]
  signedWeight: number
  transaction: WorkItemTransaction
  transactionHash: string
  type: WorkItemType
  typeDisplay: string
  sourceAccount: {
    id: string
    address: string
    name: string
    type: string
  }
  destinationAccount: Target
  status: WorkItemStatus
}
