import { useTranslation } from 'react-i18next'
import {
  AccountDropdownOption,
  ExternalDropdownOption,
} from './pay-request-form.types'

/**
 * Hook to get available balance text for a selected account option.
 * @param {object[]} options Array of {@link AccountDropdownOption | ExternalDropdownOption}
 * @param {string|undefined} accountId The selected accountId
 * @returns
 */
export function useAccountOptionAvailableBalance(
  options: (AccountDropdownOption | ExternalDropdownOption)[],
  accountId?: string,
  { enabled = true }: { enabled?: boolean } = {},
) {
  const { t } = useTranslation(['common', 'payments'])

  if (enabled && accountId) {
    const { data } = options.find((opt) => opt.value === accountId) ?? {}

    if (data && 'balance' in data) {
      const { balance, currency } = data
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      return t('payments:available-balance', {
        amount: balance,
        currency,
      })
    }
  }

  return ''
}
