import i18n from '@/i18n'
import { User } from 'common'

export const getUserFullname = (
  user?: Pick<User, 'firstName' | 'lastName'>,
) => {
  if (!user) return i18n.t('common:Unknown')

  return user.firstName === 'API'
    ? `API - ${user.lastName}`
    : `${user.firstName} ${user.lastName}`.trim()
}
