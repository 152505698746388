export default {
  'Issuer-Account': 'Cuenta del emisor',
  'Standby-Account': 'Cuenta en espera',
  'Operational-Account': 'Cuenta operativa',
  Loading: 'Cargando...',
  New: 'Nueva',
  Signer: 'Firmante',
  Signers: 'Firmantes',
  Nickname: 'Apodo',
  Yes: 'Si',
  No: 'No',
  and: 'y',
  User: 'Usuario',
  user_one: 'usuario',
  user_other: 'usuarios',
  Accounts: 'Cuentas',
  'My-Tasks': 'Mis Tareas',
  'Save-Changes': 'Guardar cambios',
  'Edit-Account-Name': 'Cambiar Nombre de Cuenta',
  'External-Accounts': 'Cuentas Externas',
  'External-Account': 'Cuenta Externa',
  'All-Accounts': 'Todas las Cuentas',
  'Account-Name': 'Nombre de Cuenta',
  'Destination-Tag': 'Etiqueta de Destino',
  'Correlation-Id': 'Id de correlación',
  Ripple: 'Ripple',
  'Ledger-Nano': 'Ledger Nano',
  'Ripple-Custodial-Key': 'Clave custodiada por Ripple',
  Metaco: 'Metaco',
  Xumm: 'Xumm',
  Keypair: 'Par de Claves',
  Edit: 'Cambiar',
  Settings: 'Ajustes',
  Users: 'Usuarios',
  Mint: 'Emitir',
  Pay: 'Pagar',
  Roles: 'Roles',
  Account: 'Cuenta',
  Role: 'Rol',
  Name: 'Nombre',
  Email: 'Email',
  Issuer: 'Emisor',
  Standby: 'Intermedia',
  Operational: 'Operacional',
  Destroy: 'Destruir',
  Global: 'Global',
  'Account-Address': 'Dirección de Cuenta',
  'Account-Actions': 'Acciones de Cuenta',
  'Account-Edits': 'Cambios en la Cuentas',
  'Account-Edit': 'Cambio en la Cuenta',
  Security: 'Seguridad',
  'Account-Type': 'Tipo de Cuenta',
  freeze: 'bloquear',
  mint: 'emitir',
  Queued: 'En Cola',
  'In-Review': 'En Revisión',
  Pending: 'Pendiente',
  Ready: 'Lista',
  Submit: 'Enviar',
  Submitted: 'Enviada',
  Settled: 'Confirmada',
  Retry: 'Reintentar',
  'Retry-Request': 'Solicitud de reintento',
  Canceled: 'Cancelada',
  Failed: 'Fallida',
  Expired: 'Expirada',
  Unknown: 'Desconocida',
  unknown: 'desconocida',
  'First-Name': 'Nombre',
  'Last-Name': 'Apellido',
  'Date-Requested': 'Fecha Petición',
  'Request-Type': 'Tipo de Petición',
  'Requested-By': 'Pedida por',
  'Reason-for-Edit': 'Razón del Cambio',
  'Created-On': 'Creado',
  'Date-Last-Modified': 'Fecha/hora de la última modificación',
  'Date-Created': 'Fecha/hora de creación',
  Enabled: 'Activado',
  Disabled: 'Desactivado',
  Reason: 'Razón',
  Description: 'Descripción',
  Title: 'Título',
  Permissions: 'Permisos',
  Delete: 'Eliminar',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  Close: 'Cerrar',
  Date: 'Fecha',
  'Payment-Direction': 'Dirección',
  Amount: 'Cantidad',
  From: 'De',
  To: 'A',
  Memo: 'Memo',
  Received: 'Recibido',
  Payment: 'Pago',
  Continue: 'Continuar',
  Remove: 'Eliminar',
  Sent: 'Enviado',
  Currency: 'Moneda',
  'Can-Sign': 'Puede Firmar',
  'Issuer-Address': 'Dirección del Emisor',
  Add: 'Añadir',
  All: 'Todo',
  Quorum: 'Quorum',
  NA: 'N/A',
  'QR-Code': 'Código QR',
  'Wallet-Address': 'Dirección de Billetera',
  'View-Docs': 'Ver Documentación',
  Address: 'Dirección',
  Creator: 'Creador',
  Breadcrumb: 'Camino',
  Overview: 'Resumen',
  Original: 'Original',
  Update: 'Actualizar',
  Create: 'Crear',
  Type: 'Tipo',
  Status: 'Estado',
  Success: 'Éxito',
  'Get-Address': 'Obtener Dirección',
  'Cancel-Request': 'Cancelar Petición',
  'dont-remind': 'No volver a recordarme',
  'Sign-Transaction': 'Firmar Transacción',
  'Unknown-Address': 'Dirección Desconocida',
  'something-went-wrong': 'Algo ha ido mal',
  'something-went-wrong.adding-signature': 'Algo ha ido mal añadiendo su firma.',
  'Copy-ledger-address': 'Copiar Dirección',
  'log-in-again.sign': 'Por favor, entre de nuevo para firmar transacciones.',
  'log-in-again.keypair': 'Por favor, entre de nuevo para crear un nuevo par de claves.',
  'log-in-again.session': 'Usuario creado correctamente. Por razones de seguridad, entre de nuevo para continuar creando un par de claves para este usuario',
  Confirm: 'Confirmar',
  'Confirm-Access': 'Confirmar Acceso',
  'Log-In': 'Entrar',
  Configure: 'Configurar',
  'Configure-Account': 'Comenzar la Configuración',
  Approve: 'Aprobar',
  Approved: 'Aprobada',
  'work-item.id': 'Work Item ID',
  'work-item.signing-not-implemented': `Firmas para este tipo de custodia ({{platform}}) no esta disponible aún.`,
  Hash: 'Hash',
  Weight: 'Peso',
  Reporting: 'Reportes',
  'Redemption-Log': null,
  'Signing-Service': 'Servicio de Firma',
  'Needs-to-Approve': 'Necesita Aprobar',
  'Sent-For-Signing': 'Enviada para Firma',
  'Signing-Failed': 'Firmado Fallado',
  'dont-ask-again': 'No me vuelva a recordar',
  'unconfigured-account.instructions': 'Esta cuenta no ha sido configurada totalmente. Por favor, termine de configurar la cuenta para empezar a enviar y recibir transacciones.',
  'View-Details': 'Ver Detalles',
  Sign: 'Firmar',
  Signed: 'Firmada',
  Active: 'Activo',
  Deactivate: 'Desactivar',
  Payments: 'Pagos',
  Actions: 'Acciones',
  Details: 'Detalles',
  Balance: 'Saldo',
}
