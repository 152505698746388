import { AccountType, CbdcAccount, CbdcSigner, ExternalAccount } from 'common'

/**
 * Calculate the sum of the weights of the signer keypairs.
 *
 * A CbdcSigner object looks like:
 *
 * @example
 * {
 *    signerUserId: "rGr7ZzyHjf1ESQRjAZ0BVA4JCv7qyEZP14",
 *    signerKeyPairId: "8bbeb061-2016-4a53-b977-a3ad4e6f49d7"
 *    signerWeight: 3,
 * }
 *
 * @param {CbdcSigner} signers An array of {@link CbdcSigner}.
 * @returns {number} The sum of the weight of each signer.
 */
export const getWeightSumOfSigners = (signers: CbdcSigner[]) => {
  return signers.reduce(
    (total, { signerWeight = 0 }) => total + signerWeight,
    0,
  )
}

/**
 * Get an account name based on its address.
 * This is a curried function that requires you to provide a list of accounts
 * and external accounts, then returns a function that accepts an address
 * and finds the matching name from the provided accounts.
 */
export const accountNameByAddress =
  (accounts: CbdcAccount[], externalAccounts: ExternalAccount[]) =>
  (address: string): string | undefined => {
    const account = accounts.find((a) => a.address === address)

    if (account) {
      return account.accountName
    } else {
      const account = externalAccounts.find((a) => a.address === address)
      return account?.accountName
    }
  }

export const isAccountIssuer = (accountType: string) => {
  return accountType === AccountType.Issuer
}

export const isAccountExternal = (accountType = '') =>
  /external/i.test(accountType)
