import { MachineUserSettings, RequestError } from 'common'
import { UseQueryOptions, useQuery } from 'react-query'
import { getMachineUsersSettings } from './tenant-settings-api'

export enum TenantSettingsQueryKeys {
  MachineUsers = 'machine-users-settings',
}

export const useMachineUserSettings = (
  opts?: UseQueryOptions<MachineUserSettings, RequestError>,
) => {
  return useQuery<MachineUserSettings, RequestError>(
    [TenantSettingsQueryKeys.MachineUsers],
    getMachineUsersSettings,
    opts,
  )
}
