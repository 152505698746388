import { z } from 'zod'
import { roleSchema } from '../roles'
import { userSchema } from './user'
import { userAccountMinSchema } from './user-account'
import { userKeyPairSchema } from './user-keypair'

export const fullUserSchema = z.object({
  user: userSchema,
  roles: roleSchema.array().default([]),
  keyPairs: userKeyPairSchema.array().default([]),
  accounts: userAccountMinSchema.array().default([]),
})

export type FullUser = z.infer<typeof fullUserSchema>
