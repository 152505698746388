import axios, { AxiosError, AxiosResponse } from 'axios'
import { LOGIN, NOT_FOUND, UNAUTHORIZED } from '../constants'
import {
  appHistory,
  getCbdcServerUrl,
  getDC3POServerUrl,
  logoutViaOAuth,
} from '../helpers'
import { NotFoundError, UnauthorizedError } from './utils'

const ignoreRoutes = [LOGIN, UNAUTHORIZED, NOT_FOUND]

const createRequestClient = axios.create

const xummRequestClient = createRequestClient({
  baseURL: getDC3POServerUrl(),
})

type CreateAuthenticatedRequestClientOptions = {
  publicRoutes?: string[]
}

const createAuthenticatedRequestClient = ({
  publicRoutes = [],
}: CreateAuthenticatedRequestClientOptions = {}) => {
  const ignorePaths = [...ignoreRoutes, ...publicRoutes]

  const authenticatedRequestClient = createRequestClient({
    baseURL: getCbdcServerUrl(),
    withCredentials: true,
  })

  authenticatedRequestClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (
        error.response?.status === 404 &&
        error.response.config.method?.toUpperCase() !== 'HEAD'
      ) {
        throw new NotFoundError()
      }

      if (error.response?.status === 403) {
        throw new UnauthorizedError()
      }

      // we want to skip this check for some requests
      // for example, we call login with an empty payload to logout
      // which results in a 401 response
      const isFromLogin = error.request.responseURL.includes('/login')
      if (error.response?.status === 401 && !isFromLogin) {
        if (
          !ignorePaths.some((pathname) =>
            appHistory.location.pathname.includes(pathname),
          )
        ) {
          logoutViaOAuth({ remember: true })
        }
      }

      throw error
    },
  )
  return authenticatedRequestClient
}

export {
  createAuthenticatedRequestClient,
  createRequestClient,
  xummRequestClient,
}

export const getResponseData = (response: AxiosResponse) => response.data
