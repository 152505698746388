export default {
  INTERNAL_SERVER_ERROR: 'An unexpected error has occured.',
  UNAUTHORIZED: 'You do not have access to this resource. Please check your credentials.',
  UNPROCESSABLE_ENTITY: 'Invalid argument provided.',
  ID_MISMATCH: 'The ID provided in the path parameter does not match the ID in the request body.',
  INVALID_PROPERTY_VALUE: 'Invalid value provided in request body.',
  UNKNOWN_PROPERTY: 'An unknown property has been provided in the request body.',
  CANNOT_EXCHANGE_OAUTH_CODE: 'Unable to exchange the OAuth code for an access token.',
  CANNOT_CONNECT_AUTH0_API: 'There was an issue connecting to the Auth0 API.',
  CANNOT_AUTHENTICATE_AUTH0_API: 'There was an issue authenticating with the Auth0 API.',
  PASSWORD_AUTH_FAILED: 'Invalid username or password.',
  PASSWORD_AUTH_NOT_ALLOWED: 'Password authentication is not allowed.',
  SESSION_USER_NOT_IN_SYSTEM: 'The user ID in the session token is invalid.',
  CANNOT_PARSE_AUTH0_JWT: 'Unable to parse Auth0 JWT. The JWT could be malformed or incorrect.',
  NO_VALID_REFRESH_TOKENS: 'Authentication refresh token invalid or not found.',
  CANNOT_ENCRYPT_SESSION: 'Unable to encrypt session.',
  CANNOT_DECRYPT_SESSION: 'Unable to decrypt session.',
  CANNOT_CREATE_USER: 'Unable to create user.',
  CANNOT_CREATE_JWT: 'Cannot create JWT for user.',
  ILLEGAL_KEYPAIR_STATE: 'Illegal keypair state. The keypair must be in the {{keyPairId}} state to proceed.',
  UNSUPPORTED_KEYPAIR_PLATFORM: 'The provided keypair platform is not supported.',
  SIGNING_SERVICE_DISABLED: 'The signing service is disabled in this environment.',
  KEYPAIR_CONFLICT: 'The provided keypair already exists.',
  KEYPAIR_NOT_FOUND: 'Keypair not found. Please provide a valid keypair.',
  KEYPAIR_ASSOCIATED_TO_SIGNER: 'The keypair is currently associated to a signer and cannot be deactivated.',
  ROLE_ROLEID_CONFLICT: 'A role with the same ID already exists.',
  ROLE_PERMISSIONS_CONFLICT: 'A role with the same set of permissions already exists.',
  ROLE_NOT_FOUND: 'Role not found. Please provide a valid role.',
  ROLE_ASSOCIATED_TO_USERS: 'There are still users associated to this role. Please disassociate the users before attempting to delete it.',
  ALTERNATE_ROLE_NEEDED: 'You must provide an alternative role in order to delete the current one.',
  ADMIN_ROLE_CANNOT_BE_DELETED: 'The System Admin role cannot be deleted.',
  SOME_ROLES_NOT_FOUND: 'The required roles were not found.',
  TENANT_NOT_FOUND: 'Tenant not found. Please provide a valid tenant.',
  TENANT_TENANTID_CONFLICT: 'A tenant with the same ID already exists',
  TENANT_NAME_CONFLICT: 'A tenant with the same name already exists.',
  ILLEGAL_INDIVIDUAL_FREEZE_WORKITEM: 'Illegal individual freeze request. Please check the state of the account.',
  ILLEGAL_INDIVIDUAL_UNFREEZE_WORKITEM: 'Illegal individual unfreeze request. Please check the state of the account.',
  ILLEGAL_GLOBAL_FREEZE_WORKITEM: 'Illegal global freeze request. Please check the state of the Issuer account',
  ILLEGAL_GLOBAL_UNFREEZE_WORKITEM: 'Illegal global unfreeze request. Please check the state of the Issuer account.',
  ILLEGAL_WORKITEM_STATE: 'Illegal WorkItem state. The WorkItem must be in the {{expectedStatuses}} state to proceed.',
  INSUFFICIENT_FUNDS: 'Source account does not have sufficient funds.',
  INVALID_SIGNER: `Invalid signer. The user is not part of the account's signers list.`,
  NO_TRUSTLINE_TO_ISSUER: 'A Trust Line between the account and the Issuer cannot be found.',
  WORKITEM_WORKITEMID_CONFLICT: 'A WorkItem with the same ID already exists.',
  WORKITEM_NOT_FOUND: 'The WorkItem you provided cannot be found.',
  WORKITEM_SIGNATURE_CONFLICT: 'The WorkItem has already been signed.',
  WORKITEM_TRANSACTION_NOT_FOUND: 'WorkItem transaction cannot be found.',
  WORKITEM_TRANSACTION_SIGNATURE_NOT_FOUND: 'The WorkItem transaction signature cannot be found.',
  EXTERNAL_ACCOUNT_ACCOUNTID_CONFLICT: 'An ExternalAccount with that address already exists.',
  EXTERNAL_ACCOUNT_ADDRESS_DEST_TAG_CONFLICT: 'An ExternalAccount with that destination tag already exists.',
  EXTERNAL_ACCOUNT_NAME_CONFLICT: 'An ExternalAccount with that name already exists.',
  EXTERNAL_ACCOUNT_CORRELATIONID_CONFLICT: 'An ExternalAccount with that corelation ID already exists.',
  EXTERNAL_ACCOUNT_NOT_FOUND: 'That External Account does not exist in the system.',
  ACCOUNT_EDIT_EDITID_CONFLICT: 'An Account Edit with that ID already exists in the system.',
  ACCOUNT_EDIT_NOT_FOUND: 'That AccountEdit ID was not found in the system.',
  ACTIVE_ACCOUNT_EDIT_BLOCKS_NEW_WORKITEMS: 'There is a pending account edit on the account which blocks the creation of new transactions.',
  NEW_NAME_MUST_DIFFER_FROM_OLD: 'The edited account name cannot be the same as the original.',
  NEITHER_SIGNERS_NOR_QUORUM_CHANGES: 'The edited account cannot have the same signer settings as the original.',
  NEW_SIGNERS_SHOULD_BE_MEMBERS: 'Users cannot be added as signers to an account unless they are added as members first.',
  SOME_USERS_ARE_MEMBERS_ALREADY: 'Some of the users added were members of the account already.',
  SOME_USERS_LACK_ACCOUNT_PERMISSIONS: 'Cannot add some users to the account because they do not have the correct permissions.',
  CANNOT_REMOVE_SIGNERS: 'Cannot remove users from the account because they must be removed from the signers list on the account first.',
  USERS_NEED_TO_BE_MEMBERS: 'Cannot remove users from the account because they are not members.',
  ACCOUNT_NOT_FOUND: 'That account does not exist in the system.',
  ACCOUNT_LEDGER_INFO_ACCOUNTID_CONFLICT: 'An account with that ID already exists in the system.',
  ACCOUNT_LEDGER_INFO_ADDRESS_CONFLICT: 'An account with that address already exists in the system',
  ISSUER_ACCOUNT_NOT_FOUND: 'An Issuer Account must be created and configured before any other accounts can be created',
  SETUP_ACCOUNTID_CONFLICT: 'An account setup with that ID already exists in the system',
  SETUP_NAME_CONFLICT: 'An account setup with that name already exists in the system',
  SETUP_NOT_FOUND: 'That account setup does not exist in the system',
  ACCOUNT_LEDGER_INFO_MISSING: 'Cannot gather the ledger information required for account setup',
  NON_ISSUER_USE_AUTH_TRUSTLINES: 'Only the Issuer Account can determine if Authorized Trust Lines are required',
  ISSUER_MISSING_TICK_SIZE: 'A tick size must be set on the Issuer Account',
  SETUP_INVALID_TICK_SIZE: 'The tick size set must be within the range 3-15',
  SETUP_ISSUER_ACCOUNTID_MISSING: 'An Issuer Account ID is required for account setup',
  SETUP_ISSUER_ACCOUNTID_NOT_EMPTY: 'The Issuer account ID is generated internally by the API. Please leave this value empty',
  SETUP_MISSING_SIGNERS: 'A signers list is required for account setup',
  SETUP_TOO_MANY_SIGNERS: 'The number of signers on an account cannot exceed 8',
  SETUP_CURRENCY_XRP_NOT_ALLOWED: 'The currency code XRP is not valid, please choose a different one',
  SETUP_FLAG_ALREADY_SET: 'This flag has already been set on ledger',
  SETUP_SIGNER_LIST_ALREADY_EXISTS: 'The signer list has already been set on ledger',
  SETUP_TRUSTLINE_ALREADY_EXISTS: 'A Trust Line already exists for this account on ledger',
  SETUP_TICK_SIZE_ALREADY_EXISTS: 'The tick size has already been set on ledger',
  SETUP_STEP_INVALID: 'Cannot move to next setup step without the name of the step set',
  SETUP_NAME_EMPTY: 'The account name cannot be empty',
  ISSUER_ALREADY_EXISTS: 'An Issuer Account already exists',
  ACCOUNT_EXISTS_ON_LEDGER: 'This account address already exists on ledger',
  SETUP_STEP_NOT_FOUND: 'The setup step requested does not exist in the system',
  SETUP_STEP_SHOULD_HAVE_ONE_TRANSACTION: 'Setup step cannot have more than one workitem associated',
  SETUP_STEP_SHOULD_HAVE_ONE_SIGNATURE: 'Only one signature should be added to each setup step',
  SETUP_STEP_HAS_NO_PENDING_WORKITEM: 'Cannot find pending setup step to submit',
  CUSTODIAL_KEYS_DISABLED: 'This key type is disabled',
  SETUP_INVALID_ACCOUNT_TYPE: 'This account type does not require an account setup.',
  SETUP_INVALID_SIGNERS: 'Signers added to this account must have the can sign permission on {{accountType}}',
  USER_USERNAME_CONFLICT: 'A user with that email already exists',
  USER_USERID_CONFLICT: 'A user with that ID already exists',
  USER_SELF_DEACTIVATION: 'Users cannot deactivate themselves',
  USER_ACCOUNT_SIGNER: 'User is a signer on this account',
  USER_ACCOUNT_SETUP_SIGNER: 'This user is a signer on the account',
  LAST_ADMIN_DEACTIVATION: 'System Admin cannot be deleted because they are the only System Admin user in the system',
  USER_USERNAME_NOT_FOUND: 'Cannot find {{username}} in the system',
  USER_USERID_NOT_FOUND: 'Cannot find {{userId}} in the system',
  USER_CANNOT_BE_ADMIN: 'Non-admins users cannot be changed to an admin in the system',
  ADMIN_CANNOT_BE_NON_ADMIN: 'Admin users in the system cannot be changed to non-admins',
  USER_CANNOT_HAVE_MULTIPLE_ROLES: 'Each user in the system can only have one role',
  MACHINE_USERS_FEATURE_UNAVAILABLE: 'User does not have access to machine user feature',
}
