import { MachineUser, RequestError } from 'common'
import { UseQueryOptions, useQuery } from 'react-query'
import { getMachineUser, getMachineUsers } from './machine-users-api'

export enum MachineUserQueryKeys {
  Users = 'machine-users',
}

export const useMachineUsers = (
  opts?: UseQueryOptions<MachineUser[], RequestError>,
) =>
  useQuery<MachineUser[], RequestError>(
    [MachineUserQueryKeys.Users],
    getMachineUsers,
    opts,
  )

export const useMachineUser = (
  userId: string,
  opts?: UseQueryOptions<MachineUser, RequestError>,
) =>
  useQuery<MachineUser, RequestError>(
    [MachineUserQueryKeys.Users, userId],
    () => getMachineUser(userId),
    opts,
  )
