import {
  MachineUser,
  MachineUserApiKey,
  MachineUserResponse,
  machineUserApiKeySchema,
  machineUserResponseSchema,
  safeParseApiResponse,
} from 'common'
import { requestClient } from '../request-client'

export type MachineUserRequest = {
  userId: string
  username: string
  nickname: string
  publicKey?: string
}

export const getMachineUsers = (): Promise<MachineUser[]> =>
  requestClient
    .get(`/machine-users`)
    .then(safeParseApiResponse(machineUserResponseSchema.array()))
    .then((results) =>
      results.map((result: MachineUserResponse) => result.user),
    )

export const getMachineUser = (userId: string): Promise<MachineUser> =>
  requestClient
    .get(`/machine-users/${userId}`)
    .then(safeParseApiResponse(machineUserResponseSchema))
    .then((result: MachineUserResponse) => result.user)

export const getMachineUserApiKey = ({
  userId,
}: Pick<MachineUserRequest, 'userId'>): Promise<MachineUserApiKey> =>
  requestClient
    .post(`/machine-users/${userId}/client-secret`)
    .then(safeParseApiResponse(machineUserApiKeySchema))

export const regenerateMachineUserApiKey = ({
  userId,
}: Pick<MachineUserRequest, 'userId'>): Promise<MachineUserApiKey> =>
  requestClient.post(`/machine-users/${userId}/rotate-client-secret`)

export type UpdateMachineUserPublicKeyRequest = Pick<
  MachineUserRequest,
  'userId'
> & { publicKey: string }

export const updateMachineUserPublicKey = ({
  userId,
  publicKey,
}: UpdateMachineUserPublicKeyRequest): Promise<MachineUserApiKey> =>
  requestClient.put(`/machine-users/${userId}/public-key`, publicKey, {
    headers: { 'Content-Type': 'application/json' },
  })

/**
 * Create a machine user
 */
export const createMachineUser = ({ userId, ...payload }: MachineUserRequest) =>
  requestClient
    .put(`/machine-users/${userId}`, payload)
    .then(safeParseApiResponse(machineUserResponseSchema))
