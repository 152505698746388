import { z } from 'zod'
import { userSchema } from '../users'

export enum MachineUserStatus {
  READY = 'READY', // the client secret has been viewed and the user can be used to authenticate.
  FAILED = 'FAILED', // some unrecoverable failure has prevented the user from being created successfully.
  PENDING = 'PENDING', // initial state, which implies we're waiting on activity in the OAuth provider.
  PASSWORD_READY = 'PASSWORD_READY', // the client secret in the OAuth provider is available to be viewed once.
}

export const machineUserApiKeySchema = z.object({
  clientSecret: z.string(),
})

export type MachineUserApiKey = z.infer<typeof machineUserApiKeySchema>

export const machineUserSchema = userSchema.extend({
  machineUserDetails: z.object({
    clientId: z.string(),
    clientSecretViewedAt: z.string(),
    clientSecretUpdatedAt: z.string(),
    machineUserStatus: z.nativeEnum(MachineUserStatus),
    publicKey: z.string(),
    updatedAt: z.string(),
    createdAt: z.string(),
  }),
})

export type MachineUser = z.infer<typeof machineUserSchema>

export const machineUserResponseSchema = z.object({
  user: machineUserSchema,
})

export type MachineUserResponse = z.infer<typeof machineUserResponseSchema>
