import { FreezeType, WorkItemType } from 'common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useFreezeDescription = (freezeType: FreezeType) => {
  const { t } = useTranslation(['transactions'])

  return useMemo(
    () =>
      ({
        [WorkItemType.GlobalFreeze]: {
          docsHref: 'https://xrpl.org/freezes.html#global-freeze',
          details: t('transactions:Global-Freeze-Details'),
          description: t('transactions:global-freeze-details-text'),
        },
        [WorkItemType.GlobalUnfreeze]: {
          docsHref: 'https://xrpl.org/freezes.html#global-freeze',
          details: t('transactions:Global-Unfreeze-Details'),
          description: t('transactions:global-unfreeze-details-text'),
        },
        [WorkItemType.IndividualFreeze]: {
          docsHref: 'https://xrpl.org/freezes.html#individual-freeze',
          details: t('transactions:Freeze-Details'),
          description: t('transactions:freeze-details-text'),
        },
        [WorkItemType.IndividualUnfreeze]: {
          docsHref: 'https://xrpl.org/freezes.html#individual-freeze',
          details: t('transactions:Unfreeze-Details'),
          description: t('transactions:unfreeze-details-text'),
        },
        [WorkItemType.IndividualFreezeExternal]: {
          docsHref: 'https://xrpl.org/freezes.html#individual-freeze',
          details: t('transactions:Freeze-Details'),
          description: t('transactions:freeze-details-text'),
        },
        [WorkItemType.IndividualUnfreezeExternal]: {
          docsHref: 'https://xrpl.org/freezes.html#individual-freeze',
          details: t('transactions:Unfreeze-Details'),
          description: t('transactions:unfreeze-details-text'),
        },
      }[freezeType]),
    [freezeType],
  )
}
