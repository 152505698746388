import { CurrencyCode } from 'common'
import { convertHexToString, convertStringToHex } from 'xrpl'

// https://xrpl.org/currency-formats.html#nonstandard-currency-codes
const NON_STANDARD_CODE_LENGTH = 40

/**
 * Convert an hexadecimal value to readable string.
 *
 * @param hex The hexadecimal to convert.
 * @returns A human readable string.
 */
export const hexToString = (hex: string) =>
  // eslint-disable-next-line no-control-regex
  convertHexToString(hex).replace(/\u0000+$/, '')

/**
 * Converts a human readable currency code to hexadecimal.
 * If the currency has 3 characters (XRP, EUR, USD...) then return immediately this currency code.
 * If the currency has more than 3 characters, then encode it to the XRP ledger format.
 * Example: USDM will become 5553444D00000000000000000000000000000000
 *
 * @param currencyCode The currency code to potentially encode to the XRP ledger format.
 * @returns A {@link string}
 */
export const convertCurrencyCodeToHex = (
  currencyCode: CurrencyCode,
): CurrencyCode =>
  currencyCode.length > 3
    ? convertStringToHex(currencyCode).padEnd(NON_STANDARD_CODE_LENGTH, '0')
    : currencyCode

/**
 * Helper to correctly display the currency code if its length is more than 3.
 * Example: 5553444D00000000000000000000000000000000 will become USDM
 *
 * @param currencyCode The currency code to potentially format correctly.
 * @returns A {@link String} representing the currency code readable by a human.
 */
export const convertHexCurrencyCodeToString = (currencyCode: string) =>
  currencyCode.length === NON_STANDARD_CODE_LENGTH
    ? hexToString(currencyCode)
    : currencyCode
