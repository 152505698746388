export enum WS_QUEUES {
  ACCOUNT_EDITS = '/user/queue/account-edits',
  ACCOUNT_SETUPS = '/user/queue/account-setups',
  CBDC_ACCOUNTS = '/user/queue/cbdc-accounts',
  GLOBAL_FREEZE = '/user/queue/global-freeze',
  /** role create, delete, update */
  ROLES = '/user/queue/roles',
  /** User events, key pair events */
  USERS = '/user/queue/users',
  WORKITEMS = '/user/queue/work-items',
  EXTERNAL_ACCOUNTS = '/user/queue/external-accounts',
  BALANCES = '/user/queue/balances',
}
