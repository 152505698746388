import { isAccountExternal } from '@/shared/helpers'
import { LookupAccountResult } from '@/shared/hooks'
import {
  AccountFreezeType,
  FreezeAction,
  FreezeType,
  WorkItem,
  WorkItemStatus,
  WorkItemTransactionSignatureStatus,
  WorkItemType,
} from 'common'
import { MultiSignRequestProps } from './work-items-api.types'

export const getSignatureApiPath = (
  { signatureId, transactionId, workItemId }: MultiSignRequestProps,
  isCustodial?: boolean,
) =>
  `/work-items/${workItemId}/transactions/${transactionId}/signatures/${signatureId}/${
    isCustodial ? '' : 'signature'
  }`

/** Mapper for updating cancelled status */
export const syncCancelStatus = (workItemId: string) => (item: WorkItem) => {
  if (item.id === workItemId) {
    return { ...item, status: WorkItemStatus.Cancelled }
  }
  return item
}

type SyncCustodialSignatureProps = Pick<
  MultiSignRequestProps,
  'signatureId' | 'transactionId' | 'workItemId'
>
/** Mapper to update signature state in cache */
export const syncCustodialSignature =
  ({ signatureId, transactionId, workItemId }: SyncCustodialSignatureProps) =>
  (workItem: WorkItem) => {
    if (workItem.id === workItemId) {
      // New list of transactions
      const newTransactions = workItem.transactions.map((transaction) => {
        if (transaction.id === transactionId) {
          // New list of signatures
          const newSignatures = transaction.signatures.map((signature) => {
            if (signature.id === signatureId) {
              return {
                ...signature,
                status: WorkItemTransactionSignatureStatus.SentForSigning,
              }
            }
            return signature
          })
          return { ...transaction, signatures: newSignatures }
        }
        return transaction
      })
      return { ...workItem, transactions: newTransactions }
    }
    return workItem
  }

/** Mapper to add signature to cache */
export const syncMultiSignature =
  (
    workItemId: string,
    signature: string,
    signatureId: string,
    status = WorkItemTransactionSignatureStatus.Signed,
  ) =>
  (item: WorkItem) => {
    if (item.id === workItemId) {
      const transaction = item.transactions[0]
      return {
        ...item,
        transactions: [
          {
            ...transaction,
            signatures: transaction.signatures.map((s) =>
              s.id === signatureId
                ? {
                    ...s,
                    status,
                    signature,
                  }
                : s,
            ),
          },
        ],
      }
    }
    return item
  }

/** Updater for the status of a transaction if successfully submitted */
export const syncSubmissionStatus =
  (workItemId: string) => (item: WorkItem) => {
    if (item.id === workItemId) {
      return { ...item, status: WorkItemStatus.Submitted }
    }
    return item
  }

export const getWorkItemFromFreezeAction = (
  action: FreezeAction,
  freezeType: AccountFreezeType,
  account?: LookupAccountResult,
) => {
  // mapping action and setting variables to a WorkItemType
  // because ts doesn't let you do dynamic string-based access to enum properties
  const TYPES_MAP: Record<
    AccountFreezeType,
    Record<FreezeAction, FreezeType>
  > = {
    [AccountFreezeType.Global]: {
      freeze: WorkItemType.GlobalFreeze,
      unfreeze: WorkItemType.GlobalUnfreeze,
    },
    [AccountFreezeType.Individual]: {
      freeze: WorkItemType.IndividualFreeze,
      unfreeze: WorkItemType.IndividualUnfreeze,
    },
    [AccountFreezeType.IndividualExternal]: {
      freeze: WorkItemType.IndividualFreezeExternal,
      unfreeze: WorkItemType.IndividualUnfreezeExternal,
    },
  }

  const type =
    freezeType === AccountFreezeType.Individual &&
    isAccountExternal(account?.accountType)
      ? AccountFreezeType.IndividualExternal
      : freezeType

  return TYPES_MAP[type][action]
}
