import { z } from 'zod'

export const tenantSchema = z.object({
  clientCode: z.string(),
  explorerUrl: z.string(),
  faucetUrl: z.string(),
  isMetacoDomainCreated: z.boolean(),
  machineUsersEnabled: z.boolean(),
  machineUsersFeatureEnabled: z.boolean(),
  metacoInstanceId: z.string().optional(),
  metacoVaultId: z.string().optional(),
  name: z.string(),
  rippledUrl: z.string(),
  tenantId: z.string(),
})

export type Tenant = z.infer<typeof tenantSchema>
