export default {
  Keypairs: 'Paires de clés',
  'Keypair-Name': 'Nom de la paire de clés',
  'Keypair-Type': 'Type de la paire de clés',
  'Edit-User-Details': "Modifier les détails de l'utilisateur",
  'Add-Accounts': 'Ajouter des comptes',
  'Add-Account': 'Ajouter un compte',
  'Add-Keypair': 'Ajouter une paire de clés',
  'Public-Key': 'Clé publique',
  'Account-Lookup': 'Recherche de compte',
  'App-User': "Utilisateur de l'application",
  Signing: 'Signature',
  'machine-user': 'Utilisateur de la machine',
  'machine-user.initiator': 'Utilisateur de la machine - Initiateur',
  'machine-user.signer': 'Utilisateur de la machine - Signataire',
  'machine-user.description': "Un utilisateur de la machine a la possibilité d'accéder en toute sécurité au système backend de l'émetteur en utilisant une clé API secrète unique.",
  'machine-user.can-sign': 'Peut signer pour des transactions',
  'machine-user.cannot-sign': 'Impossible de signer pour les transactions',
  'New-User': 'Nouvel utilisateur',
  'User-Details': "Type d'utilisateur",
  'User-Type': "Type d'utilisateur",
  'Users-Added': 'Utilisateurs ajoutés',
  'Users-Removed': 'Utilisateurs supprimés',
  'User-List': 'Liste des utilisateurs',
  'deactivate-keypair.title': 'Désactiver la paire de clés?',
  'deactivate-keypair.confirmation': 'Êtes-vous sûr de vouloir désactiver cette paire de clés? Cette action ne peut pas être annulée.',
  'deactivate-keypair.success': 'La paire de clefs a été désactivée avec succès',
  'deactivate-keypair.error': 'Échec de la désactivation de la paire de clés',
  'deactivate-keypair.forbidden.title': 'Vous ne pouvez pas désactiver cette paire de clefs',
  'deactivate-keypair.forbidden.body': 'Cette paire de clefs est utilisée pour signer des transactions pour $t(plurals:accounts, {"count": {{count}}}). Pour désactiver cette paire de clefs, retirez-là de ces $t(plurals:accounts, {"count": {{count}}}): {{names}}',
  Role: 'Rôle',
  'Key-Type': 'Type de clé',
  'machine-user.api-key-card.api-credentials': "Informations d'identification API",
  'machine-user.api-key-card.api-secret-key': 'Clé secrète API',
  'machine-user.api-key-card.view-secret-key': 'Afficher la clé',
  'machine-user.api-key-card.regenerate-secret-key': 'Régénérer la clé',
  'machine-user.api-key-card.copy-secret-key': 'Copier la clé',
  'machine-user.api-key-card.generating-secret-key': 'Votre clé secrète est en cours de génération...',
  'machine-user.api-key-card.copy-secret-key-instructions': "Assurez-vous de copier la clé secrète ci-dessous, car elle ne peut être consultée qu'une seule fois.",
  'machine-user.api-key-card.copy-secret-key-description': 'Si vous avez perdu ou oublié cette clé, vous pouvez la régénérer ci-dessous. Sachez que tous les scripts ou applications utilisant cette clé devront être mis à jour.',
  'machine-user.api-key-card.key-generated-on': 'Clé générée le {{date}}',
  'machine-user.api-key-card.key-viewed-on': 'Clé consultée le {{date}}',
  'machine-user.regenerate-key-modal.title': 'Régénérer la clé?',
  'machine-user.regenerate-key-modal.message': "Une fois que vous aurez régénéré votre clé secrète API, l'ancienne clé ne fonctionnera plus pour authentifier votre utilisateur.",
  'machine-user.regenerate-key-modal.error': "Une erreur s'est produite lors de la régénération de la clé API",
  'machine-user.delete-user.message': "Si vous désactivez cet utilisateur, la clé secrète ne fonctionnera plus pour s'authentifier.",
  'machine-user.delete-user-with-accounts.message': "Si vous désactivez cet utilisateur, la clé secrète ne fonctionnera plus pour s'authentifier et l'utilisateur sera supprimé de <Strong>$t(plurals:Accounts, {'count': {{numAccounts}}})</Strong>.",
  'Signer-Accounts': 'Comptes signataires',
  'Total-Accounts': 'Total des comptes',
  'loading-users': 'Chargement des utilisateurs...',
  'loading-user': "Chargement de l'utilisateur...",
  'no-users': 'Aucun utilisateur trouvé',
  'no-user': "Aucun utilisateur trouvé avec l'identifiant : {{userId}}",
  'keypairs.description': 'Cet utilisateur a un rôle qui nécessite une ou plusieurs paires de clés pour signer les transactions.',
  'keypairs.placeholder': "Disponible après la création de l'utilisateur",
  'keypairs.creating': "Génération d'adresse",
  'create-keypairs.duplicate': 'Cette paire de clés existe déjà. Veuillez réessayer avec une clé différente.',
  'create-keypairs.error': "Une erreur s'est produite lors de la création de la paire de clés. Veuillez réessayer.",
  'connect-ledger-nano.error': 'Impossible de se connecter à votre appareil Ledger',
  'connect-ledger-nano.found': 'Compte {{ledgerName}} disponible récupéré',
  'connect-ledger-nano.loading': 'Connexion à Ledger Nano en cours',
  'connect-ledger-nano.description': 'Pour finaliser la configuration du compte, commencez par brancher et connecter votre Ledger Nano.',
  'update-role.signer-error': "Cet utilisateur est un signataire sur $t(plurals:accounts, count). Pour modifier leur rôle, vous devez d'abord les supprimer en tant que signataires de tous les comptes.",
  'You-Cannot-Change-Their-Role': 'Vous ne pouvez pas changer leur rôle',
  'add-user-account.instructions': 'Recherchez et sélectionnez le(s) compte(s) que vous souhaitez ajouter à {{user}}, et pour lesquels {{user}} dispose des droits appropriés.',
  'add-user-account.restrictions': "La recherche de compte affichera uniquement les comptes que l'utilisateur est autorisé à consulter.",
  'add-user-account.success': '$t(plurals:accounts, {"count": {{numAdded}}}) ajouté(s) avec succès',
  'remove-user-account.title': 'Supprimer $t(plurals:Accounts, {"count": {{numAccounts}}}) ?',
  'remove-user-account.prompt': 'Êtes-vous sûr de vouloir supprimer {{user}} du $t(plurals:accounts, {"count": {{count}}}): {{accounts}} ?',
  'remove-user-account.success': '$t(plurals:Accounts, {"count": {{numRemoved}}}) supprimé(s) avec succès',
  About: 'À propos',
  'Full-Name': 'Nom complet',
  'User-Lookup': "Recherche d'utilisateur",
  'Date-Created': 'Date de création',
  'Create-User': 'Créer un utilisateur',
  'create-user.name-already-used': 'Nom déjà utilisé',
  'create-user.email-already-used': 'Email déjà utilisé',
  'create-user.machine-user.bad-public-key': 'La clé publique doit être au format PEM',
  'create-user.error': "Une erreur s'est produite lors de la création de l'utilisateur.",
  'create-user.success': 'Utilisateur créé avec succès',
  'create-keypair.error': 'Échec de la création de $t(plurals:keypairs, {"count": {{count}}})',
  'create-keypair.success': '$t(plurals:keypairs, {"count": {{count}}}) ajoutée(s) avec succès',
  'rename-keypair.wrong-id': "Identifiant de paire de clés {{oldItemIndex}} incorrect. La paire de clés n'a pas pu être renommée.",
  'add-account-users.instructions': 'Recherchez et sélectionnez le(s) utilisateur(s) que vous souhaitez ajouter à ce compte.',
  'add-account-users.success': "Une erreur s'est produite lors de l'ajout des utilisateurs.",
  'add-account-users.error': '{{count}} utilisateurs ajoutés avec succès au compte.',
  'edit-user.instructions': "Utilisez les champs ci-dessous pour modifier le nom de l'utilisateur.",
  'edit-user-role.instructions': 'Recherchez et sélectionnez le nouveau rôle que vous souhaitez attribuer à cet utilisateur.',
  'edit-user-role.warning': "<Strong>Ce rôle supprimera l'utilisateur de $t(plurals:accounts, {'count' :{{totalImpactedAccounts}}}).</Strong> Si vous sélectionnez ce rôle et l'attribuez à l'utilisateur, celui-ci sera supprimé de tous les comptes en conflit avec les autorisations du nouveau rôle.",
  'edit-user-role.missing-keypair': `<Strong>Ce rôle nécessite une paire de clés.</Strong> Le rôle sélectionné dispose de l'autorisation « peut signer » activée. Si vous sélectionnez ce rôle et l'attribuez à l'utilisateur, vous devrez lui attribuer une paire de clés avant de pouvoir être sélectionné en tant que signataire d'un compte.`,
  'edit-user-role.success': 'Rôle mis à jour avec succès',
  'Change-User-Role': "Changer le rôle de l'utilisateur",
  'edit-user-role.error': "Le rôle n'a pas pu être mis à jour",
  'rename-keypair.title': 'Renommer la paire de clés',
  'rename-keypair.instructions': 'Pour renommer cette paire de clés, saisissez un nouveau nom ci-dessous.',
  'add-keypair.success': 'La paire de clés a été ajoutée avec succès',
  'add-keypair.instructions': 'Ajoutez une paire de clés pour que cet utilisateur signe les transactions.',
  'update-user-info.error': "Une erreur s'est produite lors de la mise à jour des informations de l'utilisateur.",
  'update-user-info.success': "L'utilisateur a été mis à jour avec succès.",
  'deactivate-user.cta': "Désactiver l'utilisateur",
  'deactivate-user.allowed': "Désactiver l'utilisateur?",
  'deactivate-user.confirm': 'Êtes-vous sûr de vouloir désactiver cet utilisateur? Cette action ne peut pas être annulée.',
  'deactivate-user.will-lose-accounts': 'Si vous désactivez cet utilisateur, il sera supprimé de <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>. Vous ne pouvez pas annuler cette action.',
  'deactivate-user.denied': 'Vous ne pouvez pas désactiver cet utilisateur',
  'deactivate-user.configured-signer-warning': 'Pour désactiver cet utilisateur, vous devez d\'abord le supprimer en tant que signataire sur <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong>.',
  'deactivate-user.unconfigured-signer-warning': 'Pour désactiver cet utilisateur, vous devez d\'abord le supprimer en tant que signataire sur <Strong>$t(plurals:Accounts, {"count": {{numSetups}}}) en attente de configuration</Strong>.',
  'deactivate-user.configured-unconfigured-signer-warning': 'Pour désactiver cet utilisateur, vous devez d\'abord le supprimer en tant que signataire sur <Strong>$t(plurals:Accounts, {"count": {{numAccounts}}})</Strong> et <Strong>$t(plurals:Accounts , {"count": {{numSetups}}}) configuration en attente</Strong>.',
  'deactivate-user.error': "Une erreur s'est produite lors de la désactivation de l'utilisateur. Veuillez réessayer.",
  'deactivate-user.success': "L'utilisateur a été désactivé avec succès",
  'cannot-deactivate-admin': 'Vous Ne Pouvez Pas Vous Désactiver',
  'deactivate-admin-message-one': 'Vous êtes le seul utilisateur administrateur du système et ne pouvez pas être désactivé.',
  'deactivate-admin-message-two': "Pour désactiver cet administrateur, vous devez d'abord créer un autre administrateur.",
  'no-active-keypair': "Cet utilisateur n'a pas de paire de clés active. Ils doivent en recevoir un avant de pouvoir être sélectionnés en tant que signataires d'un compte.",
  'public-key-banner.title': 'Vous devez saisir une clé publique.',
  'machine-user.public-key-card.title': 'Clé publique API',
  'machine-user.public-key-card.view-api-key.title': 'Ajouter une clé publique API',
  'machine-user.public-key-card.edit-api-key.title': "Modifier la clé publique de l'API",
  'machine-user.public-key-card.edit-api-key.error': "Une erreur s'est produite lors de la mise à jour de la clé publique",
  'machine-user.public-key-card.view-api-key.instructions': 'Collez une copie de votre clé publique ci-dessous. <Link>En savoir plus</Link>',
  'machine-user.public-key-card.edit-api-key.label': 'Entrez la clé ici',
  'machine-user.public-key-card.edit-key': 'Modifier la clé',
  'machine-user.public-key-card.key-added-on': 'Clé ajoutée le {{date}}',
  'machine-user.public-key-card.key-updated-on': 'Clé mise à jour le {{date}}',
}
