import {
  DEFAULT_BASE_RESERVE,
  XRP_FUNDING_AMOUNT,
} from '@/pages/wallet/constants'
import {
  getFormattedTxnDate,
  getTxnCurrency,
  getTxnDestinationAmount,
} from '@/shared/helpers'
import { useAccountMetadataLookup } from '@/shared/hooks'
import { Controls, prepareQueries } from '@ripple/design-system'
import {
  MAX_API_PAGE_SIZE,
  TransactionDirection,
  createRequestClient,
  useXRPLContext,
} from 'common'
import { useCallback } from 'react'
import { useQueries, useQuery } from 'react-query'
import { Client, TransactionStream } from 'xrpl'
import { useExternalAccounts } from '../accounts'
import {
  KnownXrplErrorCodes,
  accountTxnMatchesXRPLFilters,
  isXRPLErrorCode,
  isXRPLErrorResponse,
  txnMatchesXRPLFilters,
} from './xrpl.helpers'
import {
  AccountIssuedTokens,
  PaymentTxFilterProps,
  XRPLPaymentTransaction,
  XrplQueryKeys,
} from './xrpl.types'

export const useServerInfo = () => {
  const { client } = useXRPLContext()

  return useQuery(XrplQueryKeys.ServerInfo, () =>
    client.request({ command: XrplQueryKeys.ServerInfo }),
  )
}

export type AccountRedemptionPayment = {
  hash: string
  amount: string
  currency: string
  lastUpdated: string
  direction: TransactionDirection.Received
  sourceAccount: {
    name: string
    address: string
  }
  destinationAccount: {
    name: string
    address: string
  }
}

const useToAccountRedemptionPayment = () => {
  const accountLookup = useAccountMetadataLookup()

  return useCallback(
    (data: XRPLPaymentTransaction) =>
      ({
        hash: data.hash,
        sourceAccount: {
          name:
            accountLookup.lookupAccountByAddress(data.Account)?.accountName ??
            data.Account,
          address: data.Account,
        },
        destinationAccount: {
          name:
            accountLookup.lookupAccountByAddress(data.Destination)
              ?.accountName ?? data.Destination,
          address: data.Destination,
        },
        direction: TransactionDirection.Received,
        lastUpdated: getFormattedTxnDate(data.date),
        amount: getTxnDestinationAmount(data.Amount),
        currency: getTxnCurrency(data.Amount),
      } as AccountRedemptionPayment),
    [accountLookup.isLoading],
  )
}

export const useAccountRedemptionPayments = (address: string) => {
  const toAccountRedemptionPayment = useToAccountRedemptionPayment()

  const externalAccountsResponse = useExternalAccounts({
    pagination: { pageSize: MAX_API_PAGE_SIZE },
  } as Controls)

  const transactionsResponse = useAccountTransactions(address, {
    noXRP: true,
    includeTxnStatus: ['success'],
    paymentDirection: TransactionDirection.Received,
  })

  const externalAddresses = externalAccountsResponse.data?.content.map(
    (data) => data.address,
  )

  return prepareQueries(
    transactionsResponse.data
      ?.filter((tx) => externalAddresses?.includes(tx.Account))
      .map<AccountRedemptionPayment>(toAccountRedemptionPayment),
    transactionsResponse,
    externalAccountsResponse,
  )
}

export const useAccountRedemptionPayment = (txnHash: string) => {
  const toAccountRedemptionPayment = useToAccountRedemptionPayment()

  const transactionResponse = useTransaction(txnHash)

  return {
    ...transactionResponse,
    data:
      transactionResponse.data &&
      toAccountRedemptionPayment(transactionResponse.data),
  }
}

export const useTransaction = (
  txnHash: string,
  filters?: PaymentTxFilterProps,
) => {
  const { client } = useXRPLContext()

  return useQuery<XRPLPaymentTransaction | null>(
    [XrplQueryKeys.Transaction, txnHash, filters],
    () =>
      client
        .request({
          command: XrplQueryKeys.Transaction,
          transaction: txnHash,
        })
        .then((response) => {
          if (
            !response.result.validated ||
            !txnMatchesXRPLFilters(response.result, filters, txnHash)
          ) {
            return null
          }

          return response.result as unknown as XRPLPaymentTransaction
        })
        .catch((err) => {
          if (isXRPLErrorResponse(err)) {
            if (
              isXRPLErrorCode(err.data, KnownXrplErrorCodes.TransactionNotFound)
            ) {
              return null
            }
          }

          throw err
        }),
  )
}

export const useAccountTransactions = (
  address: string,
  filters?: PaymentTxFilterProps,
) => {
  const { client } = useXRPLContext()

  const empty = [] as XRPLPaymentTransaction[]

  return useQuery([XrplQueryKeys.AccountTransactions, address, filters], () =>
    client
      .request({
        command: XrplQueryKeys.AccountTransactions,
        account: address,
      })
      .then((response) =>
        response.result.validated
          ? (response.result.transactions
              .filter((tx) =>
                accountTxnMatchesXRPLFilters(tx, filters, address),
              )
              .map((transaction) => transaction.tx) as XRPLPaymentTransaction[])
          : empty,
      )
      .catch((err) => {
        if (isXRPLErrorResponse(err)) {
          if (isXRPLErrorCode(err.data, KnownXrplErrorCodes.AccountMalformed)) {
            return empty
          }
        }

        throw err
      }),
  )
}

export const useAccountBalance = (address: string) => {
  const { client } = useXRPLContext()

  const [serverInfoResponse, balanceResponse] = useQueries([
    {
      queryKey: [XrplQueryKeys.ServerInfo, address],
      queryFn: () => client.request({ command: XrplQueryKeys.ServerInfo }),
    },
    {
      queryKey: [XrplQueryKeys.AccountBalance, address],
      queryFn: () =>
        client.getXrpBalance(address).catch((err) => {
          if (isXRPLErrorResponse(err)) {
            if (
              isXRPLErrorCode(err.data, KnownXrplErrorCodes.AccountNotFound)
            ) {
              return 0
            }
          }

          throw err
        }),
    },
  ])

  let xrpBaseReserve = 0
  let balance = 0

  if (!balanceResponse.error) {
    xrpBaseReserve = +(
      serverInfoResponse.data?.result.info.validated_ledger?.reserve_base_xrp ??
      DEFAULT_BASE_RESERVE
    )
    balance = Math.max(
      +(balanceResponse.data ?? xrpBaseReserve) - xrpBaseReserve,
      0,
    )
  }

  return {
    balanceAvailableForSpending: balance,
    xrpBaseReserve,
    error: serverInfoResponse.error || balanceResponse.error,
    isError: serverInfoResponse.isError || balanceResponse.isError,
    isLoading: serverInfoResponse.isLoading || balanceResponse.isLoading,
    isSuccess: serverInfoResponse.isSuccess && balanceResponse.isSuccess,
    refetch: () => {
      serverInfoResponse.refetch()
      balanceResponse.refetch()
    },
  }
}

export const useAccountTrustlines = (address: string) => {
  const { client } = useXRPLContext()

  return useQuery([XrplQueryKeys.AccountTrustlines, address], () =>
    client
      .request({ command: XrplQueryKeys.AccountTrustlines, account: address })
      .then((response) => response.result.lines)
      .catch((err) => {
        if (isXRPLErrorResponse(err)) {
          if (isXRPLErrorCode(err.data, KnownXrplErrorCodes.AccountNotFound)) {
            return []
          }
        }

        throw err
      }),
  )
}

export const fundAccount = (
  client: Client,
  address: string,
  faucetHost: string,
  xrpAmount = XRP_FUNDING_AMOUNT,
) => {
  const response = createRequestClient({ baseURL: `https://${faucetHost}` })
    .post('/accounts', {
      destination: address,
      xrpAmount: `${xrpAmount}`,
    })
    .then(() => {
      client.connection.request({
        command: XrplQueryKeys.Subscribe,
        accounts: [address],
      })
    })
    .then(
      () =>
        new Promise<string>((resolve) => {
          client.connection.once('transaction', (event: TransactionStream) => {
            client.connection.request({
              command: XrplQueryKeys.Unsubscribe,
              accounts: [address],
            })
            return resolve(event.engine_result)
          })
        }),
    )

  return response
}

// This specific use case doesn't play nice with the useQuery pattern.
// This needs to behave more like a mutation, where we don't have the address
// until we try to submit. We lose out on caching, but that's not a big concern here.
export function useAccountIssuedTokensGetter() {
  const { client } = useXRPLContext()

  return (address: string) =>
    client
      .request({ command: XrplQueryKeys.GatewayBalances, account: address })
      .then((response) => response.result.obligations as AccountIssuedTokens)
      .catch((err) => {
        if (isXRPLErrorResponse(err)) {
          if (isXRPLErrorCode(err.data, KnownXrplErrorCodes.AccountNotFound)) {
            return {} as AccountIssuedTokens
          }
        }

        throw err
      })
}
