import {
  AccountEdit,
  AccountEditStatus,
  NewAccountEdit,
  NewAccountUsersEdit,
  PayloadSignature,
  RequestError,
  WorkItemStatus,
  WorkItemTransactionSignature,
  WorkItemType,
  accountEditSchema,
  getResponseData,
  invalidateQueryCache,
  queryClient,
  safeParseApiResponse,
  workItemTransactionSignatureSchema,
} from 'common'
import { useMutation } from 'react-query'
import { v4 } from 'uuid'
import { AccountsQueryKeys } from '../accounts'
import { requestClient } from '../request-client'
import { UserQueryKeys } from '../users'
import { syncCustodialSignature } from '../work-items'
import { ACCOUNT_EDITS_URL } from './account-edits.constants'
import { AccountEditQueryKeys } from './account-edits.queries'

/**
 * Hook to create a new "account edit" request (to update the account name or signer list for example).
 *
 * @returns A UseMutationResult.
 */
export const useCreateAccountEdit = () => {
  return useMutation<AccountEdit, RequestError, NewAccountEdit>((payload) =>
    requestClient.post(ACCOUNT_EDITS_URL, payload).then(getResponseData),
  )
}

/**
 * Hook to sign an account edit with a Ledger Nano or other non-custodial wallets.
 *
 * @param {string} accountEditId The account edit id.
 * @param {string} signatureId The signature id.
 * @param {string} transactionId The transaction id.
 * @returns A UseMutationResult.
 */
export const useAddAccountEditSignature = (
  accountEditId: string,
  signatureId: string,
  transactionId: string,
) => {
  return useMutation<
    WorkItemTransactionSignature,
    RequestError,
    PayloadSignature
  >(
    (payload) =>
      requestClient
        .put(
          `${ACCOUNT_EDITS_URL}/${accountEditId}/transactions/${transactionId}/signatures/${signatureId}/signature`,
          payload,
        )
        .then(safeParseApiResponse(workItemTransactionSignatureSchema)),
    {
      // Optimistic update for the signature status
      onSuccess: () => {
        const accountEdit = queryClient.getQueryData<AccountEdit>([
          AccountEditQueryKeys.FullEdit,
          accountEditId,
        ])
        if (!accountEdit) return

        const { workItem } = accountEdit
        if (workItem) {
          const updatedWorkItem = syncCustodialSignature({
            signatureId,
            transactionId,
            workItemId: workItem.id,
          })(workItem)

          queryClient.setQueryData<AccountEdit>(
            [AccountEditQueryKeys.FullEdit, accountEditId],
            () => {
              return {
                ...accountEdit,
                workItem: {
                  ...updatedWorkItem,
                  type: WorkItemType.SignerListSet,
                },
              }
            },
          )
        }
      },
    },
  )
}

/**
 * Hook to request an account edit signature (typically from the signing service).
 *
 * @param {string} accountEditId The account edit id.
 * @param {string} signatureId The signature id.
 * @param {string} transactionId The transaction id.
 * @returns A UseMutationResult.
 */
export const useRequestAccountEditSignature = (
  accountEditId: string,
  signatureId: string,
  transactionId: string,
) => {
  return useMutation<undefined, RequestError, undefined>(
    (payload) =>
      requestClient
        .post(
          `${ACCOUNT_EDITS_URL}/${accountEditId}/transactions/${transactionId}/signatures/${signatureId}`,
          {
            payload,
          },
        )
        .then(getResponseData),
    {
      // Optimistic update for the signature status
      onSuccess: () => {
        const accountEdit = queryClient.getQueryData<AccountEdit>([
          AccountEditQueryKeys.FullEdit,
          accountEditId,
        ])
        if (!accountEdit) return

        const { workItem } = accountEdit
        if (workItem) {
          const updatedWorkItem = syncCustodialSignature({
            signatureId,
            transactionId,
            workItemId: workItem.id,
          })(workItem)

          queryClient.setQueryData<AccountEdit>(
            [AccountEditQueryKeys.FullEdit, accountEditId],
            () => {
              return {
                ...accountEdit,
                workItem: {
                  ...updatedWorkItem,
                  type: WorkItemType.SignerListSet,
                },
              }
            },
          )
        }
      },
    },
  )
}

/**
 * Hook to submit an AccountEdit transaction to the XRP Ledger. This
 * is possible when the quorum is reached.
 *
 * @param {string} accountEditId The account edit ID.
 * @returns A UseMutationResult.
 */
export const useSubmitAccountEdit = (accountEditId: string) => {
  return useMutation<AccountEdit, RequestError, undefined>(
    () =>
      requestClient
        .post(`${ACCOUNT_EDITS_URL}/${accountEditId}`)
        .then(safeParseApiResponse(accountEditSchema)),
    {
      // Optimistic update for the workitem status
      onSuccess: (accountEdit) => {
        queryClient.setQueryData<AccountEdit>(
          [AccountEditQueryKeys.FullEdit, accountEditId],
          () => {
            return {
              ...accountEdit,
              status: AccountEditStatus.Complete,
              workItem: accountEdit.workItem
                ? {
                    ...accountEdit.workItem,
                    status: WorkItemStatus.Submitted,
                  }
                : undefined,
            }
          },
        )
      },
    },
  )
}

/**
 * Hook to cancel an account edit request.
 *
 * @param {string} accountEditId The account edit ID.
 * @returns A UseMutationResult
 */
export const useCancelAccountEdit = () => {
  return useMutation<AccountEdit, RequestError, string>(
    (accountEditId: string) =>
      requestClient
        .delete(`${ACCOUNT_EDITS_URL}/${accountEditId}`)
        .then(safeParseApiResponse(accountEditSchema)),
    {
      // Optimistic update for the workitem status
      onSuccess: (accountEdit) => {
        queryClient.setQueryData<AccountEdit>(
          [AccountEditQueryKeys.FullEdit, accountEdit.id],
          () => {
            return {
              ...accountEdit,
              status: AccountEditStatus.Cancelled,
              workItem: accountEdit.workItem
                ? {
                    ...accountEdit?.workItem,
                    status: WorkItemStatus.Cancelled,
                  }
                : undefined,
            }
          },
        )
      },
    },
  )
}

export type ManageAccountUsersPayload = NewAccountUsersEdit & {
  address: string
}

export const useManageAccountUsers = (action: 'add' | 'remove') => {
  return useMutation<AccountEdit, RequestError, ManageAccountUsersPayload>(
    ({ address, ...rest }) =>
      requestClient.post(`${ACCOUNT_EDITS_URL}/${v4()}/${action}-users`, rest),
    {
      onSuccess: (_, { address, userIds }) => {
        invalidateQueryCache(queryClient, [
          [AccountsQueryKeys.AccountDetails, address],
          ...userIds.map((userId) => [UserQueryKeys.FullUsers, userId]),
        ])
      },
    },
  )
}
