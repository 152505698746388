import { z } from 'zod'
import { workItemSchema } from '../workitems'
import { accountSetupStepTypeSchema } from './account-setup-step-type'

/**
 * Response from the GET "/account-setups/{accountId}/{pathName}" API.
 * https://github.com/xpring-eng/cbdc-backend/blob/new-account-setup/src/main/java/io/ripplex/cbdc/model/accountsetup/AccountSetupStep.java
 */

export const accountSetupStepSchema = z.union([
  z.null(),
  z.object({
    type: accountSetupStepTypeSchema,
    accountId: z.string(),
    pendingWorkItem: workItemSchema.optional(),
    completedWorkItem: workItemSchema.optional(),
    processingWorkItem: workItemSchema.optional(),
    failedWorkItems: workItemSchema.array().default([]),
  }),
])

export type AccountSetupStep = z.infer<typeof accountSetupStepSchema>
