import { isString } from '@ripple/ui-helpers'
import { dropsToXrp, Payment } from 'xrpl'
import i18n from '../../i18n'
import { convertRippleDate, formatDatetime } from './datetime.helpers'
import { convertHexCurrencyCodeToString } from './payments'

export const getTxnDestinationAmount = (amount: Payment['Amount']) =>
  isString(amount) ? dropsToXrp(+amount).toString() : amount.value

export const getTxnCurrency = (amount: Payment['Amount']) =>
  isString(amount) ? 'XRP' : convertHexCurrencyCodeToString(amount.currency)

export const getFormattedTxnDate = (date?: number) =>
  date
    ? formatDatetime(convertRippleDate(date))
    : i18n.t('transactions:Date-Unavailable')
