export default {
  Payments: 'Paiements',
  'New-Payment': 'Nouveau paiement',
  'New-Request': 'Nouvelle demande',
  'Mint-Requests': 'Demandes de création',
  'Destroy-Requests': 'Demandes de destruction',
  'loading-payments': 'Chargement des demandes {{type}}...',
  'account-edit-pending': "Cet compte a une modification de compte en attente. Les nouvelles demandes ne peuvent pas être initiées tant que la modification n'est pas terminée.",
  'available-balance': '{{amount}} {{currency}} disponible',
  'no-funds': "Le compte n'a pas de fonds",
  'New-Destroy-Request': 'Nouvelle demande de destruction',
  'New-Mint-Request': 'Nouvelle demande de création',
  'New-Payment-Request': 'Nouvelle demande de paiement',
  'Create-Request': 'Créer une demande',
  'bad-currency-code': 'Impossible de récupérer le code de devise',
  'no-issuer-account': 'Aucun compte émetteur trouvé',
  'insufficient-funds': 'Fonds insuffisants disponibles dans {{source}} ({{balance}} {{currencyCode}}).',
  'trustline-limit-exceeded': 'Le montant dépassera la limite de ligne de confiance ({{maxAmount}} {{currencyCode}}) pour {{destinationAccount}}.',
  'Payment-Form-Issues': 'Il y a des problèmes avec votre formulaire. Veuillez vérifier.',
  'Total-Minted': 'Total émis',
  'Total-on-Standby': 'Total des Comptes de Attente',
  'Total-Distributable': 'Total dans les Comptes Opérationnels',
  'In-Circulation': 'Total en Circulation',
  'digital-currency': 'monnaie numérique',
  'aggregate-balance.total.tooltip': "L'offre totale de {{currency}} dans l'ensemble du système (y compris les comptes internes et externes).",
  'aggregate-balance.standby.tooltip': 'Le montant total de {{currency}} dans les comptes en attente.',
  'aggregate-balance.distribution.tooltip': 'Le montant total de {{currency}} dans les comptes de distribution.',
  'aggregate-balance.external.tooltip': 'Le montant total de {{currency}} dans tous les comptes externes.',
  'no-payments': 'Aucun paiement',
  'no-payments.destroy': 'Aucune demande de destruction trouvée où vous êtes signataire.',
  'no-payments.mint': 'Aucune demande de création trouvée où vous êtes signataire.',
  'no-payments.payment': 'Aucune demande de paiement trouvée où vous êtes signataire.',
}
