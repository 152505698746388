import { useAccountByAddress, useAccountSetup } from '@/shared/api'
import { isValidAddress } from 'xrpl'

export function useAccountOrSetup(address: string) {
  const isAccountConfigured = isValidAddress(address)
  const {
    data: account,
    isLoading: isAccountLoading,
    isSuccess: isAccountSuccess,
  } = useAccountByAddress(address, {
    // only call if account is configured
    enabled: isAccountConfigured,
  })

  const {
    data: accountSetup,
    isLoading: isSetupLoading,
    isSuccess: isSetupSuccess,
  } = useAccountSetup(address, {
    // only call if account is not configured
    enabled: !isAccountConfigured,
    refetchOnWindowFocus: false,
  })

  return {
    account,
    isAccountConfigured,
    isAccountLoading,
    isAccountSuccess,
    accountSetup,
    isSetupLoading,
    isSetupSuccess,
  }
}
