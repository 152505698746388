export enum TransactionType {
  AccountSet = 'AccountSet',
  AccountDelete = 'AccountDelete',
  CheckCancel = 'CheckCancel',
  CheckCash = 'CheckCash',
  CheckCreate = 'CheckCreate',
  DepositPreauth = 'DepositPreauth',
  EscrowCancel = 'EscrowCancel',
  EscrowCreate = 'EscrowCreate',
  EscrowFinish = 'EscrowFinish',
  OfferCancel = 'OfferCancel',
  OfferCreate = 'OfferCreate',
  Payment = 'Payment',
  PaymentChannelClaim = 'PaymentChannelClaim',
  PaymentChannelCreate = 'PaymentChannelCreate',
  PaymentChannelFund = 'PaymentChannelFund',
  SetRegularKey = 'SetRegularKey',
  SignerListSet = 'SignerListSet',
  TrustSet = 'TrustSet',
}
