export const MY_TASKS = '/my-tasks'

export const ACCOUNTS = {
  root: '/accounts',
  pending: '/accounts/pending',
  create: '/accounts/create',
  account: '/account/:address',
  changeLog: '/account/:address/change-log',
  edit: '/account/:address/edit',
  reporting: '/account/:address/reporting',
  redemptionLog: '/account/:address/redemption-log',
  settings: '/account/:address/settings',
  users: '/account/:address/users',
  // for use in nested routes as the `path` prop
  childPaths: {
    account: ':address',
    changeLog: 'change-log',
    create: 'create',
    edit: 'edit',
    reporting: 'reporting',
    redemptionLog: 'redemption-log',
    settings: 'settings',
    users: 'users',
    pending: 'pending',
    external: 'external',
  },
}

export const EXTERNAL_ACCOUNTS = {
  root: '/external-accounts',
  account: '/external-accounts/:accountId',
}

export const ACCOUNT_QUEUED_TRANSACTIONS = '/queued-transactions/:cbdcAccountId'

export const ACCOUNT_EDIT_TXN = '/account-edits/:txnId'

export const SECURITY = '/security'

export const PAYMENTS = '/payments'

export const TRANSACTION = '/transactions/:txnId'

export const REDEMPTIONS = {
  root: '/redemptions',
  payment: '/redemptions/:txnHash',
}

export const WALLET = {
  root: '/wallet',
  account: '/wallet/:address',
  connectAccount: '/wallet/connect-account',
}

export const TXN_EXPORTER = {
  root: '/txnexporter',
  transactions: '/txnexporter/:address',
}

export const ROLES = {
  root: '/roles',
  create: '/roles/create',
  overview: '/roles/:roleId',
  roleUsers: '/roles/:roleId/users',
  edit: '/roles/:roleId/edit',
  childPaths: {
    role: ':roleId',
    users: 'users',
    create: 'create',
    edit: 'edit',
  },
}

export const USERS = {
  childPaths: {
    userAccounts: 'accounts',
  },
  create: '/users/create',
  overview: '/users/:userId',
  root: '/users',
  userAccounts: '/users/:userId/accounts',
}
