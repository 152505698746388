import {
  PayloadSignature,
  payloadSignatureSchema,
  RequestError,
  safeParseApiResponse,
  WorkItem,
  workItemSchema,
  WorkItemType,
} from 'common'
import { useMutation } from 'react-query'
import { requestClient } from '../request-client'
import {
  AddMultiSignProps,
  MultiSignRequestProps,
  WorkItemRequestProps,
} from './work-items-api.types'
import { getSignatureApiPath } from './work-items.helpers'

/** Cancels a work item */
export const useCancelWorkItem = () => {
  return useMutation<void, RequestError, WorkItemRequestProps>(
    ({ workItemId }) => requestClient.delete(`/work-items/${workItemId}`),
  )
}

/**
 * Generates the transaction signature for a custodial key.
 * https://github.com/xpring-eng/cbdc-backend/blob/nk/cbdc-account-id/src/main/java/io/ripplex/cbdc/web/controller/PaymentRequestController.java#L62
 */
export const useRequestMultiSignature = () => {
  return useMutation<WorkItem, RequestError, MultiSignRequestProps>((props) =>
    requestClient
      .post(getSignatureApiPath(props, true), {})
      .then(safeParseApiResponse(workItemSchema)),
  )
}

/**
 * Save a transaction signature in the database.
 * This is used for signatures coming from external wallets like Ledger Nano.
 * https://github.com/xpring-eng/cbdc-backend/blob/nk/cbdc-account-id/src/main/java/io/ripplex/cbdc/web/controller/PaymentRequestController.java#L74
 */
export const useAddMultiSignature = () => {
  return useMutation<PayloadSignature, RequestError, AddMultiSignProps>(
    async ({ signature, ...rest }) => {
      return requestClient
        .put(getSignatureApiPath(rest), { signature })
        .then(safeParseApiResponse(payloadSignatureSchema))
    },
  )
}

/**
 * Submits a workItem's transactions to the ledger.
 * Should be used when the quorum has been reached.
 * We know this API can be called when WorkItem.status is READY_FOR_SUBMISSION.
 */
export const useSubmitWorkItemTransactions = () => {
  return useMutation<WorkItem, RequestError, WorkItemRequestProps>(
    ({ workItemId }) =>
      requestClient
        .post(`/work-items/${workItemId}`, {})
        .then(safeParseApiResponse(workItemSchema)),
  )
}

export type TrustlineUpdateProps = {
  workItemId: string
  accountId: string
  sourceAccount: string
}

/**
 * Submits a workItem's transactions to the ledger.
 * We know this API can be called when WorkItem.status is READY_FOR_SUBMISSION.
 */
export const useSubmitAuthTrustlineWorkItem = () => {
  return useMutation<WorkItem, RequestError, TrustlineUpdateProps>(
    ({ workItemId, accountId, sourceAccount }) =>
      requestClient.put(`/work-items/authorize-trust-line/${workItemId}`, {
        accountId,
        sourceAccount,
        workItemType: WorkItemType.AuthorizeTrustLine,
      }),
  )
}
