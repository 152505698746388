export default {
  'Transaction-Exporter': 'Transaction Exporter',
  'Lookup-Transactions': 'Lookup Transactions',
  'Apply-Filters': 'Apply Filters',
  'Edit-Filters': 'Edit Filters',
  'date-range.all': 'All Time',
  'date-range.day': 'Last 24 hours',
  'date-range.week': 'Last 7 days',
  'date-range.month': 'Last 30 days',
  'date-range.custom': 'Range',
  'payment-direction': 'Payment Direction is {{paymentDirection}}',
  'date.from': 'Date is from {{startDate}}',
  'date.between': 'Date range is {{startDate}} to {{endDate}}',
  'date.to': 'Date is through {{endDate}}',
  'date.is': 'Date is {{date}}',
  'Date-range': 'Date range',
  'Start-date': 'Start date',
  'End-date': 'End date',
  'Payment-direction': 'Payment direction',
  Refresh: 'Refresh',
  Export: 'Export',
  'Back-to-Search': 'Back to Search',
  'last-updated': 'Last update at {{lastUpdate}} UTC',
}
