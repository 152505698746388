export default {
  'loading-account-edit': 'Loading account edit...',
  'loading-transaction': 'Loading transaction...',
  'Request-Type': 'Request Type',
  'Keypair-Id': 'Keypair Id',
  'Public-Key': 'Public Key',
  'Submit-Transaction': 'Submit Transaction',
  'Date-Settled': 'Date Settled',
  'Date-Expired': 'Date Expired',
  'Date-Failed': 'Date Failed',
  'Date-Sent': 'Date Sent',
  'Date-Signed': 'Date Signed',
  'Cancel-Transaction': 'Cancel Transaction',
  'transaction.confirm': 'Are you sure you want to submit this transaction?',
  'transaction.success': 'Your transaction has been submitted!',
  'transaction.error': 'We encountered an error submitting your transaction',
  'Date-Canceled': 'Date Canceled',
  'Canceled-By': 'Canceled By',
  'Transaction-Type': 'Transaction Type',
  'Transaction-Direction': 'Transaction Direction',
  Received: 'Received',
  Direction: 'Direction',
  Sent: 'Sent',
  'Transaction-Hash': 'Transaction Hash',
  'Ledger-Transaction-Hash': 'Ledger Transaction Hash',
  'Source-Account': 'Source Account',
  'Source-Account-Name': 'Source Account Name',
  'Source-Account-Type': 'Source Account Type',
  'Source-Account-Address': 'Source Account Address',
  'Destination-Account': 'Destination Account',
  'Destination-Account-Name': 'Destination Account Name',
  'Destination-Account-Type': 'Destination Account Type',
  'Destination-Account-Address': 'Destination Account Address',
  'Signing-Quorum': 'Signing Quorum',
  'Signing-Quorum-Instructions': 'Enter the minimum signer weight required to approve a transaction.',
  'Date-Initiated': 'Date Initiated',
  'Initiated-By': 'Initiated By',
  'Workitem-ID': 'Workitem ID',
  'Account-Edit': 'Account Edit',
  'Account-Setup': 'Account Setup',
  'Account-Flags': 'Account Flags',
  Trustline: 'Trust Line',
  'no-transactions': 'No Transactions',
  'no-transactions.description': 'There are no transactions that need your attention.',
  'Destroy-Amount': 'Destroy Amount',
  'Request-Overview': 'Request Overview',
  'Destroy-Request': 'Destroy Request',
  'Freeze-Request': 'Freeze Request',
  'Unfreeze-Request': 'Unfreeze Request',
  'Global-Freeze-Request': 'Global Freeze Request',
  'Global-Unfreeze-Request': 'Global Unfreeze Request',
  'Mint-Request': 'Mint Request',
  'Trustline-Request': 'Trust Line Request',
  'trustline-request-success': 'Your trustline request has been created!',
  'trustline-request-error': 'We encountered an error submitting your trustline request',
  'Signer-List-Change': 'Signers/Quorum Change',
  'Freeze-Account-Name': 'Freeze Account: {{name}}',
  'Transaction-Amount': 'Transaction Amount',
  'Mint-Amount': 'Mint Amount',
  'Create-Transaction': 'Create Transaction',
  'Create-Mint-Request': 'Create Mint Request',
  'Payment-Amount': 'Payment Amount',
  'Payment-Request': 'Payment Request',
  'New-Mint-Request': 'New Mint Request',
  'Mint-Request-Disabled.tooltip-description': 'Minting is disabled because there is a change to the signers list “In Review” on the Issuer account.',
  'New-Transaction': 'New Transaction',
  Signed: 'Signed',
  'Pending-Signature': 'Pending Signature',
  'Amount-To-Destroy': 'Amount to Destroy',
  'Amount-To-Mint': 'Amount to Mint',
  'Amount-To-Pay': 'Amount to Pay',
  'Pay-To': 'Pay To',
  'Mint-To': 'Mint To',
  'Transaction-Details': 'Transaction Details',
  'Destroy-Info': 'Destroyed currency is always sent back to the Issuer from which it was received.',
  'no-tasks': 'No tasks',
  'all-payments': 'All Payments',
  'assigned-to-me': 'Assigned to Me',
  'Destroy-Always-Returns-To-Issuer': 'Destroyed currency is always sent back to the Issuer from which it was received.',
  'Destroy-Source-Placeholder': 'Select an account to destroy from',
  'Mint-Destination-Placeholder': 'Select an account to mint to',
  'Pay-Source-Placeholder': 'Select an account to pay from',
  'Pay-Destination-Placeholder': 'Select an account to pay to',
  'Destroy-Request-Confirmation': 'Destroy Request Confirmation',
  'Mint-Request-Confirmation': 'Mint Request Confirmation',
  'Review-Mint-Request': 'Review Mint Request',
  'Review-Pay-Request': 'Review Pay Request',
  'Review-Destroy-Request': 'Review Destroy Request',
  'Review-Trustline-Request': 'Review Trustline Request',
  'Review-Freeze-Request': 'Review Freeze Request',
  'Review-Unfreeze-Request': 'Review Unfreeze Request',
  'Review-Global-Freeze-Request': 'Review Global Freeze Request',
  'Review-Global-Unfreeze-Request': 'Review Global Unfreeze Request',
  'Review-Signer-Change-Request': 'Review Signer Change Request',
  'Review-Quorum-Change-Request': 'Review Quorum Change Request',
  'No-HID-Support': 'Your browser does not support HID which is required to use the Ledger Nano to sign transactions. Please use Chrome, Brave, Edge or Opera.',
  'Date-Unavailable': 'Date unavailable',
  'error.memo-too-long': 'Memo size is too long',
  'error.invalid-address': 'Invalid address',
  'Account-Details-Retrieved': 'Account details retrieved.',
  'nano-error.transport-undefined': 'Communication with the Ledger Nano impossible (transport undefined).',
  'nano-error.unable-to-connect': 'Unable to connect to your Ledger Nano. Please unlock your device and clear any unsigned transactions before trying again.',
  'nano-error.incorrect-bytes': 'Incorrect bytes to sign.',
  'nano-error.txn-too-large': 'Transaction too large.',
  'nano-error.invalid-channel': 'Invalid channel, if the issue persists reconnect your Ledger Nano.',
  'nano-error.ledger-key-index-unreachable': 'Ledger key index {{index}} could not be retrieved.',
  'nano-error.signing-account-not-found': 'Signing account not found.',
  'nano-error.signature-not-found': 'Ledger Nano signature not found.',
  'nano-success.connected': 'Connected to {{product}}',
  'nano.check-for-signing': 'Check your Ledger device for signing...',
  'nano.open-and-retry': 'Open the Ledger Nano XRP app and retry.',
  'nano.unlock-and-retry': 'Unlock your Ledger, open the XRP app and retry.',
  'nano.txn-rejected': 'Transaction rejected by the user.',
  'destroy-request-error': 'We encountered an error creating your destroy request.',
  'mint-request-error': 'We encountered an error creating your mint request.',
  'payment-request-error': 'We encountered an error creating your payment request.',
  'destroy-request-success': 'Your destroy request has been created!',
  'mint-request-success': 'Your mint request has been created!',
  'payment-request-success': 'Your payment request has been created!',
  'signature-failed': 'Something went wrong adding your signature.',
  'signature-success': 'Your signature has been added!',
  'signer-email-not-found': 'Email not found',
  'Account-to-Freeze': 'Account to Freeze',
  'Account-to-Unfreeze': 'Account to Unfreeze',
  'Global-Freeze-Details': 'Global Freeze Details',
  'global-freeze-details-text': 'A global freeze affects all trust lines to the issuer. Once frozen, all accounts in the network will only be able to send destroy transactions to the issuer.',
  'Global-Unfreeze-Details': 'Global Unfreeze Details',
  'global-unfreeze-details-text': 'A global unfreeze affects all trustlines to the issuer. Once unfrozen, all accounts in the network will be able to transact with each other.',
  'Freeze-Details': 'Freeze Details',
  'freeze-details-text': 'The trust line between the account and the issuer will be frozen. Once frozen the account will only be able to send payments to the issuer account, taking that currency out of circulation.',
  'Unfreeze-Details': 'Unfreeze Details',
  'unfreeze-details-text': 'The trust line between the account and the issuer will be unfrozen. Once unfrozen, the account will be able to transact with all accounts in the network.',
  'All-Accounts': 'All Accounts',
  'Submit-Request': 'Submit Request',
  'Signed-Successfully': 'Signed Successfully',
  'reason-for-disabled-submit': 'Once quorum has been reached, you can submit the transaction to the ledger.',
  'Account-Overview': 'Account Overview',
  'Request-Details': 'Request Details',
  'freeze-request.freeze.description': 'The trust line between the account and the issuer will be frozen. Once frozen, the account will only be able to send payments to the issuer account, which means removing currency from circulation. <Link>View Docs</Link>',
  'freeze-request.unfreeze.description': 'The trustline between the account and the issuer will be unfrozen. Once unfrozen, the account will be able to transact with all accounts in the network. <Link>View Docs</Link>',
  'freeze-request.global-freeze.description': 'A global freeze affects all trustlines to the issuer. Once frozen, all accounts in the network will only be able to send payments to the issuer, which means removing currency from circulation. <Link>View Docs</Link>',
  'freeze-request.global-unfreeze.description': 'A global unfreeze affects all trustlines to the issuer. Once unfrozen, all accounts in the network will be able to transact with each other. <Link>View Docs</Link>',
  'no-security-actions': 'No security actions yet.',
  'no-security-actions.description': 'There are no security actions that need your attention.',
}
