import { formatDatetime, getAccountEditTypeDisplay } from '@/shared/helpers'
import { AccountEditMetadata, CbdcAccountMetadata } from 'common'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export type AccountEditsMetadata = ReturnType<
  ReturnType<typeof useAccountEditsMetadata>
>

/**
 * Hook to get a stable function that returns display values for account edits metadata
 * This hook is specifically for use in account edits metadata collections, to bring in user and account
 * details in a performant way and reduce common display formatting
 */
export function useAccountEditsMetadata() {
  const { t } = useTranslation(['accounts'])

  return useCallback(
    (
      {
        id,
        status,
        createdBy,
        createdAt,
        requestSummary,
        isNameChange,
        isQuorumChange,
        isSignerListChange,
        isUsersChange,
        cbdcAccountId,
        workItemId = '',
      }: AccountEditMetadata,
      accountsMeta?: CbdcAccountMetadata[],
    ) => ({
      id,
      isNameChange,
      isQuorumChange,
      isSignerListChange,
      isUsersChange,
      status,
      createdBy,
      requestSummary,
      accountName:
        accountsMeta?.find((meta) => meta.cbdcAccountId === cbdcAccountId)
          ?.accountName ?? t('accounts:Unknown-Account'),
      createdAt: formatDatetime(createdAt),
      requestType: getAccountEditTypeDisplay({
        isNameChange,
        isUsersChange,
        isQuorumChange,
        isSignerListChange,
        requestSummary,
      }),
      workItemId,
    }),
    [],
  )
}
