import {
  useAccountFreezesByAccountId,
  useExternalAccountFreezesByAccountId,
} from '@/shared/api'
import { WS_QUEUES } from '@/shared/constants'
import {
  isAccountExternal,
  isAccountFreezeWorkItem,
  isAccountUnfreezeWorkItem,
} from '@/shared/helpers'
import { useAccountMetadataLookup } from '@/shared/hooks'
import {
  WorkItem,
  WorkItemStatus,
  useSubscription,
  workItemStatuses,
  workItemTypes,
} from 'common'

export function useHasPendingOrActiveFreeze(accountId: string, enabled = true) {
  const accountLookup = useAccountMetadataLookup()
  const account = accountLookup.lookupAccount(accountId)
  const isExternal = isAccountExternal(account?.accountType)

  const { data, refetch } = useAccountFreezesByAccountId(accountId, {
    enabled: !!accountId && enabled && !isExternal,
  })

  const { data: externalAccountsData, refetch: refetchExternalAccounts } =
    useExternalAccountFreezesByAccountId(accountId, {
      enabled: !!accountId && enabled && isExternal,
    })

  // listen for freeze work item messages and refetch
  // when one matching the targeted account is completed
  useSubscription<WorkItem>(WS_QUEUES.WORKITEMS, {
    onData: ({ data }) => {
      const isMatchingFreezeItem =
        data.transactions[0].destinationAccount === accountId

      if (isMatchingFreezeItem) {
        if (workItemTypes.freezes.account.internal.includes(data.type)) {
          refetch()
        } else {
          refetchExternalAccounts()
        }
      }
    },
  })

  const accounts = data?.content.length
    ? data.content
    : externalAccountsData ?? []

  const latestFreeze = accounts[0]
  const isLatestFreezePending = [
    ...workItemStatuses.pending,
    WorkItemStatus.Queued,
  ].includes(latestFreeze?.status)
  const lastCompletedFreeze = accounts.find(
    ({ status }) => status === WorkItemStatus.Complete,
  )

  return {
    hasActiveFreeze:
      !!lastCompletedFreeze &&
      isAccountFreezeWorkItem(lastCompletedFreeze.type),
    hasPendingFreeze:
      isLatestFreezePending && isAccountFreezeWorkItem(latestFreeze?.type),
    hasPendingUnfreeze:
      isLatestFreezePending && isAccountUnfreezeWorkItem(latestFreeze?.type),
    freezeWorkItem: latestFreeze,
  }
}
