import { GeneralObject } from './general'

/**
 * Helper to get the Object keys typed correctly.
 *
 * @param {Object} obj An object.
 * @returns An array of the object keys
 */
export const objectKeys = <T extends GeneralObject>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[]
}
