export default {
  'loading-account-edit': 'Cargando cambio en la cuenta...',
  'loading-transaction': 'Cargando transacción...',
  'Request-Type': 'Tipo de Solicitud',
  'Keypair-Id': 'Id de Par de Claves',
  'Public-Key': 'Clave Pública',
  'Submit-Transaction': 'Enviar Transacción',
  'Date-Settled': 'Fecha de Confirmación',
  'Date-Expired': 'Fecha de vencimiento',
  'Date-Failed': 'Fecha fallida',
  'Date-Sent': 'Fecha de envío',
  'Date-Signed': 'Fecha de firma',
  'Cancel-Transaction': 'Cancelar Transacción',
  'transaction.confirm': '¿Está seguro de enviar esta transacción?',
  'transaction.success': '¡Su transacción ha sido enviada!',
  'transaction.error': 'Se ha producido un error enviando su transacción',
  'Date-Canceled': 'Fecha de Cancelación',
  'Canceled-By': 'Cancelada por',
  'Transaction-Type': 'Tipo de Transacción',
  'Transaction-Direction': 'Dirección de Transacción',
  Received: 'Recibida',
  Direction: 'Dirección',
  Sent: 'Enviada',
  'Transaction-Hash': 'Hash de Transacción',
  'Ledger-Transaction-Hash': 'Hash de transacciones del libro mayor',
  'Source-Account': 'Cuenta Origen',
  'Source-Account-Name': 'Nombre Cuenta Origen',
  'Source-Account-Type': 'Tipo Cuenta Origen',
  'Source-Account-Address': 'Dirección Cuenta Origen',
  'Destination-Account': 'Cuenta Destino',
  'Destination-Account-Name': 'Nombre Cuenta Destino',
  'Destination-Account-Type': 'Tipo Cuenta Destino',
  'Destination-Account-Address': 'Dirección Cuenta Destino',
  'Signing-Quorum': 'Quórum de firmas',
  'Signing-Quorum-Instructions': 'Ingrese el peso mínimo del firmante requerido para aprobar una transacción.',
  'Date-Initiated': 'Fecha Inicio',
  'Initiated-By': 'Iniciada por',
  'Workitem-ID': 'ID de Trabajo',
  'Account-Edit': 'Cambio en Cuenta',
  'Account-Setup': 'Configuración en Cuenta',
  'Account-Flags': 'Parámetros de Cuenta',
  Trustline: 'Línea de Confianza',
  'no-transactions': 'Sin transacciones',
  'no-transactions.description': 'No hay transacciones que necesiten tu atención.',
  'Destroy-Amount': 'Monto de Destrucción',
  'Request-Overview': 'Descripción general de la solicitud',
  'Destroy-Request': 'Petición de Destrucción',
  'Freeze-Request': 'Petición de Bloqueo',
  'Unfreeze-Request': 'Petición de Desbloqueo',
  'Global-Freeze-Request': 'Solicitud de congelación global',
  'Global-Unfreeze-Request': 'Solicitud de descongelación global',
  'Mint-Request': 'Petición de Emisión',
  'Signer-List-Change': 'Cambio de Firmantes/Quórum',
  'Trustline-Request': 'Solicitud de línea de confianza',
  'trustline-request-success': '¡Su solicitud de línea de confianza ha sido creada!',
  'trustline-request-error': 'Encontramos un error al enviar su solicitud de línea de confianza',
  'Freeze-Account-Name': 'Bloqueo Cuenta: {{name}}',
  'Transaction-Amount': 'Valor Transacción',
  'Mint-Amount': 'Valor Emisión',
  'Create-Transaction': 'Crear Transacción',
  'Create-Mint-Request': 'Crear Petición de Emisión',
  'Payment-Amount': 'Monto del Pago',
  'Payment-Request': 'Petición de Pago',
  'New-Mint-Request': 'Nueva Petición de Emisión',
  'Mint-Request-Disabled.tooltip-description': 'La acuñación está deshabilitada porque hay un cambio en la lista de firmantes "En revisión" en la cuenta del Emisor.',
  'New-Transaction': 'Nueva Transacción',
  Signed: 'Firmada',
  'Pending-Signature': 'Pendiente de Firma',
  'Amount-To-Destroy': 'Cantidad a Destruir',
  'Amount-To-Mint': 'Cantidad a Emitir',
  'Amount-To-Pay': 'Cantidad a Pagar',
  'Pay-To': 'Pagar A',
  'Mint-To': 'Emitir A',
  'Destroy-Info': 'La moneda destruida es siempre enviada de vuelta al Emisor del que se recibió.',
  'Transaction-Details': 'Detalles de la transacción',
  'no-tasks': 'Sin tareas',
  'all-payments': 'Todos los pagos',
  'assigned-to-me': 'Asignado a mí',
  'Destroy-Always-Returns-To-Issuer': 'La moneda destruida siempre se devuelve al Emisor del que se recibió.',
  'Destroy-Source-Placeholder': 'Seleccione una cuenta para destruir',
  'Mint-Destination-Placeholder': 'Seleccione una cuenta para acuñar',
  'Pay-Source-Placeholder': 'Seleccione una cuenta desde la que pagar',
  'Pay-Destination-Placeholder': 'Seleccione una cuenta para pagar',
  'Destroy-Request-Confirmation': 'Confirmación de solicitud de destrucción',
  'Mint-Request-Confirmation': 'Confirmación de solicitud de menta',
  'Review-Mint-Request': 'Revisar la solicitud de menta',
  'Review-Pay-Request': 'Revisar solicitud de pago',
  'Review-Destroy-Request': 'Revisar solicitud de destrucción',
  'Review-Trustline-Request': 'Revisar la solicitud de Trustline',
  'Review-Freeze-Request': 'Revisar la solicitud de congelación',
  'Review-Unfreeze-Request': 'Revisar la solicitud de descongelación',
  'Review-Global-Freeze-Request': 'Revisar la solicitud de congelamiento global',
  'Review-Global-Unfreeze-Request': 'Revisar la solicitud de descongelación global',
  'Review-Signer-Change-Request': 'Revisar la solicitud de cambio de firmante',
  'Review-Quorum-Change-Request': 'Revisar la solicitud de cambio de quórum',
  'No-HID-Support': 'Su navegador no es compatible con HID, que se requiere para usar Ledger Nano para firmar transacciones. Utilice Chrome, Brave, Edge o Opera.',
  'Date-Unavailable': 'Fecha no disponible',
  'error.memo-too-long': 'El tamaño de la nota es demasiado largo',
  'error.invalid-address': 'Dirección inválida',
  'Account-Details-Retrieved': 'Detalles de la cuenta recuperados.',
  'nano-error.transport-undefined': 'Comunicación con el Ledger Nano imposible (transporte indefinido).',
  'nano-error.unable-to-connect': 'No se puede conectar a su Ledger Nano. Desbloquee su dispositivo y borre cualquier transacción sin firmar antes de volver a intentarlo.',
  'nano-error.incorrect-bytes': 'Bytes incorrectos para firmar.',
  'nano-error.txn-too-large': 'Transacción demasiado grande.',
  'nano-error.invalid-channel': 'Canal no válido, si el problema persiste, vuelva a conectar su Ledger Nano.',
  'nano-error.ledger-key-index-unreachable': 'No se pudo recuperar el índice de clave de libro mayor {{index}}.',
  'nano-error.signing-account-not-found': 'Cuenta de firma no encontrada.',
  'nano-error.signature-not-found': 'No se encontró la firma Ledger Nano.',
  'nano-success.connected': 'Conectado a {{product}}',
  'nano.check-for-signing': 'Verifique su dispositivo Ledger para firmar...',
  'nano.open-and-retry': 'Abra la aplicación Ledger Nano XRP y vuelva a intentarlo.',
  'nano.unlock-and-retry': 'Desbloquee su Ledger, abra la aplicación XRP y vuelva a intentarlo.',
  'nano.txn-rejected': 'Transacción rechazada por el usuario.',
  'destroy-request-error': 'Encontramos un error al crear su solicitud de destrucción.',
  'mint-request-error': 'Encontramos un error al crear su solicitud de menta.',
  'payment-request-error': 'Encontramos un error al crear su solicitud de pago.',
  'destroy-request-success': '¡Tu solicitud de destrucción ha sido creada!',
  'mint-request-success': '¡Tu solicitud de menta ha sido creada!',
  'payment-request-success': '¡Su solicitud de pago ha sido creada!',
  'signature-failed': 'Algo salió mal al agregar tu firma.',
  'signature-success': '¡Tu firma ha sido añadida!',
  'signer-email-not-found': 'El correo electrónico no encontrado',
  'Account-to-Freeze': 'Cuenta para congelar',
  'Account-to-Unfreeze': 'Cuenta para descongelar',
  'Global-Freeze-Details': 'Detalles del congelamiento global',
  'global-freeze-details-text': 'Una congelación global afecta a todas las líneas de confianza con el emisor. Una vez congeladas, todas las cuentas de la red solo podrán enviar transacciones de destrucción al emisor.',
  'Global-Unfreeze-Details': 'Detalles del descongelamiento global',
  'global-unfreeze-details-text': 'Un descongelamiento global afecta todas las líneas de confianza con el emisor. Una vez descongeladas, todas las cuentas de la red podrán realizar transacciones entre sí.',
  'Freeze-Details': 'Detalles de congelación',
  'freeze-details-text': "La ligne de confiance entre le compte et l'émetteur sera gelée. Une fois gelé, le compte ne pourra envoyer des paiements que vers le compte de l'émetteur, retirant ainsi cette monnaie de la circulation.",
  'Unfreeze-Details': 'Detalles de descongelación',
  'unfreeze-details-text': 'Se congelará la línea de confianza entre la cuenta y el emisor. Una vez descongelada, la cuenta podrá realizar transacciones con todas las cuentas de la red.',
  'All-Accounts': 'Todas las cuentas',
  'Submit-Request': 'Enviar peticion',
  'Signed-Successfully': 'Firmado exitosamente',
  'reason-for-disabled-submit': 'Una vez que se haya alcanzado el quórum, puede enviar la transacción al libro mayor.',
  'Account-Overview': 'Descripción de cuenta',
  'Request-Details': 'Pedir detalles',
  'freeze-request.freeze.description': 'Se congelará la línea de confianza entre la cuenta y el emisor. Una vez congelada, la cuenta sólo podrá enviar pagos a la cuenta del emisor, lo que significa retirar la moneda de la circulación. <Link>Ver documentos</Link>',
  'freeze-request.unfreeze.description': 'Se desbloqueará la línea de confianza entre la cuenta y el emisor. Una vez descongelada, la cuenta podrá realizar transacciones con todas las cuentas de la red. <Link>Ver documentos</Link>',
  'freeze-request.global-freeze.description': 'Una congelación global afecta a todas las líneas de confianza con el emisor. Una vez congeladas, todas las cuentas de la red solo podrán enviar pagos al emisor, lo que significa retirar la moneda de la circulación. <Link>Ver documentos</Link>',
  'freeze-request.global-unfreeze.description': 'Un descongelamiento global afecta todas las líneas de confianza con el emisor. Una vez descongeladas, todas las cuentas de la red podrán realizar transacciones entre sí. <Link>Ver documentos</Link>',
  'no-security-actions': 'Aún no hay acciones de seguridad.',
  'no-security-actions.description': 'No hay acciones de seguridad que necesiten tu atención.',
}
