export default {
  signers: '{{count}} signataire',
  signers_other: '{{count}} signataires',
  users: '{{count}} utilisateur',
  users_other: '{{count}} utilisateurs',
  accounts: '{{count}} compte',
  accounts_other: '{{count}} comptes',
  conflicts_one: '{{count}} conflit',
  conflicts_other: '{{count}} conflits',
  'conflicts-only_one': 'conflit',
  'conflicts-only_other': 'conflits',
  keypairs: '{{count}} paire de clés',
  keypairs_other: '{{count}} paires de clés',
  Accounts: '{{count}} Compte',
  Accounts_other: '{{count}} Comptes',
}
