import { z } from 'zod'

export const xummWalletSetupSchema = z.object({
  // id is "accountId" route parameter in api requests
  id: z.string(),
  qr: z.string(),
  wss: z.string(),
  // payloadId is the reference in xumm payloads. It is equal to XummInfo["payload_uuidv4"]
  payloadId: z.string(),
  accountName: z.string(),
  redirectUrl: z.string(),
})

export type XummWalletSetup = z.infer<typeof xummWalletSetupSchema>

export const xummWebsocketDataSchema = z.object({
  txid: z.string(),
  signed: z.boolean(),
  user_token: z.boolean(),
  opened_by_deeplink: z.boolean(),
  reference_call_uuidv4: z.string(),
  payload_uuidv4: z.string(),
})

export type XummWebsocketData = z.infer<typeof xummWebsocketDataSchema>

export const xummWalletInfoSchema = z.object({
  id: z.string(),
  nodeUri: z.string(),
  address: z.string(),
  userToken: z.string(),
})

export type XummWalletInfo = z.infer<typeof xummWalletInfoSchema>

export const xummWalletTrustSetSchema = z.object({
  id: z.string(),
  qr: z.string(),
  wss: z.string(),
  payloadId: z.string(),
  redirectUrl: z.string(),
})

export type XummWalletTrustSet = z.infer<typeof xummWalletTrustSetSchema>

export const xummWalletPaymentSchema = z.object({
  id: z.string(),
  qr: z.string(),
  wss: z.string(),
  payloadId: z.string(),
  redirectUrl: z.string(),
})

export type XummWalletPayment = z.infer<typeof xummWalletPaymentSchema>
