import {
  getAccountLedgerInfo,
  useUpdateAccountSetupLedgerDetails,
} from '@/shared/api'
import { Clock3Icon, ToastType, useToast } from '@ripple/design-system'
import { ProcessingStatus } from 'common'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isWizardStepEqualToBackEndStep } from '../../account-setup-modal.helpers'
import { CommonStepProps } from '../../account-setup-modal.types'

/**
 * Hook which is used only for the first step to fetch a custodial keystore and post it to the 
 * UpdateAccountSetupLedgerDetails endpoint
 *
 * @param {number} step The ledger step, which is 1.
 * @param {CommonStepProps} Object All the props which are common to all the steps.
 * @param {boolean} show Indicates if the modal is visible or closed.
 * @returns An object containing:
 * - errorMessage: A potential error message.
   - retryHandler: A function to retry the flow.
   - toastMessage: The toast message.
   - toastType: The toast type.
   - toastIcon: The toast icon.
 */
export const useCustodialAccountSetup = (
  step: number,
  {
    accountSetup,
    pathName,
    processingStatus,
    custodialAccount,
    saveCustodialAccount,
    show,
  }: CommonStepProps,
) => {
  const { t } = useTranslation(['accounts'])
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isSavingAddress, setIsSavingAddress] = useState(false)
  const [isRetrievingAccount, setIsRetrievingAccount] = useState(false)
  const toast = useToast()

  // Check if the step returned by the back-end corresponds to the step of the Ledger Nano (ie 1).
  const isLedgerInfoStep = isWizardStepEqualToBackEndStep(step, pathName)

  const fetchAccountAndSavePublicKey = async () => {
    if (!show) return

    if (!custodialAccount) {
      // Step 1
      custodialAccount = await fetchCustodialKeystore()
    }

    if (custodialAccount) {
      // Step 2
      await savePublicKey(custodialAccount.publicKey)
    }
  }

  /**
   * ******************************************************************************************
   * Step 1, Retrieve the correct account from the backend
   * ******************************************************************************************
   */
  const fetchCustodialKeystore = async () => {
    if (!accountSetup) {
      setErrorMessage(t('accounts:account-setup.account-details-not-found'))
      return
    }
    setIsRetrievingAccount(true)

    const accountLedgerInfo = await getAccountLedgerInfo(accountSetup.accountId)

    // Save the account details in the state to have access to it faster in the next steps.
    saveCustodialAccount(accountLedgerInfo)
    setErrorMessage(undefined)
    setIsRetrievingAccount(false)

    return accountLedgerInfo
  }

  /**
   * ******************************************************************************************
   * Step 2, Save the custodial account public key in the database
   * ******************************************************************************************
   */
  const updateLedgerDetailsMutation = useUpdateAccountSetupLedgerDetails()
  const savePublicKey = async (pubKey: string) => {
    if (!show) return

    setIsSavingAddress(true)
    await updateLedgerDetailsMutation.mutateAsync(
      {
        cbdcAccountId: accountSetup.accountId,
        publicKey: pubKey,
      },
      {
        onError: () => {
          setIsSavingAddress(false)
          setErrorMessage(
            t('accounts:account-setup.ledger-nano.save-key.failed'),
          )
        },
        onSuccess: (response) => {
          handleStatusCode(response.statusCode)
        },
      },
    )
  }

  /**
   * ******************************************************************************************
   * 👇🏻 Helpers 👇🏻
   * ******************************************************************************************
   */

  /**
   * Defines the logic regarding the status code received from the back-end
   * @param {number} statusCode The status code of the API response.
   * @returns void
   */
  const handleStatusCode = (statusCode?: number) => {
    if (statusCode === 200) {
      setErrorMessage(undefined)
      // No need to setIsSavingAddress(false) as we want to avoid displaying a wrong toast message for a very short period of time
      return
    }

    /**
     * Status code === 409 means that we tried to insert a public key which already exists in the table from another tenant.
     * That's a very rare scenario.
     *
     * Status code === 422 means potentially that the account we try to save has already been configured.
     * That's also a rare scenario.
     */
    if (statusCode === 409 || statusCode === 422) {
      toast.info(t('accounts:account-setup.ledger-nano.save-address.failed'))

      saveCustodialAccount(undefined)
    } else {
      setIsSavingAddress(false)
      setErrorMessage(t('accounts:account-setup.ledger-nano.save-key.failed'))
    }
  }

  /**
   * What happens when the user clicks on the retry text button
   */
  const retryHandler = () => {
    setErrorMessage(undefined)
    fetchAccountAndSavePublicKey()
  }

  // Toast message, type and icon depending on the context.
  let toastMessage = ''
  let toastType: ToastType = 'info'
  let toastIcon = undefined

  const isFindingAccount = isRetrievingAccount && isLedgerInfoStep
  const isSaving =
    !isRetrievingAccount &&
    isLedgerInfoStep &&
    (isSavingAddress || processingStatus === ProcessingStatus.Processing)
  const hasError = !isRetrievingAccount && isLedgerInfoStep && errorMessage
  const isSuccess = !isRetrievingAccount && !isLedgerInfoStep

  if (isFindingAccount || isSaving) {
    toastMessage = t('accounts:account-setup.assigning-address')
    toastIcon = Clock3Icon
  } else if (isSuccess) {
    toastMessage = t('accounts:account-setup.associated-account')
    toastType = 'success'
  } else if (hasError) {
    toastMessage = t('accounts:account-setup.keypair-error')
    toastType = 'error'
  }

  return {
    fetchAccountAndSavePublicKey,
    errorMessage,
    retryHandler,
    toastMessage,
    toastType,
    toastIcon,
    isProcessing: isFindingAccount || isSaving,
  }
}
