export default {
  Payments: 'Pagos',
  'New-Payment': 'Nuevo Pago',
  'Mint-Requests': 'Peticiones de Emisión',
  'Destroy-Requests': 'Peticiones de Destrucción',
  'loading-payments': 'Cargando peticiones de {{type}}...',
  'account-edit-pending': 'Esta cuenta tiene un cambio pendiente. No se pueden iniciar nuevas peticiones hasta que el cambio sea completado.',
  'available-balance': '{{amount}} {{currency}} Disponible',
  'no-funds': 'La cuenta no tiene fondos',
  'New-Destroy-Request': 'Nueva Petición de Destrucción',
  'New-Mint-Request': 'Nueva Petición de Emisión',
  'New-Payment-Request': 'Nueva Petición de Pago',
  'Create-Request': 'Crear Petición',
  'bad-currency-code': 'No se ha podido obtener el código de moneda',
  'no-issuer-account': 'No se ha encontrado cuenta emisora',
  'insufficient-funds': 'Fondos insuficientes en {{source}} ({{balance}} ${{currencyCode}}).',
  'trustline-limit-exceeded': 'La cantidad sobrepasará el límite de la línea de confianza ({{maxAmount}} {{currencyCode}}) para {{destinationAccount}}.',
  'Payment-Form-Issues': 'Parece que hay algún problema con su formulario. Por favor revise.',
  'New-Request': 'Nueva petición',
  'Total-Minted': 'Total Emitido',
  'Total-on-Standby': 'Total en Cuentas Intermedias',
  'Total-Distributable': 'Total en Cuentas Operativas',
  'In-Circulation': 'Total en Circulación',
  'digital-currency': 'moneda digital',
  'aggregate-balance.total.tooltip': 'Balance total de {{currency}} en todo el sistema (incluyendo cuentas internas y externas).',
  'aggregate-balance.standby.tooltip': 'Balance total de {{currency}} en Cuentas Intermedias.',
  'aggregate-balance.distribution.tooltip': 'Balance total de {{currency}} en cuentas de Distribución.',
  'aggregate-balance.external.tooltip': 'Balance total de {{currency}} en todas las cuentas externas.',
  'no-payments': 'Sin pagos',
  'no-payments.destroy': 'No se han encontrado peticiones de destrucción de moneda en las que usted sea firmante.',
  'no-payments.mint': 'No se han encontrado peticiones de emisión en las que usted sea firmante.',
  'no-payments.payment': 'No se han encontrado peticiones de pago en las que usted sea firmante.',
}
