export enum Permission {
  AddAccount = 'ADD_ACCOUNT',
  ReadAccount = 'READ_ACCOUNT',
  EditAccount = 'EDIT_ACCOUNT',
  DeleteAccount = 'DELETE_ACCOUNT',
  Sign = 'SIGN',
  Transact = 'TRANSACT',
  Freeze = 'FREEZE',
  AddUser = 'ADD_USER',
  EditUser = 'EDIT_USER',
  DeleteUser = 'DELETE_USER',
  AddRole = 'ADD_ROLE',
  EditRole = 'EDIT_ROLE',
  DeleteRole = 'DELETE_ROLE',
  ReadRole = 'READ_ROLE',
}
