import { convertRippleDate, getTxnCurrency } from '@/shared/helpers'
import { isObject, isString, isUndefined } from '@ripple/ui-helpers'
import dayjs from 'dayjs'
import {
  AccountTxTransaction,
  BaseTransaction,
  ErrorResponse,
  TxResponse,
  XrplError,
} from 'xrpl'
import {
  PaymentTxFilterProps,
  TxnStatusInfo,
  XRPLPaymentTransaction,
  XRPLTransaction,
  XRPLTxnStatus,
} from './xrpl.types'

/**
 * Subset of XRPLErrorCodes that we handle from the [full list](https://github.com/XRPLF/rippled/blob/develop/src/ripple/protocol/ErrorCodes.h#L70)
 */
export enum KnownXrplErrorCodes {
  AccountNotFound = 19,
  AccountMalformed = 35,
  TransactionNotFound = 29,
}

/**
 * Check if XRPL.js ErrorResponse is the provided errorCode
 * @param {ErrorResponse} error
 * @param {KnownXrplErrorCodes} errorCode Error code to check against
 * @returns if ErrorResponse.error_code matches provided errorCode
 */
export const isXRPLErrorCode = (
  error: ErrorResponse,
  errorCode: KnownXrplErrorCodes,
) => !isUndefined(error.error_code) && parseInt(error.error_code) === errorCode

/**
 * Check if error is coming from XRPL.js
 * @param thing Maybe an XRPL.js error object
 */
export const isXRPLErrorResponse = (
  thing: unknown,
): thing is { data: ErrorResponse } =>
  thing instanceof XrplError &&
  isObject(thing.data) &&
  thing.data.status === 'error' &&
  thing.data.type === 'response'

export const accountTxnMatchesXRPLFilters = (
  transaction: AccountTxTransaction,
  filters: PaymentTxFilterProps = {},
  address?: string,
) => matchesXRPLFilters(transaction.tx, transaction, filters, address)

export const txnMatchesXRPLFilters = (
  transaction: TxResponse['result'],
  filters: PaymentTxFilterProps = {},
  address?: string,
) => matchesXRPLFilters(transaction as any, transaction, filters, address)

function isPaymentTransaction(
  transaction?: BaseTransaction,
): transaction is XRPLPaymentTransaction {
  return !!(transaction && transaction.TransactionType === 'Payment')
}

/**
 * Checks if a transaction matches provided filters.
 * @param filters Filters to check
 * @param tx Transaction to check
 * @param address Address of account for transactions being checked. Used to determine payment direction.
 * @returns {boolean} Boolean indicating if it matches the filters
 */
function matchesXRPLFilters(
  tx?: XRPLTransaction,
  txnStatusInfo?: TxnStatusInfo,
  filters: PaymentTxFilterProps = {},
  address?: string,
) {
  // only look at payment txns
  if (!isPaymentTransaction(tx)) return false
  // only look at token txns
  if (filters?.noXRP && getTxnCurrency(tx.Amount) === 'XRP') return false
  // filter against direction if set
  if (filters?.paymentDirection && filters.paymentDirection !== 'All') {
    const paymentDirection = address === tx.Destination ? 'Received' : 'Sent'
    if (filters.paymentDirection !== paymentDirection) return false
  }
  // filter against startDate if set
  if (filters?.startDate && tx.date) {
    const txDate = convertRippleDate(tx.date)
    if (dayjs(txDate).isBefore(filters.startDate, 'day')) return false
  }
  // filter against endDate if set
  if (filters?.endDate && tx.date) {
    const txDate = convertRippleDate(tx.date)
    if (dayjs(txDate).isAfter(filters.endDate, 'day')) {
      return false
    }
  }

  if (filters?.includeTxnStatus) {
    return filters.includeTxnStatus.includes(getTxnStatus(txnStatusInfo))
  }
  // matches all filters
  return true
}

/**
 * Get the status of a transaction result on the XRPL
 *
 * Transactions are considered successful if they have a tesSUCCESS result code and are validated
 * Transactions are considered failed if they have a tec, tem, tefPAST_SEQ, or tefMAX_LEDGER result code and are validated
 * Transactions are considered pending if they are not validated
 */
export const getTxnStatus = (result?: TxnStatusInfo): XRPLTxnStatus => {
  if (!result?.meta || isString(result.meta)) {
    return 'unrecognized'
  }

  // https://xrpl.org/finality-of-results.html#finality-of-results
  const errorPrefixes = ['tec', 'tem', 'tefPAST_SEQ', 'tefMAX_LEDGER']
  for (const code of errorPrefixes) {
    if (result.meta.TransactionResult.startsWith(code) && result.validated) {
      return 'failed'
    }
  }

  if (result.meta?.TransactionResult === 'tesSUCCESS' && result.validated) {
    return 'success'
  }

  return 'pending'
}
