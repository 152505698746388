import {
  MachineUserSettings,
  RequestError,
  machineUserSettingsSchema,
  safeParseApiResponse,
} from 'common'
import { useMutation } from 'react-query'
import { requestClient } from '../request-client'

export const useUpdateMachineUserSettings = () =>
  useMutation<MachineUserSettings, RequestError, MachineUserSettings>((body) =>
    requestClient
      .put(`/tenant-settings/machine-users`, body)
      .then(safeParseApiResponse(machineUserSettingsSchema)),
  )
