export default {
  'Transaction-Exporter': 'Exportateur de transactions',
  'Lookup-Transactions': 'Rechercher des transactions',
  'Apply-Filters': 'Appliquer les filtres',
  'Edit-Filters': 'Modifier les filtres',
  'date-range.all': 'Toutes les périodes',
  'date-range.day': 'Dernières 24 heures',
  'date-range.week': '7 derniers jours',
  'date-range.month': '30 derniers jours',
  'date-range.custom': 'Plage personnalisée',
  'payment-direction': 'La direction du paiement est {{paymentDirection}}',
  'date.from': 'La date est à partir du {{startDate}}',
  'date.between': 'La plage de dates est du {{startDate}} au {{endDate}}',
  'date.to': "La date est jusqu'au {{endDate}}",
  'date.is': 'La date est {{date}}',
  'Date-range': 'Plage de dates',
  'Start-date': 'Date de début',
  'End-date': 'Date de fin',
  'Payment-direction': 'Direction du paiement',
  Refresh: 'Actualiser',
  Export: 'Exporter',
  'Back-to-Search': 'Retour à la recherche',
  'last-updated': 'Dernière mise à jour le {{lastUpdate}} UTC',
}
