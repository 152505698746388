import { z } from 'zod'

/**
 * Single item that is part of the NewAccountSignerList which is required for the
 * PUT "/account-setups/{accountId}" API.
 * https://github.com/xpring-eng/cbdc-manager-backend/blob/main/cbdc-manager-work-items/src/main/java/io/ripplex/cbdc/workitems/model/api/NewAccountSigner.java
 */
export const newAccountSignerSchema = z.object({
  signerUserId: z.string(),
  signerKeyPairId: z.string(),
  signerWeight: z.number(),
})

export type NewAccountSigner = z.infer<typeof newAccountSignerSchema>
