import { rippleTheme, useMatchMedia } from '@ripple/design-system'

export function useBreakpoints() {
  const [isMobile, isTablet, isDesktop] = useMatchMedia([
    rippleTheme.config.media.xs, // (max-width: 767px)
    rippleTheme.config.media.sm, // (min-width: 768px)
    rippleTheme.config.media.lg, // (min-width: 1280px)
  ])

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}
