export enum WorkItemType {
  AccountSetupDisableMaster = 'ACCOUNT_SETUP_DISABLE_MASTER',
  AccountSetupFlagSet = 'ACCOUNT_SETUP_FLAG_SET',
  AccountSetupTrustSet = 'ACCOUNT_SETUP_TRUST_SET',
  AuthorizeTrustLine = 'AUTHORIZE_TRUST_LINE',
  Destroy = 'DESTROY',
  GlobalFreeze = 'GLOBAL_FREEZE',
  GlobalUnfreeze = 'GLOBAL_UNFREEZE',
  IndividualFreeze = 'INDIVIDUAL_FREEZE',
  IndividualUnfreeze = 'INDIVIDUAL_UNFREEZE',
  IndividualFreezeExternal = 'INDIVIDUAL_FREEZE_EXTERNAL',
  IndividualUnfreezeExternal = 'INDIVIDUAL_UNFREEZE_EXTERNAL',
  Mint = 'MINT',
  PayToExternal = 'PAY_TO_EXTERNAL',
  PayToOperational = 'PAY_TO_OPERATIONAL',
  PayToStandby = 'PAY_TO_STANDBY',
  SignerListSet = 'SIGNER_LIST_SET',
}

const accountFreezes = {
  internal: [WorkItemType.IndividualFreeze, WorkItemType.IndividualUnfreeze],
  external: [
    WorkItemType.IndividualFreezeExternal,
    WorkItemType.IndividualUnfreezeExternal,
  ],
  freeze: [
    WorkItemType.IndividualFreeze,
    WorkItemType.IndividualFreezeExternal,
  ],
  unfreeze: [
    WorkItemType.IndividualUnfreeze,
    WorkItemType.IndividualUnfreezeExternal,
  ],
}

const freezes = {
  account: {
    ...accountFreezes,
    all: [...accountFreezes.internal, ...accountFreezes.external],
  },
  global: [WorkItemType.GlobalFreeze, WorkItemType.GlobalUnfreeze],
}

/**
 * Source of truth for various work item type references
 */
export const workItemTypes = {
  transactions: [
    WorkItemType.Mint,
    WorkItemType.Destroy,
    WorkItemType.PayToStandby,
    WorkItemType.PayToExternal,
    WorkItemType.PayToOperational,
  ],
  freezes: {
    ...freezes,
    all: [...freezes.account.all, ...freezes.global],
  },
}
