import { IMessage, StompHeaders } from '@stomp/stompjs'
import { WS_QUEUES } from '../../constants'
import { isLocal } from '../../helpers'
import { PossibleEventTypes, WebsocketEvent } from '../../models'
import { useStomp } from './use-stomp'

type SubscriptionOptions = {
  deps?: any[]
  headers?: StompHeaders
}

type Handlers<T> = {
  onData: ({ data, type }: { data: T; type: PossibleEventTypes }) => void
  onError?: (error: Error, message: IMessage) => void
}

/**
 * Hook to interact with `WS_QUEUES` queues.
 *
 * @param queues The queues to subscribe to. It can be a string for a single queue or an array of strings for multiple queues.
 * @param handlers Handlers for onData and onError callbacks.
 * @param opts Additional options for this subscription, check '@stomp/stompjs' (https://github.com/stomp-js/stompjs) docs.
 */
export const useSubscription = <T = any>(
  queues: WS_QUEUES | WS_QUEUES[],
  handlers: Handlers<T>,
  opts: SubscriptionOptions = {},
) =>
  useStomp(
    queues,
    (message) => {
      const { onData, onError } = handlers

      try {
        const data: WebsocketEvent<T> = JSON.parse(message.body)

        if (isLocal()) {
          console.log(`[ws] Received message: ${data.type}`, data)
        }

        onData(data)
      } catch (error) {
        if (error instanceof Error) {
          onError?.(error, message)
        }

        console.error(
          `Failed to parse websocket message from queue(s): ${queues
            .toString()
            .replaceAll(',', ', ')}`,
          error,
        )
      }
    },
    opts,
  )
